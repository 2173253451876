/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'multiline_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 6.938l-3.047 3.422q1.734 2.766 2.063 6.141h-2.016q-.281-2.391-1.453-4.547L13.5 16.501 9.516 12.47l-6 6-1.5-1.5 7.5-7.5 3.984 4.031 2.859-3.281q-2.766-3.234-6.75-3.234-3.422 0-6.188 2.438L2.015 8.018q3.375-3 7.594-3 4.781 0 8.109 3.703l2.859-3.188z"/>'
  }
})
