/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'forum': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12q0 .422-.305.703t-.727.281H6l-3.984 4.031V2.999q0-.422.281-.703T3 2.015h12.984q.422 0 .727.281t.305.703v9zM21 6q.422 0 .703.281t.281.703v15L18 18H6.984q-.422 0-.703-.281T6 17.016V15h12.984V6H21z"/>'
  }
})
