<template>
  <div>
    <ConfirmModal ref="ConfirmModal" :content="confirm_content" ok_text="현재 페이지" cancel_text="모든 페이지" />
    <CRow class="justify-content-between m-4">
      <el-button class="ml-2" type="text" icon="el-icon-arrow-left" @click="$router.push('/delivery-management')">돌아가기</el-button>
      <span class="my-2" v-if="list.length">
        <strong class="h4">{{current_id+1}}</strong> / {{list.length}}
      </span>
      <span>
        <CButton class="mr-2 px-3" color="info" variant="outline" @click="onDownload">
          <svgicon name="picture_as_pdf" width="20px" height="20px" />&nbsp;다운로드
        </CButton>
        <!-- <CButton class="mr-2 px-3" color="info" @click="onPrint">
          <svgicon name="local_printshop" width="20px" height="20px" />&nbsp;출력
        </CButton> -->
      </span>
    </CRow>
    <CRow class="justify-content-center mb-4" v-if="!init_loading">
      <div class="report-container" ref="ReportContainer">
        <CCard class="p-4">
          <CCardHeader>
            <h3 class="text-center m-4">골재 반입량 부적합 보고서</h3>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="2"><strong>공장명</strong></CCol>
              <CCol sm="9" class="border-left">{{list[current_id].branch_name}}</CCol>
            </CRow>
            <CRow class="my-1 p-3">
              <div class="w-100 p-2 bg-light">
                <CImg :src="list[current_id].image_1_uri" class="w-100" />
              </div>
            </CRow>
            <CRow class="px-3">
              <div class="w-100 p-2 bg-light">
                <CImg :src="list[current_id].image_2_uri" class="w-100" />
              </div>
            </CRow>
            <CRow class="mt-4 mx-1 border">
              <CCol sm="2" class="border-bottom">
                <strong>입고시간</strong>
              </CCol>
              <CCol sm="4" class="border-left border-bottom">
                {{ transformTimestampToDate }}
              </CCol>
              <CCol sm="2" class="border-left border-bottom">
                <strong>업체명</strong>
              </CCol>
              <CCol sm="4" class="border-left border-bottom">
                {{ list[current_id].correspondent_name }}
              </CCol>
              <CCol sm="2">
                <strong>종류</strong>
              </CCol>
              <CCol sm="4" class="border-left">
                {{ list[current_id].freight_name}}
              </CCol>
              <CCol sm="2" class="border-left">
                <strong>부족량</strong>
              </CCol>
              <CCol sm="4" class="border-left">
                <span v-if="list[current_id].deficiency">{{ list[current_id].deficiency }} ㎥</span>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    </CRow>
    <CRow class="justify-content-center mb-4" v-if="pdf_all_printing">
      <div class="report-container" :id="`ReportContainerAll${index}`" v-for="(report, index) in list" :key="`printing-page-${index}`">
        <CCard class="p-4">
          <CCardHeader>
            <h3 class="text-center m-4">골재 반입량 부적합 보고서</h3>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="2"><strong>공장명</strong></CCol>
              <CCol sm="9" class="border-left">{{list[index].branch_name}}</CCol>
            </CRow>
            <CRow class="my-1 p-3">
              <div class="w-100 p-2 bg-light">
                <CImg :src="list[index].image_1_uri" class="w-100" />
              </div>
            </CRow>
            <CRow class="px-3">
              <div class="w-100 p-2 bg-light">
                <CImg :src="list[index].image_2_uri" class="w-100" />
              </div>
            </CRow>
            <CRow class="mt-4 mx-1 border">
              <CCol sm="2" class="border-bottom">
                <strong>입고시간</strong>
              </CCol>
              <CCol sm="4" class="border-left border-bottom">
                {{ transformTimestampToDateMany(index) }}
              </CCol>
              <CCol sm="2" class="border-left border-bottom">
                <strong>업체명</strong>
              </CCol>
              <CCol sm="4" class="border-left border-bottom">
                {{ list[index].correspondent_name }}
              </CCol>
              <CCol sm="2">
                <strong>종류</strong>
              </CCol>
              <CCol sm="4" class="border-left">
                {{ list[index].freight_name}}
              </CCol>
              <CCol sm="2" class="border-left">
                <strong>부족량</strong>
              </CCol>
              <CCol sm="4" class="border-left">
                <span v-if="list[index].deficiency">{{ list[index].deficiency }}㎥</span>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    </CRow>
    <FloatingBtn v-if="list.length" @click="onPrev"/>
    <FloatingBtn v-if="list.length" direction="right" @click="onNext" />
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import axios from 'axios'
import qs from 'qs'
import utility from '@/utility.js'
import FloatingBtn from '@/components/FloatingBtn.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

export default {
  name: 'DeliveryInvalidReport',

  components: {
    FloatingBtn,
    ConfirmModal
  },
  data() {
    return {
      init_loading: true,
      loading: null,
      filters: {},
      list: [],
      current_id: 0,
      now: null,
      pdf_all_printing: false,
      pdf_all_id: 0,
      confirm_content: null
    }
  },
  mounted() {
    this.getSearchFilter();
  },
  computed: {
    transformTimestampToDate(){
      return utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.list[this.current_id].created_at));
    }
  },
  methods: {
    getSearchFilter() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      axios.get(`/api/delivery/filter/`)
        .then(response => {
          this.filters.branch = response.data.branch_id;
          this.filters.correspondent = response.data.correspondent_id;
          this.filters.origin = response.data.origin;
          this.filters.freight = response.data.freight_id;
          this.filters.ts_mode = response.data.ts_mode;
          this.filters.sts = response.data.ts_sts;
          this.filters.ets = response.data.ts_ets;
          this.filters.validity = 0;
          this.getList();
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading.close();
        })
    },
    getList() {
      let max_cnt = 50;
      let query_string = '';
      let params = this.buildParam(max_cnt, 1);

      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      this.loading = this.$loading({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.list = [];
      axios.get(`/api/delivery/events/${query_string}`)
        .then(result => {
          this.list = JSON.parse(JSON.stringify(result.data.list));
          this.list.map(el => {
            el.metadata = el.meta_str ? JSON.parse(el.meta_str):null;
            const canvas_1 = document.createElement('canvas');
            const ctx_1 = canvas_1.getContext('2d');
            const img_1 = new Image;
            el.image_1_uri = null;
            img_1.onload = _=>{
              canvas_1.width = img_1.width;
              canvas_1.height = img_1.height;
              ctx_1.drawImage(img_1, 0, 0, canvas_1.width, canvas_1.height);
              el.image_1_uri = canvas_1.toDataURL('image/jpeg');
            }
            img_1.crossOrigin="anonymous";
            img_1.src = el.image_1;

            const canvas_2 = document.createElement('canvas');
            const ctx_2 = canvas_2.getContext('2d');
            const img_2 = new Image;
            el.image_2_uri = null;
            img_2.onload = _=>{
              canvas_2.width = img_2.width;
              canvas_2.height = img_2.height;
              ctx_2.drawImage(img_2, 0, 0, canvas_2.width, canvas_2.height);
              el.image_2_uri = canvas_2.toDataURL('image/jpeg');
            }
            img_2.crossOrigin="anonymous";
            img_2.src = el.metadata ? el[`image_${el.metadata.selected_image+2}`]:el.image_2;
          })
          if (result.data.page.total > 1) {
            this.$alert(
              `한 번에 처리할 수 있는 최대 보고서의 개수(${max_cnt}건)보다 많습니다.<small><br/>최근의 대상 항목 ${max_cnt}건 이전의 보고서는 제외되었습니다.</small>`,
              '확인',
              {
                confirmButtonText: '확인',
                type: 'warning',
                dangerouslyUseHTMLString: true,
                callback: _ => {
                  this.init_loading = false;
                }
              }
            );
          } else {
            this.$alert(
              `${this.list.length}건의 불량 반입량 보고서가 작성되었습니다`,
              '확인',
              {
                confirmButtonText: '확인',
                type: 'warning',
                dangerouslyUseHTMLString: true,
                callback: _ => {
                  this.init_loading = false;
                }
              }
            );
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading.close();
        })
    },
    buildParam(ipp, page) {
      let params = {
        ipp: ipp,
        page: page,
        sort: 'created_at',
        order: 'desc'
      }
      if (this.filters.branch) params.branch = this.filters.branch;
      if (this.filters.validity !== null) params.validity = this.filters.validity;
      if (this.filters.correspondent) params.correspondent = this.filters.correspondent;
      if (this.filters.origin) params.origin = this.filters.origin;
      if (this.filters.freight) params.freight = this.filters.freight;
      if (this.filters.ts_mode) {
        if (this.filters.ts_mode === 'today') {
          let now = new Date();
          now.setHours(0);
          now.setMinutes(0);
          now.setSeconds(0);
          now.setMilliseconds(0);
          params.sts = now.getTime();
        } else if (this.filters.ts_mode === 'd1') {
          let yesterday = new Date(Date.now() - (1000 * 60 * 60 * 24));
          yesterday.setHours(0);
          yesterday.setMinutes(0);
          yesterday.setSeconds(0);
          yesterday.setMilliseconds(0);
          params.sts = yesterday.getTime();
        } else if (this.filters.ts_mode === 'd2') {
          let yesterday = new Date(Date.now() - (1000 * 60 * 60 * 24 * 2));
          yesterday.setHours(0);
          yesterday.setMinutes(0);
          yesterday.setSeconds(0);
          yesterday.setMilliseconds(0);
          params.sts = yesterday.getTime();
        } else if (this.filters.ts_mode === 'h24') {
          params.sts = Date.now() - (1000 * 60 * 60 * 24);
        }
      } else if (this.filters.sts && this.filters.ets) {
        params.sts = this.filters.sts;
        params.ets = this.filters.ets;
      }
      return params;
    },

    onDownload() {
      this.confirm_content = '보고서를 PDF로 다운로드 합니다. 대상을 선택하십시오.';
      this.$refs.ConfirmModal.open(null, () => {
        this.onPDF();
      }, () => {
        this.pdf_all_printing = true;
        this.loading = this.$loading({
          lock: true,
          text: `보고서 생성 준비중...`,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(this.onPDFAll, 500);
      });
    },
    onPDF() {
      this.loading = this.$loading({
        lock: true,
        text: '보고서 생성중...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.now = new Date();
      // const container = document.createElement('div');
      const container = this.$refs.ReportContainer;
      // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.
      html2canvas(container, {imageTimeout: 5000, useCORS: true}).then(canvas => {
        // container.appendChild(canvas)
        let img = canvas.toDataURL('image/png')
        let pdf = new JsPDF('portrait', 'mm', 'a4')
        pdf.addImage(img, 'JPEG', 5, 5, 200, 287)
        let filename = utility.GetDateTimeStr(`불량보고서-${this.list[this.current_id].branch_name}-$yyyy$mm$dd-$HH$MM$ss-${this.current_id+1}.pdf`, this.now);
        pdf.save(filename);
        // container.innerHTML = ''
        this.loading.close();
      })
    },
    onPDFAll() {
      this.now = new Date();
      // const container = document.createElement('div');
      const container = document.getElementById(`ReportContainerAll${this.pdf_all_id}`);
      if (!container) {
        this.pdf_all_id = 0;
        this.pdf_all_printing = false;
        let filename = utility.GetDateTimeStr(`불량보고서-${this.list[this.current_id].branch_name}-$yyyy$mm$dd-$HH$MM$ss.pdf`, this.now);
        this.pdf.save(filename);
        this.pdf = null;
        this.loading.close();
        return;
      }
      if (this.pdf) {
        this.pdf.addPage();
      } else {
        this.pdf = new JsPDF('portrait', 'mm', 'a4')
      }
      // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.
      this.loading.text = `보고서 생성중...(${+this.pdf_all_id+1}/${this.list.length})`;
      html2canvas(container, {imageTimeout: 5000, useCORS: true}).then(canvas => {
        // container.appendChild(canvas)
        let img = canvas.toDataURL('image/png')
        this.pdf.addImage(img, 'JPEG', 5, 5, 200, 287)
        this.pdf_all_id++;
        setTimeout(this.onPDFAll, 500);
      })
    },
    onPrev() {
      this.current_id = (this.current_id - 1 + this.list.length) % this.list.length;
    },
    onNext() {
      this.current_id = (this.current_id + 1) % this.list.length;
    },
    transformTimestampToDateMany(index){
      return utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.list[index].created_at));
    }
  }
}
</script>

<style lang="scss" scoped>
.report-container {
  position: relative;
  width: 840px;
  height: 1200px;
}
</style>