/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'switch_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 15.375v-6.75L18.891 12zm-1.5 3.609L21 12l-6.984-6.984v13.969zm-4.032 0V5.015L3 11.999z"/>'
  }
})
