/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'two_wheeler': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.484 12.516q-.797 0-1.383.586t-.586 1.383.586 1.406 1.383.609 1.406-.609.609-1.406-.609-1.383-1.406-.586zm13.032-1.5q1.453 0 2.461 1.008t1.008 2.461-1.008 2.484T18.516 18t-2.484-1.031-1.031-2.484q0-1.547 1.172-2.578l-.422-.516-2.766 4.594h-3l-1.031-.938q-.234 1.266-1.219 2.109T5.485 18q-1.453 0-2.461-1.031t-1.008-2.484q0-.563.188-1.102t.492-.961.773-.75.984-.516l-.938-.797h-1.5v-.844h3.656l2.109 1.313 3.375-1.313h3.047l-1.219-1.5h-1.969V6.984h2.859l1.219 1.547 2.906-1.125v2.109h-2.109l1.313 1.734q.234-.047.445-.117t.422-.094.445-.023zm0 1.5q-.797 0-1.406.586t-.609 1.383.609 1.406 1.406.609 1.383-.609.586-1.406-.586-1.383-1.383-.586z"/>'
  }
})
