/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apartment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 15v-2.016h-1.969V15h1.969zm0 3.984v-1.969h-1.969v1.969h1.969zM15 6.984V5.015h-2.016v1.969H15zm0 4.032V9h-2.016v2.016H15zM15 15v-2.016h-2.016V15H15zm-3.984-8.016V5.015H9v1.969h2.016zm0 4.032V9H9v2.016h2.016zm0 3.984v-2.016H9V15h2.016zm-4.032-3.984V9H5.015v2.016h1.969zm0 3.984v-2.016H5.015V15h1.969zm0 3.984v-1.969H5.015v1.969h1.969zm10.032-7.968H21V21h-8.016v-3.984h-1.969V21H2.999V6.984h3.984V3h10.031v8.016z"/>'
  }
})
