/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alarm_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 3.281l-.844.703-1.453-1.406.891-.703zm8.437 15.094L6.609 8.531Q5.015 10.5 5.015 12.984q0 2.906 2.039 4.969t4.945 2.063q2.438 0 4.453-1.641zM2.906 2.297Q6.469 5.86 13.054 12.422t8.32 8.297l-1.266 1.266-2.203-2.203q-2.578 2.203-5.906 2.203-3.75 0-6.375-2.648t-2.625-6.352q0-1.359.656-3.094t1.547-2.766l-.797-.797-1.125.938-1.406-1.453 1.125-.891L1.64 3.563zm19.078 3.422l-1.266 1.547-4.594-3.891 1.266-1.5zM12 6q-1.266 0-2.391.422l-1.547-1.5q1.969-.938 3.938-.938 3.75 0 6.375 2.648T21 12.984q0 2.063-.891 3.938l-1.547-1.5q.422-1.125.422-2.438 0-2.906-2.039-4.945T12 6z"/>'
  }
})
