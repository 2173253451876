/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_cell': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 15.984v-12H8.015v12h7.969zm0-15.984q.797 0 1.406.609t.609 1.406v15.984q0 .797-.609 1.406t-1.406.609H8.015q-.797 0-1.406-.609T6 17.999V2.015q0-.797.609-1.406T8.015 0h7.969zM15 24v-2.016h2.016V24H15zm-3.984 0v-2.016h1.969V24h-1.969zm-4.032 0v-2.016H9V24H6.984z"/>'
  }
})
