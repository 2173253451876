/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi_lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 15.984v-1.5q0-.609-.445-1.055t-1.055-.445-1.055.445-.445 1.055v1.5h3zm1.032 0q.422 0 .703.305t.281.727V21q0 .422-.281.703t-.703.281H18q-.422 0-.703-.281T17.016 21v-3.984q0-.422.281-.727t.703-.305v-1.5q0-1.031.727-1.758t1.758-.727 1.781.727.75 1.758v1.5zm-2.532-6.468q-2.063 0-3.516 1.453t-1.453 3.516v2.859l-3.516 4.641-12-15.984q5.25-3.984 12-3.984t12 3.984l-2.672 3.563q-.281-.047-.844-.047z"/>'
  }
})
