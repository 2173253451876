/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_clear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 5.016h14.016v3h-5.813l-1.594 3.75L10.5 9.703l.703-1.688H8.812L5.999 5.202v-.188zm-2.719 0l.281.234L18 19.734 16.734 21l-5.672-5.672-1.547 3.656h-3l2.438-5.766L2.015 6.28z"/>'
  }
})
