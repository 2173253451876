/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'atm': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 9q.422 0 .703.281t.281.703V15h-1.5v-4.5H19.5v3.516H18V10.5h-.984V15h-1.5V9.984q0-.422.281-.703T16.5 9H21zM5.484 12v-1.5H3.515V12h1.969zM6 9q.422 0 .703.281t.281.703V15h-1.5v-1.5H3.515V15h-1.5V9.984q0-.422.281-.703T2.999 9h3zm2.016 0h6v1.5h-2.25V15h-1.5v-4.5h-2.25V9z"/>'
  }
})
