/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_florist': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 5.484q-1.031 0-1.758.75t-.727 1.781.727 1.758T12 10.5t1.758-.727.727-1.758-.727-1.781T12 5.484zm-6.422 4.782q0-1.547 1.453-2.25-1.453-.703-1.453-2.25 0-1.031.75-1.781t1.781-.75q.703 0 1.406.469v-.188q0-1.031.727-1.781T12 .985t1.758.75.727 1.781v.188q.75-.469 1.406-.469 1.031 0 1.781.75t.75 1.781q0 1.547-1.453 2.25 1.453.703 1.453 2.25 0 1.031-.75 1.758t-1.781.727q-.797 0-1.406-.422v.188q0 1.031-.727 1.758T12 15.002t-1.758-.727-.727-1.758v-.188q-.656.422-1.406.422-1.031 0-1.781-.727t-.75-1.758zM12 21.984q-3.75 0-6.375-2.648T3 12.984q3.75 0 6.375 2.648T12 21.984zm0 0q0-3.703 2.625-6.352T21 12.984q0 3.703-2.625 6.352T12 21.984z"/>'
  }
})
