/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'motion_photos_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.859 7.875l1.547 1.547Q3.984 10.688 3.984 12q0 1.641.633 3.094t1.734 2.555 2.555 1.734 3.094.633 3.094-.633 2.555-1.734 1.734-2.555.633-3.094-.633-3.094-1.734-2.555-2.555-1.734T12 3.984q-1.359 0-2.672.469l-1.5-1.547q.938-.422 1.992-.656T12 2.016q2.063 0 3.867.773t3.188 2.156 2.156 3.188.773 3.867-.773 3.867-2.156 3.188-3.188 2.156-3.867.773-3.867-.773-3.188-2.156-2.156-3.188T2.016 12q0-1.125.211-2.156t.633-1.969zM6 12q0-1.641.82-3T9 6.82 12 6t3 .82T17.18 9t.82 3-.82 3T15 17.18 12 18t-3-.82T6.82 15 6 12zm.984-6.516q0 .656-.422 1.078t-1.078.422q-.609 0-1.055-.422t-.445-1.078q0-.609.445-1.055t1.055-.445q.656 0 1.078.445t.422 1.055z"/>'
  }
})
