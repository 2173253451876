/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'schedule_send': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 21q2.063 0 3.539-1.477T21 15.984t-1.477-3.539-3.539-1.477q-2.016 0-3.492 1.453t-1.477 3.516v.094q0 2.063 1.453 3.516T15.984 21zm0-12q2.906 0 4.969 2.063t2.063 4.922-2.086 4.945-4.945 2.086q-2.391 0-4.242-1.43t-2.461-3.68L2.016 21v-6.984l9-2.016-9-2.016V3zm.516 3.516v3.234l2.25 1.5-.75 1.266-3-2.016v-3.984h1.5z"/>'
  }
})
