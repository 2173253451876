/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subtitles_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 3.984H6.844L14.813 12h5.203v2.016h-3.188l4.922 4.922q.234-.469.234-.938V6q0-.844-.586-1.43t-1.383-.586zM1.031 3.891L2.25 5.063q-.234.469-.234.938v12q0 .844.586 1.43t1.383.586h13.172l2.953 2.953 1.453-1.406L2.438 2.439zM8.016 12v2.016H3.985V12h4.031zm6 4.828V18H3.985v-2.016h9.188z"/>'
  }
})
