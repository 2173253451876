/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_wifi_4_bar_lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 14.484v2.625L12 21.515.422 6.984l.281-.234q.281-.188.586-.398t.82-.539 1.102-.609 1.336-.633 1.594-.609 1.758-.492 1.969-.352 2.133-.117 2.133.117 1.969.352 1.758.492 1.594.609 1.336.633 1.102.609.82.539.586.398l.281.234-2.063 2.625q-.281-.094-1.031-.094-2.109 0-3.539 1.43t-1.43 3.539zm6.468 1.5v-1.5q0-.609-.445-1.055t-1.055-.445-1.055.445-.445 1.055v1.5h3zm1.032 0q.375 0 .68.328t.305.703v3.984q0 .375-.305.68t-.68.305H18q-.375 0-.68-.305t-.305-.68v-3.984q0-.375.305-.703t.68-.328v-1.5q0-1.078.703-1.781T20.484 12t1.805.727.727 1.758v1.5z"/>'
  }
})
