/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'campaign': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11.016v1.969h3.984v-1.969H18zm-2.016 6.609q.75.516 1.617 1.172t1.617 1.219q.281-.422.586-.82t.586-.773q-.75-.563-1.617-1.219t-1.57-1.219q-.281.422-.586.82t-.633.82zm4.407-12.047q-.281-.375-.586-.773t-.586-.82q-.75.563-1.617 1.219t-1.617 1.219q.328.375.633.773t.586.82q.703-.563 1.57-1.219t1.617-1.219zM3.984 9q-.797 0-1.383.586t-.586 1.43v1.969q0 .844.586 1.43t1.383.586h1.031v3.984h1.969v-3.984h1.031l4.969 3v-12l-4.969 3H3.984zm11.532 3q0-.984-.422-1.852t-1.078-1.477v6.656q.656-.609 1.078-1.477t.422-1.852z"/>'
  }
})
