/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete_sweep': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 5.016v1.969h-12V5.016h3L6 3.985h3.984l1.031 1.031h3zM3 18V8.016h9.984V18q0 .797-.586 1.406t-1.383.609h-6q-.797 0-1.406-.609T3 18zm12-6h6v2.016h-6V12zm0-3.984h6.984v1.969H15V8.016zm0 7.968h3.984V18H15v-2.016z"/>'
  }
})
