/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'important_devices': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.953 9H15l-2.484 1.781.938 2.906-2.438-1.828-2.484 1.828.938-2.906L6.986 9h3.047l.984-3zm8.063-6.984q.844 0 1.406.563t.563 1.406v5.016h-1.969V3.985h-18v12H15v2.016h-2.016v2.016H15v1.969H6.984v-1.969H9v-2.016H2.016q-.844 0-1.43-.586T0 15.985v-12q0-.844.586-1.406t1.43-.563h18zm3 18v-7.031H18v7.031h5.016zm0-9q.422 0 .703.281T24 12v9q0 .422-.281.703t-.703.281H18q-.422 0-.703-.281T17.016 21v-9q0-.422.281-.703t.703-.281h5.016z"/>'
  }
})
