/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cleaning_services': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 11.016H15V3q0-.844-.586-1.43t-1.43-.586h-1.969q-.844 0-1.43.586T8.999 3v8.016h-.984q-1.406 0-2.531.656t-1.805 1.805-.68 2.508v7.031h18v-7.031q0-1.359-.68-2.508t-1.805-1.805-2.531-.656zm3 9.984h-1.969v-3q0-.422-.305-.703t-.727-.281q-.375 0-.68.281t-.305.703v3h-2.016v-3q0-.422-.281-.703t-.703-.281-.703.281-.281.703v3H8.998v-3q0-.422-.305-.703t-.68-.281q-.422 0-.727.281T6.981 18v3H5.012v-5.016q0-.797.398-1.477t1.078-1.102 1.523-.422h7.969q.844 0 1.523.422t1.078 1.102.398 1.477V21z"/>'
  }
})
