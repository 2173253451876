/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 5.016H7.828L5.109 2.297Q5.578.984 6.984.984h10.031q.797 0 1.383.609t.586 1.406v13.172l-1.969-2.016V5.014zM6.984 18.984h9.75l-9.75-9.703v9.703zm-4.218-16.5l19.219 19.219-1.266 1.266-1.734-1.734q-.094.75-.656 1.266t-1.313.516H6.985q-.797 0-1.383-.609t-.586-1.406V7.268L1.5 3.752z"/>'
  }
})
