<template>
  <CCard>
    <CCardBody class="fixed-height">
      <h5 class="text-center text-dark text-uppercase">{{header}}</h5>
      <!-- <strong class="text-uppercase text-dark">{{header}}</strong> -->
      <h1 class="font-weight-bold text-center display-4">{{text}}</h1>
      <el-divider />
      <div class="text-center">
        <slot></slot>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'CompCardSimpleNumber',
  props: {
    header: {type:String,default:''},
    text: {type:String,default:''}
  }
}
</script>

<style lang="scss" scoped>
.fixed-height {
  height: 212px;
}
</style>