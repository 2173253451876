/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contact_support': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.516 12.984q0-1.078 1.5-2.391t1.5-2.578q0-1.641-1.195-2.836t-2.836-1.195-2.813 1.195T7.5 8.015h2.016q0-.797.586-1.406T11.485 6t1.406.609.609 1.406q0 .656-.469 1.172T12 10.031t-1.031 1.102-.469 1.852h2.016zm0 3.516v-2.016H10.5V16.5h2.016zM11.484 2.016q3.516 0 6.023 2.484t2.508 6q0 3.422-2.203 6.586t-5.813 4.898v-3h-.516q-3.516 0-6-2.484t-2.484-6 2.484-6 6-2.484z"/>'
  }
})
