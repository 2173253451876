/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_rotation_none': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.484 18l-3 3v-2.016H5.015v-1.969h12.469v-2.016zM10.125 9.984h3.75L12 4.968zM12.75 3l4.734 11.016h-2.063l-.938-2.203H9.514l-.938 2.203H6.513L11.247 3h1.5z"/>'
  }
})
