/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'access_alarms': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q2.906 0 4.945-2.063t2.039-4.969-2.039-4.945T12 6 7.055 8.039t-2.039 4.945 2.039 4.969T12 20.016zm0-16.032q3.75 0 6.375 2.625T21 12.984t-2.625 6.375T12 21.984t-6.375-2.625T3 12.984t2.625-6.375T12 3.984zm.516 4.032v5.297l3.984 2.391-.797 1.219-4.688-2.906v-6h1.5zM7.922 3.422L3.281 7.219l-1.266-1.5 4.594-3.797zm14.062 2.297l-1.266 1.5-4.641-3.938 1.313-1.5z"/>'
  }
})
