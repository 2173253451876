/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linked_camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 6q.844 0 1.43.586T18 8.016h1.313q0-1.359-.984-2.344t-2.344-.984v1.313zM12 18.984q2.063 0 3.539-1.453t1.477-3.516-1.477-3.539T12 8.999t-3.539 1.477-1.477 3.539 1.477 3.516T12 18.984zM17.016 9h4.969v11.016q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383v-12q0-.797.586-1.406t1.383-.609h3.188l1.828-2.016h6v3q.844 0 1.43.586t.586 1.43zm-1.032-5.672V2.015q2.484 0 4.242 1.758t1.758 4.242h-1.313q0-1.922-1.383-3.305t-3.305-1.383zM8.813 14.016q0-1.359.938-2.297t2.25-.938 2.25.938.938 2.297-.938 2.273-2.25.914-2.25-.914-.938-2.273z"/>'
  }
})
