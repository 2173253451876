/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_individual_suite': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6.984q1.641 0 2.836 1.195t1.195 2.836v6H.984V6.984H3v7.031h8.016V6.984h7.969zm-12 6q-1.219 0-2.109-.891t-.891-2.109.891-2.109 2.109-.891 2.109.891.891 2.109-.891 2.109-2.109.891z"/>'
  }
})
