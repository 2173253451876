/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 12.984q2.906-2.859 7.008-2.859t6.961 2.859L17.016 15q-.844-.844-2.32-1.453t-2.695-.609-2.695.609T6.986 15zM9 17.016q1.266-1.266 3-1.266t3 1.266l-3 3zM.984 9q4.594-4.547 11.039-4.547T23.015 9l-2.016 2.016q-3.75-3.703-9-3.703t-9 3.703z"/>'
  }
})
