/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tablet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18V6H5.015v12h13.969zM21 3.984q.797 0 1.406.609t.609 1.406l-.047 12q0 .797-.586 1.406t-1.383.609h-18q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.406t1.406-.609h18z"/>'
  }
})
