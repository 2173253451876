/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leak_remove': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.375 11.578Q18.047 9.984 21 9.984V12q-2.203 0-4.172 1.031zm4.547 4.547l-1.594-1.594q1.266-.516 2.672-.516v1.969q-.563 0-1.078.141zM14.016 3q0 2.953-1.594 5.625l-1.453-1.453Q12 5.203 12 3h2.016zM3 4.266L4.266 3 21 19.734 19.734 21l-2.859-2.859q-.891 1.266-.891 2.859h-1.969q0-.984.422-2.227t1.031-2.039l-1.453-1.406Q11.999 17.812 11.999 21H9.983q0-1.688.773-3.773t1.852-3.352l-2.484-2.484q-3.094 2.625-7.125 2.625V12q1.359 0 3.023-.586t2.695-1.43L7.264 8.531Q5.342 9.984 2.998 9.984V8.015q1.594 0 2.859-.891zM9.984 3q0 1.406-.516 2.672L7.874 4.078q.141-.516.141-1.078h1.969z"/>'
  }
})
