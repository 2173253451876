/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sensor_window': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 3.984v16.031H6V3.984h12zm0-1.968H6q-.844 0-1.43.586t-.586 1.383v16.031q0 .797.586 1.383t1.43.586h12q.844 0 1.43-.586t.586-1.383V3.985q0-.797-.586-1.383T18 2.016zM6.984 18.984h10.031v-6H6.984v6zm3-9h4.031v1.031h3v-6H6.984v6h3V9.984z"/>'
  }
})
