/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video_call': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12.984v-1.969h-3v-3H9v3H6v1.969h3v3h2.016v-3h3zm3-2.484L21 6.516v10.969l-3.984-3.984v3.516q0 .422-.305.703t-.727.281h-12q-.422 0-.703-.281T3 17.017V6.986q0-.422.281-.703t.703-.281h12q.422 0 .727.281t.305.703v3.516z"/>'
  }
})
