/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'featured_play_list': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6.984V5.015H3v1.969h9zm0 4.032V9H3v2.016h9zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18z"/>'
  }
})
