<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <CCard>
      <CCardBody>
        <CInput
          :label="`${$t('cloud.title.organization')} ID`"
          horizontal
          :value.sync="data.target_org_id"
        />
        <small class="col-sm-9 float-right m-0 p-0">
          {{$t('cloud.title.org_id_help')}}
          <i class="ml-2 el-alert__icon el-icon-connection info-icon" @click="onOrgIDHelp" />
        </small>
      </CCardBody>
    </CCard>
    <template #header>
      <h6 class="modal-title">{{$t('button.device_transfer')}}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton @click="onSubmit" color="primary">{{$t('button.apply')}}</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'AIBridgeForm',

  data() {
    return {
      show: false,

      data: {
        device_id: null,
        target_org_id: null
      },
      notification: '',
      dismissCountDown: 0
    }
  },
  methods: {
    open(device_id) {
      this.data.device_id = device_id;
      this.data.target_org_id = null;
      this.show = true;
    },
    onCancel() {
      this.show = false;
    },
    validateAndWarning() {
      if (!this.data.target_org_id) {
        this.notification = this.$t('cloud.title.validate.org_id');
        this.dismissCountDown = 3;
        return true;
      }
      return null;
    },
    onSubmit() {
      if (this.validateAndWarning()) {
        return;
      }

      this.$emit('payload', this.data);
      this.onCancel();
    },
    onOrgIDHelp() {
      window.open('/#/setup/organization');
    }
  }
}
</script>

<style>
.info-icon {
  cursor: pointer;
  color: #ccc;
}
</style>
