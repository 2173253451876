/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12.984q1.5 0 3.281.422t3.258 1.406 1.477 2.203v3H.985v-3q0-1.219 1.477-2.203t3.258-1.406 3.281-.422zM15 12q-.609 0-1.313-.234Q15 10.219 15 8.016q0-.891-.375-2.016t-.938-1.781q.703-.234 1.313-.234 1.641 0 2.813 1.195t1.172 2.836-1.172 2.813T15 12.001zM5.016 8.016q0-1.641 1.172-2.836t2.813-1.195 2.813 1.195 1.172 2.836-1.172 2.813-2.813 1.172-2.813-1.172-1.172-2.813zm11.672 5.109q2.484.375 4.406 1.383t1.922 2.508v3h-4.031v-3q0-2.297-2.297-3.891z"/>'
  }
})
