/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_vertical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.563 20.016h10.875Q16.313 16.125 16.313 12t1.125-8.016H6.563Q7.688 7.828 7.688 12q0 4.125-1.125 8.016zm13.359 1.125q.094.188.094.281 0 .563-.656.563H4.641q-.656 0-.656-.563 0-.094.094-.281Q5.72 16.641 5.72 12T4.079 2.859q-.094-.188-.094-.281 0-.563.656-.563H19.36q.656 0 .656.563 0 .094-.094.281-1.641 4.5-1.641 9.141t1.641 9.141z"/>'
  }
})
