/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play_for_work': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 14.016h2.016q0 1.641 1.172 2.813t2.813 1.172 2.813-1.172 1.172-2.813h2.016q0 2.484-1.758 4.242t-4.242 1.758-4.242-1.758-1.758-4.242zm5.016-9h1.969v5.578h3.516l-4.5 4.5-4.5-4.5h3.516V5.016z"/>'
  }
})
