/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fingerprint': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.906 21.984h-.141q-2.156-.563-3.703-2.109-.891-.891-1.523-2.414t-.633-2.789q0-1.219.891-2.086t2.156-.867 2.18.867.914 2.086q0 .797.586 1.359t1.477.563 1.5-.563.609-1.359q0-2.813-2.133-4.828t-5.133-2.016q-2.156 0-3.938 1.102t-2.672 2.93q-.563 1.172-.563 2.813 0 1.875.656 3.609.141.469-.328.609t-.609-.281q-.75-1.922-.75-3.938 0-1.828.703-3.234.984-2.109 3.023-3.375t4.477-1.266q3.422 0 5.836 2.32t2.414 5.555q0 1.219-.914 2.063t-2.18.844-2.156-.844-.891-2.063q0-.797-.609-1.383t-1.5-.586-1.477.586-.586 1.383q0 2.625 1.875 4.5 1.313 1.313 3.281 1.828.422.047.328.609-.094.375-.469.375zm-2.484-7.312q0 1.734 1.289 3t3.211 1.266q.094 0 .422-.047t.539-.047.422.07.258.305q.094.516-.422.609-.563.094-1.219.094-1.875 0-3.094-.891-2.391-1.641-2.391-4.359 0-.516.516-.516.469 0 .469.516zM9.75 21.797q-.188 0-.328-.141-1.266-1.266-2.016-2.672-1.078-1.875-1.078-4.313 0-2.203 1.664-3.797T12 9.28t4.008 1.594 1.664 3.797q0 .469-.516.469t-.516-.469q0-1.828-1.359-3.117T12 10.265t-3.281 1.289-1.359 3.117q0 2.25.891 3.844.609 1.078 1.875 2.438.375.328 0 .703-.141.141-.375.141zM3.516 9.703Q3 9.703 3 9.234q0-.094.094-.281 1.5-2.156 3.75-3.281Q9.188 4.453 12 4.453t5.156 1.219q2.297 1.125 3.75 3.234.094.188.094.281 0 .328-.375.469t-.563-.188q-1.406-1.922-3.375-2.906-2.156-1.078-4.688-1.102T7.311 6.562Q5.202 7.64 3.889 9.515q-.141.188-.375.188zm14.297-5.25q-.188 0-.234-.047Q14.813 3 12.001 3 8.954 3 6.423 4.406q-.234.141-.469 0t-.234-.422q0-.328.234-.469 2.719-1.5 6.047-1.5 3.047 0 6.047 1.5.422.234.188.656-.188.281-.422.281z"/>'
  }
})
