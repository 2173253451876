/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'single_bed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 12v3h12v-3H6zm2.016-5.016v3h3v-3h-3zm7.968 3v-3h-3v3h3zM20.016 12v5.016h-1.359l-.656 1.969h-.984l-.656-1.969H7.689l-.703 1.969h-.984l-.656-1.969H3.987V12q0-.797.609-1.406t1.406-.609v-3q0-.797.609-1.383t1.406-.586h7.969q.797 0 1.406.586t.609 1.383v3q.797 0 1.406.609T20.016 12z"/>'
  }
})
