/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toys': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12q0 2.25-1.617 3.867t-3.867 1.617-3.891-1.617T.984 12H12zm0 0q-2.25 0-3.867-1.617T6.516 6.516t1.617-3.891T12 .984V12zm0 0q2.25 0 3.867 1.617t1.617 3.867-1.617 3.891T12 23.016V12zm0 0q0-2.25 1.617-3.867t3.867-1.617 3.891 1.617T23.016 12H12z"/>'
  }
})
