/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_split': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3.984L7.687 6.281l5.297 5.297v8.438h-1.969v-7.594L6.281 7.688 3.984 9.985v-6h6zm4.032 0h6v6l-2.297-2.297-2.906 2.906-1.406-1.406 2.906-2.906z"/>'
  }
})
