<template>
  <div>
    <CRow class="p-2">
      <CCol md="12" lg="9">
        <CCard>
          <vl-map ref="mapview" style="height:780px;" v-if="projection" v-loading="loading" @click="onClick">
            <vl-view :projection="projection" :zoom.sync="zoom" :center="center" :rotation="0" :minZoom="0.3" :maxZoom="15" @update:zoom="updateZoom"></vl-view>
            <vl-layer-image>
              <vl-source-image-static
                :url="store.floor_img_signed_url"
                :size="size"
                :extent="extent"
                :projection="projection"
                :attributions="imgCopyright"
              ></vl-source-image-static>
            </vl-layer-image>

            <!--- 전광판 -->
            <vl-feature>
              <vl-geom-polygon :coordinates="[[[0,size[1]],[240*size[0]/1280,size[1]],[240*size[0]/1280,size[1]+70*size[1]/720],[0,size[1]+70*size[1]/720],[0,size[1]]]]">
              </vl-geom-polygon>
              <vl-style-box>
                <vl-style-fill color="#333"></vl-style-fill>
                <vl-style-text :text="$t('cloud.title.occupancy').toUpperCase()" :scale="zoom*0.7" font="14px 'Orbitron', sans-serif">
                  <vl-style-fill color="#4e6"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>
            <vl-feature>
              <vl-geom-polygon :coordinates="[[[230*size[0]/1280,size[1]],[440*size[0]/1280,size[1]],[440*size[0]/1280,size[1]+70*size[1]/720],[230*size[0]/1280,size[1]+70*size[1]/720],[230*size[0]/1280,size[1]]]]">
              </vl-geom-polygon>
              <vl-style-box>
                <vl-style-fill color="#333"></vl-style-fill>
                <vl-style-text :text="String(store.occupancy_cnt)" :scale="zoom*0.7" font="14px 'Orbitron', sans-serif">
                  <vl-style-fill color="#4e6"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>

            <!--- 카메라 아이콘 -->
            <vl-feature v-for="(item, index) in store.cameras" :key="`cam-index-${index}`">
              <vl-geom-point :coordinates="[item.pos_x, item.pos_y]" />
              <vl-style-box>
                <vl-style-icon :src="item.icon_url" :anchor="[0.5, 0.5]" :scale="zoom*0.15"></vl-style-icon>
                <vl-style-text :text="item.name" :offsetY="zoom*10" :scale="zoom*0.6" v-if="item.id === camera_id">
                  <vl-style-fill :color="store.floor_label_color"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>

            <!--- 카메라 Field of View -->
            <vl-feature v-if="camera_id">
              <vl-geom-polygon :coordinates="calcFov" />
              <vl-style-box>
                <vl-style-fill color="rgba(190, 40, 60, 0.4)"></vl-style-fill>
              </vl-style-box>
            </vl-feature>

            <!--- 영역 그리기, 좌표 설정할 때 사용 -->
            <!-- <vl-layer-vector :z-index="1">
              <vl-source-vector :features.sync="features" ident="the-source"></vl-source-vector>
              <vl-style-box>
                <vl-style-stroke color="green"></vl-style-stroke>
                <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
              </vl-style-box>
            </vl-layer-vector>
            <vl-interaction-draw type="Polygon" source="the-source">
              <vl-style-box>
                <vl-style-stroke color="blue"></vl-style-stroke>
                <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
              </vl-style-box>
            </vl-interaction-draw> -->
          </vl-map>
        </CCard>
      </CCol>
      <CCol md="12" lg="3">
        <div style="height: 780px; overflow:hidden;">
          <div class="w-100 pt-2">
            <div class="h6">Camera</div>
            <el-select class="w-100 my-1 mr-2" v-model="camera_id" placeholder="Select camera" clearable @clear="onClearCamera" @change="onCamera">
              <el-option v-for="cam in store.cameras" :key="`camera-opts-${cam.id}`" :label="cam.name" :value="cam.id" />
            </el-select>
            <CCard v-if="device">
              <VideoProxyPlayer style="background-color:#eee;" :url="url" :ch="ch" :username="device.auth_id" :password="device.auth_pw" />
              <div style="background-color:#eee;">
                <CRow class="justify-content-between">
                  <div class="text-primary mx-4 my-2 px-2">
                    <b>Camera #{{ch}}</b>
                  </div>
                  <div class="mx-4 my-2 px-2">
                    <b class="text-success">Online</b>
                    <i class="el-icon-loading ml-2"></i>
                  </div>
                </CRow>
              </div>
            </CCard>
          </div>
          <div class="w-100 mt-1">
            <div class="h6">Events</div>
            <div v-if="history.length > 0">
              <CWidgetIcon
                v-for="(event, index) in history" :key="`event-history-${index}`"
                :header="event.event_type"
                :text="`${event.label} - ${event.time}`"
                :color="`gradient-${event.color}`"
                :icon-padding="false"
              >
                <CIcon :name="event.icon" width="24"/>
              </CWidgetIcon>
            </div>
            <div v-else>
              <div class="text-secondary">No data</div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import * as olExt from 'vuelayers/lib/ol-ext';
import VideoProxyPlayer from "@/components/VideoProxyPlayer.vue";
import { mapState } from 'vuex';

let host;
const proto = location.protocol.indexOf('https') < 0 ? 'ws' : 'wss';
const debug = process.env.NODE_ENV !== 'production';
if (debug) {
  host = 'dev.edgedx.ai:8000';
} else {
  // relesase (demo)
  host = location.host;
}

export default {
  name: 'RetailFloorMap',

  components: {
    VideoProxyPlayer
  },
  props: {
    store: {type:Object},
    is_selected: {type:Boolean,default:false}
  },
  data() {
    return {
      loading: false,
      zoom: 2.0,
      rotation: 0,
      projection: null,
      imgCopyright: '© <a href="https://edgedx.ai">EdgeDX Corp.</a>',

      ws: null,
      refresh_timer: null,

      camera_id: null,

      device: null,
      url: null,
      ch: null,
      history: [],

      extent: null,
      center: null,
      size: null,

      // 그림 그릴때 좌표 들어가는 변수
      features: []
    }
  },
  watch: {
    is_selected() {
      if (this.is_selected) {
        console.log('initialize watch');
        // this.$refs.mapview.refresh();
        this.initialize();
      } else {
        this.finalize();
      }
    }
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    calcFov() {
      let camera = this.store.cameras.find(el => el.id === this.camera_id);
      return [JSON.parse(camera.fov)];
    }
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.finalize();
  },
  methods: {
    initialize() {
      this.history = [];
      this.extent = [0, 0, this.store.floor_img_w, this.store.floor_img_h];
      this.center = [this.store.floor_img_w/2, this.store.floor_img_h/2];
      this.size = [this.store.floor_img_w, this.store.floor_img_h];
      let customProj = olExt.createProj({
        code: 'xkcd-image',
        units: 'pixels',
        extent: this.extent
      });
      // add it to the list of known projections
      olExt.addProj(customProj);
      this.projection = customProj.getCode();
      this.wsOpen();
    },
    finalize() {
      this.device = null;
      this.url = null;
      this.ch = null;
      this.wsClose();
    },
    wsOpen() {
      console.log('웹소켓 생성, 이벤트 리스너 추가');
      let csrfToken = Cookie.get('csrftoken');
      this.ws = new WebSocket(`${proto}://${host}/ws/retail/map/?token=${csrfToken}&store=${this.store.id}&user=${this.capability.user_profile.id}`);
      this.ws.addEventListener('message', this.onWSMessage);
      this.ws.addEventListener('close', this.wsClose);
    },
    wsClose() {
      if (this.ws) {
        console.log('웹소켓 제거, 이벤트 리스너 제거');
        this.ws.removeEventListener('message', this.onWSMessage);
        this.ws.removeEventListener('close', this.wsClose);
        this.ws.close();
        this.ws = null;
      }
    },
    onWSMessage(e) {
      let payload = JSON.parse(e.data);
      if (payload.section) {
        this.ch = payload.section.ch;
        this.url = payload.section.url;
        this.device = payload.section.device;
      }
    },
    updateZoom() {
      // console.log('update zoom', this.zoom);
      // console.log(this.features);
      // console.log(this.zoom);
      // this.$refs.mapview.render();
    },
    onCamera() {
      if (!this.camera_id) return;
      let section_id = this.store.cameras.find(el => el.id === this.camera_id).section_id;
      if (!section_id) return;
      let cmd_section = {
        command: 'request_section',
        section_id: section_id
      }
      this.ws.send(JSON.stringify(cmd_section));
      // axios.get(`/api/parking/places/${this.space.place_id}/?snapshot=1&section=${section_id}`)
      //   .then(response => {
      //     let sections = JSON.parse(JSON.stringify(response.data));
      //     if (sections.length !== 1) {
      //       this.$notify.warning({
      //         title: 'Warning',
      //         message: 'Snapshot query failed',
      //         offset: 30
      //       });
      //       return;
      //     }
      //     this.snapshot_image = 'data:image/jpeg;base64,' + sections[0].image;
      //     this.snapshot_ch = sections[0].ch;
      //     this.snapshot_section_name = sections[0].section_name;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.snapshot_image = null;
      //     this.snapshot_ch = null;
      //     this.snapshot_section_name = null;
      //   })
    },
    onClearCamera() {
      this.camera_id = null;
      this.device = null;
      this.url = null;
      this.ch = null;
    },
    onClick(e) {
      console.log(e);
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500&display=swap');
</style>