/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto_fix_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.016.984L20.485 2.39 18.001.984l1.406 2.531-1.406 2.484 2.484-1.406 2.531 1.406-1.406-2.484zm-8.344 6.235l2.109 2.109-2.438 2.438.797.844 2.578-2.578q.281-.281.281-.703t-.281-.703l-2.344-2.344q-.328-.281-.727-.281t-.68.281L11.389 8.86l.797.797 2.484-2.438zm-.797 6.656L3.281 3.281 2.015 4.547l6.844 6.844-6.563 6.563q-.281.281-.281.703t.281.703l2.344 2.344q.281.281.703.281t.703-.281l6.563-6.563 6.844 6.844 1.266-1.266-6.844-6.844z"/>'
  }
})
