/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'approval': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.016l3-4.031q0-1.219-.891-2.109T12 3.985t-2.109.891T9 6.985zm0-9q2.063 0 3.539 1.453t1.477 3.516L12 14.016h6q.797 0 1.406.586t.609 1.383v6H3.984v-6q0-.797.609-1.383t1.406-.586h6L6.983 6.985q0-2.063 1.477-3.516t3.539-1.453zM18 18v-2.016H6V18h12z"/>'
  }
})
