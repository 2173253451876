/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sms_failed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 9.984V6h-1.969v3.984h1.969zm0 4.032V12h-1.969v2.016h1.969zm7.032-12q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18H6l-3.984 3.984v-18q0-.797.586-1.383t1.383-.586h16.031z"/>'
  }
})
