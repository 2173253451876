/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'done_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.781 2.203L24 6.422 8.437 21.985l-8.438-8.438 4.219-4.219 4.219 4.219zm0 2.813L8.437 16.36l-4.219-4.172-1.406 1.359 5.625 5.625 12.75-12.75z"/>'
  }
})
