/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_textdirection_r_to_l': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 17.016h12v1.969h-12v3l-4.031-3.984 4.031-3.984v3zm1.968-7.032q-1.641 0-2.813-1.172T5.999 5.999t1.172-2.813 2.813-1.172H18v1.969h-2.016v11.016h-1.969V3.983h-2.016v11.016H9.983V9.983z"/>'
  }
})
