/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'domain_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18.984h3.984l-1.969-1.969h-2.016v1.969zM9.984 15v-2.016H8.015V15h1.969zm0 3.984v-1.969H8.015v1.969h1.969zM6 11.016V9H3.984v2.016H6zM6 15v-2.016H3.984V15H6zm0 3.984v-1.969H3.984v1.969H6zM1.313 1.781l21 20.906L21 24l-3-3H2.016V5.016L.094 3.094zm14.671 9.235H18v1.969h-2.016v-1.969zm-7.968-6v.891L5.11 3.001h6.891v3.984h9.984v12.938l-1.969-2.016V9.001H12v.891L9.094 6.986h.891V5.017H8.016z"/>'
  }
})
