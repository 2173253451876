<template>
  <div>
    <CCard>
      <CCardHeader v-if="showTitle">
        <CIcon name="cil-justify-center" /><strong> {{ title }} </strong><small>{{smallTitle}}</small>
        <CDropdown
          v-if="addBtn"
          size="sm"
          :toggler-text="addBtnText"
          color="primary"
          class="m-0 d-inline-block float-right"
        >
          <CDropdownItem @click="OnAdd">Add {{title.replace(/hes$/, 'h').replace(/s$/, '')}}</CDropdownItem>
        </CDropdown>
      </CCardHeader>
      <CCardBody>
        <CListGroup>
          <CListGroupItem href="#" class="flex-column align-items-start" v-for="item in items" :key="`list-group-${item.id}`">
            <div class="d-flex w-100 justify-content-start">
              <div class="d-sm-down-none">
                <img :src="item.url" width="320px" height="180px" style="object-fit:cover;" />
              </div>
              <div class="flex-grow-1 mx-3">
                <div class="my-2 d-md-none">
                  <img :src="item.url" width="100%" height="180px" style="object-fit:contain;" />
                </div>
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">{{ item.name }}</h5>
                  <small>{{ item.duration }}</small>
                </div>
                <div>
                  <p class="mb-1">
                    {{ item.address }}
                  </p>
                </div>
                <div v-for="(index, l) in item.logic" :key="`list-group-logic-${item.id}-${index}`">
                  <small>{{ item.logic[l] }}</small>
                </div>
              </div>
            </div>
          </CListGroupItem>
        </CListGroup>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  props: {
    title: {type: String, default: 'List'},
    smallTitle: {type: String, default: ''},
    showTitle: {type: Boolean, default: true},
    addBtn: {type: Boolean, default: false},
    addBtnText: {type: String, default: 'Add'},
    items: {type:Array, default:[]},
  },
  methods: {
    OnAdd() {
      this.$emit('add');
    }
  }
}
</script>