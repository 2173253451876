/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 12v3h3v2.016h-3v3h-1.969v-3h-3V15h3v-3h1.969zM3.891 12q0 1.266.914 2.18t2.18.914h4.031v1.922H6.985q-2.063 0-3.516-1.477T2.016 12t1.453-3.539 3.516-1.477h4.031v1.922H6.985q-1.266 0-2.18.914T3.891 12zm16.218 0q0-1.266-.914-2.18t-2.18-.914h-4.031V6.984h4.031q2.063 0 3.516 1.477T21.984 12h-1.875zm-12.093-.984h7.969v1.969H8.016v-1.969z"/>'
  }
})
