/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video_library': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 14.484l6-4.5-6-4.5v9zm8.016-12.468q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18h-12q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586h12zM3.984 6v14.016H18v1.969H3.984q-.797 0-1.383-.586t-.586-1.383V6h1.969z"/>'
  }
})
