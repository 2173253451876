/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'swap_horizontal_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 17.484V15h3.984v-2.016H9V10.5l-3.516 3.516zm6-10.968V9h-3.984v2.016H15V13.5l3.516-3.516zM21.984 12q0 4.125-2.93 7.055t-7.055 2.93-7.055-2.93T2.014 12t2.93-7.055 7.055-2.93 7.055 2.93T21.984 12z"/>'
  }
})
