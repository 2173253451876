/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_pin_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 14.016q1.031 0 2.203-.633t1.781-1.523q0-.891-1.383-1.477t-2.602-.586-2.602.586-1.383 1.477q1.406 2.156 3.984 2.156zm0-10.032q-.844 0-1.43.586T9.984 6t.586 1.43 1.43.586 1.43-.586.586-1.43-.586-1.43T12 3.984zm0-1.968q2.906 0 4.945 2.039T18.984 9q0 1.453-.727 3.328t-1.758 3.516-2.039 3.07-1.711 2.273l-.75.797q-.281-.328-.75-.867t-1.688-2.156-2.133-3.141-1.664-3.445T5.014 9q0-2.906 2.039-4.945t4.945-2.039z"/>'
  }
})
