/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'developer_mode': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 18.984v-1.969h1.969v3.984q0 .797-.586 1.406t-1.383.609H6.985q-.797 0-1.383-.609t-.586-1.406v-3.984h1.969v1.969h10.031zm-7.032-3.796l-1.406 1.406L3.984 12l4.594-4.594 1.406 1.406L6.843 12zm5.438 1.406l-1.406-1.406L17.157 12l-3.141-3.188 1.406-1.406L20.016 12zM6.984 5.016v1.969H5.015V3.001q0-.797.586-1.406T6.984.986l10.031.047q.797 0 1.383.586t.586 1.383v3.984h-1.969V5.017H6.984z"/>'
  }
})
