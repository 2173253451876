/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'looks_3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 10.5V9q0-.844-.563-1.43t-1.406-.586H9V9h4.031v2.016h-2.016v1.969h2.016v2.016H9v2.016h4.031q.844 0 1.406-.586t.563-1.43v-1.5q0-.656-.422-1.078t-1.078-.422q.656 0 1.078-.422T15 10.501zM19.031 3q.797 0 1.383.609T21 5.015v13.969q0 .797-.586 1.406t-1.383.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h14.016z"/>'
  }
})
