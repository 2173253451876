/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_open': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15.609l-1.406 1.406-5.016-5.016 5.016-5.016L21 8.389l-3.563 3.609zM3 6h12.984v2.016H3V6zm0 6.984v-1.969h9.984v1.969H3zM3 18v-2.016h12.984V18H3z"/>'
  }
})
