/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play_arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 5.016L18.985 12 8.016 18.984V5.015z"/>'
  }
})
