/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_back_ios': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.672 3.891L3.563 12l8.109 8.109-1.781 1.781L0 11.999l9.891-9.891z"/>'
  }
})
