/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_backup_restore': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3q3.75 0 6.375 2.625T21 12t-2.625 6.375T12 21q-1.266 0-2.859-.539t-2.625-1.336l1.406-1.406Q9.703 18.985 12 18.985q2.906 0 4.945-2.039t2.039-4.945-2.039-4.945T12 5.017 7.055 7.056t-2.039 4.945h3l-4.031 3.984-3.984-3.984h3q0-3.75 2.625-6.375t6.375-2.625zm2.016 9q0 .797-.609 1.406t-1.406.609-1.406-.609T9.986 12t.609-1.406 1.406-.609 1.406.609.609 1.406z"/>'
  }
})
