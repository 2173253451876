/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'queue_music': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 6h4.969v2.016h-3v9q0 1.219-.891 2.109t-2.109.891-2.109-.891-.891-2.109.891-2.109 2.109-.891q.375 0 1.031.188V6.001zM3 15.984v-1.969h8.016v1.969H3zm12-6V12H3V9.984h12zM15 6v2.016H3V6h12z"/>'
  }
})
