/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_bar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9h-1.547q.281-.422.422-.938T18 6.984q0-1.078-.539-1.992t-1.453-1.453T14.016 3q-.469 0-.984.141-.609-.516-1.383-.82t-1.664-.305q-1.406 0-2.555.75T5.719 4.735q-1.172.375-1.945 1.406t-.773 2.344q0 1.406.844 2.461t2.156 1.43v8.625h11.016v-2.016h1.969q.844 0 1.43-.586t.586-1.383v-6q0-.844-.586-1.43T18.986 9zm-12 1.5q-.797 0-1.383-.586t-.586-1.43q0-.609.375-1.125t.984-.75l.797-.281.375-.75q.328-.703.984-1.125t1.453-.422q.609 0 1.055.211t.68.445l.797.609.422-.141 1.031-.141q.844 0 1.43.586t.586 1.383h-3q-.984 0-1.688.352t-1.242.891-.984 1.055-.938.867-1.148.352zm12 6.516h-1.969v-6h1.969v6z"/>'
  }
})
