/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit_road': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 3.984h-2.016v7.922L18 9.89V3.984zm-14.016 0H6v16.031H3.984V3.984zm6 0H12v4.031H9.984V3.984zm0 6H12v4.031H9.984V9.984zm0 6H12v4.031H9.984v-4.031zm12.563-3.375l-1.125-1.172q-.469-.422-1.078-.422t-1.031.422l-5.297 5.297v3.281h3.234l5.297-5.297q.469-.469.469-1.078t-.469-1.031zm-5.953 5.86h-1.031v-1.031l3.422-3.469L20.016 15z"/>'
  }
})
