/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'enhanced_encryption': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 15.984v-1.969h-3v-3h-1.969v3h-3v1.969h3v3h1.969v-3h3zM8.906 6v2.016h6.188V6q0-1.266-.914-2.18T12 2.906t-2.18.914T8.906 6zM18 8.016q.797 0 1.406.586t.609 1.383v10.031q0 .797-.609 1.383T18 21.985H6q-.797 0-1.406-.586t-.609-1.383V9.985q0-.797.609-1.383T6 8.016h.984V6q0-2.063 1.477-3.539T12 .984t3.539 1.477T17.016 6v2.016H18z"/>'
  }
})
