/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wb_shade': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 14.016V9.985H6.984v4.031H9zM8.016 3.984l6 6H12v10.031H3.984V9.984H2.015zm6 16.032v-3l3 3h-3zm0-8.016l7.969 8.016h-2.484l-5.484-5.531v-2.484z"/>'
  }
})
