/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 14.016V9.985H9.985v4.031h4.031zm6-4.032h-4.031v4.031h4.031v1.969h-4.031v4.031h-1.969v-4.031H9.985v4.031H8.016v-4.031H3.985v-1.969h4.031V9.984H3.985V8.015h4.031V3.984h1.969v4.031h4.031V3.984h1.969v4.031h4.031v1.969z"/>'
  }
})
