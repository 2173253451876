/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bluetooth_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 18.188l1.875-1.875-1.875-1.922v3.797zM5.391 3.984l14.625 14.625-1.406 1.406-2.297-2.297L12 21.984h-.984V14.39l-4.594 4.594-1.406-1.406L10.594 12 3.985 5.391zm7.593 1.829v3.234l-1.969-2.016V2.015h.984l5.719 5.672-3.047 3.047-1.406-1.406 1.594-1.641z"/>'
  }
})
