/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zoom_out_map': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15v6h-6l2.297-2.297-2.906-2.859 1.453-1.453 2.859 2.906zM9 21H3v-6l2.297 2.297 2.859-2.906 1.453 1.453-2.906 2.859zM3 9V3h6L6.703 5.297l2.906 2.859-1.453 1.453-2.859-2.906zm12-6h6v6l-2.297-2.297-2.859 2.906-1.453-1.453 2.906-2.859z"/>'
  }
})
