/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rotate_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.875 15.469q.797-1.078 1.031-2.484h2.016q-.281 2.156-1.594 3.891zm-3.891 2.437q1.406-.234 2.484-1.031l1.453 1.453q-1.688 1.313-3.938 1.594v-2.016zm6.938-6.89h-2.016q-.234-1.406-1.031-2.484l1.453-1.406q1.359 1.688 1.594 3.891zm-4.359-5.485l-4.547 4.453V6.093Q8.86 6.468 7.43 8.132T6 11.999t1.43 3.867 3.586 2.039v2.016q-2.953-.375-4.992-2.625t-2.039-5.297 2.039-5.297 4.992-2.625V.983z"/>'
  }
})
