/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'control_point_duplicate': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 18.984q2.906 0 4.945-2.039T21.984 12t-2.039-4.945T15 5.016t-4.945 2.039T8.016 12t2.039 4.945T15 18.984zM15 3q3.75 0 6.375 2.625T24 12t-2.625 6.375T15 21t-6.375-2.625T6 12t2.625-6.375T15 3zM2.016 12q0 1.828 1.172 3.703t2.813 2.625v2.156q-2.625-.938-4.313-3.281T0 12t1.688-5.203 4.313-3.281v2.156q-1.828.844-2.906 2.555T2.017 12zm13.968-3.984v3h3v1.969h-3v3h-1.969v-3h-3v-1.969h3v-3h1.969z"/>'
  }
})
