/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sync_problem': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 12.984v-6h1.969v6h-1.969zm9.984-9l-2.344 2.391Q21 8.719 21 12q0 2.766-1.688 4.922t-4.313 2.813v-2.063q1.641-.609 2.813-2.273t1.172-3.398q0-1.031-.492-2.25T17.25 7.782L15 9.985v-6h6zm-9.984 13.032V15h1.969v2.016h-1.969zM3 12q0-2.766 1.688-4.922t4.313-2.813v2.063q-1.641.609-2.813 2.273t-1.172 3.398q0 1.031.492 2.25t1.242 1.969L9 14.015v6H3l2.344-2.391Q3 15.28 3 11.999z"/>'
  }
})
