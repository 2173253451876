/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'near_me': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3l-7.547 18h-.984l-2.625-6.844L3 11.531v-.984z"/>'
  }
})
