/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'layers_clear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.281.984l18.703 18.75L20.718 21l-3.75-3.797-4.969 3.844-9-6.984 1.641-1.266 7.359 5.766 3.516-2.766-1.406-1.406L12 15.985l-3.82-2.977Q4.782 10.359 3 9l3.234-2.531L2.015 2.25zM21 9q-1.266.984-4.031 3.141L9.094 4.266 12 2.016zm-1.172 6l-1.453-1.453 1.172-.891L21 14.062z"/>'
  }
})
