/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'timer_3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.531 10.453q-.609 0-1.031.281t-.422.797v.047q0 .563.938.891.328.141.844.234t1.359.375q.375.141 1.031.563.328.234.609.75.234.469.234.984 0 1.734-2.063 2.438-.563.188-1.453.188-2.625 0-3.422-1.828-.234-.563-.234-1.031h1.875q0 .609.539 1.008t1.289.398q1.547 0 1.547-1.078 0-.281-.141-.492t-.281-.281-.469-.211q-.234-.094-.984-.281-1.313-.281-2.25-.891-.844-.563-.844-1.688 0-1.781 1.969-2.438Q16.734 9 17.53 9q1.641 0 2.578.797.938.75.938 1.969h-1.969q0-.938-.891-1.172-.563-.141-.656-.141zm-7.453 1.406q1.969.656 1.969 2.719 0 1.734-1.125 2.578-1.078.938-2.813.938t-2.836-.891-1.102-2.484h2.016q0 .938.516 1.313.609.469 1.406.469 1.969 0 1.969-1.922t-2.156-1.922H6.703V11.11h1.172q1.5 0 1.922-1.078.094-.234.094-.75 0-1.781-1.781-1.781-1.219 0-1.688.938-.141.281-.141.703H4.312q0-2.109 2.25-3 .797-.234 1.547-.234 1.781 0 2.766.844t.984 2.531-1.781 2.578z"/>'
  }
})
