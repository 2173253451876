/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video_settings': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 6h18v5.016h2.016V6q0-.844-.586-1.43T21 3.984H3q-.844 0-1.43.586T.984 6v12q0 .844.586 1.43t1.43.586h9V18H3V6zm12 6L9 8.016v7.969zm7.688 6.422q.094-.469.047-.844l1.078-.844q.141-.141.047-.328l-1.031-1.781q-.094-.188-.328-.141L21.235 15q-.328-.234-.75-.422l-.188-1.359q-.047-.234-.281-.234h-2.063q-.188 0-.234.234l-.188 1.359q-.234.094-.422.188t-.328.234l-1.313-.516q-.188-.094-.281.094l-1.031 1.828q-.094.188.047.281l1.078.891q-.047.469 0 .844l-1.078.844q-.188.141-.047.328l1.031 1.781q.094.188.281.141L16.734 21q.375.234.75.422l.234 1.359q0 .188.234.188h2.063q.234 0 .234-.188l.234-1.359.375-.188.328-.234 1.313.516q.188.047.281-.141l1.031-1.781q.141-.188-.047-.328zM18.984 19.5q-.609 0-1.055-.445T17.484 18t.445-1.055 1.055-.445q.656 0 1.078.445T20.484 18t-.422 1.055-1.078.445z"/>'
  }
})
