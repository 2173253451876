/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_run': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.891 19.359L2.907 18l.375-2.016 4.922.984 1.594-8.109-1.828.703v3.422H6.001V8.296l5.203-2.203q.141 0 .398-.047T12 5.999q.984 0 1.688.984l.984 1.594q1.359 2.391 4.313 2.391v2.016q-3.328 0-5.484-2.484l-.609 3 2.109 1.969v7.5h-2.016v-6L10.876 15zM13.5 5.484q-.797 0-1.406-.609t-.609-1.406.609-1.383T13.5 1.5t1.383.586.586 1.383-.586 1.406-1.383.609z"/>'
  }
})
