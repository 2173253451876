/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 13.031v-2.016h-3v-3h-1.969v3h-3v2.016h3v3h1.969v-3h3zm2.907 3.797L21 18.937v1.078H3v-1.078l2.109-2.109v-5.813q0-2.25 1.547-4.219t3.75-2.484v-.703q0-.656.469-1.125T12 2.015t1.125.469.469 1.125v.703q2.297.516 3.797 2.414t1.5 4.289v5.813zM10.031 21h3.938q0 .797-.586 1.406T12 23.015t-1.383-.609T10.031 21z"/>'
  }
})
