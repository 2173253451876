/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'control_camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12q0-1.219.891-2.109T12 9t2.109.891T15 12t-.891 2.109T12 15t-2.109-.891T9 12zM5.531 8.438l1.781 1.781L5.531 12l1.781 1.781-1.781 1.781-3.516-3.563zm2.907 10.031l1.781-1.781L12 18.469l1.781-1.781 1.781 1.781-3.563 3.516zm10.031-2.906l-1.781-1.781 1.781-1.781-1.781-1.781 1.781-1.781 3.516 3.563zM15.563 5.531l-1.781 1.781-1.781-1.781-1.781 1.781-1.781-1.781 3.563-3.516z"/>'
  }
})
