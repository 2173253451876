/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contact_mail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12V6h-7.969v6h7.969zm-7.968 6v-.984q0-1.359-2.063-2.227t-3.938-.867-3.938.867-2.063 2.227V18h12zm-6-12q-1.219 0-2.109.891T5.016 9t.891 2.109T8.016 12t2.109-.891T11.016 9t-.891-2.109T8.016 6zm13.968-3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H2.015q-.797 0-1.406-.609T0 18.984V5.015q0-.797.609-1.406T2.015 3h19.969zM21 8.016l-3 1.969-3-1.969V6.985l3 2.016 3-2.016v1.031z"/>'
  }
})
