/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment_bank': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 2.016H3.985q-.797 0-1.383.586t-.586 1.383v18L6 18.001h14.016q.797 0 1.383-.586t.586-1.43v-12q0-.797-.586-1.383t-1.383-.586zm-1.032 10.968l-2.484-1.5-2.484 1.5V5.015h4.969v7.969z"/>'
  }
})
