/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'move_to_inbox': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 9.984L12 14.015 8.016 9.984h1.969v-3h4.031v3h1.969zm3 5.016V5.016H4.968V15h4.031q0 1.219.891 2.109t2.109.891 2.109-.891.891-2.109h3.984zm0-12q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H4.968q-.844 0-1.406-.586t-.563-1.43V5.014q0-.844.563-1.43t1.406-.586h14.016z"/>'
  }
})
