/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifications_none': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 17.016v-6q0-1.922-1.078-3.211T12 6.516 9.094 7.805t-1.078 3.211v6h7.969zM18 15.984L20.016 18v.984H3.985V18l2.016-2.016v-4.969q0-2.344 1.195-4.078t3.305-2.25v-.703q0-.609.422-1.055t1.078-.445 1.078.445.422 1.055v.703q2.109.516 3.305 2.25t1.195 4.078v4.969zm-6 6q-.797 0-1.406-.586t-.609-1.383h4.031q0 .797-.609 1.383t-1.406.586z"/>'
  }
})
