<template>
  <CardContainer :title="titleCompose" v-if="stats"
    v-loading="loading"
    element-loading-text="로딩중..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <template slot="view_menu" v-if="false">
      <CButton color="secondary" size="sm" class="d-inline-block float-right ml-2">
        <svgicon name="settings" width="16px" height="16px" />
      </CButton>
    </template>
    <template slot="chart">
      <CChartLine
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import CardContainer from '@/containers/StatisticsCard.vue'
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'MonthlyAmount',
  components: {
    CardContainer,
    CChartLine
  },
  props: {
    stats: {type: Array, default: () => { return []}},
    loading: {type: Boolean, default: false}
  },
  computed: {
    titleCompose() {
      return `연간 부족입고량 추이`
    },
    chartData() {
      // *** COLOR Palette
      //  #f3a935  (243,169,53)  warning
      //  #c73558  (199,53,88)   danger
      //  #6ebe9f  (110,190,159) success
      //  #2586a4  (37,134,164)  primary
      //  #55596a  (85,89,106)   secondary
      return [
        {
          label: '부족입고량',
          data: this.stats.map(el => el.insufficient),
          borderColor: '#c73558',
          fill: false,
          tension: 0.4
        }
      ]
    },
    chartLabel() {
      return this.stats.map((el, index) => {
        let t = new Date(el.date);
        if (index === 0 || t.getMonth() === 0) return `${t.getFullYear()}-${1+t.getMonth()}월`;
        return `${1+t.getMonth()}월`;
      });
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: '㎥'
            },
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        }
      };
    }
  }
}
</script>
