/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'queue_play_next': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 18l-4.5 4.5L18 21l3-3-3-3 1.5-1.5zM12.984 9.984h3V12h-3v3h-1.969v-3h-3V9.984h3v-3h1.969v3zM21 3q.844 0 1.43.586t.586 1.43v7.969H21V5.016H3v12h15v1.969h-2.016v2.016H8.015v-2.016H2.999q-.844 0-1.43-.563t-.586-1.406v-12q0-.844.586-1.43T2.999 3h18z"/>'
  }
})
