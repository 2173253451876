/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_parking': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.219 11.016q.797 0 1.383-.609t.586-1.406-.586-1.406-1.383-.609H9.985v4.031h3.234zM12.984 3q2.484 0 4.242 1.758T18.984 9t-1.758 4.242T12.984 15h-3v6H6V3h6.984z"/>'
  }
})
