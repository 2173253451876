/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_add_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 9.984h.891L6 9.093v.891zm5.391 4.5L8.907 12H6.001v3H3.985v-3h-3V9.984h3V7.078L.001 3.094 1.22 1.781l21 20.906L20.907 24l-3.984-3.984H6.985V18q0-1.172 1.266-2.086t3.141-1.43zm11.625 5.532h-.938l-6-5.906q2.438.141 4.688 1.219t2.25 2.672v2.016zm-12-12q0-1.641 1.172-2.836t2.813-1.195 2.813 1.195 1.172 2.836-1.172 2.813-2.813 1.172-2.813-1.172-1.172-2.813z"/>'
  }
})
