/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cast_for_education': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3H3q-.844 0-1.43.586t-.586 1.43v3H3v-3h18v13.969h-6.984v2.016H21q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T21 3zM.984 18v3h3q0-.844-.398-1.523t-1.078-1.078-1.523-.398zm0-3.984v1.969q1.406 0 2.531.68t1.805 1.828.68 2.508h2.016q0-1.453-.563-2.719T5.93 16.055t-2.227-1.5-2.719-.539zm0-4.032V12q1.875 0 3.516.703t2.859 1.945 1.922 2.859.703 3.492H12q0-2.297-.844-4.289t-2.367-3.492-3.516-2.367-4.289-.867zm10.032 1.125v1.969L14.485 15l3.516-1.922v-1.969l-3.516 1.875zM14.484 6L9 9l5.484 3 5.531-3z"/>'
  }
})
