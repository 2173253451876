/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'block_flipped': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12q0-2.063-.773-3.867t-2.156-3.188-3.188-2.156T12 2.016t-3.867.773-3.188 2.156-2.156 3.188T2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867zM5.719 7.078l11.203 11.203Q14.906 20.015 12 20.015q-1.641 0-3.094-.633t-2.555-1.734-1.734-2.555-.633-3.094q0-2.906 1.734-4.922zM20.016 12q0 2.906-1.734 4.922L7.079 5.719q2.016-1.734 4.922-1.734 1.641 0 3.094.633t2.555 1.734 1.734 2.555.633 3.094z"/>'
  }
})
