/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'west': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 18.984l1.406-1.406-4.594-4.594h16.172v-1.969H5.812l4.594-4.594L9 5.015l-6.984 6.984z"/>'
  }
})
