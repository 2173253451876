/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_moderator': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 20.016v2.953h-1.969v-2.953h-3V18h3v-3h1.969v3h3v2.016h-3zm-5.765 2.578q-.094.047-.539.211t-.68.211q-3.844-.938-6.422-4.383T3 11.016v-6l9-4.031 9 4.031v6q0 1.266-.281 2.625-1.219-.656-2.719-.656-2.484 0-4.242 1.758T12 18.985q0 2.016 1.219 3.609z"/>'
  }
})
