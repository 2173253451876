/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'markunread_mailbox': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 6q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383v-12q0-.797.586-1.406T3.985 6h2.016V0h8.016v3.984h-6V12h1.969V6h10.031z"/>'
  }
})
