<template>
  <div>
    <CRow class="p-2">
      <CCol md="12" lg="9">
        <CCard>
          <vl-map ref="mapview" style="height:780px;" v-if="projection" v-loading="loading" @click="onClick">
            <vl-view :projection="projection" :zoom.sync="zoom" :center="space.center" :rotation="0" :minZoom="0.3" :maxZoom="15" @update:zoom="updateZoom"></vl-view>
            <vl-layer-image>
              <vl-source-image-static
                :url="space.floor_img_signed_url"
                :size="space.size"
                :extent="space.extent"
                :projection="projection"
                :attributions="imgCopyright"
              ></vl-source-image-static>
            </vl-layer-image>

            <!--- 전광판 -->
            <vl-feature>
              <vl-geom-polygon :coordinates="[[[0,space.size[1]],[170*space.size[0]/1280,space.size[1]],[170*space.size[0]/1280,space.size[1]+70*space.size[1]/720],[0,space.size[1]+70*space.size[1]/720],[0,space.size[1]]]]">
              </vl-geom-polygon>
              <vl-style-box>
                <vl-style-fill color="#333"></vl-style-fill>
                <vl-style-text :text="`FREE`" :scale="zoom" font="14px 'Orbitron', sans-serif">
                  <vl-style-fill color="green"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>
            <vl-feature>
              <vl-geom-polygon :coordinates="[[[160*space.size[0]/1280,space.size[1]],[370*space.size[0]/1280,space.size[1]],[370*space.size[0]/1280,space.size[1]+70*space.size[1]/720],[160*space.size[0]/1280,space.size[1]+70*space.size[1]/720],[160*space.size[0]/1280,space.size[1]]]]">
              </vl-geom-polygon>
              <vl-style-box>
                <vl-style-fill color="#333"></vl-style-fill>
                <vl-style-text :text="calcFree" :scale="zoom" font="14px 'Orbitron', sans-serif">
                  <vl-style-fill color="green"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>

            <!--- 주차면 동그라미 -->
            <vl-feature v-for="(item, index) in spaces" :key="`status-index-${index}`">
              <vl-geom-point :coordinates="[item.center_x, item.center_y]" />
              <vl-style-box>
                <vl-style-circle :radius="zoom * item.radius * 0.8 / (space.size[0] / 1280)">
                  <vl-style-fill :color="item.last_state.state === 'enter' ? '#e55353':'#2eb85c'"></vl-style-fill>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>

            <!--- 주차면 최근 이벤트 강조 -->
            <vl-feature v-for="(item, index) in recent_updated" :key="`recent-index-${index}`">
              <vl-geom-point :coordinates="item.coordinates" />
              <vl-style-box>
                <vl-style-circle :radius="zoom * item.radius * 0.6 / (space.size[0] / 1280)">
                  <vl-style-fill :color="item.renderCount % 2 === 0 ? (item.state === 'enter' ? 'rgba(229,83,83, 0.7)':'rgba(46,184,134, 0.7)'):'rgba(0,0,0,0)'"></vl-style-fill>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>

            <!--- 주차면 상태 유지 기간 라벨 -->
            <vl-feature v-for="(item, index) in spaces" :key="`duration-index-${index}`">
              <vl-geom-point :coordinates="[item.center_x, item.center_y]" />
              <vl-style-box>
                <vl-style-text
                  :text="item.last_state && calcDuration(item.last_state.from)"
                  :offsetY="zoom*15 / (space.size[0] / 1280)"
                  :scale="zoom*0.6 / Math.min(space.size[0] / 1280, 1.5)"
                >
                  <vl-style-fill :color="place.floor_label_color"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>

            <!--- 주차면 이름 -->
            <vl-feature v-for="(item, index) in spaces" :key="`label-index-${index}`">
              <vl-geom-point :coordinates="[item.center_x, item.center_y]" />
              <vl-style-box>
                <vl-style-text
                  :text="item.label"
                  :offsetY="zoom*(-12) / (space.size[0] / 1280)"
                  :scale="zoom*0.5 / Math.min(space.size[0] / 1280, 1.5)"
                >
                  <vl-style-fill :color="place.floor_label_color"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>

            <!--- 카메라 아이콘 -->
            <vl-feature v-for="(item, index) in fovs" :key="`cam-index-${index}`">
              <vl-geom-point :coordinates="[item.pos_x, item.pos_y]" />
              <vl-style-box>
                <vl-style-icon src="/img/cam-icon2.png" :anchor="[0.5, 0.5]" :scale="zoom*0.15"></vl-style-icon>
                <vl-style-text :text="item.label" :offsetY="zoom*10" :scale="zoom*0.6">
                  <vl-style-fill :color="place.floor_label_color"></vl-style-fill>
                </vl-style-text>
              </vl-style-box>
            </vl-feature>

            <!--- 카메라 Field of View -->
            <vl-feature v-if="fov">
              <vl-geom-polygon :coordinates="calcFov" />
              <vl-style-box>
                <vl-style-fill color="rgba(190, 40, 60, 0.4)"></vl-style-fill>
              </vl-style-box>
            </vl-feature>

            <!--- 영역 그리기, 좌표 설정할 때 사용 -->
            <!-- <vl-layer-vector :z-index="1">
              <vl-source-vector :features.sync="features" ident="the-source"></vl-source-vector>
              <vl-style-box>
                <vl-style-stroke color="green"></vl-style-stroke>
                <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
              </vl-style-box>
            </vl-layer-vector>
            <vl-interaction-draw type="Polygon" source="the-source">
              <vl-style-box>
                <vl-style-stroke color="blue"></vl-style-stroke>
                <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
              </vl-style-box>
            </vl-interaction-draw> -->
          </vl-map>
        </CCard>
      </CCol>
      <CCol md="12" lg="3">
        <div style="height: 780px; overflow:hidden;">
          <div class="w-100 pt-2">
            <div class="h6">Camera</div>
            <el-select class="w-100 my-1 mr-2" v-model="fov" placeholder="Select camera" clearable @clear="onClearFov" @change="onFov">
              <el-option v-for="fov in fovs" :key="`fov-opts-${fov.id}`" :label="fov.label" :value="fov.id" />
            </el-select>
            <CCard v-if="device">
              <VideoProxyPlayer style="background-color:#eee;" :url="url" :ch="ch" :username="device.auth_id" :password="device.auth_pw" />
              <div style="background-color:#eee;">
                <CRow class="justify-content-between">
                  <div class="text-primary mx-4 my-2 px-2">
                    <b>Camera #{{ch}}</b>
                  </div>
                  <div class="mx-4 my-2 px-2">
                    <b class="text-success">Online</b>
                    <i class="el-icon-loading ml-2"></i>
                  </div>
                </CRow>
              </div>
            </CCard>
            <!-- <CCard v-if="snapshot_image">
              <CCardImg class="custom-img-cover" variant="top" :src="snapshot_image" />
              <div style="background-color:#eee;">
                <CRow class="justify-content-between">
                  <div class="text-primary mx-4 my-2 px-2">
                    <b>Camera #{{snapshot_ch}}</b>
                  </div>
                  <div class="mx-4 my-2 px-2">
                    <b class="text-success">Online</b>
                    <i class="el-icon-loading ml-2"></i>
                    <small class="text-info ml-1">5s</small>
                  </div>
                </CRow>
                <div class="text-dark mx-2 mb-2 px-2 pb-2">
                  Last Updated: {{ last_updated || "-" }}
                </div>
              </div>
            </CCard> -->
          </div>
          <div class="w-100 mt-1">
            <div class="h6">Events</div>
            <div v-if="history.length > 0">
              <CWidgetIcon
                v-for="(event, index) in history" :key="`event-history-${index}`"
                :header="event.event_type"
                :text="`${event.label} - ${event.time}`"
                :color="`gradient-${event.color}`"
                :icon-padding="false"
              >
                <CIcon :name="event.icon" width="24"/>
              </CWidgetIcon>
            </div>
            <div v-else>
              <div class="text-secondary">No data</div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import axios from 'axios';
import * as olExt from 'vuelayers/lib/ol-ext';
import VideoProxyPlayer from "@/components/VideoProxyPlayer.vue";
import { mapState } from 'vuex';

let host;
const proto = location.protocol.indexOf('https') < 0 ? 'ws' : 'wss';
const debug = process.env.NODE_ENV !== 'production';
if (debug) {
  host = 'dev.edgedx.ai:8000';
} else {
  // relesase (demo)
  host = location.host;
}

export default {
  name: 'ParkingFloorMap',

  components: {
    VideoProxyPlayer
  },
  props: {
    place: {type:Object},
    space: {type:Object},
    is_selected: {type:Boolean,default:false}
  },
  watch: {
    is_selected() {
      if (this.is_selected) {
        // this.$refs.mapview.refresh();
        this.initialize();
        this.updateFeature();
      } else {
        this.onWSClose();
      }
    }
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    calcFov() {
      let selFov = this.fovs.find(el => el.id === this.fov);
      return [selFov.fov];
    },
    calcFree() {
      let free = this.spaces.filter(el => el.last_state.state === 'exit');
      let free_cnt = free.length;
      return `${free_cnt}`;
    }
  },
  data() {
    return {
      loading: false,
      zoom: 2,
      rotation: 0,
      projection: null,
      imgCopyright: '© <a href="https://edgedx.ai">EdgeDX Corp.</a>',

      spaces: [],
      ws: null,
      refresh_timer: null,

      fovs: [],
      fov: null,
      snapshot_image: null,
      snapshot_ch: null,
      snapshot_section_name: null,

      history: [],
      last_updated: null,

      recent_updated: [],

      device: null,
      url: null,
      ch: null,

      // 그림 그릴때 좌표 들어가는 변수
      features: []
    }
  },
  mounted() {
    let customProj = olExt.createProj({
      code: 'xkcd-image',
      units: 'pixels',
      extent: this.space.extent,
    });
    // add it to the list of known projections
    olExt.addProj(customProj);
    this.projection = customProj.getCode();
    this.updateFeature();
  },
  beforeDestroy() {
    this.onWSClose();
  },
  methods: {
    initialize() {
      this.fov = null;
      this.snapshot_image = null;
      this.snapshot_ch = null;
      this.snapshot_section_name = null;
      this.history = [];
    },
    updateFeature() {
      if (!this.space.place_id) return;
      this.loading = true;
      axios.get(`/api/parking/camera-fovs/?place=${this.space.place_id}`)
        .then(response => {
          let dup = JSON.parse(JSON.stringify(response.data.list));
          this.fovs = dup.map(el => {
            el.fov = JSON.parse(el.fov);
            return el;
          });
        })

      axios.get(`/api/parking/spaces/?place=${this.space.place_id}`)
        .then(response => {
          this.spaces = JSON.parse(JSON.stringify(response.data.list));
          this.spaces.forEach(el => {
            el.geometry = JSON.parse(el.geometry);
          });
          // this.$refs.mapview.refresh();

          console.log('웹소켓 생성, 이벤트 리스너 추가');
          let csrfToken = Cookie.get('csrftoken');
          this.ws = new WebSocket(`${proto}://${host}/ws/occupancy/map/?token=${csrfToken}&place=${this.space.place_id}&user=${this.capability.user_profile.id}`);
          this.ws.addEventListener('message', this.onWSMessage);
          this.ws.addEventListener('close', this.onWSClose);
          console.log('Refresh 타이머 추가');
          this.refreshTimer();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(_ => {
          this.loading = false;
        })
    },
    updateZoom() {
      // console.log('update zoom', this.zoom);
      // console.log(this.features)
      // console.log(this.zoom)
      // this.$refs.mapview.render();
    },
    // updateCenter() {
    //   console.log('update center', this.space.center);
    // },
    calcDuration(from) {
      let d = new Date(from.replace(/\s/g, 'T'));
      d = d.getTime();
      let diff = Date.now() - d;
      let diff_sec = parseInt(diff / 1000);
      let sec = diff_sec % 60;
      let min = parseInt(diff_sec / 60) % 60;
      let hour = parseInt(diff_sec / 3600);
      if (hour <= 0 && min <= 0) return `${sec}s`;
      else if (hour <= 0) return `${min}m`;
      else if (hour < 24) {
        if (hour < 10) hour = '0'+hour;
        if (min < 10) min = '0'+min;
        return `${hour}:${min}`;
      }
      return `${parseInt(hour/24)}d`;
    },
    onWSMessage(e) {
      const status = {
        enter: 'Occupied',
        exit: 'Free'
      }
      const colors = {
        enter: 'danger',
        exit: 'success'
      }
      const icons = {
        enter: 'cil-arrow-thick-to-bottom',
        exit: 'cil-arrow-thick-from-bottom'
      }
      let payload = JSON.parse(e.data);
      if (payload.events) {
        for (let data of payload.events) {
          data.geometry = JSON.parse(data.geometry);
          const index = this.spaces.findIndex(el => el.id === data.id);
          this.spaces[index] = data;

          let from = new Date(data.last_state.from.replace(/\s/g, 'T'));
          if (Date.now() - from.getTime() < 20*1000) {
            let ts = from.getTime();
            from = this.$utility.GetDateTimeStr("$day, $dd $month $yyyy $HH:$MM:$ss", from);
            // history 에서 중복 체크
            if (this.history.find(el => el.label === data.label && el.time === from)) continue;

            this.history.push({
              event_type: status[data.last_state.state],
              label: data.label,
              time: from,
              ts: ts,
              color: colors[data.last_state.state],
              icon: icons[data.last_state.state],
            })
            this.history.sort((a,b) => {
              if (a.ts - b.ts > 0) return (-1);
              else return (1);
            })
            if (this.history.length >= 7) {
              this.history.splice(7);
            }

            let existing = this.recent_updated.find(el => el.label === data.label);
            if (existing) {
              existing.renderCount = 0;
              existing.state = data.last_state.state;
            } else {
              this.recent_updated.push({
                state: data.last_state.state,
                label: data.label,
                time: Date.now(),
                coordinates: [data.center_x, data.center_y],
                renderCount: 0,
                radius: this.spaces[index].radius * 3,
              });
            }
          }
        }
        this.spaces = JSON.parse(JSON.stringify(this.spaces));
      }
      // if (payload.section) {
      //   let section = payload.section;
      //   this.snapshot_image = 'data:image/jpeg;base64,' + section.image;
      //   this.snapshot_ch = section.ch;
      //   this.snapshot_section_name = section.section_name;
      //   let now = new Date();
      //   this.last_updated = this.$utility.GetDateTimeStr("$day, $dd $month $yyyy $HH:$MM:$ss", now);
      // }
      if (payload.section) {
        this.ch = payload.section.ch;
        this.url = payload.section.url;
        this.device = payload.section.device;
      }
      if (!this.fov) {
        this.snapshot_image = null;
        this.snapshot_ch = null;
        this.snapshot_section_name = null;
      }
    },
    onWSClose() {
      if (this.ws) {
        console.log('웹소켓 제거, 이벤트 리스너 제거');
        this.ws.removeEventListener('message', this.onWSMessage);
        this.ws.removeEventListener('close', this.onWSClose);
        this.ws.close();
        this.ws = null;
      }
      if (this.refresh_timer) {
        console.log('Refresh 타이머 제거');
        clearTimeout(this.refresh_timer);
        this.refresh_timer = null;
      }
    },
    refreshTimer() {
      // console.log('refresh', Date.now());
      if (this.ws) {
        this.spaces = JSON.parse(JSON.stringify(this.spaces));
        this.recent_updated.map(el => {
          el.renderCount++;
        });
        this.recent_updated = this.recent_updated.filter(el => el.renderCount < 10);
        this.recent_updated = JSON.parse(JSON.stringify(this.recent_updated));
        // console.log(this.recent_updated);
        this.$refs.mapview.render();
      }
      this.refresh_timer = setTimeout(this.refreshTimer, 600);
    },

    onFov() {
      if (!this.fov) return;
      let section_id = this.fovs.find(el => el.id === this.fov).section_id;
      let cmd_fov = {
        command: 'request_section',
        place_id: this.space.place_id,
        section_id: section_id
      }
      this.ws.send(JSON.stringify(cmd_fov));
      // axios.get(`/api/parking/places/${this.space.place_id}/?snapshot=1&section=${section_id}`)
      //   .then(response => {
      //     let sections = JSON.parse(JSON.stringify(response.data));
      //     if (sections.length !== 1) {
      //       this.$notify.warning({
      //         title: 'Warning',
      //         message: 'Snapshot query failed',
      //         offset: 30
      //       });
      //       return;
      //     }
      //     this.snapshot_image = 'data:image/jpeg;base64,' + sections[0].image;
      //     this.snapshot_ch = sections[0].ch;
      //     this.snapshot_section_name = sections[0].section_name;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.snapshot_image = null;
      //     this.snapshot_ch = null;
      //     this.snapshot_section_name = null;
      //   })
    },
    onClearFov() {
      this.fov = null;
      this.snapshot_image = null;
      this.snapshot_ch = null;
      this.snapshot_section_name = null;
      let cmd_clear = {
        command: 'clear_section'
      }
      this.ws.send(JSON.stringify(cmd_clear));
    },


    onClick(e) {
      console.log(e);
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500&display=swap');
</style>