/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 9v6h3L12 18.984 8.016 15h3V9h-3L12 5.016 15.984 9h-3zm-9-6.984h16.031v1.969H3.984V2.016zm0 18h16.031v1.969H3.984v-1.969z"/>'
  }
})
