/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'all_inbox': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 15.984h6v3q0 .797-.609 1.406t-1.406.609H5.016q-.797 0-1.406-.609t-.609-1.406v-3h6q0 1.219.891 2.109t2.109.891 2.109-.891.891-2.109zM18.984 9V5.016H5.015V9h3.984q0 1.219.891 2.109t2.109.891 2.109-.891T14.999 9h3.984zm0-6q.797 0 1.406.609t.609 1.406v6.984q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 11.999V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
