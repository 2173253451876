/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exposure_plus_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 6.984v4.031H12v1.969H8.016v4.031H6v-4.031H2.016v-1.969H6V6.984h2.016zm8.015 9.329h5.953v1.688h-8.625v-1.5l4.172-4.547q.844-.844 1.453-1.875.375-.609.375-1.313 0-.75-.516-1.453-.469-.609-1.406-.609-.984 0-1.641.656-.516.516-.516 1.641h-2.156q0-1.688 1.125-2.813.656-.656 1.359-.891.844-.281 1.875-.281h.047q2.766 0 3.703 2.063.281.609.281 1.453 0 1.125-.75 2.344-.563.938-.797 1.172-.656.75-1.078 1.172z"/>'
  }
})
