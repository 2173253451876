/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mark_as_unread': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 11.672V9.984L13.5 13.312 6.984 9.984v1.688L13.5 15zm0-3.656q.797 0 1.383.586t.586 1.383v9q0 .797-.586 1.406T20.016 21H6.985q-.797 0-1.383-.609t-.586-1.406v-9q0-.797.586-1.383t1.383-.586h13.031zm-1.172-1.032h-2.625l-5.719-3-6.516 3.422v9.609q-.797 0-1.383-.609T2.015 15V7.172q0-.984.797-1.359L10.5 2.016l7.547 3.797q.703.422.797 1.172z"/>'
  }
})
