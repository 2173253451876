/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apps': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 20.016v-4.031h4.031v4.031h-4.031zm0-6V9.985h4.031v4.031h-4.031zm-6-6V3.985h4.031v4.031H9.984zm6-4.032h4.031v4.031h-4.031V3.984zm-6 10.032V9.985h4.031v4.031H9.984zm-6 0V9.985h4.031v4.031H3.984zm0 6v-4.031h4.031v4.031H3.984zm6 0v-4.031h4.031v4.031H9.984zm-6-12V3.985h4.031v4.031H3.984z"/>'
  }
})
