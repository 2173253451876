/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip_to_back': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 17.016V15h2.016v2.016H15zm0-12V3h2.016v2.016H15zM5.016 6.984v12h12V21h-12q-.844 0-1.43-.586T3 18.984v-12h2.016zm13.968 10.032V15H21q0 .797-.609 1.406t-1.406.609zm0-8.016V6.984H21V9h-2.016zm0 3.984v-1.969H21v1.969h-2.016zM9 17.016q-.844 0-1.43-.586T6.984 15H9v2.016zM12.984 3v2.016h-1.969V3h1.969zm6 0q.797 0 1.406.609t.609 1.406h-2.016V2.999zm-6 12v2.016h-1.969V15h1.969zM9 3v2.016H6.984q0-.844.586-1.43T9 3zm0 8.016v1.969H6.984v-1.969H9zm0-4.032V9H6.984V6.984H9z"/>'
  }
})
