<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="size"
    color="primary"
  >
    <!-- <VideoWebRTCPlayer :url="url" :request_id="request_id" @playlist="onPlaylist" /> -->
    <VideoProxyPlayer v-if="device" :url="url" :ch="ch" :username="device.auth_id" :password="device.auth_pw" filled-screen />
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="show = false" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="onClose" color="primary">
        Close
      </CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios'
// import VideoWebRTCPlayer from '@/components/VideoWebRTCPlayer.vue'
import VideoProxyPlayer from '@/components/VideoProxyPlayer.vue'

export default {
  name: 'BulkResultModal',
  // components: { VideoWebRTCPlayer },
  components: { VideoProxyPlayer },
  props: {
    title: { type: String, default: 'Live video' },
    size: { type: String, default: 'xl' }
  },
  data() {
    return {
      show: false,
      device_id: null,
      ch: null,
      url: null,
      // request_id: null
      device: null
    }
  },
  methods: {
    open(params) {
      this.show = true;
      this.device_id = params.device_id;
      this.ch = params.ch;
      // axios.get(`/api/devices/${this.device_id}/webrtc/path/`)
      //   .then(result => {
      //     this.url = result.data.url;
      //   })
      //   .catch(e => {
      //     console.error(e);
      //     this.url = null;
      //     this.ch = null;
      //   });
      axios.get(`/api/devices/${this.device_id}/`)
        .then(result => {
          this.device = result.data;
          axios.get(`/api/devices/${this.device_id}/stream/`)
            .then(result => {
              this.url = result.data.url;
            })
        })
    },
    onClose() {
      this.show = false;
      this.device_id = null;
      this.ch = null;
      this.url = null;
      this.device = null;
      // this.request_id = null;
    },
    // onPlaylist(list) {
    //   let item = list.find(el => el.description === `ch${this.ch}`);
    //   this.request_id = item.id;
    // }
  }
}
</script>