/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_size_select_actual': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 17.016h13.969l-4.5-6-3.469 4.5-2.531-3zM21 3q.75 0 1.383.633t.633 1.383v13.969q0 .75-.633 1.383T21 21.001H3q-.797 0-1.406-.609t-.609-1.406V5.017q0-.75.633-1.383t1.383-.633h18z"/>'
  }
})
