<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <input type="file" ref="fileInput" accept="image/jpeg,image/png" @change="handleImage" hidden/>
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <div v-if="step === 0">
      <SearchList v-if="store_updated.branch === null"
        :ph="$t('cloud.title.ph_select_branch')"
        :list="branch_list"
        :pageTotal="pageTotalBranch"
        @search="onSearchBranch"
        @focus="onFocusBranch"
        @select="onSelectBranch"
        @page="onPageBranch"
      />
      <CCard v-else>
        <CCardBody>
          <div class="my-2">{{$t('cloud.title.branch')}}</div>
          <CWidgetIcon :header="store_updated.branch_name" :text="(branch.addr1||'') + ' ' + (branch.addr2||'')" color="info">
            <CIcon name="cil-location-pin" width="24" />
          </CWidgetIcon>
          <CButton style="float: right;" color="danger" shape="pill" @click="onReset">{{$t('button.reset')}}</CButton>
        </CCardBody>
      </CCard>
    </div>
    <CCard v-if="step === 1">
      <CCardBody>
        <CInput
          type="number"
          :label="$t('cloud.title.capacity_person')"
          horizontal
          v-model="store_updated.capacity"
        />
        <CInput
          :label="$t('cloud.title.note')"
          horizontal
          v-model="store_updated.note"
        />
      </CCardBody>
    </CCard>
    <CCard v-if="step === 2">
      <CCardHeader v-if="image_uri">
        <img :src="image_uri" style="object-fit: contain; width: 100%;">
      </CCardHeader>
      <CCardBody>
        <el-form label-width="25%" @submit.native.prevent>
          <el-form-item label="Image">
            <div class="mb-1">
              <el-button type="primary" size="small" @click="onUpload">{{$t('button.select')}}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </CCardBody>
    </CCard>
    <template #header>
      <h6 class="modal-title">{{title}}  {{` - ${$t('cloud.title.step_n_t', {n:(step+1),t:3})}`}}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step===0" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===0" @click="onNext" color="info">{{$t('button.next')}}</CButton>
      <CButton v-if="step===1" @click="onPrev" color="secondary">{{$t('button.prev')}}</CButton>
      <CButton v-if="step===1" @click="onNext" color="info">{{$t('button.next')}}</CButton>
      <CButton v-if="step===2" @click="onPrev" color="secondary">{{$t('button.prev')}}</CButton>
      <CButton v-if="step===2" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue'

export default {
  name: 'StoreForm',
  components: {
    SearchList
  },
  data() {
    return {
      show: false,

      store: null,
      store_updated: {
        branch: null,
        branch_name: null,
        capacity: 0,
        note: null,
        image: null
      },
      title: null,
      image_uri: null,

      branch: null,
      branch_list: [],
      searchTextBranch: null,
      pageTotalBranch: 1,
      pageCurrentBranch: 1,

      notification: '',
      dismissCountDown: 0,

      step: 0
    }
  },
  methods: {
    open(param) {
      this.initialize(param);
      this.show = true;
    },
    initialize(param) {
      this.onCancel();
      this.title = param.title;
      this.store = param.store;
      if (this.store) {
        this.store_updated = JSON.parse(JSON.stringify(this.store));
      } else {
        this.store_updated = {
          branch: null,
          branch_name: null,
          capacity: 0,
          note: null,
          image: null
        }
      }
    },
    onCancel() {
      this.show = false;

      this.store = null;
      this.store_updated = {
        branch: null,
        branch_name: null,
        capacity: 0,
        note: null,
        image: null
      }
      this.title = null;
      this.branch = null;
      this.image_uri = null;
      this.step = 0;
      this.$refs.fileInput.value = null;
    },

    getBranch(){
      let query_string = '';
      const params = {
        search: this.searchTextBranch,
        page: this.pageCurrentBranch
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          this.pageTotalBranch = result.data.page.total;
          this.pageCurrentBranch = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onSearchBranch: function(text) {
      this.searchTextBranch = text;
      this.getBranch();
    },
    onFocusBranch: function() {
      this.getBranch();
    },
    onSelectBranch: function(id) {
      this.branch = this.branch_list.find(el=>el.id===id);
      this.store_updated.branch = this.branch.id;
      this.store_updated.branch_name = this.branch.name;
    },
    onPageBranch: function(p) {
      this.pageCurrentBranch = p;
      this.getBranch();
    },
    onNext() {
      if (this.step === 0) {
        if (!this.store_updated.branch) {
          this.notification = this.$t('cloud.title.warn_required_branch');
          this.dismissCountDown = 3;
          return;
        }
      }
      if (this.step === 1) {
        if (this.warningWrong()) {
          return;
        }
      }
      this.step++;
    },
    onPrev() {
      this.step--;
    },
    onReset() {
      this.store_updated.branch = null;
      this.store_updated.branch_name = null;
      this.pageCurrentBranch = 1;
      this.searchTextBranch = null;
      this.branch_list = [];
    },
    warningWrong() {
      if (!this.store_updated.capacity) {
        this.notification = this.$t('cloud.title.warn_required_capacity');
        this.dismissCountDown = 3;
        return true;
      }
    },
    onSubmit() {
      let blob = null;
      if (this.image_uri) {
        blob = this.$utility.DataURI2Blob(this.image_uri);
      }
      const fd = new FormData;
      fd.append("branch", this.store_updated.branch);
      fd.append("capacity", this.store_updated.capacity);
      fd.append("note", this.store_updated.note);
      if (blob) {
        fd.append("image", blob, "image.jpg");
      }
      this.$emit('payload', fd, this.store_updated.id);
      this.onCancel();
    },

    handleImage(e) {
      if (e.target.files.length === 0) {
        return;
      }
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const reader = new FileReader;
      const img = new Image;

      reader.onload = event => {
        this.image_uri = event.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);
    },
    onUpload() {
      this.$refs.fileInput.click();
    }
  }
}
</script>
