/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outlet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zM9 12q-.422 0-.703-.305t-.281-.68v-3q0-.422.281-.727T9 6.983t.703.305.281.727v3q0 .375-.281.68T9 12zm5.016 6H9.985v-2.016q0-.797.586-1.383t1.43-.586 1.43.586.586 1.383V18zm1.968-6.984q0 .375-.281.68t-.703.305-.703-.305-.281-.68v-3q0-.422.281-.727T15 6.984t.703.305.281.727v3z"/>'
  }
})
