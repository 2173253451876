/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'electric_bike': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6.984h-.797l-1.688-4.688q-.234-.563-.75-.938T14.577.983h-2.578v2.016h2.578l1.5 3.984h-4.828l-.375-.984h1.125V3.983H6.983v2.016h1.781l1.781 5.016h-.656q-.188-1.125-.867-2.016T7.381 7.593 5.248 7.03q-1.406-.094-2.602.586T.724 9.444t-.727 2.555.656 2.531 1.805 1.805 2.555.68q1.219 0 2.25-.516t1.711-1.43.914-2.086h4.219q.188 1.125.867 2.016t1.641 1.406 2.133.563q1.406.094 2.602-.586t1.922-1.828.727-2.555-.656-2.531-1.805-1.805-2.555-.68zm-11.156 6q-.328.891-1.078 1.453T5.016 15q-.844 0-1.523-.398t-1.078-1.078-.398-1.523.398-1.523T3.493 9.4t1.523-.398q.984 0 1.734.563t1.078 1.453H5.015v1.969h2.813zm6.281-1.968h-1.406L11.953 9H15q-.328.422-.563.938t-.328 1.078zM18.984 15q-.844 0-1.523-.398t-1.078-1.078-.398-1.523q0-.703.281-1.289t.797-1.008l.938 2.672 1.875-.703-.938-2.672h.047q.844 0 1.523.398t1.078 1.078.398 1.523-.398 1.523-1.078 1.078-1.523.398zm-7.968 5.016H6.985l6 3V21h4.031l-6-3v2.016z"/>'
  }
})
