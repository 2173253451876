/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'g': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12.984h1.969V15h-4.969V9H21q0-.844-.586-1.43t-1.43-.586h-4.969q-.844 0-1.43.586T11.999 9v6q0 .844.586 1.43t1.43.586h4.969q.844 0 1.43-.586T21 15v-3.984h-3.984v1.969zM3 12.984h5.016V15H3v2.016h5.016q.797 0 1.383-.586T9.985 15v-2.016q0-.797-.586-1.383t-1.383-.586h-3V8.999h4.969V6.983H3.001v6z"/>'
  }
})
