/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'batch_prediction': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 8.016H6.985q-.797 0-1.383.586t-.586 1.383v10.031q0 .797.586 1.383t1.383.586h10.031q.797 0 1.383-.586t.586-1.383V9.985q0-.797-.586-1.383t-1.383-.586zm-4.032 12.468h-1.969v-1.5h1.969v1.5zm0-2.484h-1.969q0-.563-.375-1.125t-.891-1.172-.891-1.289-.375-1.43q0-.938.492-1.734t1.266-1.266 1.758-.469 1.758.469 1.266 1.266.492 1.734q0 .75-.375 1.43t-.891 1.289-.891 1.172T12.983 18zM18 6.516H6q0-.656.445-1.078T7.5 5.016h9q.609 0 1.055.422T18 6.516zm-.984-3H6.985q0-.656.445-1.078t1.055-.422h7.031q.609 0 1.055.422t.445 1.078z"/>'
  }
})
