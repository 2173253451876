/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_align_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3h18v2.016H3V3zm0 18v-2.016h18V21H3zm0-8.016v-1.969h18v1.969H3zm12-6V9H3V6.984h12zM15 15v2.016H3V15h12z"/>'
  }
})
