/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'category': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 13.5h8.016v8.016H3V13.5zm9.984 3.984q0-1.875 1.313-3.188t3.188-1.313 3.188 1.313 1.313 3.188-1.313 3.188-3.188 1.313-3.188-1.313-1.313-3.188zM12 2.016l5.484 9H6.515z"/>'
  }
})
