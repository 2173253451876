/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'east': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 5.016l-1.406 1.406 4.594 4.594H2.016v1.969h16.172l-4.594 4.594L15 18.985l6.984-6.984z"/>'
  }
})
