/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pie_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.031 13.031h8.953q-.328 3.563-2.859 6.094t-6.094 2.859v-8.953zm0-11.015q3.563.328 6.094 2.859t2.859 6.094h-8.953V2.016zm-2.015 0v19.969q-3.797-.375-6.398-3.234t-2.602-6.75 2.602-6.75 6.398-3.234z"/>'
  }
})
