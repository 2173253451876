/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'south_west': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 18.984v-1.969H8.391L20.016 5.39 18.61 3.984 6.985 15.609V9H5.016v9.984H15z"/>'
  }
})
