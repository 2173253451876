/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'inbox': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 15V5.016H4.968V15h4.031q0 1.219.891 2.109t2.109.891 2.109-.891.891-2.109h3.984zm0-12q.844 0 1.43.586T21 5.016v13.969q0 .797-.609 1.406T18.985 21H4.969q-.844 0-1.406-.586T3 18.984V5.015q0-.844.563-1.43t1.406-.586h14.016z"/>'
  }
})
