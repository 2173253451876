/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 8.016V6H6v2.016h12zm-3.984 6V12H6v2.016h8.016zM6 9v2.016h12V9H6zm14.016-6.984q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18H6l-3.984 3.984v-18q0-.797.586-1.383t1.383-.586h16.031z"/>'
  }
})
