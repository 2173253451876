/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'switch_video': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 15.516L16.5 12l-3.516-3.516v2.531h-6V8.484L3.515 12l3.469 3.516v-2.531h6v2.531zm5.016-6l3.984-4.031v13.031L18 14.485v3.516q0 .422-.281.703t-.703.281H3q-.422 0-.703-.281t-.281-.703v-12q0-.422.281-.703T3 5.017h14.016q.422 0 .703.281t.281.703v3.516z"/>'
  }
})
