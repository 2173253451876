/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'biotech': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 18.984q-.797 0-1.383.586T5.015 21h13.969q0-.844-.586-1.43t-1.383-.586h-4.031v-1.969h3q.844 0 1.43-.586t.586-1.43H9.984q-.797 0-1.477-.398t-1.102-1.078T6.983 12q0-.797.422-1.477t1.031-1.102q-.422-.609-.422-1.406 0-.188.023-.328t.07-.328q-1.359.563-2.227 1.828T5.013 12q0 1.359.656 2.508t1.805 1.828 2.508.68v1.969h-3zm3.563-13.453q1.031 0 1.734.727t.703 1.758q0 .563-.211 1.031t-.633.844l.609 1.594.938-.328.328.938 1.875-.703-.328-.938.938-.328-2.719-7.547-.984.375-.328-.938-1.875.656.328.938-.938.375zM9 8.016q0-.656.445-1.078t1.055-.422 1.055.422T12 8.016q0 .609-.445 1.055t-1.055.445-1.055-.445T9 8.016z"/>'
  }
})
