/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 5.203v-.188l10.969 6.984-2.531 1.641zm12 14.531L4.266 3.984 3 5.25l5.016 5.016v8.719l5.297-3.375 5.438 5.391z"/>'
  }
})
