/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wb_sunny': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.563 18.563l1.781-1.828 1.406 1.406-1.781 1.828zm7.453 3.89V19.5h1.969v2.953h-1.969zM12 5.484q2.484 0 4.242 1.758T18 11.484t-1.758 4.242T12 17.484t-4.242-1.758T6 11.484t1.758-4.242T12 5.484zm8.016 5.016h3v2.016h-3V10.5zm-2.766 7.641l1.406-1.359 1.781 1.781-1.406 1.406zm3.188-13.688l-1.781 1.781-1.406-1.406 1.781-1.781zM12.984.563v2.953h-1.969V.563h1.969zm-9 9.937v2.016h-3V10.5h3zM6.75 4.828L5.344 6.234 3.563 4.453l1.406-1.406z"/>'
  }
})
