<template>
  <CardContainer :title="titleCompose" v-if="stats">
    <template slot="view_menu">
      <CButton color="secondary" size="sm" class="d-inline-block float-right ml-2" v-if="false">
        <svgicon name="settings" width="16px" height="16px" />
      </CButton>
      <CDropdown
        size="sm"
        :togglerText="titleSuffix || '골재종류 카테고리'"
        color="primary"
        class="m-0 p-0 d-inline-block float-right"
        :disabled="freight_cate1.length <= 0"
      >
        <CDropdownItem
          v-for="(cate, index) in freight_cate1"
          :key="`freight-layer1-cate-${index}`"
          @click="onChangeCate(cate.name)">{{cate.name}}
        </CDropdownItem>
      </CDropdown>
    </template>
    <template slot="chart">
      <CChartBar
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import axios from 'axios'
import CardContainer from '@/containers/StatisticsCard.vue'
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'FreightAmount',
  components: {
    CardContainer,
    CChartBar
  },
  props: {
    titleSuffix: {type: String, default: ''},
    stats: {type: Array, default: () => { return []}}
  },
  data() {
    return {
      freight_cate1: []
    }
  },
  mounted() {
    this.getFreightCate1();
  },
  computed: {
    titleCompose() {
      return `골재종류별 입고량 - ${this.titleSuffix}`
    },
    chartData() {
      // *** COLOR Palette
      //  #f3a935  (243,169,53)  warning
      //  #c73558  (199,53,88)   danger
      //  #6ebe9f  (110,190,159) success
      //  #2586a4  (37,134,164)  primary
      //  #55596a  (85,89,106)   secondary
      return [
        {
          label: '전체',
          data: this.stats.map(el => el.quantity),
          backgroundColor: '#2586a4'
        },
        {
          label: '정상입고',
          data: this.stats.map(el => el.valid_quantity || 0),
          backgroundColor: '#6ebe9f',
          stack: 'ratio'
        },
        {
          label: '불량',
          data: this.stats.map(el => el.insufficient || 0),
          backgroundColor: '#c73558',
          stack: 'ratio'
        }
      ]
    },
    chartLabel() {
      return this.stats.map(el => el.name)
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: '㎥'
            },
            stacked: true,
            ticks: {
              beginAtZero: true,
              // maxTicksLimit: 5,
              // stepSize: Math.ceil(250 / 5),
              // max: 250
            },
            gridLines: {
              display: true
            }
          }]
        }
      };
    }
  },
  methods: {
    getFreightCate1() {
      axios.get(`/api/delivery/statistics/freight/cate1/`)
        .then(result => {
          this.freight_cate1 = result.data;
          if (this.freight_cate1.length) {
            this.$emit('change-cate', this.freight_cate1[0].name);
          }
        })
        .catch(error => {
          console.error(error);
        })
    },
    onChangeCate(name) {
      this.$emit('change-cate', name);
    }
  }
}
</script>
