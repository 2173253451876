/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power_settings_new': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.813 5.156Q21.001 7.875 21.001 12q0 3.75-2.625 6.375T12.001 21t-6.375-2.625T3.001 12q0-1.688.938-3.703t2.25-3.141l1.406 1.406q-1.078.891-1.828 2.484t-.75 2.953q0 2.906 2.039 4.945t4.945 2.039 4.945-2.039 2.039-4.945q0-1.359-.75-2.953t-1.828-2.438zM12.984 3v9.984h-1.969V3h1.969z"/>'
  }
})
