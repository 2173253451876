/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.891.773T4.921 4.922 2.788 8.11t-.773 3.891.773 3.891 2.133 3.188 3.188 2.133 3.891.773 3.891-.773 3.188-2.133 2.133-3.188.773-3.891-.773-3.891-2.133-3.188-3.188-2.133T12 2.016z"/>'
  }
})
