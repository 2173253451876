/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'history_edu': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 3.984V5.39q-1.266-.516-2.625-.516-1.313 0-2.602.516t-2.32 1.547l3.328 3.328h1.078v1.125q.656.609 1.477.961T9 12.749v2.25H6v3q0 .844.586 1.43t1.43.586H18q.844 0 1.523-.422t1.078-1.102.398-1.477V3.983h-12zm-1.125 6.422V8.25h-2.25L4.547 7.219q.891-.328 1.828-.328 2.063 0 3.563 1.453l1.406 1.406-.188.188q-.797.797-1.922.797-.703 0-1.359-.328zm11.109 6.61q0 .375-.281.68t-.703.305-.703-.305-.281-.68V15h-6v-2.578q.891-.375 1.547-1.031l.188-.188 2.859 2.813h1.406V12.61l-6-6v-.609h7.969v11.016z"/>'
  }
})
