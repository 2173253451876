import axios from 'axios';
import qs from 'qs';

export default {
  Login: function (payload, cb, errcb) {
    axios.post(`/api/auth/token/`,
      {
        username: payload.username,
        password: payload.password
      })
      .then(result => {
        localStorage.setItem('access', result.data.access);
        localStorage.setItem('access_exp', result.data.access_exp);
        localStorage.setItem('refresh', result.data.refresh);
        localStorage.setItem('refresh_exp', result.data.refresh_exp);
        cb(result);
      })
      .catch(e => errcb(e))
  },
  Logout: function (cb, errcb) {
    axios.post(`/api/auth/token/logout/`, { refresh: localStorage.getItem('refresh') })
      .then(result => cb(result))
      .catch(e => errcb(e))
      .finally(() => {
        localStorage.removeItem('access');
        localStorage.removeItem('access_exp');
        localStorage.removeItem('refresh');
        localStorage.removeItem('refresh_exp');
      })
  },


  GetDevices: function(cb, errcb, params) {
    let query_string = ''
    if (params) {
      query_string = '?' + qs.stringify(params)
    }
    axios.get(`/api/devices/${query_string}`)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddDevices: function(cb, errcb, payload) {
    axios.post(`/api/upload/devices/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddBranches: function(cb, errcb, payload) {
    axios.post(`/api/upload/branches/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddUsers: function(cb, errcb, payload){
    axios.post(`/api/upload/users/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },


  GetTimelapses: function(cb, errcb, params) {
    let query_string = ''
    if (params) {
      query_string = '?' + qs.stringify(params)
    }
    axios.get(`/api/timelapse/generations/${query_string}`)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddTimelapse: function(cb, errcb, payload) {
    axios.post(`/api/timelapse/generations/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },


  GetHlsUrl: function(cb, errcb, params) {
    axios.get(`/api/devices/${params.id}/stream/`)
      .then(result => cb(result))
      .catch(e => errcb(e))
  }
}
