/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_android': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.25 18V3.984H6.75V18h10.5zm-3.234 3v-.984H9.985V21h4.031zM15.984.984q1.219 0 2.109.891t.891 2.109v16.031q0 1.219-.891 2.109t-2.109.891H8.015q-1.219 0-2.109-.891t-.891-2.109V3.984q0-1.219.891-2.109T8.015.984h7.969z"/>'
  }
})
