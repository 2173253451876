/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_list_numbered_rtl': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 11.016h13.969v1.969H2.016v-1.969zm0 6h13.969v1.969H2.016v-1.969zm0-12h13.969v1.969H2.016V5.016zm15.984 6V9.985h3v.938l-1.781 2.063H21v1.031h-3v-.938l1.781-2.063H18zm.984-3v-3H18V3.985h2.016v4.031h-1.031zm-.984 9v-1.031h3v4.031h-3v-1.031h2.016v-.469h-1.031v-1.031h1.031v-.469H18z"/>'
  }
})
