/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'explore': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.203 14.203L18 6 9.797 9.797 6 18zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm0 8.906q.469 0 .773.305t.305.773-.305.773-.773.305-.773-.305-.305-.773.305-.773.773-.305z"/>'
  }
})
