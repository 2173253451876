<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="size"
    color="primary"
  >
    <CProgress
      :value="counter"
      showPercentage
      color="success"
      animated
      show-value
      style="height:20px;"
      class="mt-1"
    />
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="danger">Stop</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'ProgressModal',
  props: {
    title: { type: String, default: 'Uploading' },
    counter: { type: Number, default: 0 },
    size: { type: String, default: 'lg' }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    onCancel() {
      this.show = false;
      this.$emit('cancel');
    }
  }
}
</script>