/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.047 8.063q1.547-1.547 5.039-2.555t6.211-1.289l2.719-.234q-.047 1.172-.258 3.023t-1.242 5.625-2.578 5.32q-1.594 1.594-3.797 1.945t-4.125-.586q.422-2.344 2.016-5.086t3.375-4.242q-5.109 2.625-7.359 7.969-.844-.844-1.453-2.32t-.609-2.648.609-2.625 1.453-2.297z"/>'
  }
})
