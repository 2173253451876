/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'disc_full': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 14.016q.797 0 1.406-.609t.609-1.406-.609-1.406-1.406-.609-1.383.609-.586 1.406.586 1.406 1.383.609zm0-10.032q3.328 0 5.672 2.367T18 11.999t-2.344 5.648-5.672 2.367q-3.281 0-5.625-2.344t-2.344-5.672 2.344-5.672 5.625-2.344zm10.032 3h1.969V12h-1.969V6.984zm0 9v-1.969h1.969v1.969h-1.969z"/>'
  }
})
