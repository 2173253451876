<template>
  <div>
    <CButtonGroup class="mb-4">
      <CButton :color="t==='daily'?'primary':'secondary'" @click="t='daily'">{{$t('cloud.title.stat_daily')}}</CButton>
      <CButton :color="t==='weekly'?'primary':'secondary'" @click="t='weekly'">{{$t('cloud.title.stat_weekly')}}</CButton>
      <CButton :color="t==='monthly'?'primary':'secondary'" @click="t='monthly'">{{$t('cloud.title.stat_monthly')}}</CButton>
      <CButton :color="t==='custom'?'primary':'secondary'" @click="t='custom'">{{$t('cloud.title.stat_custom')}}</CButton>
    </CButtonGroup>
    <DeliveryStatDaily v-if="t === 'daily'" />
    <DeliveryStatWeekly v-else-if="t === 'weekly'" />
    <DeliveryStatMonthly v-else-if="t === 'monthly'" />
    <DeliveryStatCustom v-else-if="t === 'custom'" />
  </div>
</template>

<script>
import DeliveryStatDaily from './Daily.vue'
import DeliveryStatWeekly from './Weekly.vue'
import DeliveryStatMonthly from './Monthly.vue'
import DeliveryStatCustom from './Custom.vue'

export default {
  name: 'DeliveryStatistics',

  components: {
    DeliveryStatDaily,
    DeliveryStatWeekly,
    DeliveryStatMonthly,
    DeliveryStatCustom
  },

  data () {
    return {
      t: 'daily'
    }
  },

}
</script>
