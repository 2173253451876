/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family_restroom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 3.984q0-.797.586-1.383T18 2.015t1.43.586.586 1.383q0 .844-.586 1.43T18 6t-1.43-.586-.586-1.43zm4.032 18v-6H22.5L19.969 8.39q-.234-.656-.75-1.031t-1.172-.375h-.094q-.656 0-1.172.375t-.75 1.031l-.844 2.578q.797.422 1.313 1.242t.516 1.805v7.969h3zm-7.5-10.5q.609 0 1.055-.422t.445-1.078q0-.609-.445-1.055t-1.055-.445q-.656 0-1.078.445t-.422 1.055q0 .656.422 1.078t1.078.422zM5.484 6q.844 0 1.43-.586t.586-1.43q0-.797-.586-1.383t-1.43-.586q-.797 0-1.383.586t-.586 1.383q0 .844.586 1.43T5.484 6zM7.5 21.984V15H9V9q0-.844-.586-1.43t-1.43-.586h-3q-.797 0-1.383.586T2.015 9v6h1.5v6.984h3.984zm6.516 0V18H15v-3.984q0-.609-.445-1.055t-1.055-.445h-2.016q-.609 0-1.055.445t-.445 1.055V18h1.031v3.984h3z"/>'
  }
})
