/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subdirectory_arrow_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 15l-6 6-1.406-1.406 3.609-3.609H3.984v-12H6v10.031h9.188l-3.609-3.609 1.406-1.406z"/>'
  }
})
