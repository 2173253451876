/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minimize': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 18.984h12V21H6v-2.016z"/>'
  }
})
