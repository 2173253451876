/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'assistant_direction': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.734 12.563q.656-.656 0-1.125l-7.172-7.219q-.609-.469-1.172 0l-7.172 7.219q-.234.375-.234.563t.234.563l7.172 7.219q.609.469 1.172 0zM12 .984q4.594 0 7.805 3.211T23.016 12t-3.211 7.805T12 23.016t-7.805-3.211T.984 12t3.211-7.805T12 .984zm2.016 9V7.5l3.469 3.516-3.469 3.469v-2.484H9.985v3H8.016v-3.984q0-1.031.984-1.031h5.016z"/>'
  }
})
