/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exposure_plus_1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 18H18V7.359L15 8.39V6.702l4.688-1.688h.328v12.984zM9.984 6.984v4.031h4.031v1.969H9.984v4.031H8.015v-4.031H3.984v-1.969h4.031V6.984h1.969z"/>'
  }
})
