/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'connected_tv': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 8.016q3.328 0 5.672 2.344T12 15.985h-1.453q0-2.719-1.922-4.617T3.984 9.47V8.017zm0 3q2.063 0 3.539 1.453T9 15.985H7.547q0-1.453-1.055-2.508t-2.508-1.055v-1.406zm0 3q.844 0 1.43.563T6 15.985H3.984v-1.969zm17.016 3v-12H3v12h18zM21 3q.797 0 1.406.609t.609 1.406l-.047 12q0 .797-.586 1.383t-1.383.586h-5.016V21H8.014v-2.016H2.998q-.797 0-1.406-.586t-.609-1.383v-12q0-.797.609-1.406T2.998 3h18z"/>'
  }
})
