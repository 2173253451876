/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smart_button': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 9v6q0 .844-.586 1.43t-1.383.586h-1.031V15h1.031V9H3.984v6h6v2.016h-6q-.797 0-1.383-.586T2.015 15V9q0-.844.586-1.43t1.383-.586h16.031q.797 0 1.383.586T21.984 9zm-7.5 9.984l1.125-2.391L18 15.515l-2.391-1.125-1.125-2.391-1.078 2.391-2.391 1.125 2.391 1.078zm2.532-4.968l.609-1.406 1.359-.609-1.359-.609-.609-1.406-.656 1.406-1.359.609 1.359.609zm-2.532 4.968l1.125-2.391L18 15.515l-2.391-1.125-1.125-2.391-1.078 2.391-2.391 1.125 2.391 1.078zm2.532-4.968l.609-1.406 1.359-.609-1.359-.609-.609-1.406-.656 1.406-1.359.609 1.359.609z"/>'
  }
})
