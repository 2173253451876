/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_cellular_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.781 4.5l17.203 17.25-1.266 1.266L18.749 21H.983l8.859-8.859-6.328-6.375zM21 .984v17.203l-8.578-8.578z"/>'
  }
})
