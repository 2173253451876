/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'next_week': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 18.516L15 14.485l-3.984-3.984-1.031.984 3 3-3 3zm-1.032-13.5v1.969h4.031V5.016H9.984zM14.016 3q.797 0 1.383.586t.586 1.43v1.969h4.031q.797 0 1.383.609T21.985 9v11.016q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383V9q0-.797.586-1.406t1.383-.609h4.031V5.016q0-.797.586-1.406t1.383-.609h4.031z"/>'
  }
})
