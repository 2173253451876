/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_upward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 12L12 3.984 20.016 12l-1.453 1.406-5.578-5.578v12.188h-1.969V7.828l-5.625 5.578z"/>'
  }
})
