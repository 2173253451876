/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group_add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 12.984q1.875 0 3.938.82t2.063 2.18V18h-12v-2.016q0-1.359 2.063-2.18t3.938-.82zm6.657.188q1.734.281 3.047 1.008t1.313 1.805v2.016h-3v-2.016q0-1.594-1.359-2.813zm-6.657-2.156q-1.219 0-2.109-.891t-.891-2.109.891-2.109 2.109-.891 2.109.891.891 2.109-.891 2.109-2.109.891zm5.016 0q-.469 0-.891-.141Q18 9.609 18 8.016t-.891-2.859q.422-.141.891-.141 1.219 0 2.109.891T21 8.016t-.891 2.109-2.109.891zM8.016 9.984V12h-3v3H3v-3H0V9.984h3v-3h2.016v3h3z"/>'
  }
})
