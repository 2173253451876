/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home_repair_service': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15.984h-2.016V15H8.015v.984H5.999V15H2.015v5.016h19.969V15H18v.984zm2.016-7.968h-3V6q0-.844-.586-1.43T15 3.984H9q-.844 0-1.43.586T6.984 6v2.016h-3q-.797 0-1.383.586t-.586 1.383v4.031h3.984V12h2.016v2.016h7.969V12H18v2.016h3.984V9.985q0-.797-.586-1.383t-1.383-.586zm-5.016 0H9V6h6v2.016z"/>'
  }
})
