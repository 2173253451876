/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.25L5.812 21l1.641-7.031-5.438-4.734 7.172-.609L12 2.017l2.813 6.609 7.172.609-5.438 4.734L18.188 21z"/>'
  }
})
