/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pending_actions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12q-1.406 0-2.531.68t-1.805 1.805-.68 2.531q0 1.359.68 2.508t1.805 1.805 2.531.656q1.359 0 2.508-.656t1.805-1.805.656-2.508q0-1.406-.656-2.531t-1.805-1.805-2.508-.68zm1.64 7.359L16.5 17.203v-3.188h.984v2.766l1.875 1.875zM18 3h-3.188q-.328-.891-1.078-1.453T12 .984t-1.734.563T9.188 3H6q-.844 0-1.43.586t-.586 1.43v15q0 .797.586 1.383t1.43.586h6.094q-.891-.844-1.406-1.969H6v-15h2.016v3h7.969v-3h2.016v5.063q1.031.141 2.016.609V5.016q0-.844-.586-1.43T18.001 3zm-6 2.016q-.422 0-.703-.305t-.281-.727q0-.375.281-.68T12 2.999t.703.305.281.68q0 .422-.281.727T12 5.016z"/>'
  }
})
