/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'escalator_warning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.516 2.016q.797 0 1.383.586t.586 1.383q0 .844-.586 1.43t-1.383.586q-.844 0-1.43-.586T4.5 3.985q0-.797.586-1.383t1.43-.586zm9 7.5q0 .609.422 1.055t1.078.445q.609 0 1.055-.445t.445-1.055q0-.656-.445-1.078t-1.055-.422q-.656 0-1.078.422t-.422 1.078zm3 2.484h-2.859q-.422 0-.82.234t-.633.609l-.891 1.359-3.609-6.188q-.281-.469-.727-.75t-.961-.281h-3q-.844 0-1.43.586T3 8.999v6h1.5v6.984h5.016V11.624l2.531 4.359h2.203l.75-1.078v7.078h3.984v-4.969h1.031v-3.516q0-.609-.445-1.055t-1.055-.445z"/>'
  }
})
