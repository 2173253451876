/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mark_email_read': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18.984q0-1.453.539-2.719t1.5-2.227 2.227-1.5 2.719-.539q.844 0 1.594.188t1.406.516V6q0-.844-.586-1.43t-1.383-.586H3.985q-.797 0-1.383.586T2.016 6v12q0 .844.586 1.43t1.383.586h8.109Q12 19.453 12 18.985zM3.984 6L12 11.016 20.016 6v2.016L12 12.985 3.984 8.016V6zm13.36 15.984l-3.563-3.516 1.406-1.406 2.156 2.109 4.219-4.219 1.453 1.406z"/>'
  }
})
