/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mp4': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 14.016h1.5v1.5h-1.5v-1.5zm1.968-4.032v-1.5H16.5v-3H15v3h-1.5v-3H12v4.5h3v1.5h1.5v-1.5h.984zm-7.5-4.5h-3v1.5h1.5v4.5h1.5v-6zm5.532 13.032v-1.5h1.969q.422 0 .727-.305t.305-.727V13.5q0-.422-.305-.703t-.727-.281h-3.469v6h1.5zm-3.516 0V13.5q0-.422-.281-.703t-.703-.281h-4.5q-.422 0-.727.281t-.305.703v5.016h1.5v-4.5h1.031v3h1.5v-3h.984v4.5h1.5zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
