/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'timeline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.016 8.016q0 .797-.609 1.383t-1.406.586h-.047q-.328 0-.469-.047l-3.563 3.563q.094.281.094.516 0 .797-.609 1.383t-1.406.586-1.406-.586-.609-1.383q0-.234.094-.516l-2.578-2.578q-.281.094-.516.094t-.516-.094L4.923 15.47q.094.281.094.516 0 .797-.609 1.406t-1.406.609-1.406-.609-.609-1.406.609-1.383 1.406-.586q.375 0 .516.047l4.547-4.547q-.047-.141-.047-.516 0-.797.586-1.406t1.383-.609 1.406.609.609 1.406q0 .375-.047.516l2.531 2.531q.141-.047.516-.047t.516.047l3.563-3.516q-.094-.281-.094-.516 0-.797.609-1.406t1.406-.609 1.406.609.609 1.406z"/>'
  }
})
