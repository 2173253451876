/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pedal_bike': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.188 9.984L16.5 5.296q-.234-.563-.75-.938t-1.172-.375H12v2.016h2.578l1.5 3.984H11.25l-.375-.984H12V6.983H6.984v2.016h1.781l1.781 5.016H9.89q-.188-1.125-.867-2.016t-1.641-1.406-2.133-.563q-1.406-.094-2.602.586T.725 12.444t-.727 2.555.656 2.531 1.805 1.805 2.555.68q1.219 0 2.25-.516t1.711-1.43.914-2.086h4.219q.188 1.125.867 2.016t1.641 1.406 2.133.563q1.406.094 2.602-.586t1.922-1.828.727-2.555-.656-2.531-1.805-1.805-2.555-.68h-.797zm-10.36 6q-.328.891-1.078 1.453T5.016 18q-.844 0-1.523-.398t-1.078-1.078-.398-1.523.398-1.523T3.493 12.4t1.523-.398q.984 0 1.734.563t1.078 1.453H5.015v1.969h2.813zm6.281-1.968h-1.406L11.953 12H15q-.328.422-.563.938t-.328 1.078zM18.984 18q-.844 0-1.523-.398t-1.078-1.078-.398-1.523q0-.703.281-1.289t.797-1.008l.938 2.672 1.875-.703-.938-2.672h.094q.797 0 1.477.398t1.102 1.078.422 1.523-.422 1.523-1.102 1.078-1.523.398z"/>'
  }
})
