/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shuffle_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.813 13.406l-1.406 1.406 3.141 3.141-2.063 2.063h5.531v-5.531l-2.063 2.063zm-.329-9.422l2.063 2.063L3.984 18.61l1.406 1.406L17.953 7.453l2.063 2.063V3.985h-5.531zm-3.89 5.204L5.391 3.985 3.985 5.391l5.203 5.203zM21 .984q.797 0 1.406.609t.609 1.406v18q0 .797-.609 1.406T21 23.014H3q-.797 0-1.406-.609t-.609-1.406v-18q0-.797.609-1.406T3 .984h18z"/>'
  }
})
