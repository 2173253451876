/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'iso': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 17.016H12v-1.5h5.016v1.5zm1.968 1.968V5.015L5.015 18.984h13.969zM5.484 7.5V9H7.5v2.016H9V9h2.016V7.5H9V5.484H7.5V7.5H5.484zm13.5-4.5q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
