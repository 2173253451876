/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'network_check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 12.984q3-3 7.313-2.859l-1.313 2.859q-.984.141-2.156.727T6.985 15zm12 2.016q-.516-.516-1.219-.984l.563-2.906q1.5.75 2.625 1.875zM21 11.016q-1.828-1.828-4.125-2.766l.516-2.813q1.359.516 3 1.547T23.016 9zM.984 9Q3.75 6.234 7.382 5.133t7.336-.445L13.546 7.36q-2.859-.375-5.648.586T3 11.016zm14.907-3.984q.516 0 .516.469l-2.438 12.891v.047q-.141.656-.703 1.125T12 20.017q-.844 0-1.43-.586t-.586-1.43q0-.516.234-.984l5.203-11.625q.141-.375.469-.375z"/>'
  }
})
