/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wine_bar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 3v6q0 1.5.656 2.742t1.781 2.086 2.578 1.078v4.078h-3V21h7.969v-2.016h-3v-4.078q1.453-.234 2.578-1.078t1.781-2.086T17.999 9V3h-12zm9.984 5.016H8.015v-3h7.969v3z"/>'
  }
})
