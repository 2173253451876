/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'child_friendly': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 20.016q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm-9 0q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm11.297-4.125q1.172 1.078 1.172 2.625 0 1.453-1.008 2.461t-2.461 1.008q-1.313 0-2.297-.867t-1.172-2.133h-2.109q-.188 1.266-1.148 2.133t-2.273.867q-1.453 0-2.484-1.008t-1.031-2.461q0-2.063 1.828-3.094l-2.109-4.406H2.018V9h3.469l.938 2.016h14.578q0 1.125-.492 2.555t-1.195 2.32zM12.984 2.016q3.328 0 5.672 2.344T21 9.985h-8.016V2.016z"/>'
  }
})
