/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto_fix_high': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 5.578l2.484 1.406L8.578 4.5l1.406-2.484L7.5 3.422 5.016 2.016 6.422 4.5 5.016 6.984zm12 9.844l-2.484-1.406 1.406 2.484-1.406 2.484 2.484-1.406 2.484 1.406-1.406-2.484 1.406-2.484zm2.484-13.406L19.5 3.422l-2.484-1.406L18.422 4.5l-1.406 2.484L19.5 5.578l2.484 1.406L20.578 4.5zm-7.593 5.297q-.328-.328-.727-.328t-.727.328L1.312 18.938q-.328.328-.328.727t.328.727l2.297 2.297q.328.328.727.328t.727-.328l11.625-11.625q.328-.328.328-.727t-.328-.68l-2.297-2.344zm-1.032 5.484l-2.156-2.156 2.438-2.438 2.156 2.156-2.438 2.438z"/>'
  }
})
