/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'whatshot': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.719 18.984q2.016 0 3.398-1.383t1.383-3.398q0-2.063-.563-4.031-1.453 1.922-4.641 2.578-2.813.609-2.813 3.094 0 1.313.938 2.227t2.297.914zM13.5.656q3 2.438 4.758 5.93t1.758 7.43q0 3.281-2.344 5.625T12 21.985t-5.672-2.344-2.344-5.625q0-5.063 3.234-8.906v.375q0 1.547 1.031 2.625t2.578 1.078q1.5 0 2.461-1.055t.961-2.648q0-.938-.188-2.156t-.375-1.922z"/>'
  }
})
