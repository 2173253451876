/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_stream': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 5.016H21v6H3.984v-6zm0 12.984v-6H21v6H3.984z"/>'
  }
})
