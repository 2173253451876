/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'playlist_play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 14.016l4.969 3-4.969 3v-6zm-10.032 0H12v1.969H3.984v-1.969zm0-8.016h12v2.016h-12V6zm0 3.984h12V12h-12V9.984z"/>'
  }
})
