/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'network_cell': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 21.984H2.015L21.984 2.015v19.969z"/>'
  }
})
