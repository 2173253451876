/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food_bank': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3L3.984 9v12h16.031V9zm.516 9.516q0 .609-.445 1.055t-1.055.445V18H9.985v-3.984q-.609 0-1.055-.445t-.445-1.055v-3h1.031v3h.469v-3h1.031v3h.469v-3h1.031v3zM15 18h-.984v-3.516h-1.031v-3q0-.797.586-1.383t1.43-.586v8.484z"/>'
  }
})
