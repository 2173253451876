/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_rotation_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 19.734l-3-3h2.016V4.265h1.969v12.469h2.016zm8.016-10.359v3.75l5.016-1.875zM21 12L9.984 16.734v-2.063l2.203-.938V8.764l-2.203-.938V5.763L21 10.497v1.5z"/>'
  }
})
