/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dialer_sip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15.516q.422 0 .703.281T21 16.5v3.516q0 .422-.281.703t-.703.281q-7.031 0-12.023-4.992T3.001 3.985q0-.422.281-.703t.703-.281h3.516q.422 0 .703.281t.281.703v.047q0 1.922.563 3.516.188.609-.234 1.031l-2.203 2.203q.938 1.781 2.883 3.727t3.727 2.883l2.203-2.203q.328-.328.703-.328.141 0 .328.094 1.688.563 3.563.563zm0-10.5V3.985h-1.031v1.031h1.031zM18 3h3v3h-2.016v2.016H18V3zm-3 2.016v3h-3V6.985h2.016v-.984H12v-3h3v.984h-2.016v1.031H15zM17.016 3v5.016h-1.031V3h1.031z"/>'
  }
})
