/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_drink': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.328 8.016l.422-4.031H5.25l.422 4.031h12.656zM12 18.984q1.219 0 2.109-.891T15 15.984q0-.891-.75-2.25t-1.5-2.25l-.75-.891q-3 3.375-3 5.391 0 1.219.891 2.109t2.109.891zM3 2.016h18L18.984 20.25q-.094.75-.656 1.242t-1.313.492H6.984q-.75 0-1.313-.492t-.656-1.242z"/>'
  }
})
