/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unfold_less': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.594 5.391L12 9.985 7.406 5.391l1.406-1.406L12 7.173l3.188-3.188zM7.406 18.609L12 14.015l4.594 4.594-1.406 1.406L12 16.827l-3.188 3.188z"/>'
  }
})
