/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'near_me_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6.328L21 3l-3.328 9zm10.594 13.453L4.219 1.406 2.813 2.812l5.063 5.063-4.875 1.828v1.406l7.078 2.813L12.892 21h1.406l1.828-4.875 5.063 5.063z"/>'
  }
})
