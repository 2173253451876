/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_b_and_w': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18.984V5.015H12v6zm0-15.984q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969zM12 11.016l-6.984 7.969H12v-7.969z"/>'
  }
})
