<template>
  <div>
    <Search :PlaceHolder="ph" @search="onSearch" @focus="onFocus" @blur="onBlur">
      <CListGroup v-if="list.length > 0">
        <CListGroupItem tag="button" v-for="i in list" :key="`list-group-item-${i.id}`" @click="onSelect(i.id)">{{i.name}} {{i.mac}}</CListGroupItem>
        <CRow class="justify-content-end mt-3 mr-1" v-if="pageTotal > 1">
          <CPagination
            :activePage.sync="pageCurrent"
            :pages="pageTotal"
            @update:activePage="onUpdatePage"
          >
          </CPagination>
        </CRow>
      </CListGroup>
    </Search>
  </div>
</template>

<script>
import Search from '@/containers/Search.vue'
import ListGroup from './ListGroup.vue'

export default {
  name: 'SearchList',
  components: {
    Search,
    ListGroup
  },
  props: {
    ph: {type: String, default: 'Search'},
    list: {type: Array, default: []},
    pageTotal: {type: Number, default: 1}
  },
  data() {
    return {
      pageCurrent: 1
    }
  },
  methods: {
    onSearch: function(text) {
      this.searchText = text;
      this.$emit('search', text);
    },
    onFocus: function() {
      this.$emit('focus');
    },
    onBlur: function() {
      this.$emit('blur');
    },
    onSelect: function(id) {
      this.$emit('select', id);
    },
    onUpdatePage: function(e) {
      this.$emit('page', this.pageCurrent);
    }
  }
}
</script>
