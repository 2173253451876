/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brunch_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 15.984v-1.969h4.031v1.969h4.5q.469 0 .469.516v.984q0 .516-.469.516H2.484q-.469 0-.469-.516V16.5q0-.516.469-.516h4.5zM18 15.891l-.422-.422q-.656-.703-1.125-1.875t-.469-2.109V2.016h6v9.516q0 2.297-1.5 3.938l-.469.516v4.031h1.969v1.969H18v-6.094zm-2.484 6.093H2.485q-.469 0-.469-.469v-1.5h13.969v1.5q0 .469-.469.469zM18 8.016h2.016V3.985H18v4.031z"/>'
  }
})
