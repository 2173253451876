/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hourglass_bottom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 21.984v-6L14.016 12 18 7.969V2.016H6v6L9.984 12 6 15.984v6h12zM8.016 7.5V3.984h7.969V7.5l-3.984 3.984z"/>'
  }
})
