/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_column': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 5.016H21V18h-5.016V5.016zM3.984 18V5.016H9V18H3.984zm6 0V5.016H15V18H9.984z"/>'
  }
})
