/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'looks': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6q4.547 0 7.781 3.234t3.234 7.781h-2.016q0-3.703-2.625-6.352t-6.375-2.648-6.375 2.648-2.625 6.352H.983q0-4.547 3.234-7.781T11.998 6zm0 3.984q2.859 0 4.922 2.063t2.063 4.969h-1.969q0-2.063-1.477-3.539T12 12t-3.539 1.477-1.477 3.539H5.015q0-2.906 2.063-4.969T12 9.984z"/>'
  }
})
