/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3h4.031v12h-4.031V3zM15 3q.797 0 1.406.609t.609 1.406v9.984q0 .797-.609 1.406l-6.563 6.609-1.078-1.078q-.422-.422-.422-1.031v-.328l.984-4.594H2.999q-.797 0-1.406-.586t-.609-1.383v-2.016q0-.375.141-.75l3.047-7.031Q4.641 2.998 6 2.998h9z"/>'
  }
})
