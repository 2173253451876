<template>
  <div>
    <ConfirmModal ref="ConfirmModal" :content="confirm_text" ok_text="확인" cancel_text="취소" />

    <SearchFilter :FACTORY_OPTS="factory_list" :CORRESPONDENT_OPTS="correspondent_list" :FREIGHT_OPTS="freight_list" @change="onFilterChange" />
    <CCard>
      <CCardHeader>
        <CRow class="justify-content-between">
          <div class="mx-3 my-1">
            <CIcon name="cil-truck" /> <strong>골재입고 현황</strong> <span class="mr-4"> ({{count}}회, 입고량 {{total_amount}}㎥, 부족량 {{invalid_amount}}㎥) </span>
            <CButton variant="outline" class="mr-1" :color="expand_view ? 'secondary':'primary'" @click="onBrief">
              <svgicon name="compress" width="20px" height="20px" />
            </CButton>
            <CButton variant="outline" :color="expand_view ? 'primary':'secondary'" @click="onExpand">
              <svgicon name="expand" width="20px" height="20px" />
            </CButton>
          </div>
          <div class="mx-3 my-1 custom-table-column-sm-ignore">
            <CDropdown v-if="!edit"
              toggler-text="기능"
              class="mr-2"
              color="info"
            >
              <CDropdownHeader>골재입고 현황</CDropdownHeader>
              <CDropdownItem @click="onEdit">
                <span class="mr-2"><svgicon name="create" width="18px" height="18px" /></span>
                현황 정보 수정
              </CDropdownItem>
              <CDropdownItem @click="onDownloadCSV">
                <span class="mr-2"><svgicon name="file_download" width="18px" height="18px" /></span>
                골재입고 내역 다운로드
              </CDropdownItem>
              <CDropdownDivider/>
              <CDropdownHeader>불량 반입량 보고서</CDropdownHeader>
              <CDropdownItem @click="onReport">
                <span class="mr-2"><svgicon name="picture_as_pdf" width="18px" height="18px" /></span>
                보고서 다운로드
              </CDropdownItem>
            </CDropdown>
            <CButton v-if="edit" class="mr-1" color="primary" @click="onSave">저장</CButton>
            <CButton v-if="edit" class="mr-2" color="secondary" @click="onCancel">취소</CButton>
          </div>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <div class="custom-table-header-line">
          <div class="custom-table-column-normal">입고시간</div>
          <div class="custom-table-column-normal custom-table-column-sm-ignore" v-if="myAuthorizationLevel.level < 100">공장</div>
          <div class="custom-table-column-normal">거래처</div>
          <div class="custom-table-column-normal custom-table-column-sm-ignore">산지</div>
          <div class="custom-table-column-long custom-table-column-sm-ignore">종류</div>
          <div class="custom-table-column-normal custom-table-column-sm-ignore">적재량</div>
          <div class="custom-table-column-normal custom-table-column-sm-ignore">차량번호</div>
          <div class="custom-table-column-longlong">검수결과</div>
          <div class="custom-table-column-short custom-table-column-sm-ignore">부족량</div>
        </div>

        <div v-if="CORRESPONDENT_OPTS.length && FREIGHT_OPTS.length">
          <div v-for="item in list" :key="`event-item-${item.id}`">
            <ListItem
              :row="item"
              :expand-view="expand_view"
              :expand-id="expand_id"
              :edit="edit"
              :CORRESPONDENT_OPTS="CORRESPONDENT_OPTS.filter(el => el.branch_id === item.branch_id || !el.branch_id)"
              :FREIGHT_OPTS="FREIGHT_OPTS.filter(el => el.branch_id === item.branch_id || !el.branch_id)"
              @updated="onUpdated"
              @row-click="onRowClick"
              @image-click="onImage"
              @plate-image-click="onPlateImage"
            ></ListItem>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <CRow class="justify-content-end" v-if="pageTotal > 1">
      <CPagination
        :activePage.sync="pageCurrent"
        :pages="pageTotal"
        @update:activePage="onUpdatePage"
      >
      </CPagination>
      <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
    </CRow>
    <TopBtn />
    <ImagePopup ref="ImagePopup" @save="onSaveImage" />
    <ImagePopup ref="PlateImagePopup" :carousel="false" />
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import ListItem from './ListItem.vue'
import SearchFilter from './SearchFilter.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import TopBtn from '@/components/TopBtn.vue'
import ImagePopup from '@/components/modals/ImagePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryEvent',

  components: {
    ConfirmModal,
    SearchFilter,
    ListItem,
    TopBtn,
    ImagePopup
  },
  data () {
    return {
      loading: false,
      confirm_text: null,

      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      filters: {},
      count: 0,
      list: [],

      total_amount: null,
      valid_amount: null,
      invalid_amount: null,

      updated_events : [],

      expand_view : false,
      expand_id: null,
      edit : false,

      factory_list: [],
      freight_list: [],
      FREIGHT_OPTS: [],
      correspondent_list: [],
      CORRESPONDENT_OPTS: []
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ])
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getFactoryList();
      this.getFreightList();
      this.getCorrespondentList();
      // this.getList();
    },
    getList() {
      let query_string = '';
      let params = this.buildParam(10, this.pageCurrent);

      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      this.loading = this.$loading({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.list = [];
      axios.get(`/api/delivery/events/${query_string}`)
        .then(result => {
          this.list = this.$utility.copy(result.data.list);
          this.list = this.list.map(el => {
            el.metadata = el.meta_str ? JSON.parse(el.meta_str):null;
            return el;
          });
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
          this.total_amount = result.data.amount.total;
          this.valid_amount = result.data.amount.valid;
          this.invalid_amount = result.data.amount.invalid;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading.close();
        })
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    buildParam(ipp, page) {
      let params = {
        ipp: ipp,
        page: page
      }
      if (this.filters.branch) params.branch = this.filters.branch;
      if (this.filters.validity !== null) params.validity = this.filters.validity;
      if (this.filters.correspondent) params.correspondent = this.filters.correspondent;
      if (this.filters.origin) params.origin = this.filters.origin;
      if (this.filters.freight) params.freight = this.filters.freight;
      if (this.filters.no_dump) params.no_dump = this.filters.no_dump;
      if (this.filters.ts_mode) {
        if (this.filters.ts_mode === 'today') {
          let now = new Date();
          now.setHours(0);
          now.setMinutes(0);
          now.setSeconds(0);
          now.setMilliseconds(0);
          params.sts = now.getTime();
        } else if (this.filters.ts_mode === 'd1') {
          let yesterday = new Date(Date.now() - (1000 * 60 * 60 * 24));
          yesterday.setHours(0);
          yesterday.setMinutes(0);
          yesterday.setSeconds(0);
          yesterday.setMilliseconds(0);
          params.sts = yesterday.getTime();
        } else if (this.filters.ts_mode === 'd2') {
          let yesterday = new Date(Date.now() - (1000 * 60 * 60 * 24 * 2));
          yesterday.setHours(0);
          yesterday.setMinutes(0);
          yesterday.setSeconds(0);
          yesterday.setMilliseconds(0);
          params.sts = yesterday.getTime();
        } else if (this.filters.ts_mode === 'h24') {
          params.sts = Date.now() - (1000 * 60 * 60 * 24);
        }
      } else if (this.filters.sts && this.filters.ets) {
        params.sts = this.filters.sts;
        params.ets = this.filters.ets;
      }
      return params;
    },

    getFactoryList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 100,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.factory_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
    },
    getFreightList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 1000,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/freights/${query_string}`)
        .then(result => {
          this.freight_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
          this.FREIGHT_OPTS = this.freight_list.map(el => {
            return { value: el.id, label: el.name, branch_id: el.branch_id };
          });
          this.FREIGHT_OPTS.push({
            value: null,
            label: '정보 없음'
          })
        })
        .catch(e => {
          console.error(e);
        })
    },
    getCorrespondentList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 1000,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/correspondents/${query_string}`)
        .then(result => {
          this.correspondent_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
          this.CORRESPONDENT_OPTS = this.correspondent_list.map(el => {
            return { value: el.id, label: el.name, branch_id: el.branch_id, origins: el.origins, quantity_contract: el.quantity_contract ? el.quantity_contract : [] }
          });
          this.CORRESPONDENT_OPTS.push({
            value: null,
            label: '정보 없음'
          })
        })
        .catch(e => {
          console.error(e);
        })
    },
    onConfirmed() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      axios.put(`/api/delivery/events/`, this.updated_events)
        .then(() => {
          this.$notify.success({
            title: '완료',
            message: `${this.updated_events.length} 항목이 수정되었습니다`,
            offset: 30
          });
          this.updated_events = [];
        })
        .catch(error => {
          this.$alert(
            `요청을 처리할 수 없습니다. <small><br/>오류: ${error.response.data.error}<br/>상세: ${error.response.data.error_detail || '없음'}</small>`,
            '요청 실패',
            {
              confirmButtonText: '확인',
              type: 'warning',
              dangerouslyUseHTMLString: true
            }
          );
          console.error(error);
        })
        .finally(() => {
          this.loading.close();
          this.edit = false;
        })
    },
    onEdit() {
      this.edit = true;
    },
    onCancel() {
      this.updated_events = [];
      this.expand_id = null;
      this.edit = false;
      this.getList();
    },
    onSave() {
      if (!this.updated_events.length) {
        this.$notify.info({
          title: '확인',
          message: '변경된 항목이 없습니다',
          offset: 30
        });
        this.edit = false;
        return;
      }
      this.confirm_text = `${this.updated_events.length} 항목의 수정사항을 저장 하시겠습니까?`;
      this.$refs.ConfirmModal.open(null, this.onConfirmed);
    },
    onUpdated(payload) {
      let isEvent = this.updated_events.findIndex(el => el.id === payload.id);
      let isSame = this.checkSame(payload);
      let newPayload = {
        id: payload.id,
        lp_num: payload.lp_num,
        class_name: payload.class_name,
        quantity: payload.quantity,
        state: payload.state,
        deficiency: payload.deficiency,
        freight_id: payload.freight_id,
        correspondent_id: payload.correspondent_id,
        branch_id: payload.branch_id,
        origin: payload.origin
      };
      if (isEvent < 0) {
        this.updated_events.push(newPayload);
      } else {
        if (isSame) {
          this.updated_events.splice(isEvent, 1);
        } else {
          this.updated_events.splice(isEvent, 1, newPayload);
        }
      }
    },


    onRowClick(id) {
      if (this.expand_id === id) {
        if (!this.edit) {
          this.expand_id = null;
          return;
        }
      }
      this.expand_id = id;
    },
    onExpand() {
      this.expand_id = null;
      this.expand_view = true;
    },
    onBrief() {
      this.expand_id = null;
      this.expand_view = false;
    },

    checkSame(payload) {
      let elem = this.list.find(el => el.id === payload.id);
      if (!elem) return false;
      if (elem.lp_num !== payload.lp_num) return false;
      if (elem.class_name !== payload.class_name) return false;
      if (elem.quantity !== payload.quantity) return false;
      if (elem.state !== payload.state) return false;
      if (elem.freight_id !== payload.freight_id) return false;
      if (elem.correspondent_id !== payload.correspondent_id) return false;
      if (elem.branch_id !== payload.branch_id) return false;
      if (elem.deficiency !== payload.deficiency) return false;
      return true;
    },

    onFilterChange(payload) {
      this.filters = payload;
      this.getList();
    },
    onDownloadCSV() {
      let confirm_title = '요청을 처리할 수 없습니다';
      let confirm_text = `최대 다운로드 항목 수 초과(${this.count}항목)<br/>`;
      confirm_text = confirm_text + `최대 ${this.$resource.MAX_DOWNLOAD_COUNT} 항목의 데이터를 다운로드할 수 있습니다<br/>`;
      confirm_text = confirm_text + `<small>상단의 검색 필터에 <strong>입고 시간대</strong>를 조정하거나 `;
      confirm_text = confirm_text + `<strong>조건</strong>을 추가하여 대상의 개수를 ${this.$resource.MAX_DOWNLOAD_COUNT} 항목 이하로 조정한 후 다운로드 하시기 바랍니다.<br/>`;
      confirm_text = confirm_text + `또는 현재 목록에서 최근의 ${this.$resource.MAX_DOWNLOAD_COUNT} 항목만 다운로드 할 수 있습니다.</small>`;
      if (this.count > this.$resource.MAX_DOWNLOAD_COUNT) {
        this.$confirm(
          confirm_text,
          confirm_title,
          {
            confirmButtonText: '다운로드',
            cancelButtonText: '닫기',
            type: 'warning',
            dangerouslyUseHTMLString: true
          }
        )
        .then(() => {
          this.downloadBuildCSV();
        })
        .catch(() => {
          this.$notify.warning({
            title: '확인',
            message: `다운로드가 취소되었습니다`,
            offset: 30
          });
        })
        return;
      }
      this.downloadBuildCSV();
    },
    downloadBuildCSV() {
      this.confirm_text = `총 ${Math.min(this.count, this.$resource.MAX_DOWNLOAD_COUNT)} 항목을 다운로드합니다. 계속하시겠습니까?`;
      this.$refs.ConfirmModal.open(null, () => {
        let query_string = '';
        let params = this.buildParam(this.$resource.MAX_DOWNLOAD_COUNT, 1);
        if (params) {
          query_string = '?' + qs.stringify(params);
        }

        this.loading = this.$loading({
          lock: true,
          text: 'Downloading...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        axios.get(`/api/delivery/events/${query_string}`)
          .then(response => {
            const download_list = response.data.list;
            const arr = download_list.map(el => {
              return {
                '입고시간': this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(el.created_at)),
                '공장': el.branch_name,
                '거래처': el.correspondent_name,
                '산지': el.origin,
                '종류': el.freight_name,
                '적재량': `${el.quantity}`,
                '차량번호': el.lp_num,
                '검수': el.state === 1 ? '정상':(el.state === 0 ? '불량':'미검수'),
                '부족량': el.deficiency ? `${el.deficiency}㎥`:''
              }
            });
            let filename = '입고내역-$yyyy$mm$dd-$HH$MM$ss.csv';
            this.$utility.DownloadCSV(arr, filename);
          })
          .catch(error => {
            this.$alert(
              `요청을 처리할 수 없습니다. <small><br/>오류: ${error.response.data.error}<br/>상세: ${error.response.data.error_detail || '없음'}</small>`,
              '요청 실패',
              {
                confirmButtonText: '확인',
                type: 'warning',
                dangerouslyUseHTMLString: true
              }
            );
            console.error(error);
          })
          .finally(() => {
            this.loading.close();
          })
      })
    },

    onReport() {
      this.$router.push('/service/delivery/error-report');
    },
    onImage(param){
      this.$refs.ImagePopup.open(param);
      // window.open(url, 'image_popup');
    },
    onPlateImage(param) {
      this.$refs.PlateImagePopup.open(param);
    },
    onSaveImage(param) {
      axios.patch(`/api/delivery/events/${param.id}/`, {index:param.index})
        .then(response => {
          let new_alarm = this.$utility.copy(response.data);
          new_alarm.metadata = JSON.parse(new_alarm.meta_str);
          const index = this.list.findIndex(el => el.id === new_alarm.id);
          if (index < 0) return;
          this.list.splice(index, 1, new_alarm);
        })
    }
  }
}
</script>
