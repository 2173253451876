/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 18.984v-9h5.016V21h-3q-.797 0-1.406-.609t-.609-1.406zM20.016 3q.797 0 1.383.609t.586 1.406v3H3.001v-3q0-.797.609-1.406T5.016 3h15zm-3 18V9.984h4.969v9q0 .797-.586 1.406t-1.383.609h-3zM9.984 10.031H15V21H9.984V10.031z"/>'
  }
})
