/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'do_not_touch': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 10.172L10.5 7.688V2.25q0-.516.375-.891t.891-.375.867.375.352.891v7.922zm7.032 2.578v-7.5q0-.516-.375-.891t-.891-.375-.891.375-.375.891v5.766H16.5V3.235q0-.516-.375-.867t-.891-.352-.867.352-.352.867v7.922l6 6v-4.406zm-10.5-8.484q0-.516-.375-.891T8.25 3t-.867.352-.352.82l2.484 2.484V4.265zm3.468 5.906L10.5 7.688V2.25q0-.516.375-.891t.891-.375.867.375.352.891v7.922zm7.032 2.578v-7.5q0-.516-.375-.891t-.891-.375-.891.375-.375.891v5.766H16.5V3.235q0-.516-.375-.867t-.891-.352-.867.352-.352.867v7.922l6 6v-4.406zm-10.5-8.484q0-.516-.375-.891T8.25 3t-.867.352-.352.82l2.484 2.484V4.265zm11.672 16.922L2.813 2.813 1.407 4.219l5.625 5.625h-.047v4.266q-.563-.281-1.148-.633t-1.008-.586l-.422-.234q-.281-.141-.563-.141-.375 0-.703.234 0 .047-.164.211t-.398.375-.398.375l-.164.164 6.797 7.172q.891.938 2.156.938h6.047q.891 0 1.641-.516h-.047l1.172 1.125z"/>'
  }
})
