/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'present_to_all': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 12H8.015l3.984-3.984L15.983 12h-1.969v3.984H9.983V12zM21 19.031V4.968H3v14.063h18zM21 3q.844 0 1.43.586t.586 1.43v13.969q0 .844-.586 1.43t-1.43.586H3q-.844 0-1.43-.586t-.586-1.43V5.016q0-.844.586-1.43T3 3h18z"/>'
  }
})
