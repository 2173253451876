/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shutter_speed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.313 7.172l-2.719 4.688-2.297-3.563Q9.985 6.984 12 6.984q.469 0 1.313.188zm3.046 9.937L13.546 12h4.359q.094.656.094.984v.047q0 .984-.469 2.18t-1.172 1.898zM7.547 9l3.281 5.016H6.094Q6 13.36 6 12.985q0-.938.445-2.109t1.102-1.875zm3.047 9.844l2.859-4.922 2.109 3.891q-1.547 1.172-3.563 1.172-.703 0-1.406-.141zm7.078-7.828h-5.391l2.016-3.563q1.031.422 2.039 1.477t1.336 2.086zm-6 3.984l-2.016 3.516q-1.031-.422-1.992-1.453T6.328 15h5.344zM12 20.016q2.906 0 4.945-2.063t2.039-4.969-2.039-4.945T12 6 7.055 8.039t-2.039 4.945 2.039 4.969T12 20.016zm7.031-12.61q.797 1.031 1.383 2.648t.586 2.93q0 3.703-2.625 6.352T12 21.984t-6.375-2.648T3 12.984t2.625-6.352T12 3.984q1.266 0 2.93.609t2.695 1.406l1.406-1.453q.75.609 1.406 1.406zM15 .984V3H9V.984h6z"/>'
  }
})
