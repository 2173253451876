/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'height': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 6.984v10.031h3L12 20.999l-3.984-3.984h3V6.984h-3L12 3l3.984 3.984h-3z"/>'
  }
})
