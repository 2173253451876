/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar_today': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 21V8.016H3.985V21h16.031zm0-18q.797 0 1.383.609t.586 1.406v15.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406V5.015q0-.797.586-1.406T3.985 3h1.031V.984h1.969V3h10.031V.984h1.969V3h1.031z"/>'
  }
})
