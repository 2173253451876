/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_rotation_angledown': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.25 21h-4.219l1.406-1.406-8.859-8.859 1.453-1.406 8.813 8.859 1.406-1.406v4.219zM12.609 8.016l2.625 2.625 2.203-4.875zm6.797-3.094l-4.453 11.109-1.453-1.453.891-2.203-3.516-3.563-2.203.938-1.453-1.5 11.109-4.406z"/>'
  }
})
