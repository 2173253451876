/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phonelink_ring': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 20.016V3.985H3.985v16.031h10.031zm0-19.032q.797 0 1.383.609t.586 1.406v18q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-18q0-.797.586-1.406T3.985.984h10.031zM18 9.797q.938.984.938 2.203T18 14.109l-.984-1.031q.844-1.172 0-2.297zm2.109-2.109q1.875 1.781 1.875 4.289t-1.875 4.242l-1.031-1.031q1.359-1.453 1.359-3.305t-1.359-3.164z"/>'
  }
})
