/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'k_plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 12.516v-1.031H18v-1.5h-.984v1.5h-1.5v1.031h1.5v1.5H18v-1.5h1.5zM13.734 15h1.781l-2.25-3 2.25-3h-1.781L12 11.25V9h-1.5v6H12v-2.25zM9 15V9H6v1.5h1.5V15H9zm9.984-12q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
