/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_car': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 11.016h13.969l-1.5-4.5H6.516zm12.468 4.968q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm-10.968 0q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zM18.938 6l2.063 6v8.016q0 .422-.281.703t-.703.281h-1.031q-.422 0-.703-.281t-.281-.703v-1.031h-12v1.031q0 .422-.281.703T5.018 21H3.987q-.422 0-.703-.281t-.281-.703V12l2.063-6q.281-.984 1.453-.984h10.969q1.172 0 1.453.984z"/>'
  }
})
