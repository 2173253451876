/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'change_history': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3.984l9.984 16.031H2.015zm0 3.797L5.625 18h12.75z"/>'
  }
})
