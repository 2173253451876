/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'business_center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 6.984V5.015H9.985v1.969h4.031zm6 0q.797 0 1.383.609t.586 1.406v3q0 .797-.586 1.406t-1.383.609h-6v-2.016H9.985v2.016h-6q-.844 0-1.406-.586t-.563-1.43v-3q0-.797.586-1.406t1.383-.609h3.984V5.014l2.016-2.016h3.984l2.016 2.016v1.969h4.031zm-10.032 9h4.031V15h6.984v3.984q0 .844-.586 1.43t-1.43.586H5.014q-.844 0-1.43-.586t-.586-1.43V15h6.984v.984z"/>'
  }
})
