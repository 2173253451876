/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fire_extinguisher': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 18.984h10.031v1.031q0 .797-.586 1.383t-1.43.586h-6q-.844 0-1.43-.586t-.586-1.383v-1.031zm0-.984h10.031v-5.016H6.984V18zM17.016 3v6l-3.188-.656q1.172.469 1.992 1.43t1.102 2.227H7.078q.281-1.266 1.102-2.227t1.992-1.43q-.516-.375-.797-.938l-4.359-.891V5.484l4.359-.891q.375-.703 1.078-1.148T12 3q.516 0 .984.188t.844.469zm-4.032 3q0-.422-.281-.703T12 5.016t-.703.281-.281.703.281.703.703.281.703-.281.281-.703z"/>'
  }
})
