/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burst_mode': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 17.016H21l-3.188-4.219-2.531 3.188-1.781-2.156zm10.968-12q.422 0 .727.281t.305.703v12q0 .422-.305.703t-.727.281h-12q-.422 0-.703-.281T9 18V6q0-.422.281-.703t.703-.281h12zm-16.968 0h1.969v13.969H5.016V5.016zm-4.032 0H3v13.969H.984V5.016z"/>'
  }
})
