/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_return': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6.984H21v6H5.812l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172V6.983z"/>'
  }
})
