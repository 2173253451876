/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'support_agent': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 12.234q0-2.063-.727-3.75t-1.992-2.93-2.883-1.898T12 3t-3.375.656-2.883 1.875-2.016 2.93-.727 3.82q-.469.234-.727.703t-.258 1.031v1.969q0 .844.586 1.43T3.983 18h1.031v-6.094q0-1.453.539-2.719t1.5-2.227 2.227-1.5 2.719-.539 2.719.539 2.227 1.5 1.5 2.227.539 2.719v7.078h-7.969V21h7.969q.844 0 1.43-.586t.586-1.43v-1.219q.422-.234.703-.656t.281-.984v-2.297q0-.516-.281-.938T21 12.234zm-12.984.75q0-.422.281-.703T9 12t.703.281.281.703-.281.727-.703.305-.703-.305-.281-.727zm6 0q0-.422.281-.703T15 12t.703.281.281.703-.281.727-.703.305-.703-.305-.281-.727zM18 11.016q-.234-1.406-1.078-2.555t-2.109-1.805T12.047 6q-1.125 0-2.25.445T7.781 7.734 6.398 9.773 6 12.468q1.875-.797 3.188-2.344t1.688-3.563q.656 1.313 1.734 2.32t2.461 1.57 2.93.563z"/>'
  }
})
