/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 12.984h-6v6h-1.969v-6h-6v-1.969h6v-6h1.969v6h6v1.969z"/>'
  }
})
