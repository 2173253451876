/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_input_antenna': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .984q4.547 0 7.781 3.234t3.234 7.781h-2.016q0-3.75-2.625-6.375t-6.375-2.625-6.375 2.625-2.625 6.375H.983q0-4.547 3.234-7.781T11.998.984zm.984 13.313v3.281L16.406 21 15 22.406l-3-3-3 3L7.594 21l3.422-3.422v-3.281q-1.5-.609-1.5-2.297 0-1.031.727-1.758t1.758-.727 1.758.727.727 1.758q0 1.688-1.5 2.297zM12 5.016q2.906 0 4.945 2.039T18.984 12h-1.969q0-2.063-1.477-3.539t-3.539-1.477T8.46 8.461 6.983 12H5.014q0-2.906 2.039-4.945t4.945-2.039z"/>'
  }
})
