<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="10" v-if="filter_date">
            <div class="filter_row">
              <div class="filter_label d-md-down-none">{{$t('cloud.title.time_range')}}</div>
              <el-date-picker v-if="PERIOD === 'daily'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="date" :clearable="false" @change="onQuery" />
              <el-date-picker v-else-if="PERIOD === 'weekly'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="week" :format="$t('cloud.title.time_fmt_weekly')" :clearable="false"  @change="onQuery" />
              <el-date-picker v-else-if="PERIOD === 'monthly'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="month" :format="$t('cloud.title.time_fmt_monthly')" :clearable="false"  @change="onQuery" />
              <el-date-picker
                v-else-if="PERIOD === 'custom'"
                class="ml-4 my-1"
                v-model="filter_dates"
                type="datetimerange"
                range-separator="-"
                :start-placeholder="$t('cloud.title.range_start_date')"
                :end-placeholder="$t('cloud.title.range_end_date')"
                :default-time="['00:00:00', '23:59:59']"
                @change="onQuery"
              />
              <div class="d-md-down-none">
                <!-- <CButton class="ml-2 my-1" color="primary" @click="onQuery">Query</CButton> -->
                <CButton class="ml-2 my-1" color="secondary" @click="onReset">{{$t('button.reset')}}</CButton>
              </div>
            </div>
            <div class="filter_row">
              <div class="filter_label d-md-down-none">{{$t('cloud.title.retail_store')}}</div>
              <el-select class="ml-4 my-1 filter_input_size" v-model="filter_branch" :placeholder="$t('cloud.title.all_store')" filterable clearable @change="onQuery" @clear="onQuery">
                <el-option-group
                  v-for="group in regions"
                  :key="`regions-${group.id}`"
                  :label="group.name">
                  <el-option
                    v-for="option in group.options"
                    :key="`branch-${option.id}`"
                    :label="option.name"
                    :value="option.id">
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
            <div class="filter_row d-lg-none">
              <div class="filter_label d-md-down-none border-0"></div>
              <!-- <CButton class="ml-4 my-1" color="primary" @click="onQuery">Query</CButton> -->
              <CButton class="ml-2 my-1" color="secondary" @click="onReset">{{$t('button.reset')}}</CButton>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'RetailStoreOccupancyFilter',
  props: {
    PERIOD: {type: String, default: 'daily'},
    STORE_OPTS: {type: Array, default: () => {return [];}},
    COUNTER_OPTS: {type: Array, default: () => {return [];}},
  },

  data() {
    return {
      filter_dates: null,
      filter_date: null,
      filter_branch: null,
      filter_counter: null,
      counter_filtered: [],

      payload: {}
    }
  },
  mounted() {
    this.filter_date = new Date();
    this.filter_dates = [new Date(), new Date()];
    this.filter_dates[0].setHours(0);
    this.filter_dates[0].setMinutes(0);
    this.filter_dates[0].setSeconds(0);
    this.filter_dates[0].setMilliseconds(0);
    this.filter_dates[1].setHours(23);
    this.filter_dates[1].setMinutes(59);
    this.filter_dates[1].setSeconds(59);
    this.filter_dates[1].setMilliseconds(999);
    this.onQuery();
  },
  computed: {
    regions() {
      let rtn = [];
      for (const store of this.STORE_OPTS) {
        let rtn_region = rtn.find(el => el.name === store.branch_connected.region_name);
        if (!rtn_region) {
          rtn.push(
            {
              name: store.branch_connected.region_name,
              id: store.branch_connected.region,
              options: [
                {name: store.name, id: store.branch_connected.id}
              ]
            }
          )
        } else {
          rtn_region.options.push({name: store.name, id: store.branch_connected.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    },
    filteredCounters() {
      if (!this.filter_branch) return this.COUNTER_OPTS;
      else return this.COUNTER_OPTS.filter(el => el.branch_id === this.filter_branch);
    }
  },
  methods: {
    onQuery() {
      let payload = {
        date: this.filter_date,
        branch: this.filter_branch,
        counter: this.filter_counter
      };
      this.$emit('query', payload);
    },
    onReset() {
      this.filter_date = new Date();
      this.filter_dates = [new Date(), new Date()];
      this.filter_dates[0].setHours(0);
      this.filter_dates[0].setMinutes(0);
      this.filter_dates[0].setSeconds(0);
      this.filter_dates[0].setMilliseconds(0);
      this.filter_dates[1].setHours(23);
      this.filter_dates[1].setMinutes(59);
      this.filter_dates[1].setSeconds(59);
      this.filter_dates[1].setMilliseconds(999);
      this.filter_branch = null;
      this.filter_counter = null;
      this.onQuery();
    }
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size {
  width: 250px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>
