/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tv_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 5.016q.844 0 1.43.563t.586 1.406v12q0 .609-.375 1.125L21 18.469V6.985H9.516L7.5 5.016h3.094L7.313 1.688l.703-.703L12 5.016 15.984.985l.703.703-3.281 3.328H21zM3 18.984h13.453l-12-12H3v12zM.984 3.563L2.25 2.25l19.453 19.5-1.266 1.266L18.468 21H2.999q-.797 0-1.406-.609t-.609-1.406v-12q0-.703.445-1.242t1.102-.68z"/>'
  }
})
