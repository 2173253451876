/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'network_wifi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.438 10.922l.047.047-8.484 10.5-8.484-10.5.047-.047L.376 6.984Q6.048 3 12.001 3t11.625 3.984z"/>'
  }
})
