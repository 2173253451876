/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_5': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12.984q0 .844-.586 1.43T15 15h-3.984v-2.016H15v-1.969h-3.984v-6h6v1.969h-4.031V9h2.016q.844 0 1.43.586t.586 1.43v1.969zM3 5.016V21h15.984v2.016H3q-.797 0-1.406-.609t-.609-1.406V5.017h2.016zm18 12V3H6.984v14.016H21zM21 .984q.797 0 1.406.609t.609 1.406v14.016q0 .797-.609 1.383T21 18.984H6.984q-.797 0-1.383-.586t-.586-1.383V2.999q0-.797.586-1.406T6.984.984H21z"/>'
  }
})
