/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backpack': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 8.016v12q0 .797-.586 1.383t-1.43.586H6q-.844 0-1.43-.586t-.586-1.383v-12q0-1.406.867-2.461t2.133-1.43V2.016h3v1.969h4.031V2.016h3v2.109q1.266.375 2.133 1.43t.867 2.461zM6 12v2.016h9.984v1.969H18v-3.984H6z"/>'
  }
})
