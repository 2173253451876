/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_legroom_normal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.484 18q.609 0 1.055.422t.445 1.078-.445 1.078-1.055.422h-4.5v-6.984H9q-1.219 0-2.109-.891T6 11.016V3h6v6h5.016q.797 0 1.383.609t.586 1.406v6.984h1.5zM5.016 12q0 1.219.891 2.109T8.016 15h6v2.016h-6q-2.063 0-3.539-1.477T3 12V3h2.016v9z"/>'
  }
})
