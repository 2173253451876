/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fiber_smart_record': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 4.266q2.625.656 4.313 2.813t1.688 4.922-1.688 4.922-4.313 2.813v-2.063q1.641-.609 2.813-2.273t1.172-3.398-1.172-3.398-2.813-2.273V4.268zM.984 12q0-3.281 2.367-5.648t5.648-2.367 5.648 2.367T17.014 12t-2.367 5.648-5.648 2.367-5.648-2.367T.984 12z"/>'
  }
})
