/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stairs': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zM18 8.016h-2.438v3.328h-2.578v3.328h-2.578V18H6v-2.016h2.438v-3.328h2.578V9.328h2.578V6H18v2.016z"/>'
  }
})
