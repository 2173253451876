/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'perm_camera_mic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12.984V9q0-.797-.609-1.406t-1.406-.609-1.406.609T9.986 9v3.984q0 .797.609 1.406t1.406.609 1.406-.609.609-1.406zm6-7.968q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 21h-7.031v-2.109q2.109-.375 3.563-2.039t1.453-3.867h-2.016q0 1.641-1.172 2.836T12 17.016t-2.813-1.195-1.172-2.836H5.999q0 2.203 1.453 3.867t3.563 2.039V21H3.984q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h3.188L9 3h6l1.828 2.016h3.188z"/>'
  }
})
