/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location_pin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-1.453 0-2.719.539t-2.227 1.5-1.5 2.227-.539 2.719q0 1.313.352 2.531t1.055 2.297q.703 1.172 1.57 2.203t1.57 2.203q.375.563.633 1.102t.539 1.148q.141.281.281.633t.375.609.609.258q.375 0 .609-.258t.375-.609.281-.633q.281-.609.539-1.148t.633-1.102q.703-1.125 1.57-2.18t1.57-2.227q.703-1.078 1.055-2.297T18.983 9q0-1.453-.539-2.719t-1.5-2.227-2.227-1.5-2.719-.539zm0 9.75q-1.031 0-1.758-.75t-.727-1.781.727-1.758T12 6.75t1.758.727.727 1.758-.727 1.781-1.758.75z"/>'
  }
})
