/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sentiment_dissatisfied': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 16.5q-1.031 0-1.969.516l-.516-.563q-.047-.047-.516-.563 1.313-.891 3-.891 1.594 0 3 .891-.094.047-.539.539t-.492.586q-.891-.516-1.969-.516zm0 3.516q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm-5.016 7.5q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445-1.055-.445-.445-1.055zm7.032 0q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445-1.055-.445-.445-1.055z"/>'
  }
})
