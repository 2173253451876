/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'battery_alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 14.016V9h-1.969v5.016h1.969zm0 3.984v-2.016h-1.969V18h1.969zm2.672-14.016q.563 0 .961.398t.398.961v15.328q0 .563-.398.938t-.961.375H8.343q-.563 0-.961-.375t-.398-.938V5.343q0-.563.398-.961t.961-.398h1.641V2.015h4.031v1.969h1.641z"/>'
  }
})
