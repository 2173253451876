/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pageview': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.781 18.188l1.406-1.406-2.906-2.906q.703-1.172.703-2.391 0-1.875-1.313-3.188t-3.188-1.313-3.188 1.313-1.313 3.188 1.313 3.188 3.188 1.313q1.219 0 2.391-.703zm3.235-14.204q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h16.031zM11.484 9q1.031 0 1.781.727t.75 1.758-.75 1.781-1.781.75-1.758-.75-.727-1.781.727-1.758T11.484 9z"/>'
  }
})
