/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flight_land': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 14.438Q10.172 13.36 4.36 11.86l-1.594-.469V6.235l1.453.375.938 2.344 4.969 1.313v-8.25l1.922.516 2.766 9 5.297 1.406q.609.188.914.727t.164 1.148q-.188.609-.703.891t-1.125.141zM2.484 18.984h19.031V21H2.484v-2.016z"/>'
  }
})
