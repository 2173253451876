/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.422 6.563L12 3.985l8.016 8.016-2.578 2.578 1.406 1.406 2.578-2.578q.563-.563.563-1.406t-.563-1.406l-8.016-8.016q-.563-.563-1.406-.563t-1.406.563L8.016 5.157zm-6.609-3.75L1.407 4.219l3.75 3.797-2.578 2.578q-.563.563-.563 1.406t.563 1.406l8.016 8.016q.563.563 1.406.563t1.406-.563l2.578-2.578 3.797 3.75 1.406-1.406zM12 20.016L3.984 12l2.578-2.578 1.594 1.594H6.984v1.969h3.188l1.5 1.5-1.078 1.125L12 17.016l1.078-1.125 1.5 1.5zM10.922 8.109L12 6.984 17.016 12l-1.078 1.078z"/>'
  }
})
