/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nature_people': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.5 11.016q-.656 0-1.078-.445T3 9.516t.422-1.055T4.5 8.016t1.078.445T6 9.516t-.422 1.055-1.078.445zm17.672-1.828q0 2.578-1.805 4.594t-4.383 2.344v3.891h3v1.969H3v-4.969h-.984v-4.031q0-.422.281-.703T3 12.002h3q.422 0 .703.281t.281.703v4.031H6v3h8.016v-3.938q-2.438-.375-4.148-2.391t-1.711-4.5q0-2.906 2.063-4.969t4.969-2.063 4.945 2.063 2.039 4.969z"/>'
  }
})
