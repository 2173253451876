/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mouse': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 1.078V9H3.985q0-3.047 2.039-5.297t4.992-2.625zM3.984 15v-3.984h16.031V15q0 3.281-2.367 5.648T12 23.015t-5.648-2.367T3.985 15zm9-13.922q2.953.375 4.992 2.625T20.015 9h-7.031V1.078z"/>'
  }
})
