/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'event_busy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18.984V8.015H5.015v10.969h13.969zm0-15.984q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43V5.014q0-.797.586-1.406t1.43-.609h.984V.983h2.016v2.016h7.969V.983H18v2.016h.984zM9.328 17.016L8.25 15.938l2.438-2.438-2.438-2.438 1.078-1.078 2.438 2.438 2.438-2.438 1.031 1.078-2.438 2.438 2.438 2.438-1.031 1.078-2.438-2.438z"/>'
  }
})
