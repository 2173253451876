/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'monitor': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 3H3.985q-.797 0-1.383.586t-.586 1.43v10.969q0 .844.586 1.43t1.383.586h3l-.984.984v2.016h12v-2.016l-.984-.984h3q.797 0 1.383-.586t.586-1.43V5.016q0-.844-.586-1.43T20.017 3zm0 12.984H3.985V5.015h16.031v10.969z"/>'
  }
})
