/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18v-3l3.984 3.984L12 23.015v-3q-3.281 0-5.648-2.367T3.985 12q0-2.344 1.266-4.266l1.453 1.453Q6.001 10.453 6.001 12q0 2.484 1.758 4.242T12.001 18zm0-14.016q3.281 0 5.648 2.367t2.367 5.648q0 2.344-1.266 4.266l-1.453-1.453q.703-1.266.703-2.813 0-2.484-1.758-4.242t-4.242-1.758v3L8.015 5.015 11.999.984v3z"/>'
  }
})
