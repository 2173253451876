/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bento': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 11.016v-6h4.031q.797 0 1.383.586t.586 1.383v4.031h-6zm4.032 7.968q.797 0 1.383-.586t.586-1.383v-4.031h-6v6h4.031zm-6-13.968v13.969H3.985q-.797 0-1.383-.586t-.586-1.383V6.985q0-.797.586-1.383t1.383-.586h10.031zM9.516 12q0-.609-.445-1.055T8.016 10.5q-.656 0-1.078.445T6.516 12t.422 1.055 1.078.445q.609 0 1.055-.445T9.516 12z"/>'
  }
})
