/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_right_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.031 11.016v-3L20.015 12l-3.984 3.984v-3H3.984v-1.969h12.047z"/>'
  }
})
