/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dynamic_form': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 20.016v-9H15V3.985h6.984l-1.969 5.016h1.969zM15 12.984v7.031H3.984q-.797 0-1.383-.586t-.586-1.43v-3q0-.844.586-1.43t1.383-.586H15zM6.234 15.75h-1.5v1.5h1.5v-1.5zm6.75-11.766v7.031h-9q-.797 0-1.383-.586t-.586-1.43v-3q0-.844.586-1.43t1.383-.586h9zM6.234 6.75h-1.5v1.5h1.5v-1.5z"/>'
  }
})
