/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_carousel': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 6h3.984v11.016H18V6zM2.016 17.016V6H6v11.016H2.016zm4.968 1.968v-15h10.031v15H6.984z"/>'
  }
})
