/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'code': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.578 16.594L19.219 12l-4.641-4.594L15.984 6l6 6-6 6zm-5.156 0L8.016 18l-6-6 6-6 1.406 1.406L4.781 12z"/>'
  }
})
