/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.516 8.016q1.031 0 1.758-.75t.727-1.781q0-.609-.375-1.383t-.867-1.477-.867-1.172l-.375-.469-.375.469-.891 1.172-.891 1.477-.375 1.383q0 1.031.75 1.781t1.781.75zM13.5 9q.609 0 1.055-.445T15 7.5q0-.328-.234-.75t-.516-.82-.516-.656l-.234-.258-.234.258-.516.656-.516.82L12 7.5q0 .609.445 1.055T13.5 9zM9.141 5.016l-7.219 6.797q-.938.844-.938 2.156v6.047q0 .797.422 1.477t1.102 1.102 1.477.422h13.781q.516 0 .867-.375t.352-.891-.352-.891-.867-.375H12v-.984h7.734q.516 0 .891-.375t.375-.891-.375-.867-.891-.352H12v-1.031h8.766q.516 0 .867-.352t.352-.867-.352-.891-.867-.375H12v-.984h6.75q.516 0 .891-.375t.375-.891-.375-.891-.891-.375H8.859q.328-.563.68-1.148t.586-1.008l.234-.422q.141-.281.141-.563 0-.375-.281-.703l-.164-.164-.375-.398-.375-.398-.164-.164z"/>'
  }
})
