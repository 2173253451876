/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fast_forward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 6l8.531 6-8.531 6V6zm-9 12V6l8.531 6z"/>'
  }
})
