/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_circle_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q-1.641 0-3.094-.633t-2.555-1.734-1.734-2.555T3.984 12t.633-3.094 1.734-2.555 2.555-1.734T12 3.984t3.094.633 2.555 1.734 1.734 2.555.633 3.094-.633 3.094-1.734 2.555-2.555 1.734-3.094.633zm0 1.968q2.063 0 3.867-.773t3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016t-3.867.773-3.188 2.156-2.156 3.188T2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773zM11.016 12v3.984h1.969V12h3l-3.984-3.984L8.017 12h3z"/>'
  }
})
