/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backspace': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 15.609L15.421 12l3.563-3.609-1.406-1.406-3.563 3.609-3.609-3.609L9 8.391 12.609 12 9 15.609l1.406 1.406 3.609-3.609 3.563 3.609zm3-12.609q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609h-15q-.938 0-1.594-.891l-5.391-8.109L5.39 3.89q.656-.891 1.594-.891h15z"/>'
  }
})
