<template>
  <div>
    <CRow v-if="subscribingDelivery">
      <CCol md="12" lg="6">
        <BranchDistribution :title="$t('cloud.title.card_branch_distribution')" />
      </CCol>

      <CCol md="6" lg="3">
        <CWidgetProgressIcon
          :header="$t('cloud.title.service_period')"
          :text="getServicePeriod(subscribingDelivery)"
          :color="getServiceUsage(subscribingDelivery) > 80 ? 'danger': 'sucess'"
          :value="getServiceUsage(subscribingDelivery)"
        >
          <CIcon name="cil-truck" height="36"/>
        </CWidgetProgressIcon>
        <CWidgetProgressIcon v-if="correspondent_total_count !== null"
          :header="`${correspondent_total_count}`"
          :text="$t('cloud.title.card_text_vendors')"
          color="danger"
          :value="correspondent_total_count"
        >
          <CIcon name="cil-building" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol md="6" lg="3">
        <CWidgetProgressIcon
          :header="`${capability.organization.num_branch}`"
          :text="$t('cloud.title.card_text_factories')"
          color="info"
          :value="100"
        >
          <CIcon name="cil-factory" height="36"/>
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="`${capability.organization.num_user}`"
          :text="$t('cloud.title.card_text_users')"
          color="warning"
          :value="capability.organization.num_user/capability.organization.max_user*100"
        >
          <CIcon name="cil-people" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
    </CRow>

    <CRow v-if="subscribingDelivery">
      <CCol md="12" lg="6">
        <TodayDeliver />
      </CCol>
      <CCol md="12" lg="6">
        <WeekAmount />
      </CCol>
    </CRow>

    <CRow v-if="subscribingParking">
      <CCol md="12" lg="6">
        <BranchDistribution :title="$t('cloud.title.card_place_distribution')" branch_type="place" />
      </CCol>
      <CCol md="6" lg="3">
        <CWidgetProgressIcon
          :header="`${capability.organization.num_branch}`"
          :text="$t('cloud.title.card_text_branches')"
          color="info"
          :value="capability.organization.num_branch"
        >
          <CIcon name="cil-building" height="36"/>
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="`${place_total_count}`"
          :text="$t('cloud.title.card_text_places')"
          color="danger"
          :value="place_total_count"
        >
          <CIcon name="cil-garage" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol md="6" lg="3">
        <CWidgetProgressIcon
          :header="`${capability.organization.num_device}`"
          :text="$t('cloud.title.card_text_devices')"
          color="success"
          :value="100"
        >
          <CIcon name="cil-storage" height="36"/>
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="`${capability.organization.num_user}`"
          :text="$t('cloud.title.card_text_users')"
          color="warning"
          :value="capability.organization.num_user/capability.organization.max_user*100"
        >
          <CIcon name="cil-people" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
    </CRow>
    <CRow v-if="subscribingParking">
      <CCol md="12" lg="6">
        <CurrentOccupancy />
      </CCol>
      <CCol md="12" lg="6">
        <RecentOccupancy />
      </CCol>
    </CRow>

    <CRow v-if="subscribingRetail">
      <CCol md="12" lg="4">
        <CWidgetProgressIcon
          :header="`${store_total_count}`"
          :text="$t('cloud.title.card_text_stores')"
          color="success"
          :value="store_total_count"
        >
          <CIcon name="cil-room" height="36"/>
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="`${capability.organization.num_device}`"
          :text="$t('cloud.title.card_text_devices')"
          color="success"
          :value="100"
        >
          <CIcon name="cil-storage" height="36"/>
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="`${capability.organization.num_user}`"
          :text="$t('cloud.title.card_text_users')"
          color="warning"
          :value="capability.organization.num_user/capability.organization.max_user*100"
        >
          <CIcon name="cil-people" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol md="12" lg="6" class="d-lg-none">
        <BranchDistribution :title="$t('cloud.title.card_store_distribution')" branch_type="store" />
      </CCol>
      <CCol lg="8" class="d-md-down-none">
        <BranchDistribution :title="$t('cloud.title.card_store_distribution')" branch_type="store" :map-height="538" />
      </CCol>
      <CCol md="12">
        <StoreRank />
      </CCol>
    </CRow>
    <CRow v-if="subscribingRetail">
      <CCol md="12" lg="6">
      </CCol>
      <!-- <CCol md="12" lg="6">
        <CounterTrend time-range="d" />
      </CCol>
      <CCol md="12" lg="6">
        <BIGender />
      </CCol> -->
    </CRow>

    <CRow v-if="subscribingCongestion">
      <CCol md="6" lg="4">
        <CWidgetProgressIcon
          :header="$t('cloud.title.service_period')"
          :text="getServicePeriod(subscribingCongestion)"
          :color="getServiceUsage(subscribingCongestion) > 80 ? 'danger': 'sucess'"
          :value="getServiceUsage(subscribingCongestion)"
        >
          <CIcon name="cil-group" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol md="6" lg="4">
        <CWidgetProgressIcon
          :header="`${congestion.zone_count}`"
          :text="$t('cloud.title.manage_zone_cnt')"
          color="info"
          :value="100"
        >
          <CIcon name="cil-video" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol md="6" lg="4">
        <CWidgetProgressIcon
          :header="$t('cloud.title.recent_reporting')"
          :text="congestion.last_reporting_time"
          color="success"
          :value="100"
        >
          <CIcon name="cil-av-timer" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol md="12" lg="6">
        <CardLineCharts
          :title="$t('cloud.title.occupancy_reporting_count')"
          :stats="[congestion.reporting_counts_hourly]"
          :labels="[$t('cloud.title.reporting_count_hourly')]"
          :colors="['#355ed4']"
        />
      </CCol>
      <CCol md="12" lg="6">
        <CardLineCharts
          :title="$t('cloud.title.occupancy_reporting_count')"
          :stats="[congestion.reporting_counts_daily]"
          :labels="[$t('cloud.title.reporting_count_daily')]"
          :colors="['#b0534c']"
        />
      </CCol>
      <CCol md="12" lg="6">
        <CardLineCharts
          :title="$t('cloud.title.query_count')"
          :stats="[congestion.query_counts_hourly]"
          :labels="[$t('cloud.title.query_count_hourly')]"
          :colors="['#355ed4']"
        />
      </CCol>
      <CCol md="12" lg="6">
        <CardLineCharts
          :title="$t('cloud.title.query_count')"
          :stats="[congestion.query_counts_daily]"
          :labels="[$t('cloud.title.query_count_daily')]"
          :colors="['#b0534c']"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import { mapState, mapGetters } from 'vuex'
import StoreRank from './cards/StoreRank.vue'
import TodayDeliver from './cards/TodayDeliver.vue'
import WeekAmount from './cards/WeekAmount.vue'
import CurrentOccupancy from './cards/CurrentOccupancy.vue'
import RecentOccupancy from './cards/RecentOccupancy.vue'
import BranchDistribution from './cards/BranchDistribution.vue'
import CardLineCharts from '@/components/cards/LineCharts.vue'
// import TestPie from '../../components/charts/TestPie.vue'
// import TestLine from '../../components/charts/TestLine.vue'

export default {
  name: 'Dashboard',

  components: {
    StoreRank,
    TodayDeliver,
    WeekAmount,
    CurrentOccupancy,
    RecentOccupancy,
    BranchDistribution,
    CardLineCharts,
  },
  data() {
    return {
      layout: [],
      card_list: null,
      branch_list: [],
      branch_total_count: null,
      correspondent_list: [],
      correspondent_total_count: null,
      place_list: [],
      place_total_count: 0,
      store_list: [],
      store_total_count: 0,
      congestion: {
        zone_count: '',
        last_reporting_time: '',
        last_reporting_zone: '',
        reporting_counts_hourly: [],
        reporting_counts_daily: [],
        query_counts_hourly: [],
        query_counts_daily: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    ...mapState([
      'capability'
    ]),
    subscribingDelivery() {
      if (!(this.capability && this.capability.organization.id)) return null;

      const subscriptions = this.capability.organization.subscription;
      if (subscriptions.length <= 0) return null;

      const service = subscriptions.find(el => el.service_key === 'delivery');
      if (!service) return null;

      const remain_hours = this.$utility.SubscriptionRemainHour(service);
      if (remain_hours <= 0) return null;

      return service;
    },
    subscribingParking() {
      if (!(this.capability && this.capability.organization.id)) return false;

      const subscriptions = this.capability.organization.subscription;
      if (subscriptions.length <= 0) return false;

      const service = subscriptions.find(el => el.service_key === 'parking');
      if (!service) return false;

      const remain_hours = this.$utility.SubscriptionRemainHour(service);
      if (remain_hours <= 0) return false;

      return service;
    },
    subscribingRetail() {
      if (!(this.capability && this.capability.organization.id)) return false;

      const subscriptions = this.capability.organization.subscription;
      if (subscriptions.length <= 0) return false;

      const service = subscriptions.find(el => el.service_key === 'retail');
      if (!service) return false;

      const remain_hours = this.$utility.SubscriptionRemainHour(service);
      if (remain_hours <= 0) return false;

      return service;
    },
    subscribingOccupancy() {
      if (!(this.capability && this.capability.organization.id)) return false;

      const subscriptions = this.capability.organization.subscription;
      if (subscriptions.length <= 0) return false;

      const service = subscriptions.find(el => el.service_key === 'occupancy');
      if (!service) return false;

      const remain_hours = this.$utility.SubscriptionRemainHour(service);
      if (remain_hours <= 0) return false;

      return service;
    },
    subscribingCongestion() {
      if (!(this.capability && this.capability.organization.id)) return false;

      const subscriptions = this.capability.organization.subscription;
      if (subscriptions.length <= 0) return false;

      const service = subscriptions.find(el => el.service_key === 'congestion');
      if (!service) return false;

      const remain_hours = this.$utility.SubscriptionRemainHour(service);
      if (remain_hours <= 0) return false;

      return service;
    }
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getCardList();
      // this.getBranchList();
      if (this.subscribingDelivery) {
        // this.getFreightList();
        this.getCorrespondentList();
      }
      if (this.subscribingParking) {
        this.getPlaceList();
      }
      if (this.subscribingRetail) {
        this.getStoreList();
      }
      if (this.subscribingCongestion) {
        this.getCongestionZone();
        this.getCongestionStats();
      }
    },
    getCardList() {
      axios.get(`/api/dashboard/cards/`)
        .then(result => {
          this.card_list = result.data;
          for (const card of this.card_list) {
            this.layout.push({"x":card.x*3,"y":card.y,"w":card.card_type.width,"h":card.card_type.height,"i":`card-list-items-${card.id}`})
          }
        })
        .catch(error => {
          console.error(error);
        })
    },
    // getBranchList() {
    //   let query_string = '';
    //   const params = {
    //     ipp: 10,
    //     page: 1
    //   };
    //   if (params) {
    //     query_string = '?' + qs.stringify(params)
    //   }
    //   axios.get(`/api/branches/${query_string}`)
    //     .then(result => {
    //       this.branch_list = result.data.list;
    //       // this.pageTotal = result.data.page.total;
    //       // this.pageCurrent = result.data.page.current;
    //       this.branch_total_count = result.data.page.count;
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     })
    // },
    getCorrespondentList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 10,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/correspondents/${query_string}`)
        .then(result => {
          this.correspondent_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          this.correspondent_total_count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
        })
    },
    getPlaceList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 10,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/parking/places/${query_string}`)
        .then(result => {
          this.place_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          this.place_total_count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
        })
    },
    getStoreList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 10,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/bi/stores/${query_string}`)
        .then(result => {
          this.store_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          this.store_total_count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
        })
    },
    getServicePeriod(service) {
      const from_date = new Date(service.created_at);
      const from_str = this.$utility.GetDateTimeStr("$yyyy-$mm-$dd", from_date);
      const to_date = new Date(service.expiration);
      const to_str = this.$utility.GetDateTimeStr("$yyyy-$mm-$dd", to_date);
      return `${from_str} ~ ${to_str}`;
    },
    getServiceUsage(service) {
      const total_day = service.period_day;
      const used_ts = Date.now() - new Date(service.created_at);
      const used_day = parseInt(used_ts / 1000 / 60 / 60 / 24);
      return parseInt(used_day / total_day * 100);
    },

    getCongestionZone() {
      axios.get(`/api/congestion-control/zones/latest/?ipp=5`)
        .then(result => {
          this.congestion.zone_count = result.data.page.items_total_count;
          if (this.congestion.zone_count > 0) {
            this.congestion.last_reporting_time = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(result.data.list[0].latest.date));
          } else {
            '-'
          }
        })
        .catch(e => {
          console.error(e);
        })
    },
    getCongestionStats() {
      let d_ets = new Date();
      d_ets.setHours(d_ets.getHours() + 1);
      d_ets.setMinutes(0);
      d_ets.setSeconds(0);
      d_ets.setMilliseconds(0);
      let ets = d_ets.getTime();
      let d_sts = new Date(ets - (24*60*60*1000));
      let sts = d_sts.getTime();
      let params = {sts,ets};
      axios.get(`/api/congestion-control/statistics/reporting-count/?${qs.stringify(params)}`)
        .then(result => {
          this.congestion.reporting_counts_hourly = result.data.map(el => {
            return {
              x: el.created_at,
              value: el.rcount
            }
          });
        })
        .catch(e => {
          console.error(e);
        })
      axios.get(`/api/congestion-control/statistics/query-count/?${qs.stringify(params)}`)
        .then(result => {
          this.congestion.query_counts_hourly = result.data.map(el => {
            return {
              x: el.created_at,
              value: el.rcount
            }
          });
        })
        .catch(e => {
          console.error(e);
        })

      d_ets = new Date();
      d_ets.setHours(0);
      d_ets.setMinutes(0);
      d_ets.setSeconds(0);
      d_ets.setMilliseconds(0);
      d_ets.setDate(d_ets.getDate()+1);
      ets = d_ets.getTime();
      d_sts = new Date(ets - (15*24*60*60*1000));
      sts = d_sts.getTime();
      params = {sts,ets,interval:24};
      axios.get(`/api/congestion-control/statistics/reporting-count/?${qs.stringify(params)}`)
        .then(result => {
          this.congestion.reporting_counts_daily = result.data.map(el => {
            return {
              x: el.created_at,
              value: el.rcount
            }
          });
        })
        .catch(e => {
          console.error(e);
        })
      axios.get(`/api/congestion-control/statistics/query-count/?${qs.stringify(params)}`)
        .then(result => {
          this.congestion.query_counts_daily = result.data.map(el => {
            return {
              x: el.created_at,
              value: el.rcount
            }
          });
        })
        .catch(e => {
          console.error(e);
        })
    }
  }
}
</script>
