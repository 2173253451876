<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <div v-if="step === 0">
      <SearchList v-if="device_updated.organization === null"
        ph="Select Organization"
        :list="organization_list"
        :pageTotal="pageTotalOrganization"
        @search="onSearchOrganization"
        @focus="onFocusOrganization"
        @select="onSelectOrganization"
        @page="onPageOrganization"
      />
      <CCard v-else>
        <CCardBody>
          <div class="my-2">Organization</div>
          <CWidgetIcon :header="device_updated.organization_name" text="" color="info">
            <CIcon name="cil-building" width="24" />
          </CWidgetIcon>
          <CButton style="float: right;" color="danger" shape="pill" @click="onReset">Reset</CButton>
        </CCardBody>
      </CCard>
    </div>
    <CCard v-if="step === 1">
      <CCardBody>
        <CInput
          label="Name"
          placeholder="Device name"
          v-model="device_updated.name"
        />
        <CInput
          label="MAC Address"
          placeholder="00:11:5f:00:00:00"
          v-model="device_updated.mac"
        />
        <CInputCheckbox
          class="m-1"
          label="Authentication"
          @click="authChange"
          :checked.sync="auth_required"
        />
        <CInput
          v-if="auth_required"
          label="ID"
          placeholder="Device Authentication ID"
          v-model="auth_id"
        />
        <CInput
          v-if="auth_required"
          label="Password"
          placeholder="Device Authentication Password"
          type="password"
          v-model="auth_pw"
        />
      </CCardBody>
    </CCard>
    <template #header>
      <h6 class="modal-title">{{title}}  {{" - STEP ("+(step+1)+"/2)"}}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step === 0" @click="onCancel" color="secondary">Cancel</CButton>
      <CButton v-if="step === 0" @click="onNext" color="primary">Next</CButton>
      <CButton v-if="step === 1" @click="onPrev" color="secondary">Prev</CButton>
      <CButton v-if="step === 1" @click="onSubmit" color="primary">Submit</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue';

export default {
  name: 'DeviceAdd',
  components: {
    SearchList
  },
  data() {
    return {
      show: false,

      name: null,
      mac: null,

      auth_id: null,
      auth_pw: null,
      auth_required: false,

      searchTextOrganization: null,
      organization_list: [],
      pageTotalOrganization: 1,
      pageCurrentOrganization: 1,

      device: null,
      title: '',
      device_updated:{
        id:null,
        name:null,
        mac:null,
        status:null,
        max_ch:null,
        model:null,
        organization:null,
        organization_name:null
      },

      notification: '',
      dismissCountDown: 0,
      step: 0
    }
  },
  methods: {
    open(param) {
      this.title = param.title;
      if (param.device) {
        this.device = JSON.parse(JSON.stringify(param.device));
        this.auth_id = param.device.auth_id;
        this.auth_pw = param.device.auth_pw;
      }
      this.initialize();
      this.show = true;
    },
    initialize(){
      if(this.device) { // edit
        this.device_updated = JSON.parse(JSON.stringify(this.device));
        this.auth_required = false;
        if (this.device.auth_id && this.device.auth_pw) {
          this.auth_required = true;
        }
      } else { // add
        this.device_updated = {
          id:null,
          name:null,
          mac:null,
          status:null,
          max_ch:null,
          model:null,
          organization:null,
          organization_name:null
        };
      }
      this.step = 0;
    },
    getOrganization(){
      let query_string = '';
      const params = {
        search: this.searchTextOrganization,
        page: this.pageCurrentOrganization
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/organizations/${query_string}`)
        .then(result => {
          this.organization_list = result.data.list;
          this.pageTotalOrganization = result.data.page.total;
          this.pageCurrentOrganization = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onSearchOrganization: function(text) {
      this.searchTextOrganization = text;
      this.getOrganization();
    },
    onFocusOrganization: function() {
      this.getOrganization();
    },
    onSelectOrganization: function(id) {
      let selected_organization = this.organization_list.find(el=>el.id===id);
      this.device_updated.organization = selected_organization.id;
      this.device_updated.organization_name = selected_organization.name;
    },
    onCancel() {
      this.show = false;

      this.device_updated.name = null;
      this.device_updated.mac = null;
      this.auth_id = null;
      this.auth_pw = null;
      this.auth_required = false;
    },
    onSubmit() {
      if(this.validate_basic()){
        let payload = {
          name: this.device_updated.name,
          mac: this.device_updated.mac,
          auth_id: this.auth_id,
          auth_pw: this.auth_pw,
          organization: this.device_updated.organization
        };
        if (this.device) {
          payload.id = this.device.id;
        }
        this.$emit('payload', payload);
        this.show = false;
      }else{
        return;
      }
    },

    validate_basic(){
      if(this.device &&
        this.device.organization === this.device_updated.organization &&
        this.device.name === this.device_updated.name &&
        this.device.mac === this.device_updated.mac &&
        this.device.auth_id === this.device_updated.auth_id &&
        this.device.auth_pw === this.device_updated.auth_pw) {
        this.notification = '변경된 항목이 없습니다'
        this.dismissCountDown = 3;
        return false;
      }

      if (!this.device_updated.name) {
        this.notification = 'Name is required';
        this.dismissCountDown = 3;
        return false;
      }
      if (this.device_updated.name.length < 4) {
        this.notification = 'Name is too short';
        this.dismissCountDown = 3;
        return false;
      }
      if (!this.device_updated.mac) {
        this.notification = 'MAC address is required'
        this.dismissCountDown = 3;
        return false;
      }
      if (/[^0-9a-fzA-F\:]+/.test(this.device_updated.mac)) {
        this.notification = 'MAC address format is wrong'
        this.dismissCountDown = 3;
        return false;
      }
      if (this.device_updated.mac.replaceAll(':', '').length !== 12) {
        this.notification = 'MAC address format is wrong'
        this.dismissCountDown = 3;
        return false;
      }
      if(this.auth_required) { // id/pw form 이 있을 경우에만
        if (!this.auth_id || !this.auth_pw || this.auth_id.length < 4) {
          this.notification = 'Device authentication error'
          this.dismissCountDown = 3;
          return false;
        }
      }
      return true;
    },
    authChange : function(){
      this.auth_id = null;
      this.auth_pw = null;
    },
    onPageOrganization: function(p) {
      this.pageCurrentOrganization = p;
      this.getOrganization();
    },
    onNext() {
      if (this.step === 0) {
        if (!this.device_updated.organization) {
          this.notification = "Organization is required.";
          this.dismissCountDown = 3;
          return;
        }
      }
      this.step++;
    },
    onPrev() {
      this.step--;
    },
    onReset() {
      this.device_updated.organization = null;
      this.device_updated.organization_name = null;
      this.pageCurrentOrganization = 1;
      this.searchTextOrganization = null;
      this.organization_list = [];
    }
  }
}
</script>