<template>
  <div>
    <!-- <CButtonGroup class="mb-4">
      <CButton :color="t==='analytics'?'primary':'secondary'" @click="t='analytics'">{{$t('cloud.title.retail_analytics')}}</CButton>
      <CButton :color="t==='setting'?'primary':'secondary'" @click="t='setting'">{{$t('cloud.title.setting')}}</CButton>
    </CButtonGroup> -->

    <Analytics v-if="t==='analytics'" />
  </div>
</template>

<script>
import Analytics from './Analytics.vue'

export default {
  name: 'RetailVisitAdvanced',

  components: {
    Analytics
  },

  data () {
    return {
      t: 'analytics'
    }
  }
}
</script>