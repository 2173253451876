/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'construction': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.781 15.188l2.109-2.156 6 6-2.109 2.156zm3.703-5.204q.984 0 1.781-.469t1.266-1.266T21 6.515q0-.469-.117-.867t-.305-.727l-2.672 2.672-1.5-1.5 2.672-2.672q-.328-.188-.727-.305t-.867-.117q-.938 0-1.734.469t-1.266 1.266-.469 1.781q0 .563.188 1.125l-1.828 1.875-1.781-1.781.703-.703-1.406-1.406L12 3.469q-.563-.563-1.336-.75t-1.547 0-1.336.75L4.218 7.032l1.406 1.406H2.811l-.703.703 3.516 3.563.703-.703V9.142l1.453 1.406.703-.703 1.781 1.781-7.406 7.406 2.109 2.109L16.358 9.797q.563.188 1.125.188z"/>'
  }
})
