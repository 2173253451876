<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="chart">
      <center>
        <div style="max-width: 630px;">
          <CChartLine
            :datasets="chartData"
            :labels="chartLabel"
            :options="chartOption"
          />
        </div>
      </center>
    </template>
  </CardContainer>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'RecentOccupancyRate',
  components: {
    CardContainer,
    CChartLine
  },
  computed: {
    chartData() {
      let rtn = {
        label: `${this.$t('cloud.title.card_label_occupancy')}(%)`,
        data: [],
        borderColor: '#2586a4',
        fill: false,
        tension: 0.4
      }
      if (this.stats.length <= 0) return [rtn];
      for (let stat of this.stats) {
        let occupancy = Math.round(stat.occupied / (stat.occupied+stat.free) * 100);
        rtn.data.push(occupancy);
      }
      return [rtn];
    },
    chartLabel() {
      if (this.stats.length <= 0) return ['No data'];
      let rtn = [];
      for (let i in this.stats) {
        const created_at = this.stats[i].created_at;
        const d = new Date(created_at);
        const hour = d.getHours();
        const ampm = 'AM';
        if (hour > 12) ampm = 'PM';
        if (i % 3 === 0) rtn.push(ampm+' '+hour);
        else rtn.push('');
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              max: 100
            },
            gridLines: {
              display: true
            }
          }]
        }
      };
    }
  },
  data() {
    return {
      stats: null,
      title: this.$t('cloud.title.card_recent_occupancy')
    }
  },
  mounted() {
    this.statRecentOccupancy();
  },
  methods: {
    statRecentOccupancy() {
      let query_string = '';
      const params = {};
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      params.sts = now.getTime();
      params.ets = params.sts + (1000 * 60 * 60 * 24);
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/parking/statistics/recent-occupancy/${query_string}`)
        .then(result => {
          this.stats = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>