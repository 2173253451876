/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dialpad': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .984q.797 0 1.406.609t.609 1.406-.609 1.406T12 5.014t-1.406-.609-.609-1.406.609-1.406T12 .984zm0 6q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 6.984zm6 0q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T18 6.984zm0 6q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T18 12.984zm-6 0q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 12.984zm6-7.968q-.797 0-1.406-.609t-.609-1.406.609-1.406T18 .986t1.406.609.609 1.406-.609 1.406T18 5.016zM6 12.984q.797 0 1.406.609t.609 1.406-.609 1.406T6 17.014t-1.406-.609-.609-1.406.609-1.406T6 12.984zm0-6q.797 0 1.406.609t.609 1.406-.609 1.406T6 11.014t-1.406-.609-.609-1.406.609-1.406T6 6.984zm0-6q.797 0 1.406.609t.609 1.406-.609 1.406T6 5.014t-1.406-.609-.609-1.406.609-1.406T6 .984zm6 18q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 18.984z"/>'
  }
})
