/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_missed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.594 6.984L21 8.39l-9 9-6.984-6.984V15H3V6.984h8.016V9H6.422L12 14.578z"/>'
  }
})
