/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contact_page': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 2.016H6q-.844 0-1.43.586t-.586 1.383v16.031q0 .797.586 1.383t1.43.586h12q.844 0 1.43-.586t.586-1.383v-12zM12 9.984q.844 0 1.43.586t.586 1.43-.586 1.43-1.43.586-1.43-.586T9.984 12t.586-1.43T12 9.984zM15.984 18H8.015v-.563q0-.609.328-1.125t.891-.75q.609-.281 1.313-.422T12 14.999t1.453.141 1.313.422q.563.234.891.75t.328 1.125V18z"/>'
  }
})
