/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select_all': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 9v6h6V9H9zm-2.016 8.016V6.985h10.031v10.031H6.984zm8.016-12V3h2.016v2.016H15zM15 21v-2.016h2.016V21H15zm3.984-3.984V15H21v2.016h-2.016zm0-8.016V6.984H21V9h-2.016zm0 12v-2.016H21q0 .797-.609 1.406t-1.406.609zm0-8.016v-1.969H21v1.969h-2.016zM11.016 21v-2.016h1.969V21h-1.969zM9 3v2.016H6.984V3H9zM3 17.016V15h2.016v2.016H3zM5.016 21q-.797 0-1.406-.609t-.609-1.406h2.016v2.016zM18.984 3q.797 0 1.406.609t.609 1.406h-2.016V2.999zm-6 0v2.016h-1.969V3h1.969zM3 9V6.984h2.016V9H3zm3.984 12v-2.016H9V21H6.984zM3 12.984v-1.969h2.016v1.969H3zm0-7.968q0-.797.609-1.406t1.406-.609v2.016H2.999z"/>'
  }
})
