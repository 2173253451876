/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_vertical_select': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.922 21.141q-.797-2.203-1.219-4.523t-.422-4.617q0-4.688 1.641-9.141.094-.141.094-.281 0-.563-.656-.563H4.641q-.656 0-.656.563 0 .141.047.281.844 2.203 1.266 4.523T5.72 12q0 4.688-1.641 9.141-.094.141-.094.281 0 .563.656.563H19.36q.656 0 .656-.563 0-.141-.094-.281z"/>'
  }
})
