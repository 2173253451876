/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'explicit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9V6.984H9v10.031h6v-2.016h-3.984v-2.016H15v-1.969h-3.984V8.998H15zm3.984-6q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
