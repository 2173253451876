/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'analytics': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zM9 17.016H6.984V12H9v5.016zm3.984 0h-1.969v-3h1.969v3zm0-5.016h-1.969V9.984h1.969V12zm4.032 5.016H15V6.985h2.016v10.031z"/>'
  }
})
