/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open_in_browser': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9.984l3.984 4.031h-3v6h-1.969v-6h-3zm6.984-6q.844 0 1.43.586T21 6v12q0 .797-.609 1.406t-1.406.609h-3.984v-2.016h3.984V8.015H5.016v9.984H9v2.016H5.016q-.844 0-1.43-.586T3 17.999v-12q0-.844.586-1.43t1.43-.586h13.969z"/>'
  }
})
