/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'priority_high': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3h4.031v12H9.984V3zm0 15.984q0-.844.586-1.406t1.43-.563 1.43.563.586 1.406-.586 1.43T12 21t-1.43-.586-.586-1.43z"/>'
  }
})
