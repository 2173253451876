/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'swap_calls': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 3.984l3.984 4.031h-3v6.984q0 1.641-1.172 2.813t-2.813 1.172-2.813-1.172-1.172-2.813V8.015q0-.797-.609-1.406T8.999 6t-1.406.609-.609 1.406v6.984h3L6 18.983l-3.984-3.984h3V8.015q0-1.641 1.172-2.836t2.813-1.195 2.813 1.195 1.172 2.836v6.984q0 .797.609 1.406t1.406.609 1.406-.609.609-1.406V8.015h-3z"/>'
  }
})
