/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ev_station': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 18L12 11.016H9.984V6L6 13.5h2.016V18zM18 9.984q.422 0 .703-.281T18.984 9t-.281-.703T18 8.016t-.703.281-.281.703.281.703.703.281zm1.781-2.765q.703.703.703 1.781v9.516q0 1.031-.727 1.758t-1.758.727-1.758-.727-.727-1.758V13.5h-1.5V21H3.983V5.016q0-.797.609-1.406t1.406-.609h6q.797 0 1.406.609t.609 1.406V12h.984q.797 0 1.406.609t.609 1.406v4.5q0 .422.281.703t.703.281.703-.281.281-.703v-7.219q-.422.188-.984.188-1.031 0-1.758-.727t-.727-1.758q0-1.688 1.594-2.344l-2.109-2.109 1.078-1.031z"/>'
  }
})
