/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sd_storage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 8.016V3.985h-2.016v4.031H18zm-3 0V3.985h-2.016v4.031H15zm-3 0V3.985H9.984v4.031H12zm6-6q.797 0 1.406.586t.609 1.383v16.031q0 .797-.609 1.383T18 21.985H6q-.797 0-1.406-.586t-.609-1.383l.047-12 5.953-6h8.016z"/>'
  }
})
