/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'article': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zm-4.968 14.016H6.985V15h7.031v2.016zm3-4.032H6.985v-1.969h10.031v1.969zm0-3.984H6.985V6.984h10.031V9z"/>'
  }
})
