/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'supervisor_account': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12.984q1.031 0 2.391.281Q9 14.578 9 16.734v2.25H2.016V16.5q0-1.078 1.289-1.922t2.836-1.219T9 12.984zm7.5 1.032q1.734 0 3.609.75t1.875 1.969v2.25H11.015v-2.25q0-1.219 1.875-1.969t3.609-.75zm-7.5-3q-1.219 0-2.109-.891T6 8.016t.891-2.109T9 5.016t2.109.891T12 8.016t-.891 2.109T9 11.016zm7.5.984q-1.031 0-1.758-.727t-.727-1.758.727-1.781 1.758-.75 1.758.75.727 1.781-.727 1.758T16.5 12z"/>'
  }
})
