<template>
  <div></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DeviceRegistration',
  mounted() {
    const device_id = this.$route.query.deviceId;
    const otp = this.$route.query.otp;

    axios.post('/api/bridge/', {device_id, otp})
      .then(() =>{
        this.$router.push('/device-portal');
      })
      .catch(error =>{
        console.error(error);
      })
  }
}
</script>
