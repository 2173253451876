/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_convenience_store': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 12V6.984H15V9h-.984V6.984h-1.031v3h2.016V12h.984zm-4.968-2.016v-3h-3v1.031h1.969v.984H8.016v3h3v-.984H9V9.984h2.016zm7.968-3h3v13.031h-7.969v-4.031H9.984v4.031H2.015V6.984h3v-3h13.969v3z"/>'
  }
})
