/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rss_feed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 10.078q4.125 0 7.031 2.906t2.906 7.031h-2.859q0-2.906-2.086-4.992t-4.992-2.086v-2.859zm0-5.625q6.422 0 10.992 4.57t4.57 10.992h-2.813q0-5.297-3.727-9.023T3.983 7.265V4.452zm0 13.36q0-.891.633-1.523t1.57-.633 1.547.609.609 1.547-.633 1.57-1.523.633q-.938 0-1.57-.633t-.633-1.57z"/>'
  }
})
