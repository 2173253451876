/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'av_timer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 12q0-.422.281-.703t.703-.281.727.281.305.703-.305.703-.727.281-.703-.281T6 12zm12 0q0 .422-.281.703t-.703.281-.727-.281-.305-.703.305-.703.727-.281.703.281T18 12zm-6.984-9H12q3.75 0 6.375 2.625T21 12t-2.625 6.375T12 21t-6.375-2.625T3 12q0-4.5 3.609-7.172v-.047l6.797 6.797L12 12.984 6.562 7.593q-1.547 1.922-1.547 4.406 0 2.906 2.039 4.945t4.945 2.039 4.945-2.039 2.039-4.945q0-2.625-1.734-4.594t-4.266-2.344v1.922h-1.969V2.999zm0 14.016q0-.422.281-.727t.703-.305.703.305.281.727-.281.703T12 18t-.703-.281-.281-.703z"/>'
  }
})
