/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_soccer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.047 17.203q1.969-2.25 1.969-5.203 0-.094-.023-.188t-.023-.141l-.984-.75-1.406.469-1.453 4.359.797 1.359zM14.25 15l1.359-4.031L12 8.438l-3.609 2.531L9.75 15h4.5zm.234 4.594l.703-1.5-.609-1.078H9.469l-.656 1.078.703 1.5q1.172.422 2.484.422t2.484-.422zm-7.406-2.485l.797-1.359-1.453-4.359-1.406-.469-.984.75q0 .047-.023.141t-.023.188q0 1.219.586 2.742t1.383 2.461zm2.578-12.75q-1.172.328-2.461 1.313T5.25 7.688l.422 1.359 1.359.422 3.984-2.766V5.297zm3.328.938v1.406l3.984 2.766 1.359-.422.422-1.359q-.656-1.031-1.945-2.016t-2.461-1.313zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
