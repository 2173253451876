/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_wifi_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.281 1.453q.563.563 7.195 7.172t10.008 10.078l-1.266 1.266-3.328-3.328-3.891 4.828L.374 6.985Q1.921 5.719 4.03 4.782L2.014 2.719zm20.344 5.531l-5.438 6.797L7.828 3.468Q9.937 2.999 12 2.999q5.953 0 11.625 3.984z"/>'
  }
})
