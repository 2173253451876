/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phonelink_lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.516 11.016v-1.5q0-.563-.445-.938t-1.055-.375-1.055.375-.445.938v1.5h3zm1.265 0q.469 0 .844.375t.375.891v3.516q0 .469-.398.844t-.914.375H5.204q-.469 0-.844-.398t-.375-.914v-3.516q0-.469.375-.82t.844-.352v-1.5q0-1.031.867-1.781t1.945-.75 1.922.75.844 1.781v1.5zM18.984.984q.797 0 1.406.609t.609 1.406v18q0 .797-.609 1.406t-1.406.609H9q-.797 0-1.406-.609t-.609-1.406v-3h2.016v2.016h9.984V3.984H9.001V6H6.985V3q0-.797.609-1.406T9 .985h9.984z"/>'
  }
})
