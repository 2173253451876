/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rice_bowl': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12q0-2.063-.773-3.867t-2.156-3.188-3.188-2.156T12 2.016t-3.867.773-3.188 2.156-2.156 3.188T2.016 12q0 1.828.75 3.445t2.109 2.859 3.141 1.945v1.734h7.969v-1.734q1.781-.703 3.141-1.945t2.109-2.859.75-3.445zm-1.968 0h-4.031V5.062q1.828 1.078 2.93 2.906t1.102 4.031zm-6-7.734V12H9.985V4.266q.516-.141 1.008-.211t1.008-.07 1.008.07 1.008.211zM3.984 12q0-2.203 1.102-4.031t2.93-2.906v6.938H3.985z"/>'
  }
})
