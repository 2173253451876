/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.344 4.078l14.578 14.625-1.266 1.266-2.203-2.25q-.891.797-2.438 1.125V21h-3v-2.156q-1.547-.328-2.578-1.313T7.312 15h2.203q.188 2.109 3 2.109 1.734 0 2.391-.938l-3.516-3.469Q7.499 11.53 7.499 8.764L4.077 5.342zm7.172 2.813q-.891 0-1.547.281L9.516 5.719q.656-.328 1.5-.563V3h3v2.203q1.5.375 2.32 1.406T17.203 9H15q-.094-2.109-2.484-2.109z"/>'
  }
})
