<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CCard>
      <CCardBody>
        <CInput
          label="Section Name"
          placeholder="Section Name"
          v-model="name"
        />
        <div class="my-1">Device</div>
        <SearchList
          v-if="device === null"
          :ph="'Select Device'"
          :list="device_list"
          :pageTotal="pageTotal"
          @search="onSearchDevice"
          @focus="onFocusDevice"
          @select="onSelectDevice"
          @page="onPageDevice"
        />
        <div v-else>
          <CWidgetIcon :text="device.name" color="dark">
            <CIcon name="cil-video" width="16" />
          </CWidgetIcon>
          <div class="pl-4 mb-2">
            <CSelect
              label="Channel"
              class="custom-select_style"
              :options="$utility.Range(1,+device.max_ch+1)"
              :value.sync="ch"
            >
            </CSelect>
          </div>
          <CButton style="float: right;" color="danger" shape="pill" @click="device=null">Reset</CButton>
        </div>
      </CCardBody>
    </CCard>
    <template #header>
      <h6 class="modal-title">New Section - STEP (1/1)</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">Cancel</CButton>
      <CButton :disabled="!name" @click="onSubmit" color="primary">Submit</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue'

export default {
  name: 'SetupSectionAdd',
  components: {
    SearchList
  },
  data() {
    return {
      id: null,
      name: null,
      device: null,
      ch: null,

      show : false,
      step : 0,

      searchText: null,
      device_list: [],
      pageTotal: 1,
      pageCurrent: 1,
      count: null
    }
  },
  methods: {
    open(param) {
      this.onCancel();
      this.id = param.id;
      this.name = param.name;
      this.device = param.device;
      this.ch = param.ch;
      this.show = true;
    },
    onCancel() {
      this.show = false;
      this.id = null;
      this.name = null;
      this.device = null;
      this.ch = null;
      this.searchText = null;
      this.device_list = [];
      this.pageTotal = 1;
      this.pageCurrent = 1;
      this.count = null;
    },
    onSubmit() {
      this.show = false;
      this.$emit('payload', {
        id: this.id,
        name: this.name,
        device: (this.device && this.device.id) || null,
        ch: this.ch
      });
    },
    getDevices(){
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/devices/${query_string}`)
        .then(result => {
          this.device_list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onFocusDevice: function() {
      this.getDevices();
    },
    onSearchDevice: function(text) {
      this.searchText = text;
      this.getDevices();
    },
    onPageDevice: function(p) {
      this.pageCurrent = p;
      this.getDevices();
    },
    onSelectDevice: function(id) {
      this.device = this.device_list.find(el => el.id === id);
      this.device_list = [];
    },
    onReset: function() {
      this.device = null;
      this.ch = null;
      this.searchText = null;
      this.device_list = [];
      this.pageTotal = 1;
      this.pageCurrent = 1;
      this.count = null;
    }
  }
}
</script>