/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_symbols': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 19.5q0 .188.164.352t.305.164q.188 0 .375-.188l1.078-1.031-.75-.703-1.031 1.031q-.141.141-.141.375zm1.172-5.437q-.141 0-.305.141t-.164.328q0 .234.141.375l.328.375.375-.375q.375-.375 0-.703-.141-.141-.375-.141zm3.562 1.875l1.406 1.453-1.406 1.406 1.406 1.406-1.406 1.406-1.406-1.406-1.078 1.078q-.703.703-1.781.703t-1.781-.703q-.75-.75-.75-1.781t.75-1.781l1.078-1.078-.328-.328q-.75-.75-.75-1.781t.727-1.758 1.758-.727 1.781.727.75 1.758-.75 1.781l-.328.328.703.75zm5.766-4.922q-1.031 0-1.781-.75t-.75-1.781.75-1.758T15.516 6q.844 0 1.5.516v-4.5H21v1.969h-3v4.5q0 1.031-.727 1.781t-1.758.75zM18 19.5q0-.656.422-1.078T19.5 18t1.078.422T21 19.5t-.422 1.078T19.5 21t-1.078-.422T18 19.5zm-5.016-5.016q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445-1.055-.445-.445-1.055zm-.562 5.719l7.781-7.781 1.406 1.406-7.781 7.781zM6 11.016V6.985H3V5.016h8.016v1.969h-3v4.031H6zm-3-9h8.016v1.969H3V2.016z"/>'
  }
})
