/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'low_priority': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 11.484q0-2.672 1.898-4.57t4.57-1.898H12v1.969H8.484q-1.875 0-3.188 1.313t-1.313 3.188 1.313 3.188 3.188 1.313H9v-1.969l3 3-3 3v-2.016h-.516q-2.672 0-4.57-1.922t-1.898-4.594zm12 4.5h7.969V18h-7.969v-2.016zm0-5.484h7.969v2.016h-7.969V10.5zm0-5.484h7.969v1.969h-7.969V5.016z"/>'
  }
})
