/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_remove': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 8.016q0-1.125-.539-2.039t-1.453-1.453-2.039-.539q-1.078 0-1.992.539T6.54 5.977t-.539 2.039q0 1.078.539 1.992t1.453 1.453T9.985 12q1.125 0 2.039-.539t1.453-1.453.539-1.992zm3 1.968V12h6V9.984h-6zM2.016 18v2.016H18V18q0-.797-.563-1.43t-1.477-1.125-1.992-.797-2.133-.469-1.852-.164-1.828.164-2.109.469-1.992.797-1.477 1.125T2.014 18z"/>'
  }
})
