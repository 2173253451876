/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel_presentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.578 8.016l1.406 1.406L13.406 12l2.578 2.578-1.406 1.406L12 13.406l-2.578 2.578-1.406-1.406L10.594 12 8.016 9.422l1.406-1.406L12 10.594zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18zm0 16.078V5.015H3v14.063h18z"/>'
  }
})
