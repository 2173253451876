/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airplay': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3q.797 0 1.406.609t.609 1.406v12q0 .797-.609 1.383T21 18.984h-3.984v-1.969H21v-12H3v12h3.984v1.969H3q-.797 0-1.406-.586t-.609-1.383v-12q0-.797.609-1.406T3 3h18zM6 21.984l6-6 6 6H6z"/>'
  }
})
