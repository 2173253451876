/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lightbulb': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q2.906 0 4.945 2.039T18.984 9q0 3.516-3 5.719v2.297q0 .422-.281.703T15 18H9q-.422 0-.703-.281t-.281-.703v-2.297q-3-2.063-3-5.719 0-2.906 2.039-4.945T12 2.016zM9 21v-.984h6V21q0 .422-.281.703t-.703.281H9.985q-.422 0-.703-.281T9.001 21z"/>'
  }
})
