/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ios_share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 5.016l-1.406 1.406-1.594-1.594v11.156h-1.969V4.828L9.421 6.422 8.015 5.016 11.999.985zm4.032 4.968V21q0 .844-.586 1.43t-1.43.586H6q-.844 0-1.43-.586T3.984 21V9.984q0-.797.586-1.383T6 8.015h3v1.969H6V21h12V9.984h-3V8.015h3q.844 0 1.43.586t.586 1.383z"/>'
  }
})
