/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.031 6.984q.75 0 1.359.633T17.999 9v5.484L14.483 18v3H9.514v-3l-3.516-3.516V9q0-.75.609-1.383t1.359-.633h.047V3h1.969v3.984h4.031V3h1.969z"/>'
  }
})
