/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accessible_forward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 13.5q.797 0 1.383.609t.586 1.406v5.484h-1.969v-5.016H12q-1.078 0-1.688-.961t-.141-1.945l1.828-4.078H9.796l-.656 1.547-1.922-.563.656-1.781q.516-1.219 1.875-1.219h5.203q1.125 0 1.734.938t.141 1.922l-1.688 3.656h1.875zm-3 3.516q0 2.063-1.477 3.516T9 21.985t-3.539-1.453-1.477-3.516 1.477-3.539T9 12v2.016q-1.219 0-2.109.891T6 17.016t.891 2.109T9 20.016t2.109-.891.891-2.109h2.016zM15 4.547q0-.844.586-1.43t1.43-.586 1.406.586.563 1.43-.563 1.43-1.406.586-1.43-.586T15 4.547z"/>'
  }
})
