/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'beenhere': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 15.984l9-9-1.406-1.406-7.594 7.594-3.563-3.563-1.406 1.406zm9-15q.797 0 1.406.609t.609 1.406v12.938q0 .984-.891 1.641l-8.109 5.438-8.109-5.438q-.891-.656-.891-1.641V2.999q0-.797.609-1.406T5.014.984h13.969z"/>'
  }
})
