/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'create_new_folder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 14.016V12h-3V9h-1.969v3h-3v2.016h3v3h1.969v-3h3zM20.016 6q.844 0 1.406.586t.563 1.43V18q0 .844-.563 1.43t-1.406.586H3.985q-.844 0-1.406-.586T2.016 18V6q0-.844.563-1.43t1.406-.586h6L12.001 6h8.016z"/>'
  }
})
