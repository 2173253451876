/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smoking_rooms': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.031 10.219q1.453 0 2.461.891t1.008 2.25v1.641H18v-1.313q0-.938-.586-1.477t-1.383-.539h-1.547q-1.359 0-2.344-1.008t-.984-2.367.984-2.344 2.344-.984v1.5q-.797 0-1.313.492t-.516 1.242q0 .797.539 1.406t1.289.609h1.547zm2.813-2.485q1.406.656 2.273 2.016t.867 3V15h-1.5v-2.25q0-1.688-1.148-2.859T16.5 8.719v-1.5q.75 0 1.289-.539t.539-1.336q0-.75-.539-1.289T16.5 3.516v-1.5q1.359 0 2.344.984t.984 2.344q0 1.406-.984 2.391zM18 15.984h1.5v3H18v-3zm2.484 0h1.5v3h-1.5v-3zm-18.468 0h15v3h-15v-3z"/>'
  }
})
