/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nfc': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 6v12H6V6h3.984v2.016H8.015v7.969h7.969V8.016h-3v2.25q1.031.563 1.031 1.734 0 .797-.609 1.406T12 14.015t-1.406-.609T9.985 12q0-1.172 1.031-1.734v-2.25q0-.797.586-1.406t1.383-.609h5.016zm2.016 14.016V3.985H3.985v16.031h16.031zm0-18q.797 0 1.383.586t.586 1.383v16.031q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383V3.985q0-.797.586-1.383t1.383-.586h16.031z"/>'
  }
})
