/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera_front': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 2.016v10.5q0-1.125 1.711-1.828T12 9.985t3.305.703 1.711 1.828v-10.5H6.985zM17.016 0q.797 0 1.383.609t.586 1.406v13.969q0 .797-.586 1.406t-1.383.609H9.985l3 3-3 3v-2.016H5.016v-1.969h4.969v-2.016h-3q-.797 0-1.383-.609t-.586-1.406V2.014q0-.797.586-1.406t1.383-.609h10.031zM12 8.016q-.797 0-1.383-.609t-.586-1.406.586-1.406T12 3.986t1.406.609.609 1.406-.609 1.406T12 8.016zm2.016 12h4.969v1.969h-4.969v-1.969z"/>'
  }
})
