/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dangerous': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15.75L13.266 12l3.75-3.75-1.266-1.266-3.75 3.75-3.75-3.75L6.984 8.25l3.75 3.75-3.75 3.75 1.266 1.266 3.75-3.75 3.75 3.75zM15.75 3L21 8.25v7.5L15.75 21h-7.5L3 15.75v-7.5L8.25 3h7.5z"/>'
  }
})
