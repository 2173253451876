/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_vintage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15.984q1.641 0 2.813-1.172t1.172-2.813-1.172-2.813T12 8.014 9.187 9.186t-1.172 2.813 1.172 2.813T12 15.984zm2.906-9.046q1.641-1.313 3.797-1.313 1.594 0 3 .797 0 1.453-.891 2.953t-2.109 2.203q-.094.047-.258.141t-.305.164-.281.117q.422.188.844.422 1.219.703 2.109 2.203t.891 2.953q-1.406.797-3 .797-2.156 0-3.797-1.313.094.656.094.938 0 1.641-.82 3.023T12 23.203q-1.359-.797-2.18-2.18T9 18q0-.281.094-.938-1.641 1.313-3.797 1.313-1.594 0-3-.797 0-1.734 1.125-3.398T6.141 12l-.844-.422q-1.219-.703-2.109-2.203t-.891-2.953q1.5-.844 3-.844 2.109 0 3.797 1.359Q9 6.281 9 5.999q0-1.641.82-3.023T12 .796q1.359.797 2.18 2.18T15 5.999q0 .281-.094.938z"/>'
  }
})
