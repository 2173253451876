/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.219 7.219q11.109 11.156 13.5 13.5l-1.266 1.266L15 17.532l-.516.469v3H9.515v-3l-3.516-3.516V9.001q0-.234.047-.375L2.718 5.251l1.266-1.266 3.188 3.234h.047zM18 14.484l-.469.516-9.516-9.516V3h1.969v3.984h4.031V3h1.969v3.984q.75 0 1.383.633T18 9v5.484z"/>'
  }
})
