/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eject': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 5.016L18.656 15H5.343zm-6.984 12h13.969v1.969H5.016v-1.969z"/>'
  }
})
