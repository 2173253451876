/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_in_talk': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 12q0-1.219-.891-2.109T12 9V6.984q2.063 0 3.539 1.477T17.016 12H15zm3.984 0q0-2.906-2.039-4.945T12 5.016V3q3.75 0 6.375 2.625T21 12h-2.016zm1.032 3.516q.422 0 .703.281T21 16.5v3.516q0 .422-.281.703t-.703.281q-7.031 0-12.023-4.992T3.001 3.985q0-.422.281-.703t.703-.281h3.516q.422 0 .703.281t.281.703v.047q0 1.922.563 3.516.094.188.094.328 0 .469-.328.703l-2.203 2.203q.938 1.781 2.883 3.727t3.727 2.883l2.203-2.203q.328-.328.703-.328.141 0 .328.094 1.688.563 3.563.563z"/>'
  }
})
