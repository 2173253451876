/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_textdirection_l_to_r': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 18l-3.984 3.984v-3h-12v-1.969h12v-3zM9 9.984q-1.641 0-2.813-1.172T5.015 5.999t1.172-2.813T9 2.014h8.016v1.969H15v11.016h-2.016V3.983h-1.969v11.016H8.999V9.983z"/>'
  }
})
