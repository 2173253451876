/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'face_retouching_natural': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.734 12.984q0-.516.375-.867T9 11.765t.891.352.375.867-.375.891T9 14.25t-.891-.375-.375-.891zm13.032-4.406l-.938 2.016q.094.328.141.68t.047.727q0 1.641-.633 3.094t-1.734 2.555-2.555 1.734-3.094.633-3.094-.633-2.555-1.734-1.734-2.555-.633-3.094v-.141q1.969-.75 3.469-2.18T9.75 6.328Q11.109 8.016 13.125 9t4.359.984q.375 0 .703-.023t.656-.07l-.609-1.313-.891-1.922-1.922-.891-2.813-1.266 2.813-1.266.703-.328q-.938-.422-1.969-.656t-2.156-.234q-2.063 0-3.867.773T4.944 4.944 2.788 8.132t-.773 3.867.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867q0-1.125-.234-2.156t-.656-1.969l-.328.703zm-7.032 4.406q0-.516.375-.867t.891-.352.891.352.375.867-.375.891-.891.375-.891-.375-.375-.891zm6.844-7.406L19.5 8.016l-1.078-2.438L15.984 4.5l2.438-1.078L19.5.984l1.078 2.438L23.016 4.5z"/>'
  }
})
