/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'router': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 18v-2.016h-2.016V18H15zm-3.516 0v-2.016H9.515V18h1.969zm-3.468 0v-2.016H6V18h2.016zm10.968-5.016q.797 0 1.406.609t.609 1.406v3.984q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.983v-3.984q0-.797.609-1.406t1.406-.609h9.984V9h2.016v3.984h1.969zm.329-6.281l-.797.797q-.984-.984-2.531-.984-1.5 0-2.484.984l-.797-.797q1.406-1.406 3.281-1.406 1.922 0 3.328 1.406zm.89-.797q-1.922-1.688-4.219-1.688-2.25 0-4.172 1.688l-.797-.797Q13.124 3 15.984 3 18.89 3 21 5.109z"/>'
  }
})
