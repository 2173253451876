/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_photo_alternate': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 18.984h12l-4.031-4.969-3 3.984-1.969-3zm10.968-7.968h3v7.969q0 .797-.586 1.406T17.015 21h-12q-.797 0-1.406-.609T3 18.985v-12q0-.797.609-1.383t1.406-.586h7.969v3h3v3zm3-4.032v3h-1.969v-3h-3V5.015h3v-3h1.969v3h3v1.969h-3z"/>'
  }
})
