/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mp9': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 14.016h1.5v1.5h-1.5v-1.5zm3 1.968V13.5q0-.422-.305-.703t-.727-.281h-3.469v6h1.5v-1.5h1.969q.422 0 .727-.305t.305-.727zM12 18.516V13.5q0-.422-.281-.703t-.703-.281h-4.5q-.422 0-.727.281t-.305.703v5.016h1.5v-4.5h1.031v3h1.5v-3h.984v4.5h1.5zM6.984 5.484v1.5h1.5v4.5h1.5v-6h-3zM13.5 8.016v-1.5H15v1.5h-1.5zM12 9.984v1.5h3.516q.422 0 .703-.281t.281-.703V6.516q0-.422-.281-.727t-.703-.305h-2.531q-.422 0-.703.305t-.281.727v1.5q0 .422.281.703t.703.281h2.016v.984h-3zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
