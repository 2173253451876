/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pie_chart_outlined': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 19.922q2.531-.328 4.57-2.367t2.367-4.57h-6.938v6.938zm-9-7.922q0 3.047 2.016 5.297t5.016 2.625V4.078q-2.953.375-4.992 2.625T3.985 12zm9-7.922v6.938h6.938q-.328-2.719-2.273-4.664t-4.664-2.273zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
