/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'run_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zM13.5 6q.422 0 .703.305t.281.68q0 .422-.281.727t-.703.305-.703-.305-.281-.727q0-.375.281-.68T13.5 6zm2.484 6q-.516 0-1.359-.422t-1.547-1.359l-.375 2.391 1.313 1.406V18h-1.031v-3.563l-1.078-1.219-.516 2.625-3.797-.75.188-.984 2.813.563.938-4.875-1.547.563v1.641h-.984V9.657l3.281-1.219q.375-.141.727.023t.539.492q.422.844.914 1.289t.914.609.609.164v.984z"/>'
  }
})
