/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'online_prediction': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 11.484q0 .75-.375 1.43t-.891 1.289-.891 1.172-.375 1.125h-1.969q0-.563-.375-1.125t-.891-1.172-.891-1.289-.375-1.43q0-.938.492-1.734t1.266-1.266 1.758-.469 1.758.469 1.266 1.266.492 1.734zm-2.532 6h-1.969v1.5h1.969v-1.5zm9-5.484q0-2.063-.773-3.891t-2.133-3.188L18 5.999q1.172 1.125 1.828 2.695t.656 3.305-.656 3.305T18 17.999l1.078 1.078q1.359-1.359 2.133-3.188t.773-3.891zM3.516 12q0-1.734.656-3.305T6 6L4.922 4.922Q3.563 6.281 2.789 8.11t-.773 3.891.773 3.891 2.133 3.188L6 18.002q-1.172-1.125-1.828-2.695t-.656-3.305zm13.968 0q0 1.125-.422 2.133t-1.172 1.758l1.078 1.078q.938-.984 1.477-2.25T18.984 12t-.539-2.719-1.477-2.25L15.89 8.109q.75.75 1.172 1.758T17.484 12zM7.031 16.969l1.078-1.078q-.75-.75-1.172-1.758T6.515 12t.422-2.133 1.172-1.758L7.031 7.031q-.938.984-1.477 2.25T5.015 12t.539 2.719 1.477 2.25z"/>'
  }
})
