/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_road': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 18v-3H18v3h-3v2.016h3v3h2.016v-3h3V18h-3zM18 3.984h2.016v9H18v-9zm-14.016 0H6v16.031H3.984V3.984zm7.032 0h1.969v4.031h-1.969V3.984zm0 6h1.969v4.031h-1.969V9.984zm0 6h1.969v4.031h-1.969v-4.031z"/>'
  }
})
