/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_compact': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 5.016h18.984v6H3v-6zm6.984 13.968V12h12v6.984h-12zm-6.984 0V12h6v6.984H3z"/>'
  }
})
