/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rv_hookup': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 2.016l3 3-3 3V6H9V3.984h8.016V2.015zm.984 12v-3h-3.984v3H18zm-6.984 6q.422 0 .703-.305t.281-.727-.281-.703-.703-.281-.727.281-.305.703.305.727.727.305zm9-3h1.969v1.969h-7.969q0 1.219-.891 2.109t-2.109.891-2.109-.891-.891-2.109H6q-.797 0-1.406-.586t-.609-1.383v-3h7.031v-3H6.985v1.969l-3-3 3-3v2.016h11.016q.797 0 1.406.609t.609 1.406v6z"/>'
  }
})
