/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vertical_align_bottom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 18.984h16.031V21H3.984v-2.016zm12-6L12 17.015l-3.984-4.031h3V3h1.969v9.984h3z"/>'
  }
})
