<template>
  <div>
    <Analytics v-if="t==='analytics'" />
  </div>
</template>

<script>
import Analytics from './Analytics.vue'

export default {
  name: 'RetailStoreOccupancy',

  components: {
    Analytics
  },

  data () {
    return {
      t: 'analytics'
    }
  }
}
</script>