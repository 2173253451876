/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_arrow_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.406 8.578L12 13.172l4.594-4.594L18 9.984l-6 6-6-6z"/>'
  }
})
