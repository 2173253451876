/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_football': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 8.391L15.609 3q1.641-.047 3.023.188t1.664.516.516 1.641T21 8.392zM9.891 15.516l5.625-5.625-1.406-1.406-5.625 5.625zm3.187-12.235l7.641 7.641q-.75 4.172-3.188 6.609t-6.609 3.188l-7.641-7.641q.75-4.172 3.188-6.609t6.609-3.188zM3 15.609L8.391 21q-1.641.047-3.023-.188t-1.664-.516-.516-1.641T3 15.608z"/>'
  }
})
