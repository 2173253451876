/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 16.078q1.219 0 2.063.867t.844 2.039q0 1.219-.867 2.086t-2.039.867-2.039-.867-.867-2.086q0-.469.047-.656l-7.078-4.125Q7.173 15 6.001 15q-1.219 0-2.109-.891T3.001 12t.891-2.109T6.001 9q1.172 0 2.063.797l7.031-4.078q-.094-.469-.094-.703 0-1.219.891-2.109t2.109-.891 2.109.891.891 2.109-.891 2.109-2.109.891q-1.125 0-2.063-.844l-7.031 4.125q.094.469.094.703t-.094.703l7.125 4.125q.844-.75 1.969-.75z"/>'
  }
})
