/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'title': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 3.984h13.969v3h-5.484v12h-3v-12H5.017v-3z"/>'
  }
})
