/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.672 6.328l2.344-2.344v7.031h-7.031l3.234-3.234Q14.438 6 12 6 9.516 6 7.758 7.758T6 12t1.758 4.242T12 18q1.734 0 3.398-1.172t2.273-2.813h2.063q-.656 2.625-2.813 4.313t-4.922 1.688q-3.281 0-5.625-2.344T4.03 12t2.344-5.672 5.625-2.344q1.406 0 3.07.68t2.602 1.664z"/>'
  }
})
