/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_overscan': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 19.031V4.968H3v14.063h18zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18zm-6.984 12.984L12 18.515l-2.016-2.531h4.031zM6 9.984v4.031l-2.484-2.016zm12 0L20.484 12 18 14.016V9.985zm-6-4.5l2.016 2.531H9.985z"/>'
  }
})
