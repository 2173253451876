/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gps_fixed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18.984q2.906 0 4.945-2.039T18.984 12t-2.039-4.945T12 5.016 7.055 7.055 5.016 12t2.039 4.945T12 18.984zm8.953-7.968h2.063v1.969h-2.063q-.328 2.953-2.672 5.297t-5.297 2.672v2.063h-1.969v-2.063q-2.953-.328-5.297-2.672t-2.672-5.297H.983v-1.969h2.063q.328-2.953 2.672-5.297t5.297-2.672V.984h1.969v2.063q2.953.328 5.297 2.672t2.672 5.297zm-8.953-3q1.641 0 2.813 1.172t1.172 2.813-1.172 2.813T12 15.986t-2.813-1.172-1.172-2.813 1.172-2.813T12 8.016z"/>'
  }
})
