/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.734 9.984H6q-1.641 0-2.813 1.195t-1.172 2.836 1.172 2.813T6 18h9.75zM3 5.25l1.266-1.266L21 20.718l-1.266 1.266-2.016-1.969H5.999q-2.484 0-4.242-1.758t-1.758-4.242q0-2.438 1.688-4.172t4.078-1.828zm16.359 4.781q1.922.141 3.281 1.57t1.359 3.398q0 2.578-2.109 4.078l-1.453-1.453q1.547-.844 1.547-2.625 0-1.219-.891-2.109t-2.109-.891h-1.5v-.516q0-2.297-1.594-3.891t-3.891-1.594q-1.313 0-2.531.609l-1.5-1.453q1.828-1.172 4.031-1.172 2.531 0 4.711 1.781t2.648 4.266z"/>'
  }
})
