/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 14.016q2.531 0 5.273 1.102t2.742 2.883v2.016H6.984v-2.016q0-1.781 2.742-2.883t5.273-1.102zM6 9.984h3V12H6v3H3.984v-3h-3V9.984h3v-3H6v3zM15 12q-1.641 0-2.813-1.172t-1.172-2.813 1.172-2.836T15 3.984t2.813 1.195 1.172 2.836-1.172 2.813T15 12z"/>'
  }
})
