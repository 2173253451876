/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'timer_10': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.891 13.219v-2.531q0-1.688-.469-2.484-.328-.703-1.406-.703-.469 0-.844.141-1.078.656-1.078 3.094v2.484q0 1.125.094 1.547.047.234.422.984t1.406.75 1.406-.75q.281-.563.375-.984t.094-1.547zm-5.766-2.203q0-5.109 3.891-5.109 1.922 0 2.813 1.125 1.031 1.313 1.031 3.984v1.922q0 5.156-3.844 5.156-3.891 0-3.891-5.156v-1.922zm13.313-.563q-1.406 0-1.406 1.078 0 .656.891.938.281.094.891.234.422.094 1.313.375.375.141 1.031.563.375.234.609.75.234.469.234.984 0 1.734-2.063 2.438-.563.188-1.406.188-2.672 0-3.469-1.828-.234-.563-.234-1.031h1.922q0 .656.516 1.031t1.266.375q1.547 0 1.547-1.078 0-.281-.141-.492t-.281-.281-.469-.211-1.148-.352-1.102-.305q-.375-.141-.938-.516-.891-.609-.891-1.688 0-1.781 1.969-2.438.563-.188 1.359-.188 1.641 0 2.578.797.938.75.938 1.969h-1.969q0-.984-.844-1.172-.563-.141-.703-.141zM0 7.734L4.734 6h.281v12H2.999V8.391l-3 1.031V7.734z"/>'
  }
})
