/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'healing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.641 20.344l3.656-3.656-3.656-3.609-3.609 3.656zm-2.625-9.328q-.422 0-.727.281t-.305.703.305.703.727.281.703-.281T15 12t-.281-.703-.703-.281zM12 15q.422 0 .703-.281t.281-.703-.281-.727-.703-.305-.703.305-.281.727.281.703T12 15zm-2.016-2.016q.422 0 .727-.281t.305-.703-.305-.703-.727-.281-.703.281T9 12t.281.703.703.281zm-2.671-2.015l3.609-3.656-3.609-3.609L3.657 7.36zM12 9q-.422 0-.703.281t-.281.703.281.727.703.305.703-.305.281-.727-.281-.703T12 9zm5.719 3l3.984 3.984q.328.328.328.703t-.328.703l-4.313 4.359q-.281.281-.703.281-.469 0-.75-.281l-3.938-3.984-3.984 3.984q-.281.281-.703.281t-.703-.281L2.25 17.39q-.281-.281-.281-.703t.281-.703L6.234 12 2.25 8.062q-.281-.281-.281-.75 0-.422.281-.703l4.359-4.313q.281-.281.703-.281t.703.281l3.984 3.984 3.938-3.984q.281-.281.75-.281.422 0 .703.281l4.313 4.313q.281.281.281.703 0 .469-.281.75z"/>'
  }
})
