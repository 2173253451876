/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'child_care': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 14.016h9q-.563 1.359-1.781 2.18t-2.719.82-2.719-.82-1.781-2.18zm4.5 4.968q2.391 0 4.242-1.406t2.461-3.609q.047 0 .141.023t.141.023q.797 0 1.406-.609T21 12t-.609-1.406-1.406-.609q-.047 0-.141.023t-.141.023q-.609-2.203-2.461-3.609T12 5.016 7.758 6.422t-2.461 3.609q-.047 0-.141-.023t-.141-.023q-.797 0-1.406.609T3 12t.609 1.406 1.406.609q.047 0 .141-.023t.141-.023q.609 2.203 2.461 3.609T12 18.984zm10.922-6.328q-.188 1.031-.984 1.969t-1.781 1.219q-1.031 2.203-3.258 3.68t-4.898 1.477q-3.328 0-5.953-2.297-1.453-1.266-2.156-2.859-.984-.281-1.805-1.219t-1.008-1.969q-.094-.375-.094-.656t.094-.656q.188-1.031 1.008-1.969t1.805-1.219q.797-1.781 2.156-2.906 2.531-2.25 5.953-2.25t5.953 2.25q1.453 1.359 2.156 2.906.984.281 1.805 1.219t1.008 1.969q.094.375.094.656t-.094.656zM8.25 10.5q0-.516.375-.891t.891-.375.867.375.352.891-.352.891-.867.375-.891-.375-.375-.891zm5.016 0q0-.516.352-.891t.867-.375.891.375.375.891-.375.891-.891.375-.867-.375-.352-.891z"/>'
  }
})
