/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_8': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 11.016v1.969H15v-1.969h-2.016zm0-4.032V9H15V6.984h-2.016zm0 8.016q-.844 0-1.406-.586t-.563-1.43v-1.5q0-.609.445-1.055t1.055-.445q-.609 0-1.055-.445t-.445-1.055v-1.5q0-.844.563-1.406t1.406-.563H15q.844 0 1.43.563t.586 1.406v1.5q0 .609-.445 1.055t-1.055.445q.609 0 1.055.445t.445 1.055v1.5q0 .844-.586 1.43T15 15h-2.016zM21 17.016V3H6.984v14.016H21zM21 .984q.797 0 1.406.609t.609 1.406v14.016q0 .797-.609 1.383T21 18.984H6.984q-.797 0-1.383-.586t-.586-1.383V2.999q0-.797.586-1.406T6.984.984H21zM3 5.016V21h15.984v2.016H3q-.797 0-1.406-.609t-.609-1.406V5.017h2.016z"/>'
  }
})
