/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bar_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.219 12.984h2.766v6h-2.766v-6zm-5.625-7.968h2.813v13.969h-2.813V5.016zM5.016 9.188h3v9.797h-3V9.188z"/>'
  }
})
