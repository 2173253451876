/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'extension': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.484 11.016q1.031 0 1.781.727t.75 1.758-.75 1.758-1.781.727h-1.5v4.031q0 .797-.586 1.383t-1.383.586h-3.797v-1.5q0-1.125-.797-1.898t-1.922-.773-1.922.773-.797 1.898v1.5H3.983q-.797 0-1.383-.586t-.586-1.383V16.22h1.5q1.125 0 1.898-.797t.773-1.922-.773-1.922-1.898-.797h-1.5V6.985q0-.797.586-1.383t1.383-.586h4.031v-1.5q0-1.031.727-1.781t1.758-.75 1.758.75.727 1.781v1.5h4.031q.797 0 1.383.586t.586 1.383v4.031h1.5z"/>'
  }
})
