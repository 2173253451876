/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_sim': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.656 3.891L21.14 21.329l-1.313 1.313-1.875-1.922q-.563.281-.938.281H6.983q-.797 0-1.383-.609t-.586-1.406V7.783L2.389 5.158zm15.328 1.125v11.672L7.64 5.344 9.984 3h7.031q.797 0 1.383.609t.586 1.406z"/>'
  }
})
