/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'badge': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 13.5V12h-3.984v1.5H18zm0 3V15h-3.984v1.5H18zM12.984 9V3.984h-1.969V9h1.969zM12 18v-.75q0-.656-1.031-1.078T9 15.75t-1.969.422T6 17.25V18h6zm-3-6q-.656 0-1.078.422T7.5 13.5t.422 1.078T9 15t1.078-.422.422-1.078-.422-1.078T9 12zm11.016-5.016q.797 0 1.383.609t.586 1.406v11.016q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383V8.999q0-.797.586-1.406t1.383-.609h5.016v-3q0-.797.609-1.383t1.406-.586h1.969q.797 0 1.406.586T15 3.984v3h5.016z"/>'
  }
})
