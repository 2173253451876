/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'language': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.359 14.016h3.375q.281-1.313.281-2.016t-.281-2.016h-3.375q.141.984.141 2.016t-.141 2.016zm-1.781 5.531q1.172-.375 2.438-1.43t1.922-2.133h-2.953q-.469 1.875-1.406 3.563zm-.234-5.531q.141-.984.141-2.016t-.141-2.016H9.656q-.141.984-.141 2.016t.141 2.016h4.688zM12 19.969q1.313-1.922 1.922-3.984h-3.844q.609 2.063 1.922 3.984zM8.016 8.016Q8.579 6 9.422 4.453q-1.172.375-2.461 1.43T5.063 8.016h2.953zm-2.953 7.968q.609 1.078 1.898 2.133t2.461 1.43q-.938-1.688-1.406-3.563H5.063zm-.797-1.968h3.375Q7.5 13.032 7.5 12t.141-2.016H4.266Q3.985 11.297 3.985 12t.281 2.016zM12 4.031q-1.313 1.922-1.922 3.984h3.844Q13.313 5.952 12 4.031zm6.938 3.985q-.656-1.078-1.922-2.133t-2.438-1.43Q15.422 6 15.984 8.016h2.953zm-6.938-6q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
