/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_a_photo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.797 14.016q0-1.359.938-2.297t2.25-.938q1.359 0 2.297.938t.938 2.297q0 1.313-.938 2.25t-2.297.938-2.273-.914-.914-2.273zm3.187 4.968q2.063 0 3.539-1.453T18 14.015t-1.477-3.539-3.539-1.477-3.516 1.477-1.453 3.539 1.453 3.516 3.516 1.453zM6 9.984v-3h3v-3h6.984L17.812 6H21q.797 0 1.406.609t.609 1.406v12q0 .797-.609 1.383T21 21.984H5.016q-.797 0-1.406-.586t-.609-1.383V9.984h3zm-3-6v-3h2.016v3h3V6h-3v3H3V6H0V3.984h3z"/>'
  }
})
