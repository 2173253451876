/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insert_comment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 8.016V6H6v2.016h12zm0 3V9H6v2.016h12zm0 3V12H6v2.016h12zm2.016-12q.797 0 1.383.586t.586 1.383v18l-3.984-3.984H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h16.031z"/>'
  }
})
