/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_tennis': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15q1.641 0 2.813 1.172t1.172 2.813-1.172 2.836T18 23.016t-2.813-1.172-1.172-2.859q0-1.641 1.172-2.813T18 15zm0 2.016q-.844 0-1.406.563-.609.609-.609 1.406t.609 1.406T18 21q.844 0 1.406-.563.609-.609.609-1.43t-.609-1.43q-.563-.563-1.406-.563zm-7.687-5.344q1.172 1.172 3.164.867t3.586-1.898 1.898-3.586-.867-3.164-3.141-.844-3.563 1.922-1.922 3.563.844 3.141zM19.5 2.484q1.734 1.781 1.453 4.57t-2.484 4.992q-1.172 1.172-2.695 1.852t-2.789.68h-1.523l-.797.023-.867.117-.773.234-.82.398-.703.586-4.219 4.219-1.453-1.406 4.266-4.219q.328-.328.586-.703t.398-.82.234-.797.117-.891.023-.797V8.999q0-1.219.68-2.742t1.852-2.695q2.203-2.203 4.992-2.531t4.523 1.453z"/>'
  }
})
