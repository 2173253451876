/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rounded_corner': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 8.016v4.969h-2.016V8.016q0-1.219-.891-2.109t-2.109-.891h-4.969V3h4.969q2.063 0 3.539 1.477T21 8.016zM3 21v-2.016h2.016V21H3zm3.984 0v-2.016H9V21H6.984zm4.032 0v-2.016h1.969V21h-1.969zM6.984 5.016V3H9v2.016H6.984zM3 5.016V3h2.016v2.016H3zM3 9V6.984h2.016V9H3zm0 8.016V15h2.016v2.016H3zm0-4.032v-1.969h2.016v1.969H3zm15.984 4.032V15H21v2.016h-2.016zm0 1.968H21V21h-2.016v-2.016z"/>'
  }
})
