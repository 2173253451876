/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi_protected_setup': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.688 5.297L18.985 3h-7.969v8.016l2.297-2.297q1.453 1.078 2.344 2.742t.891 3.68q0 .984-.234 1.898t-.656 1.711q1.781-1.125 2.836-3t1.055-4.125q0-1.875-.773-3.516T16.69 5.296zM7.453 8.859q0-.984.234-1.898t.656-1.711q-1.781 1.125-2.836 3t-1.055 4.125q0 1.875.773 3.516t2.086 2.813l-2.297 2.297h7.969v-8.016l-2.297 2.297q-1.453-1.078-2.344-2.742t-.891-3.68z"/>'
  }
})
