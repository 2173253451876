/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'replay_30': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.406 15.188q0 .328.516.328.188 0 .281-.094l.188-.234q.094-.188.094-.281v-2.016q0-.047-.047-.141t-.047-.141q0-.094-.188-.211t-.281-.117q-.188 0-.328.141l-.188.188q-.094.188-.094.281v2.016q.094.188.094.281zm1.875-.985q0 .609-.094.797l-.281.609q-.328.281-.516.281-.094 0-.305.047t-.305.047q-.328 0-1.078-.375-.094-.047-.281-.609-.141-.422-.141-.797V13.5q0-.516.141-.797l.281-.609q.328-.281.516-.281.094 0 .281-.047t.281-.047q.422 0 .609.094.094.047.258.141t.258.141.281.609q.094.281.094.797v.703zM9.984 13.5q.703 0 .703-.609v-.188q-.094-.094-.094-.188t-.188-.094h-.516q-.094.094-.188.094t-.094.188v.188H8.576q0-.375.258-.727t.586-.352q.047 0 .234-.047t.234-.047q.563 0 1.125.281.375.188.375.891v.328q-.094.188-.094.281 0 .188-.188.188-.094 0-.328.234.422.234.516.375.188.375.188.609 0 .422-.094.516-.047.047-.141.188t-.141.188q-.188.188-.516.188-.094 0-.305.047t-.305.047q-.375 0-.469-.094-.047-.047-.234-.094t-.281-.094q-.422-.234-.422-.984h.844v.188q.094.094.094.188t.188.094h.516q.094-.094.188-.094t.094-.188v-.516q-.094-.094-.094-.188t-.188-.094h-.609V13.5h.375zM12 5.016q3.328 0 5.672 2.344t2.344 5.625q0 3.328-2.367 5.672t-5.648 2.344-5.648-2.344-2.367-5.672h2.016q0 2.484 1.781 4.242t4.219 1.758 4.219-1.758 1.781-4.242-1.781-4.242-4.219-1.758v4.031L6.986 6 12.002.984v4.031z"/>'
  }
})
