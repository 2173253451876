<template>
  <CCard>
    <CCardBody>
      <div class="mb-4">
        <strong>{{title}}</strong>
        <CButton v-if="enableBtnDel"
          class="m-0 p-0 px-1 ml-3 d-inline-block float-right"
        >
          <CIcon name="cil-x" />
        </CButton>
        <slot name="view_menu" />
      </div>
      <slot name="chart" />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    title: {type: String, default: null},
    enableBtnDel: {type: Boolean, default: false}
  }
}
</script>
