/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'workspaces_filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 12.984q1.641 0 2.813 1.195t1.172 2.836-1.172 2.813T18 21t-2.813-1.172-1.172-2.813 1.172-2.836T18 12.984zM12 3q1.641 0 2.813 1.172t1.172 2.813-1.172 2.836T12 11.016 9.187 9.821 8.015 6.985t1.172-2.813T12 3zm-6 9.984q1.641 0 2.813 1.195t1.172 2.836-1.172 2.813T6 21t-2.813-1.172-1.172-2.813 1.172-2.836T6 12.984z"/>'
  }
})
