/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_missed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.719 16.688q.281.281.281.703t-.281.703l-2.484 2.484q-.281.281-.703.281t-.703-.281q-1.313-1.219-2.672-1.875-.563-.234-.563-.891v-3.094q-2.156-.703-4.594-.703t-4.594.703v3.094q0 .703-.563.938-1.5.703-2.672 1.828-.281.281-.703.281t-.703-.281L.281 18.094Q0 17.813 0 17.391t.281-.703Q5.203 12 12 12q2.813 0 6.234 1.383t5.484 3.305zM6.516 5.484V9h-1.5V3h6v1.5H7.5L12 9l6-6 .984.984L12 11.015z"/>'
  }
})
