/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'portable_wifi_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.281 2.484l.984 1.031 16.734 16.734-1.266 1.266-7.5-7.547h-.047l-.188.047q-.797 0-1.406-.609T9.983 12l.047-.188-1.594-1.594q-.422.844-.422 1.781 0 2.297 1.969 3.469l-.984 1.734q-1.359-.797-2.18-2.18t-.82-3.023q0-1.781.938-3.234L5.531 7.312Q3.984 9.468 3.984 12q0 2.156 1.078 4.008t2.906 2.93l-.984 1.734q-2.297-1.313-3.633-3.633T2.015 12q0-3.469 2.063-6.141L2.015 3.75zM12 3.984q-2.016 0-3.75.938L6.797 3.469Q9.141 2.016 12 2.016q4.125 0 7.055 2.93t2.93 7.055q0 2.813-1.453 5.203l-1.5-1.453q.984-1.828.984-3.75 0-3.281-2.367-5.648t-5.648-2.367zm5.578 10.266l-1.641-1.641q.047-.188.047-.609 0-1.641-1.172-2.813t-2.813-1.172q-.422 0-.609.047L9.749 6.421q1.031-.422 2.25-.422 2.484 0 4.242 1.758t1.758 4.242q0 1.219-.422 2.25z"/>'
  }
})
