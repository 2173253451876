/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'k1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 15l-2.25-3 2.25-3h-1.734l-1.781 2.25V9h-1.5v6h1.5v-2.25L17.766 15H19.5zm-6.516-.984V9.985q0-.422-.281-.703T12 9.001H9.516q-.422 0-.727.281t-.305.703v4.031q0 .422.305.703t.727.281H12q.422 0 .703-.281t.281-.703zM7.5 15V9h-3v1.5H6V15h1.5zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969zm-9 7.5h1.5v3h-1.5v-3z"/>'
  }
})
