/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 18l7.5-5.016L9 9v9zm.984-14.016V6h4.031V3.984H9.984zm6 2.016h6v12.984q0 .844-.563 1.43T20.015 21H3.984q-.844 0-1.406-.586t-.563-1.43V6h6V3.984q0-.844.563-1.406t1.406-.563h4.031q.844 0 1.406.563t.563 1.406V6z"/>'
  }
})
