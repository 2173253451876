/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_input_svideo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 15q.609 0 1.055.422t.445 1.078-.445 1.078-1.055.422-1.055-.422-.445-1.078.445-1.078T15.516 15zm1.968-5.016q.609 0 1.055.445t.445 1.055-.445 1.055-1.055.445-1.055-.445-.445-1.055.445-1.055 1.055-.445zM12 21q3.75 0 6.375-2.625T21 12t-2.625-6.375T12 3 5.625 5.625 3 12t2.625 6.375T12 21zM12 .984q4.547 0 7.781 3.234t3.234 7.781-3.234 7.781T12 23.014 4.219 19.78.985 11.999t3.234-7.781T12 .984zM8.484 15q.609 0 1.055.422t.445 1.078-.445 1.078T8.484 18t-1.055-.422-.445-1.078.445-1.078T8.484 15zM15 6.516q0 .609-.422 1.055t-1.078.445h-3q-.656 0-1.078-.445T9 6.516t.422-1.055 1.078-.445h3q.656 0 1.078.445T15 6.516zm-6.984 4.968q0 .609-.445 1.055t-1.055.445-1.055-.445-.445-1.055.445-1.055 1.055-.445 1.055.445.445 1.055z"/>'
  }
})
