/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_made': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 5.016h9.984V15h-1.969V8.391L5.39 20.016 3.984 18.61 15.609 6.985H9V5.016z"/>'
  }
})
