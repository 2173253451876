/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brightness_1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 12q0-4.125 2.93-7.055t7.055-2.93 7.055 2.93T21.986 12t-2.93 7.055-7.055 2.93-7.055-2.93T2.016 12z"/>'
  }
})
