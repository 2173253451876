/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fact_check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 3H3.985q-.797 0-1.383.586t-.586 1.43v13.969q0 .844.586 1.43t1.383.586h16.031q.797 0 1.383-.586t.586-1.43V5.016q0-.844-.586-1.43T20.016 3zM9.984 17.016H5.015V15h4.969v2.016zm0-4.032H5.015v-1.969h4.969v1.969zm0-3.984H5.015V6.984h4.969V9zm4.829 6L12 12.141l1.406-1.406 1.406 1.453L18 9l1.406 1.406z"/>'
  }
})
