/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'k3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15l-2.25-3L18 9h-1.734l-1.781 2.25V9h-1.5v6h1.5v-2.25L16.266 15H18zm-8.016-2.484q.422 0 .727-.305t.305-.727v-1.5q0-.422-.305-.703T9.984 9H6.515v1.5h3v.984H7.499q-.422 0-.703.305t-.281.727V15h4.5v-1.5h-3v-.984h1.969zm9-9.516q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
