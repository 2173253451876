/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dry': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.656 4.875l-.094-.094q-.891-.984-.656-2.203L15 2.015h-1.875l-.094.422q-.281 2.109 1.313 3.703l.094.047q.891.984.656 2.203L15 8.999h1.875l.094-.422q.141-1.031-.211-1.992t-1.102-1.711zm3.985 0l-.047-.094q-.938-.984-.703-2.203l.094-.563H17.11l-.047.422q-.328 2.109 1.266 3.703l.094.047q.891.984.656 2.203l-.094.609h1.922l.047-.422q.141-1.031-.188-1.992t-1.125-1.711zm-10.5.141l-7.219 6.797q-.938.844-.938 2.156v6.047q0 .797.422 1.477t1.102 1.102 1.477.422h13.781q.516 0 .867-.375t.352-.891-.352-.891-.867-.375H12v-.984h7.734q.516 0 .891-.375t.375-.891-.375-.867-.891-.352H12v-1.031h8.766q.516 0 .867-.352t.352-.867-.352-.891-.867-.375H12v-.984h6.75q.516 0 .891-.375t.375-.891-.375-.891-.891-.375H8.859q.328-.563.68-1.148t.586-1.008l.234-.422q.141-.281.141-.563 0-.375-.281-.703l-.164-.164-.375-.398-.375-.398-.164-.164z"/>'
  }
})
