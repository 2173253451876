/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'screen_lock_portrait': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 18.984V5.015H6.985v13.969h10.031zm0-18q.797 0 1.383.609t.586 1.406v18q0 .797-.586 1.406t-1.383.609H6.985q-.797 0-1.383-.609t-.586-1.406v-18q0-.797.586-1.406T6.985.984h10.031zm-6.235 9v1.031h2.438V9.984q0-.469-.352-.82T12 8.812t-.867.352-.352.82zm-.797 6q-.422 0-.703-.281T9 15v-3q0-.422.281-.703t.703-.281V9.985q0-.844.586-1.406T12 8.016t1.43.563.586 1.406v1.031q.422 0 .703.281T15 12v3q0 .422-.281.703t-.703.281H9.985z"/>'
  }
})
