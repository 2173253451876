/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_train': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 9.984V5.015H6v4.969h12zm-6 7.032q.797 0 1.406-.609t.609-1.406-.609-1.406T12 12.986t-1.406.609-.609 1.406.609 1.406 1.406.609zm-8.016-1.5v-10.5q0-2.391 2.063-3.211T12 .985t5.953.82 2.063 3.211v10.5q0 1.453-1.031 2.461t-2.484 1.008l1.5 1.5v.516h-12v-.516l1.5-1.5q-1.453 0-2.484-1.008t-1.031-2.461z"/>'
  }
})
