/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'face': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648q0-1.031-.328-2.25-1.031.234-2.297.234-2.156 0-4.523-1.242T9.281 5.766q-1.547 3.75-5.25 5.391-.047.281-.047.844 0 3.281 2.367 5.648t5.648 2.367zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm3 9.75q.516 0 .891.352t.375.867-.375.891-.891.375-.891-.375-.375-.891.375-.867.891-.352zm-6 0q.516 0 .891.352t.375.867-.375.891-.891.375-.891-.375-.375-.891.375-.867.891-.352z"/>'
  }
})
