/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_to_queue': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 9.984V12h-3v3h-1.969v-3h-3V9.984h3v-3h1.969v3h3zM21 17.016v-12H3v12h18zM21 3q.844 0 1.43.586t.586 1.43l-.047 12q0 .797-.586 1.383T21 18.985h-5.016v2.016H8.015v-2.016H2.999q-.844 0-1.43-.563t-.586-1.406v-12q0-.844.586-1.43T2.999 3h18z"/>'
  }
})
