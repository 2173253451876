<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <CCard>
      <CCardBody v-if="step===0">
        <CInput
          label="Email"
          placeholder="E-mail"
          horizontal
          v-model="user_updated.email"
          :disabled="user_updated.id"
        />
        <CInput
          v-if="need_pw"
          label="Password"
          placeholder="Password"
          horizontal
          v-model="password"
          type="password"
        /> 
        <CSelect v-if="capability && capability.user_profile.id !== user_updated.id"
          label="Auth Level"
          horizontal
          :options="auth_levels || []"
          :value.sync="user_updated.auth_level"
        />
        <CInput v-else label="Auth Level" horizontal disabled :value="myAuthorizationLevel.name" />
        <CInput
          label="Name"
          placeholder="Name"
          horizontal
          v-model="user_updated.name"
        />
        <CInput
          label="Cellphone"
          placeholder="Cell Phone"
          horizontal
          v-model="user_updated.cellphone"
        />
      </CCardBody>
      <CCardBody v-if="step===1">
        <SearchList
          v-if="user_updated.branch_id === null"
          ph="Select"
          :list="branch_list"
          :pageTotal="branch_page_total"
          @search="onSearchBranch"
          @focus="onFocusBranch"
          @select="onSelectBranch"
          @page="onPageBranch"
        />
        <CCard v-else>
          <CCardBody>
            <div class="my-2">Branch</div>
            <CWidgetIcon :header="user_updated.branch_name" color="primary">
              <CIcon name="cil-room" width="24"/>
            </CWidgetIcon>
            <CButton style="float: right;" color="danger" shape="pill" @click="onReset">Reset</CButton>
          </CCardBody>
        </CCard>
      </CCardBody>
      <CCardBody v-if="step===2">
        <div>
          <el-form label-width="25%" @submit.native.prevent>
            <input type="file" ref="fileInput" accept="image/jpeg,image/png" @change="handleImage" hidden/>
            <el-form-item v-if="show_crop_ui" label="User Image">
              <div class="mb-1">
                <Jcrop
                  :src="image_uri"
                  @update="onCrop"
                  :rect="rect"
                  :options="options">
                </Jcrop>
              </div>
              <canvas ref="cropCanvas" class="border"></canvas>
            </el-form-item>
            <el-form-item v-else label="User Image">
              <div class="mb-1">
                <el-button type="primary" @click="onUpload">Select</el-button>
              </div>
              <img v-if="user_updated.image_signed_url" :src="user_updated.image_signed_url">
            </el-form-item>
          </el-form>
        </div>
      </CCardBody>
    </CCard>

    <template #header>
      <h6 class="modal-title">{{title}}  {{" - STEP ("+(step+1)+"/3)"}}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step===0" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===1 || step===2" @click="onPrev" color="secondary">{{$t('button.prev')}}</CButton>
      <CButton v-if="step===0 || step===1" @click="onNext" color="primary">{{$t('button.next')}}</CButton>
      <CButton v-if="step===2" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import { Jcrop } from 'vue-jcrop';
import utility from '@/utility.js';
import { mapState, mapGetters } from 'vuex'
import SearchList from '@/components/SearchList.vue'

export default {
  name: 'SetupUserForm',
  components: {
    Jcrop,
    SearchList
  },
  data() {
    return {
      show: false,

      need_pw : true,
      password: null,

      auth_level: null,
      cellphone: null,

      title: '',
      user: null,
      user_updated : {
        id: null,
        cellphone: null,
        name: null,
        auth_level: null,
        auth_name: null,
        organization_id: null,
        branch_id: null,
        branch_name: null,
        email: null
      },

      notification: '',
      dismissCountDown: 0,
      step: 0,

      image_uri:null, // image test
      // img_name:null,
      show_crop_ui:false,
      rect: [0, 0, 80, 80],
      position: {},
      options: {
        handles: ['se'],
        aspectRatio: 80 / 80
      },
      image_crop_uri: null,
      image_blob: null,

      branch_list: [],
      branch_search: null,
      branch_page_total: 1,
      branch_page_current: 1
    }
  },
  computed:{
    ...mapState([
      'capability'
    ]),
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    auth_levels: function() {
      if (!this.capability) return null;
      const al_filtered = this.capability.auth_levels.filter(el => el.level > this.myAuthorizationLevel.level);
      const auths = al_filtered.map(el => {
        el.value = el.level;
        el.label = el.name;
        return el;
      });
      return auths
    }
  },
  methods: {
    open(user, title) {
      this.onCancel();
      this.show = true;
      if (user) {
        this.user_updated = JSON.parse(JSON.stringify(user));
        this.need_pw = false;
      } else {
        this.need_pw = true;
        this.user_updated = {
          id: null,
          cellphone: null,
          name: null,
          auth_level: this.auth_levels[0]['level'] || null,
          auth_name: null,
          organization_id: null,
          email: null,
          branch_id: null,
          branch_name: null
        };
      }
      this.title = title;
    },
    onCancel() {
      this.show = false;
      this.password = null;
      this.user_updated = {
        id: null,
        cellphone: null,
        name: null,
        auth_level: this.auth_levels[0]['level'] || null,
        auth_name: null,
        organization_id: null,
        email: null,
        branch_id: null,
        branch_name: null
      };
      this.image_uri=null;
      this.img_name=null;
      this.img_base64=null;
      this.img_type=null;
      
      this.show_crop_ui = false;
      this.rect = [0, 0, 80, 80];
      this.orig_image = null;
      this.image_uri = null;
      this.position = {};
      this.options = {
        handles: ['se'],
        aspectRatio: 80 / 80
      };
      this.image_crop_uri = null;
      this.image_blob = null;
      if(this.$refs.fileInput)
        this.$refs.fileInput.value = null;

      this.step = 0;
    },
    checkEmailDup() {
      let query_string = '';
      const params = {
        email: this.user_updated.email
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      if (this.user_updated.id) {
        this.step++;
        if (this.user_updated.auth_level < 100) this.step++;
        return;
      }
      axios.get(`/api/users/duplication/${query_string}`)
        .then(response => {
          if (response.data.result) {
            this.notification = `Email(${response.data.email}) is already registered.`;
            this.dismissCountDown = 5;
            return;
          }
          this.step++;
          if (this.user_updated.auth_level < 100) this.step++;
        })
        .catch(error => {
          this.notification = `Network error`;
          this.dismissCountDown = 3;
          console.error(error);
        });
    },
    onNext() {
      if(this.validateBasic()){
        this.checkEmailDup();
        return;
      } else {
        console.log("Format Error")
        return
      }
    },
    onPrev() {
      this.step--;
      if (this.user_updated.auth_level !== 100) this.step--;
    },
    onSubmit() {
      if(this.validateDetail()){
        this.show = false;
        // console.log(this.user_updated.auth_level)

        const fd = new FormData;
        fd.append("name", this.user_updated.name);
        if(this.password)
          fd.append("password", this.password);
        fd.append("email", this.user_updated.email);
        fd.append("cellphone", this.user_updated.cellphone);
        fd.append("auth_level", this.user_updated.auth_level);
        fd.append("branch_id", this.user_updated.branch_id);

        let blob = null;
        if(this.image_crop_uri){
          blob = utility.DataURI2Blob(this.image_crop_uri);
          fd.append("image", blob, "user_image.jpg");
        }
        this.$emit('payload', fd, this.user_updated.id);
        this.onCancel();
      }else{
        console.log("Input Format check");
        return;
      }
    },

    validateBasic(){
      if (!this.user_updated.email){
        this.notification = 'Email is required';
        this.dismissCountDown = 3;
        return false;
      }
      let re = /\S+@\S+\.\S+/;
      if (!re.test(this.user_updated.email)){
        this.notification = 'Email Format is wrong';
        this.dismissCountDown = 3;
        return false;
      }
      if (!this.user_updated.auth_level) {
        this.notification = 'Auth Level is required';
        this.dismissCountDown = 3;
        return false;
      }
      if (!this.capability.organization.id && this.user_updated.auth_level >= 10) {
        this.notification = 'No organizational permissions';
        this.dismissCountDown = 3;
        return false;
      }
      if(this.need_pw){
        if (!this.password) {
        this.notification = 'Password is required';
        this.dismissCountDown = 3;
        return false;
        }
        if (this.password.length < 6) {
          this.notification = 'Password must be at least 6 characters long';
          this.dismissCountDown = 3;
          return false;
        }
      }
      return true;
    },
    validateDetail(){
      if(!this.user_updated.cellphone){
        this.notification = 'Cell phone is required';
        this.dismissCountDown = 3;
        return false;
      }
      if (!this.user_updated.name) {
        this.notification = 'Name is required';
        this.dismissCountDown = 3;
        return false;
      }
			if (JSON.stringify(this.user_updated) === JSON.stringify(this.user_updated.id)) {
        if(this.image_uri!==null){ // 이미지만 변경이 되었다면
        }else{
          this.notification = 'Have not changed the user'
          this.dismissCountDown = 3;
          return false;
        }
      }
      return true;
    },

    handleImage(e){
      if(e.target.files.length===0){
        return;
      }
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const reader = new FileReader;
      const img = new Image;

      // image_name
      reader.onload = event => {
        img.onload = _=>{
          if(img.width <40 || img.height < 40){
            this.notification = 'Image is too small. Minimum size is 40x40.';
            this.dismissCountDown = 3;
            return;
          }
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          this.image_uri = utility.ContainImageSize(img, 540, 400);
          this.show_crop_ui = true;
        }
        img.src = event.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);    
    },
    onUpload(){
      this.$refs.fileInput.click();
    },
    onCrop(e){
      this.position = e.sel.round();
      const img = new Image;
      img.onload = _=>{
        const position = this.position;
        this.cropImage(img, position.x, position.y, position.w, position.h);
      }
      img.src = this.image_uri;
    },
    cropImage(imageObj, x,y,w,h){
      utility.EraseCanvas(this.$refs.cropCanvas);
      const canvas = this.$refs.cropCanvas;
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext('2d');
      ctx.save();
      ctx.drawImage(imageObj, x,y,w,h, 0,0,w,h);
      ctx.restore();
      this.image_crop_uri = canvas.toDataURL('image/jpeg', 1.0);
    },
    onSearchBranch: function(text) {
      this.branch_search = text;
      this.getBranches();
    },
    onFocusBranch: function() {
      this.getBranches();
    },
    onPageBranch: function(p) {
      this.branch_page_current = p;
      this.getBranches();
    },
    onSelectBranch: function(id) {
      this.user_updated.branch_id = id;
      this.user_updated.branch_name = this.branch_list.find(el => el.id === id).name;
    },
    getBranches(){
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.branch_search,
        page: this.branch_page_current,
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          this.branch_page_total = result.data.page.total;
          this.branch_page_current = result.data.page.current;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onReset(){
      this.user_updated.branch_id = null;
      this.user_updated.branch_name = null;
      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_total = 1;
      this.branch_page_current = 1;
    }
  },
}
</script>