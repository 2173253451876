/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'login': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 6.984L9.61 8.39l2.578 2.625H2.016v1.969h10.172L9.61 15.609l1.406 1.406 4.969-5.016zm9 12H12V21h8.016q.797 0 1.383-.586t.586-1.43V5.015q0-.844-.586-1.43t-1.383-.586H12v2.016h8.016v13.969z"/>'
  }
})
