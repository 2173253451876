<template>
  <div>
    <DatePickerModal ref="DatePickerModal" title="입고 시간대" ok_text="OK" cancel_text="Cancel" @ok="onTsTimestamps" />
    <CCard v-if="!loading">
      <CCardHeader v-if="filters.length">
        <div class="filter_row">
          <div class="filter_label d-md-down-none" style="border: 0;"></div>
          <div class="ml-4 my-1">
            <el-tag class="mr-1" v-for="f in filters" :key="`filter-tags-${f.id}`" :type="f.type" closable @close="onClose(f.id)">{{f.content}}</el-tag>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CButton v-if="collapse" @click="onFold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_up" width="18px" height="18px" />
        </CButton>
        <CButton v-else @click="onUnfold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_down" width="18px" height="18px" />
        </CButton>
        <CRow>
          <CCol md="12" lg="8">
            <div class="filter_row" v-if="myAuthorizationLevel.level < 100">
              <div class="filter_label d-md-down-none">공장 구분</div>
              <el-select class="ml-4 my-1 filter_input_size" v-model="filter_branch" placeholder="전체 공장" filterable clearable @change="onFilterBranch">
                <el-option-group
                  v-for="group in regions"
                  :key="`regions-${group.id}`"
                  :label="group.name">
                  <el-option
                    v-for="option in group.options"
                    :key="`factory-${option.id}`"
                    :label="option.name"
                    :value="option.id">
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
            <div class="filter_row" >
              <div class="filter_label d-md-down-none">검수 구분</div>
              <el-radio-group class="ml-4 my-1" v-model="filter_validity" @change="onFilterChange">
                <el-radio-button
                  v-for="validity in $resource.VALIDITY_OPTS"
                  :key="`validity-opts-${validity.value}`"
                  :label="validity.value"
                >{{validity.label}}</el-radio-button>
              </el-radio-group>
            </div>
            <CCollapse :show="collapse">
              <div class="filter_row">
                <div class="filter_label d-md-down-none">거래처 구분</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_correspondent" placeholder="모든 거래처" filterable clearable @change="onFilterChange">
                  <el-option-group
                    v-for="group in branches"
                    :key="`branches-${group.id}`"
                    :label="group.name">
                    <el-option
                      v-for="option in group.options"
                      :key="`correspondents-opts-${option.id}`"
                      :label="option.name"
                      :value="option.id">
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">산지 구분</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_origin" placeholder="모든 산지" filterable clearable @change="onFilterChange">
                  <el-option-group
                    v-for="group in origins"
                    :key="`correspondents-${group.id}`"
                    :label="group.name">
                    <el-option
                      v-for="option in group.options"
                      :key="`origins-opts-${option.id}`"
                      :label="option.name"
                      :value="option.name">
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">제품 구분</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_freight" placeholder="모든 제품" filterable clearable @change="onFilterChange">
                  <el-option
                    v-for="option in FREIGHT_OPTS"
                    :key="`freights-opts-${option.id}`"
                    :label="option.name"
                    :value="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="filter_row" >
                <div class="filter_label d-md-down-none">덤프 외 차량</div>
                <el-radio-group class="ml-4 my-1" v-model="filter_no_dump" @change="onFilterChange">
                  <el-radio-button
                    v-for="opt in $resource.EXCLUDE_OPTS"
                    :key="`validity-opts-${opt.value}`"
                    :label="opt.value"
                  >{{opt.label}}</el-radio-button>
                </el-radio-group>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">입고일 구분</div>
                  <el-tooltip class="ml-4 my-1" effect="dark" :content="$t('cloud.title.filter_ts_today')" placement="top">
                    <el-button :type="filter_ts_mode === 'today'?'primary':''" circle @click="onTsToday">
                      <svgicon name="today" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_yesterday')" placement="top">
                    <el-button :type="filter_ts_mode === 'd1'?'primary':''" circle @click="onTsMinus1">
                      <svgicon name="exposure_neg_1" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_before_yesterday')" placement="top">
                    <el-button :type="filter_ts_mode === 'd2'?'primary':''" circle @click="onTsMinus2">
                      <svgicon name="exposure_neg_2" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="mx-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_last_24_hours')" placement="top">
                    <el-button :type="filter_ts_mode === 'h24'?'primary':''" circle @click="onTs24Hours">
                      <svgicon name="replay_circle_filled" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <div style="border-left: 1px solid #eee;">
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_manual')" placement="top">
                    <el-button type="info" circle @click="onTsManual">
                      <svgicon name="access_time" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
            </CCollapse>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import utility from '@/utility.js';
import DatePickerModal from '@/components/modals/DatePickerModal.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'DeliverySearchFilter',
  props: {
    FACTORY_OPTS: {type: Array, default: () => {return [];}},
    CORRESPONDENT_OPTS: {type: Array, default: () => {return [];}},
    FREIGHT_OPTS: {type: Array, default: () => {return [];}}
  },
  components: {
    DatePickerModal
  },

  data() {
    return {
      loading: false,

      filter_branch: null,
      filter_validity: null,
      filter_correspondent: null,
      filter_origin: null,
      filter_freight: null,
      filter_ts_mode: null,
      filter_sts: null,
      filter_ets: null,
      filter_no_dump: true,

      collapse : false
    }
  },

  mounted() {
    this.getSearchFilter();
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    filters() {
      let rtn = [];
      if (this.filter_branch) {
        let factory = this.FACTORY_OPTS.find(el=>el.id===this.filter_branch);
        if (factory) {
          rtn.push({
            id: 'branch',
            type: 'primary',
            content: `공장:${factory.name}`
          });
        }
      }
      if (this.filter_validity) {
        if (this.filter_validity === 'valid') {
          rtn.push({
            id: 'validity',
            type: 'primary',
            content: `검수:정상`
          });
        } else if (this.filter_validity === 'invalid') {
          rtn.push({
            id: 'validity',
            type: 'danger',
            content: `검수:불량`
          });
        } else if (this.filter_validity === 'undetermined') {
          rtn.push({
            id: 'validity',
            type: 'warning',
            content: `검수:미검수`
          });
        }
      }
      if (this.filter_correspondent) {
        let correspondent = this.CORRESPONDENT_OPTS.find(el => el.id === this.filter_correspondent);
        if (correspondent) {
          rtn.push({
            id: 'correspondent',
            type: 'primary',
            content: `거래처:${correspondent.name}`
          });
        }
      }
      if (this.filter_origin) {
        rtn.push({
          id: 'origin',
          type: 'primary',
          content: `산지:${this.filter_origin}`
        });
      }
      if (this.filter_freight) {
        let freight = this.FREIGHT_OPTS.find(el => el.id === this.filter_freight);
        if (freight) {
          rtn.push({
            id: 'freight',
            type: 'primary',
            content: `종류:${freight.name}`
          });
        }
      }
      if (this.filter_ts_mode) {
        if (this.filter_ts_mode === 'today') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `입고일:오늘`
          });
        } else if (this.filter_ts_mode === 'd1') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `입고일:어제부터`
          });
        } else if (this.filter_ts_mode === 'd2') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `입고일:2일전부터`
          });
        } else if (this.filter_ts_mode === 'h24') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `입고일:지난24시간`
          });
        }
      }
      if (this.filter_sts && this.filter_ets) {
        rtn.push({
          id: 'ts',
          type: 'info',
          content: `입고일: ${utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.filter_sts))}~${utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.filter_ets))}`
        });
      }
      if (this.filter_no_dump) {
        rtn.push({
          id: 'no_dump',
          type: 'primary',
          content: '덤프 외 차량 표시'
        })
      }
      return rtn;
    },
    regions() {
      let rtn = [];
      for (const factory of this.FACTORY_OPTS) {
        let rtn_region = rtn.find(el => el.name === factory.region_name);
        if (!rtn_region) {
          rtn.push(
            {
              name: factory.region_name,
              id: factory.region,
              options: [
                {name: factory.name, id: factory.id}
              ]
            }
          )
        } else {
          rtn_region.options.push({name: factory.name, id: factory.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    },
    branches() {
      let rtn = [];
      let corrs = this.CORRESPONDENT_OPTS;
      if (this.filter_branch) {
        corrs = corrs.filter(el => el.branch_id === this.filter_branch);
      }
      for (const correspondent of corrs) {
        let rtn_branch = rtn.find(el => el.name === correspondent.branch_name);
        if (!rtn_branch) {
          rtn.push(
            {
              name: correspondent.branch_name,
              id: correspondent.branch_id,
              options: [
                {name: correspondent.name, id: correspondent.id}
              ]
            }
          )
        } else {
          rtn_branch.options.push({name: correspondent.name, id: correspondent.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    },
    origins() {
      let rtn = [];
      let corrs = this.CORRESPONDENT_OPTS;
      if (this.filter_branch) {
        corrs = corrs.filter(el => el.branch_id === this.filter_branch);
      }
      for (const correspondent of corrs) {
        let rtn_corrs = rtn.find(el => el.name === correspondent.name);
        if (!rtn_corrs) {
          rtn.push(
            {
              name: correspondent.name,
              id: correspondent.id,
              options: correspondent.origins
            }
          )
        }
      }
      return rtn;
    }
  },
  methods: {
    getSearchFilter() {
      this.loading = true;
      axios.get(`/api/delivery/filter/`)
        .then(response => {
          let validity = null;
          if (response.data.validity !== null) {
            validity = response.data.validity === 1 ? 'valid':(response.data.validity === 0 ? 'invalid':'undetermined');
          }
          this.filter_branch = response.data.branch_id,
          this.filter_validity = validity,
          this.filter_correspondent = response.data.correspondent_id,
          this.filter_origin = response.data.origin,
          this.filter_freight = response.data.freight_id,
          this.filter_no_dump = response.data.no_dump,
          this.filter_ts_mode = response.data.ts_mode,
          this.filter_sts = response.data.ts_sts,
          this.filter_ets = response.data.ts_ets
        })
        .catch(error => {
          this.filter_branch = null;
          this.filter_validity = 'undetermined';
          this.filter_correspondent = null;
          this.filter_origin = null;
          this.filter_freight = null;
          this.filter_no_dump = false;
          this.filter_ts_mode = 'today';
          this.filter_sts = null;
          this.filter_ets = null;
          console.error(error);
        })
        .finally(() => {
          this.onFilterChange();
          this.loading = false;
        })
    },
    putSearchFilter() {
      let validity = null;
      if (this.filter_validity === 'valid') {
        validity = 1;
      } else if (this.filter_validity === 'invalid') {
        validity = 0;
      } else if (this.filter_validity === 'undetermined') {
        validity = 2;
      }
      axios.put(`/api/delivery/filter/`, {
        branch_id: this.filter_branch || null,
        correspondent_id: this.filter_correspondent || null,
        freight_id: this.filter_freight || null,
        origin: this.filter_origin || null,
        validity: validity,
        no_dump: this.filter_no_dump,
        ts_mode: this.filter_ts_mode,
        ts_sts: this.filter_sts,
        ts_ets: this.filter_ets,
      })
        .then(() => {
          // console.log('filter put ok');
        })
        .catch(error => {
          console.error(error);
        })
    },
    onFilterChange() {
      let payload = {
        branch: this.filter_branch,
        correspondent: this.filter_correspondent,
        origin: this.filter_origin,
        freight: this.filter_freight,
        no_dump: this.filter_no_dump,
        ts_mode: this.filter_ts_mode,
        sts: this.filter_sts,
        ets: this.filter_ets
      };
      if (this.filter_validity === 'valid') payload.validity = 1;
      else if (this.filter_validity === 'invalid') payload.validity = 0;
      else if (this.filter_validity === 'undetermined') payload.validity = 2;
      this.$emit('change', payload);
      this.putSearchFilter();
    },
    onFilterBranch() {
      this.filter_correspondent = null;
      this.filter_origin = null;
      this.onFilterChange();
    },
    onFilterValidity(){
      this.onFilterChange();
    },
    onTsToday() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'today';
      this.onFilterChange();
    },
    onTsMinus1() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'd1';
      this.onFilterChange();
    },
    onTsMinus2() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'd2';
      this.onFilterChange();
    },
    onTs24Hours() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'h24';
      this.onFilterChange();
    },
    onTsManual() {
      this.$refs.DatePickerModal.open();
    },
    onTsTimestamps({sts, ets}) {
      this.filter_ts_mode = null;
      this.filter_sts = sts;
      this.filter_ets = ets;
      this.onFilterChange();
    },

    onClose(filter_id) {
      switch(filter_id) {
        case 'branch':
          this.filter_branch = null;
          break;
        case 'validity':
          this.filter_validity = null;
          break;
        case 'correspondent':
          this.filter_correspondent = null;
          break;
        case 'origin':
          this.filter_origin = null;
          break;
        case 'freight':
          this.filter_freight = null;
          break;
        case 'ts_mode':
          this.filter_ts_mode = null;
          this.filter_sts = null;
          this.filter_ets = null;
          break;
        case 'ts':
          this.filter_ts_mode = null;
          this.filter_sts = null;
          this.filter_ets = null;
          break;
        case 'no_dump':
          this.filter_no_dump = false;
          break;
      }
      this.onFilterChange();
    },

    onFold() {
      this.collapse = false;
    },
    onUnfold() {
      this.collapse = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size {
  width: 250px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>
