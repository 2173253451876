/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'laptop_windows': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 5.016V15h16.031V5.016H3.984zM20.016 18H24v2.016H0V18h3.984v-.984q-.797 0-1.383-.609t-.586-1.406V5.017q0-.797.586-1.406t1.383-.609h16.031q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406t-1.383.609V18z"/>'
  }
})
