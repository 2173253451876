/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'more': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 13.5q.609 0 1.055-.422T20.484 12t-.445-1.078-1.055-.422-1.055.422T17.484 12t.445 1.078 1.055.422zm-4.968 0q.609 0 1.055-.422T15.516 12t-.445-1.078-1.055-.422-1.055.422T12.516 12t.445 1.078 1.055.422zM9 13.5q.656 0 1.078-.422T10.5 12t-.422-1.078T9 10.5t-1.078.422T7.5 12t.422 1.078T9 13.5zM21.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H7.078q-1.031 0-1.688-.891l-5.391-8.109L5.39 3.89q.656-.891 1.594-.891h15z"/>'
  }
})
