/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_enabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.391 10.781l-2.203-2.203q-.422-.422-.234-1.031.563-1.688.563-3.563 0-.422.281-.703T16.501 3h3.516q.422 0 .703.281t.281.703q0 7.031-4.992 12.023T3.986 20.999q-.422 0-.703-.281t-.281-.703v-3.516q0-.422.281-.703t.703-.281q1.875 0 3.563-.563.563-.234 1.031.234l2.203 2.203q4.359-2.25 6.609-6.609z"/>'
  }
})
