/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_sidebar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 20.016H2.015V3.985h13.969v16.031zm2.016-12h3.984V3.985H18v4.031zm0 12h3.984v-4.031H18v4.031zm0-6h3.984V9.985H18v4.031z"/>'
  }
})
