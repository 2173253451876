/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'g_translate': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.516 20.484V6.515q0-.422-.305-.727t-.727-.305h-9.047L12.75 9.28h1.453V8.014h1.266V9.28h4.547v1.313h-1.922q-.703 2.344-2.391 4.219l3.281 3.281-.938.891-3.094-3.094 1.031 3.094-1.969 2.531h6.469q.422 0 .727-.305t.305-.727zm-8.344-9.89l.797 2.344.844 1.125q1.453-1.594 2.063-3.469h-3.703zm-6.188 5.39q2.156 0 3.492-1.359t1.336-3.516q0-.047-.141-1.031H6.983v1.734h2.953q-.094.891-.844 1.641t-2.109.75q-1.313 0-2.227-.938t-.914-2.25q0-1.359.914-2.297t2.227-.938q1.266 0 2.063.797l1.313-1.266Q8.906 5.998 6.984 5.998q-2.063 0-3.516 1.477t-1.453 3.539 1.453 3.516 3.516 1.453zM21 3.984q.797 0 1.406.609t.609 1.406v15q0 .797-.609 1.406T21 23.014h-9l-.984-3H3q-.797 0-1.406-.609t-.609-1.406v-15q0-.797.609-1.406T3 .984h6.984l1.031 3h9.984z"/>'
  }
})
