/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'print_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 18.984h7.875l-4.969-4.969H8.016v4.969zM1.219 1.781l21 20.906L20.906 24l-3-3H6v-3.984H2.016v-6q0-1.172.844-2.039t2.063-.961L.001 3zM18 6.984H9L6 4.078V3h12v3.984zm.984 3q-.422 0-.703.305t-.281.727.281.703.703.281.727-.281.305-.703-.305-.727-.727-.305zm.094 7.032l-9.094-9h9q1.266 0 2.133.867t.867 2.133v6h-2.906z"/>'
  }
})
