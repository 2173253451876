/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_input_component': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15.984v-1.969h6v1.969q0 .984-.563 1.734T21 18.796v4.219h-2.016v-4.219q-1.969-.703-1.969-2.813zM12.984 2.016V6H15v6H9V6h2.016V2.016q0-.422.281-.727T12 .984t.703.305.281.727zM21 6h2.016v6h-6V6h1.969V2.016q0-.422.305-.727t.727-.305.703.305.281.727V6zM.984 15.984v-1.969h6v1.969q0 2.109-1.969 2.813v4.219H2.999v-4.219q-.891-.328-1.453-1.078t-.563-1.734zm8.016 0v-1.969h6v1.969q0 .984-.563 1.734t-1.453 1.078v4.219h-1.969v-4.219q-.891-.328-1.453-1.078t-.563-1.734zM5.016 2.016V6h1.969v6h-6V6h2.016V2.016q0-.422.281-.727t.703-.305.727.305.305.727z"/>'
  }
})
