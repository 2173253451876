/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'umbrella': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.484 6.938l-1.5-1.172V3.422q0-.234.164-.375t.352-.141.352.141.164.375v.563h1.969v-.563q0-1.031-.727-1.734T13.5.985t-1.758.703-.727 1.734v2.344l-1.5 1.172-3.516-.891 5.063 15.281q.094.328.375.492t.563.164.563-.164.375-.492l5.063-15.281zm-1.218 1.546l.797.609.891-.234-1.969 5.953V8.296zm-3.328.61l.797-.563.281-.234v6.516L9.047 8.86z"/>'
  }
})
