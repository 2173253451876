/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unpublished': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.188 21.188L2.813 2.813 1.407 4.219l2.25 2.25Q2.86 7.688 2.438 9.071t-.422 2.93q0 2.063.773 3.867t2.156 3.188 3.188 2.156 3.867.773q1.547 0 2.93-.422t2.602-1.219l2.25 2.25zm-10.594-4.594l-4.266-4.219 1.453-1.406 2.813 2.813.188-.188L12.188 15zm3-5.813L6.469 3.656q1.219-.797 2.602-1.219t2.93-.422q2.063 0 3.867.773t3.188 2.156 2.156 3.188.773 3.867q0 1.547-.422 2.93t-1.219 2.602L15 12.187l2.672-2.672-1.453-1.406z"/>'
  }
})
