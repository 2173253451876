/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wc': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 6q-.844 0-1.43-.586t-.586-1.43.586-1.406 1.43-.563 1.43.563.586 1.406-.586 1.43T16.5 6zm-9 0q-.844 0-1.43-.586t-.586-1.43.586-1.406 1.43-.563 1.43.563.586 1.406-.586 1.43T7.5 6zM18 21.984h-3v-6h-3l2.531-7.594q.188-.563.75-.984t1.172-.422h.094q.609 0 1.172.422t.75.984L21 15.984h-3v6zm-12.516 0v-7.5h-1.5V9q0-.797.609-1.406t1.406-.609h3q.797 0 1.406.609T11.014 9v5.484h-1.5v7.5H5.483z"/>'
  }
})
