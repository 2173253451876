<template>
  <l-map :style="`height: ${mapHeight}px`" :zoom="zoom" :center="center" :options="{scrollWheelZoom:scrollZoom, attributionControl:false}">
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker
      v-for="(overlay, index) in locations.filter(el => el.focused)"
      :lat-lng="overlay.position"
      :key="`marker-focused-${index}`"
      :z-index-offset="100"
    >
      <l-icon icon-url="img/location-pin5.png" :icon-size="[50, 50]" :icon-anchor="[25, 49]" />
      <l-tooltip>{{overlay.name}}</l-tooltip>
    </l-marker>
    <l-marker
      v-for="(overlay, index) in locations.filter(el => !el.focused)"
      :lat-lng="overlay.position"
      :key="`marker-${index}`"
    >
      <l-tooltip>{{overlay.name}}</l-tooltip>
    </l-marker>
  </l-map>
</template>

<script>
export default {
  name: 'Map',

  props: {
    locations: {type:Array},
    rotation: {type:Number, default: 0},
    zoom: {type:Number, default: 5},
    mapHeight: {type:Number, default: 600},
    center: {type:Array, default: () => [30,30]},
    scrollZoom: {type:Boolean, default: false}
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    }
  }
}
</script>
