/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip_camera_android': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 14.016h6v6h-1.969V18q-3 3.984-8.016 3.984-3.609 0-6.352-2.273t-3.445-5.695h2.063q.656 2.578 2.813 4.289T12 20.016q2.156 0 4.008-1.102t2.883-2.93h-2.906v-1.969zM8.016 9.984h-6v-6h1.969V6q3-3.984 8.016-3.984 3.609 0 6.352 2.273t3.445 5.695h-2.063q-.656-2.578-2.813-4.289T12 3.984q-2.156 0-4.008 1.102t-2.883 2.93h2.906v1.969zM9 12q0-1.219.891-2.109T12 9t2.109.891T15 12t-.891 2.109T12 15t-2.109-.891T9 12z"/>'
  }
})
