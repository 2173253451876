/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sanitizer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 6.516q0-.328.211-.75t.516-.82.539-.68l.234-.281.211.281.516.68.539.82.234.75q0 .609-.445 1.055t-1.055.445q-.656 0-1.078-.445t-.422-1.055zM19.5 15q1.031 0 1.758-.727t.727-1.758q0-.656-.375-1.43t-.867-1.477-.867-1.148l-.375-.445-.375.445-.867 1.148-.867 1.477-.375 1.43q0 1.031.727 1.758t1.758.727zm-6.516-.984h-1.969V12H8.999v2.016H6.983v1.969h2.016v2.016h2.016v-2.016h1.969v-1.969zm3-2.016v9.984h-12V12q0-1.5.656-2.742t1.805-2.086T9 6.094V3.985H6.984V2.016h6q.844 0 1.617.258t1.383.727l-1.406 1.453q-.375-.234-.773-.352t-.82-.117h-1.969v2.109q1.406.234 2.531 1.078t1.781 2.086.656 2.742z"/>'
  }
})
