/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'swap_horiz': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 9l-3.984 3.984v-3H9.985V8.015h7.031v-3zM6.984 11.016v3h7.031v1.969H6.984v3L3 15.001z"/>'
  }
})
