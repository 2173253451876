/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12q0-2.063-.773-3.867t-2.156-3.188-3.188-2.156T12 2.016t-3.867.773-3.188 2.156-2.156 3.188T2.016 12q0 1.828.586 3.445t1.688 2.93 2.555 2.203 3.141 1.219V15H8.017v-3h1.969V9.516q0-.984.492-1.781t1.266-1.266T13.502 6h2.484v3h-1.969q-.422 0-.727.305t-.305.68v2.016h3v3h-3v6.938q1.922-.188 3.563-1.031t2.859-2.18 1.898-3.047.68-3.68z"/>'
  }
})
