/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'self_improvement': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 6q0-.844.586-1.43T12 3.984t1.43.586.586 1.43-.586 1.43-1.43.586-1.43-.586T9.984 6zM21 15.984v-1.969q-1.688 0-3.094-.703t-2.484-2.016l-1.359-1.594q-.609-.703-1.547-.703h-1.031q-.938 0-1.547.703l-1.313 1.594Q7.5 12.609 6.094 13.312T3 14.015v1.969q2.063 0 3.844-.844t3.141-2.391v2.25l-3.844 1.547q-.516.188-.82.656t-.305.984q0 .75.516 1.289t1.266.539h2.203v-.516q0-1.031.727-1.758t1.758-.727h3q.234 0 .375.141t.141.328q0 .234-.141.375t-.375.141h-3q-.609 0-1.055.445t-.445 1.055v.516h7.219q.75 0 1.266-.539t.516-1.289q0-.516-.305-.984t-.82-.656l-3.844-1.547v-2.25q1.359 1.547 3.141 2.391t3.844.844z"/>'
  }
})
