/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_module': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 5.016H21v6h-5.016v-6zm-6 6v-6H15v6H9.984zm6 6.984v-6H21v6h-5.016zm-6 0v-6H15v6H9.984zm-6 0v-6H9v6H3.984zm0-6.984v-6H9v6H3.984z"/>'
  }
})
