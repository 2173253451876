/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hardware': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12.984h6v7.031q0 .422-.281.703t-.703.281H9.985q-.422 0-.703-.281t-.281-.703v-7.031zM18 3h2.016v8.016H18l-3-3v3H9v-3H3.984q0-2.063 1.477-3.539T9 3h6v3z"/>'
  }
})
