/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'not_started': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zm-.984 13.968H9V8.015h2.016v7.969zm.984 0V8.015l5.016 3.984z"/>'
  }
})
