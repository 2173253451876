/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_location': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 9.984V8.015h-3v-3h-1.969v3h-3v1.969h3v3h1.969v-3h3zM12 2.016q2.906 0 4.945 2.039T18.984 9q0 1.453-.727 3.328t-1.758 3.516-2.039 3.07-1.711 2.273l-.75.797q-.281-.328-.75-.867t-1.688-2.156-2.133-3.141-1.664-3.445T5.014 9q0-2.906 2.039-4.945t4.945-2.039z"/>'
  }
})
