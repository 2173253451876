/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'snooze': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 11.016V9h6v1.781L11.391 15H15v2.016H9v-1.828l3.609-4.172H9zm3 9q2.906 0 4.945-2.063t2.039-4.969-2.039-4.945T12 6 7.055 8.039t-2.039 4.945 2.039 4.969T12 20.016zm0-16.032q3.75 0 6.375 2.648T21 12.984t-2.625 6.352T12 21.984t-6.375-2.648T3 12.984t2.625-6.352T12 3.984zm9.984 1.735l-1.266 1.547-4.594-3.891 1.266-1.5zM7.875 3.375L3.281 7.219l-1.266-1.5 4.594-3.844z"/>'
  }
})
