/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bolt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 21H9.985l1.031-6.984H7.5q-.75 0-.375-.656.141-.234.047-.141Q9.563 9.047 12.985 3h1.031l-1.031 6.984h3.516q.656 0 .422.656z"/>'
  }
})
