/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'electric_rickshaw': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 11.203v-1.5q0-.703-.469-1.266l-3.938-4.734Q15.984 3 15.046 3H2.999q-.844 0-1.43.586t-.586 1.43v7.969q0 .844.586 1.43t1.43.586h.188q.328.891 1.078 1.453t1.734.563 1.734-.563 1.078-1.453h8.391q.281.891 1.055 1.453t1.758.563q.844 0 1.523-.422t1.078-1.102.398-1.477q0-.984-.563-1.758t-1.453-1.055zM18.422 9h-2.438V6.141zM3 5.016h3.984V9H3V5.016zM6 15q-.422 0-.703-.305t-.281-.68q0-.422.281-.727T6 12.983t.703.305.281.727q0 .375-.281.68T6 15zm3-2.016v-1.969h3V8.999H9V5.015h5.016v7.969H9zM20.016 15q-.422 0-.727-.305t-.305-.68q0-.422.305-.727t.727-.305q.375 0 .68.305t.305.727q0 .375-.305.68t-.68.305zM6.984 20.016h4.031V18l6 3h-4.031v2.016z"/>'
  }
})
