/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hdr_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.484 2.484q4.594 4.641 19.125 19.031l-1.125 1.078-7.594-7.594H9.515v-3.422l-1.5-1.5v4.922h-1.5v-2.484H4.499v2.484h-1.5v-6h1.5v2.016h2.016V8.999h.375L1.406 3.515zm10.5 8.016h-.375l-1.5-1.5h1.875q.609 0 1.055.445t.445 1.055v1.922l-1.5-1.5V10.5zm4.5 0v.984H19.5V10.5h-2.016zm0 4.5h-.375l-1.125-1.078V9H19.5q.609 0 1.055.445T21 10.5v.984q0 1.078-.891 1.406L21 14.999h-1.5l-.891-2.016h-1.125v2.016z"/>'
  }
})
