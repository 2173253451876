/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bug_report': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12V9.984H9.985V12h4.031zm0 3.984v-1.969H9.985v1.969h4.031zm6-7.968v1.969h-2.109q.094.656.094 1.031V12h2.016v2.016h-2.016V15q0 .328-.094.984h2.109V18h-2.813q-.797 1.359-2.18 2.18T12 21t-3.023-.82T6.797 18H3.984v-2.016h2.109q-.094-.656-.094-.984v-.984H3.983V12h2.016v-.984q0-.375.094-1.031H3.984V8.016h2.813Q7.5 6.844 8.625 6.047L6.984 4.406 8.39 3l2.203 2.156q.703-.141 1.406-.141t1.406.141L15.608 3l1.406 1.406-1.641 1.641q1.172.797 1.828 1.969h2.813z"/>'
  }
})
