/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock_clock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.484 14.203l2.906 1.688-.797 1.313-3.609-2.203V9.985h1.5v4.219zm7.5-.187q0 1.641-.609 3.094t-1.711 2.555-2.555 1.711-3.094.609q-1.547 0-2.883-.516t-2.414-1.453H3.984q-.844 0-1.406-.586T2.015 18V9q0-.844.586-1.406t1.383-.609v-.469q0-1.266.609-2.273t1.641-1.617 2.25-.609q1.172 0 2.156.539t1.594 1.453.703 2.086q.563-.094 1.078-.094 1.641 0 3.094.633t2.555 1.734 1.711 2.531.609 3.117zM6 6.984h5.016v-.703q-.094-.984-.82-1.641t-1.711-.656q-1.031 0-1.758.75T6 6.515v.469zm14.016 7.032q0-1.688-.82-3.047t-2.18-2.156-3-.797q-1.688 0-3.047.797t-2.156 2.156-.797 3.047q0 1.641.797 3t2.156 2.18 3.047.82q1.641 0 3-.82t2.18-2.18.82-3z"/>'
  }
})
