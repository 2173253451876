/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_meeting_room': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 11.484L5.532 3h8.484v.984h4.969V16.5l-1.969-2.016V6h-3v5.484zm-3-.468H9.985v1.969h2.016v-.984l9.75 9.75-1.313 1.266-6.422-6.469v4.453H3v-2.016h2.016V7.547L.985 3.563 2.251 2.25z"/>'
  }
})
