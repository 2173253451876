/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'carpenter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.734 14.25L6.984 1.5 3.093 5.391l8.156 11.672q-.609.563-.609 1.406t.609 1.406l1.406 1.406q.563.609 1.406.609t1.406-.609l4.266-4.219q.563-.609.563-1.43t-.563-1.383zm-5.671 5.625l-1.406-1.406 4.266-4.219 1.406 1.406z"/>'
  }
})
