/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file_download_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.609 15.281l-4.594-4.594 1.969-1.875 2.625 2.578 7.406-7.406L18.984 6zM5.016 18h13.969v2.016H5.016V18z"/>'
  }
})
