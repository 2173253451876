/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dinner_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 16.5V8.016H3V6.985h.984v-.984H3v-.984h.984V3.986H3v-.984h6q.75.047 1.336.633t.68 1.383H21v.984h-9.984q-.094.797-.68 1.43T9 8.018H6.984v7.031q.094 0 .258-.023t.258-.023q.844 0 1.922.422.609-1.406 2.109-2.414t3-1.008q2.297 0 3.891 1.594t1.594 3.891q0 .094-.023.281t-.023.234H3.142q.188-.75.844-1.5zM6 15.234V8.015h-.984v7.688q.703-.375.984-.469zm3-8.25V6H6.984v.984H9zm0-3H6.984v1.031H9V3.984zm-3.984 0v1.031H6V3.984h-.984zm0 2.016v.984H6V6h-.984zm-3 12.984h19.969L20.016 21H3.985z"/>'
  }
})
