/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spellcheck': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.609 11.578l1.406 1.406-9.516 9.516-5.063-5.109 1.406-1.406 3.656 3.703zm-15.187-.562h4.125L8.484 5.485zm6.047 4.968l-1.172-3H5.672l-1.125 3H2.438L7.547 3h1.875l5.109 12.984h-2.063z"/>'
  }
})
