/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'line_style': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3.984h18v4.031H3V3.984zM12.984 12V9.984H21V12h-8.016zM3 12V9.984h8.016V12H3zm15.984 8.016V18H21v2.016h-2.016zm-3.984 0V18h2.016v2.016H15zm-3.984 0V18h1.969v2.016h-1.969zm-4.032 0V18H9v2.016H6.984zm-3.984 0V18h2.016v2.016H3zm12.984-4.032v-1.969H21v1.969h-5.016zm-6.468 0v-1.969h4.969v1.969H9.516zm-6.516 0v-1.969h5.016v1.969H3z"/>'
  }
})
