/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'slow_motion_video': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12q0 3.844-2.578 6.703t-6.375 3.234v-2.016q2.953-.375 4.969-2.648T20.016 12 18 6.727t-4.969-2.648V2.063q3.797.375 6.375 3.234T21.984 12zM5.672 19.734l1.406-1.406q1.688 1.313 3.938 1.594v2.016q-1.266-.141-2.836-.797t-2.508-1.406zm-1.594-6.75q.234 2.203 1.594 3.891l-1.406 1.453q-1.922-2.391-2.203-5.344h2.016zm1.594-5.906q-1.313 1.734-1.594 3.938H2.062q.141-1.266.797-2.836t1.406-2.508zm5.344-3q-2.25.281-3.938 1.594L5.672 4.266q2.391-1.922 5.344-2.203v2.016zm2.015 5.719L15.984 12q-2.953 2.203-6 4.5z"/>'
  }
})
