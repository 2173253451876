/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'album': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.016q.422 0 .703.281t.281.703-.281.703-.703.281-.703-.281-.281-.703.281-.703.703-.281zm0 5.484q1.875 0 3.188-1.313t1.313-3.188-1.313-3.188T12 7.498 8.812 8.811t-1.313 3.188 1.313 3.188T12 16.5zm0-14.484q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
