/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'kitchen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 12h1.969v5.016H8.016V12zm0-6.984h1.969v3H8.016v-3zM18 9V3.984H6V9h12zm0 11.016v-9.047H6v9.047h12zm0-18q.844 0 1.43.563t.586 1.406v16.031q0 .797-.609 1.383t-1.406.586h-12q-.797 0-1.406-.586t-.609-1.383V3.985q0-.844.586-1.406t1.43-.563h12z"/>'
  }
})
