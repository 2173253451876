/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_color': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 20.016h24V24H0v-3.984zM20.719 4.031L18.75 6 15 2.25 16.969.281Q17.25 0 17.672 0t.703.281l2.344 2.344q.281.281.281.703t-.281.703zm-2.953 2.953L7.735 17.015h-3.75v-3.75L14.016 3.234z"/>'
  }
})
