/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_shopping_cart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.172 14.766q0 .234.234.234h11.578v2.016h-12q-.797 0-1.383-.609t-.586-1.406q0-.469.234-.938l1.359-2.484-3.609-7.594H.983V2.016h3.281Q5.202 3.985 6.139 6q.234.422 1.078 2.227t1.313 2.789h7.031q3.516-6.375 3.844-7.031l1.734.984-3.844 6.984q-.563 1.031-1.734 1.031H8.108l-.891 1.641zM17.016 18q.797 0 1.383.609t.586 1.406-.586 1.383-1.383.586-1.406-.586-.609-1.383.609-1.406T17.016 18zM6.984 18q.797 0 1.406.609t.609 1.406-.609 1.383-1.406.586-1.383-.586-.586-1.383.586-1.406T6.984 18zm4.032-9V6h-3V3.984h3v-3h1.969v3h3V6h-3v3h-1.969z"/>'
  }
})
