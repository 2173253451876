/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'videocam_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.281 2.016L21 19.735l-1.266 1.266-3.188-3.188q-.281.188-.563.188h-12q-.422 0-.703-.281t-.281-.703V6.986q0-.422.281-.703t.703-.281h.75L2.014 3.283zM21 6.516v10.688L9.797 6.001h6.188q.422 0 .727.281t.305.703v3.516z"/>'
  }
})
