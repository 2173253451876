/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sticky_note_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H4.968q-.797 0-1.383.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h9.984l6-6V5.017q0-.844-.586-1.43t-1.43-.586zm-12 5.016h10.031v1.969H6.984V8.016zm5.016 6H6.984V12H12v2.016zm2.016 5.484v-5.484H19.5z"/>'
  }
})
