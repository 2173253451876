/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_flags': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 17.016v-6h-5.016L12 9H6.984v6h6l1.031 2.016h3.984zM14.016 9h6v9.984h-7.031l-.984-1.969H6.985v3.984H5.016V5.718q-1.031-.563-1.031-1.734 0-.797.609-1.383T6 2.015t1.406.586.609 1.383q0 1.172-1.031 1.734v1.266h6z"/>'
  }
})
