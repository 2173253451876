/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6q1.641 0 2.813 1.172t1.172 2.813q0 1.266-1.5 2.602t-1.5 2.414h-1.969q0-1.078.469-1.852t1.031-1.125 1.031-.867.469-1.172q0-.797-.609-1.383t-1.406-.586-1.406.586-.609 1.383H8.017q0-1.641 1.172-2.813T12.002 6zm0 14.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zM11.016 18v-2.016h1.969V18h-1.969z"/>'
  }
})
