/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'school': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3l11.016 6v8.016H21v-6.938L12 15 .984 9zM5.016 13.172L12 17.016l6.984-3.844v4.031L12 21l-6.984-3.797v-4.031z"/>'
  }
})
