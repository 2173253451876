/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 21v-6.984l15-2.016-15-2.016V3l21 9z"/>'
  }
})
