/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'recommend': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11.859v-.844q0-.422-.281-.727t-.703-.305h-4.594l.563-3.375v-.234q0-.422-.281-.703l-.703-.656-4.594 4.969q-.422.328-.422.938v5.063q0 .844.586 1.43t1.43.586h5.578q.844 0 1.219-.797l2.109-4.922q.094-.188.094-.422zm-6-9.843q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
