/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'switch_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.484 8.625v6.75L5.109 12zm1.5-3.609L3 12l6.984 6.984V5.015zm4.032 0v13.969L21 12.001z"/>'
  }
})
