/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand_less': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 8.016l6 6-1.406 1.406L12 10.828l-4.594 4.594L6 14.016z"/>'
  }
})
