/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backup_table': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 6v14.016H6v1.969h14.016q.797 0 1.383-.586t.586-1.383V6h-1.969zm-4.032-3.984h-12q-.797 0-1.383.586t-.586 1.383v12q0 .844.586 1.43t1.383.586h12q.844 0 1.43-.586t.586-1.43v-12q0-.797-.586-1.383t-1.43-.586zM9 15.984H3.984v-4.969H9v4.969zm6.984 0h-4.969v-4.969h4.969v4.969zm0-6.984h-12V3.984h12V9z"/>'
  }
})
