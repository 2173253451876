/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house_siding': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 12h3L12 3l-9.984 9h3v8.016h1.969V18h10.031v2.016h1.969V12zM7.219 9.984h9.563l.234.188V12H6.985v-1.828zm7.359-1.968H9.422L12 5.672zm-7.594 7.968v-1.969h10.031v1.969H6.984z"/>'
  }
})
