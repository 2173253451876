/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'favorite': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 21.328l-1.453-1.313q-2.484-2.25-3.609-3.328T4.43 14.015t-1.898-2.883-.516-2.648q0-2.297 1.57-3.891T7.5 2.999q2.719 0 4.5 2.109 1.781-2.109 4.5-2.109 2.344 0 3.914 1.594t1.57 3.891q0 1.828-1.219 3.797t-2.648 3.422-4.664 4.359z"/>'
  }
})
