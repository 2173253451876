/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_handball': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.75 3.797Q12 3.375 12 2.531q0-.656.422-1.078t1.078-.422q.891 0 1.313.75t0 1.453q-.328.516-.938.703t-1.125-.141zm3.094 6.609q2.156 1.781 2.555 4.43t-.867 4.664l-1.734-1.031q.656-1.172.656-2.484 0-1.5-.984-2.953L9.189 23.907l-1.734-.984 3-5.203-1.734-.984-1.5 2.578-1.688-.984 5.109-8.906Q9.095 7.736 8.814 5.44t.891-4.359l1.734 1.031q-1.031 1.781-.516 3.773t2.297 3.023zM14.25 6q.422-.703 1.242-.938t1.523.188.914 1.242-.211 1.523-1.219.914-1.5-.211-.938-1.219.188-1.5z"/>'
  }
})
