/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insert_chart_outlined': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609h-15q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T4.5 3h15zm0 16.078V5.015h-15v14.063h15zm-2.484-2.062H15v-4.031h2.016v4.031zm-4.032 0h-1.969V6.985h1.969v10.031zm-3.984 0H6.984V9.985H9v7.031z"/>'
  }
})
