/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exposure': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.484 15.984h-1.969v-1.5h1.969v-1.969h1.5v1.969H18v1.5h-2.016V18h-1.5v-2.016zm4.5 3V5.015L5.015 18.984h13.969zM6 6.984v1.5h5.016v-1.5H6zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
