/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.844 21.75l2.016-3.516q1.828-3.188 2.813-4.828l3.656 6.328q-1.125.938-3 1.594t-3.328.656q-1.125 0-2.156-.234zM2.438 15h9.703l-3.703 6.328q-2.156-.797-3.727-2.461T2.438 15zm2.203-9.75l5.063 8.766h-7.5Q2.016 12.891 2.016 12q0-1.594.773-3.563t1.852-3.188zm17.156 4.734q.188.891.188 2.016 0 1.594-.773 3.563t-1.852 3.188l-4.781-8.25-.281-.516h7.5zM21.563 9H11.86l3.703-6.328q2.156.797 3.727 2.461T21.563 9zM9.422 10.5l-.094.094-3.656-6.328q1.125-.938 3-1.594T12 2.016q1.125 0 2.156.234z"/>'
  }
})
