/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'publish': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 14.016L12 6.985l6.984 7.031H15v6H9v-6H5.016zm0-10.032h13.969V6H5.016V3.984z"/>'
  }
})
