/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'align_vertical_center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 11.016h-4.969V6h-3v5.016H9.984V3h-3v8.016H1.828v1.969h5.156v8.016h3v-8.016h4.031v5.016h3v-5.016h4.969v-1.969z"/>'
  }
})
