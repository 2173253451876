/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 6.984v10.031L9 11.999z"/>'
  }
})
