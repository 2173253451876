<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="view_menu" v-if="false">
      <CButton color="secondary" size="sm" class="d-inline-block float-right ml-2">
        <svgicon name="settings" width="16px" height="16px" />
      </CButton>
    </template>
    <template slot="chart">
      <center>
        <div style="max-width: 630px;">
          <CChartPie
            :datasets="chartData"
            :labels="chartLabel"
            :options="chartOption"
          />
        </div>
      </center>
    </template>
  </CardContainer>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import CardContainer from '@/containers/StatisticsCard.vue'
import { CChartPie } from '@coreui/vue-chartjs'

export default {
  name: 'TodayDeliver',
  components: {
    CardContainer,
    CChartPie
  },
  data() {
    return {
      title: '',
      colors: ['#6ebe9f', '#c73558'],
      labels: [],
      stats: []
    }
  },
  computed: {
    // *** datasets configurations ***
    // Name	                    Type         Scriptable      Indexable      Default
    // -----------------------------------------------------------------------------------------
    // backgroundColor          Color        Yes             Yes            'rgba(0, 0, 0, 0.1)'
    // borderAlign              string       Yes             Yes            'center'
    // borderColor              Color        Yes             Yes            '#fff'
    // borderWidth              number       Yes             Yes            2
    // data                     number[]     -               -              required
    // hoverBackgroundColor     Color        Yes             Yes            undefined
    // hoverBorderColor         Color        Yes             Yes            undefined
    // hoverBorderWidth         number       Yes             Yes            undefined
    // weight                   number       -               -              1
    chartData() {
      let valid_amount = this.stats.valid_amount;
      let invalid_amount = this.stats.invalid_amount;
      return [
        {
          backgroundColor: this.colors,
          data: [valid_amount, invalid_amount]
        }
      ];
    },
    chartLabel() {
      if (this.stats.total_amount > 0) {
        let valid_amount = this.stats.valid_amount;
        let invalid_amount = this.stats.invalid_amount;
        return [`${this.$t('cloud.title.quantity_appropriate')} ${valid_amount} ㎥`, `${this.$t('cloud.title.quantity_insufficient')} ${invalid_amount} ㎥`];
      }
      return [this.$t('cloud.title.quantity_none')]
    },
    chartOption() {
      return {
        legend: {
          position: 'right',
          align: 'center',
          labels: {
            fontSize: 12
          }
        }
      };
    }
  },
  mounted() {
    this.statReceivedAmount();
  },
  methods: {
    statReceivedAmount() {
      let query_string = '';
      const params = {};
      let now = new Date();

      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      params.sts = now.getTime();
      params.ets = params.sts + (1000 * 60 * 60 * 24);
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/statistics/received-amount/${query_string}`)
        .then(result => {
          this.stats = result.data;
          this.title = this.$t('cloud.title.today_delivery');
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>
