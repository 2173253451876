/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toll': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 12q0 1.734 1.172 3.398t2.813 2.273v2.063q-2.625-.656-4.313-2.813T.984 11.999t1.688-4.922 4.313-2.813v2.063Q5.344 6.936 4.172 8.6T3 11.998zm12 6q2.484 0 4.242-1.758T21 12t-1.758-4.242T15 6t-4.242 1.758T9 12t1.758 4.242T15 18zm0-14.016q3.281 0 5.648 2.367t2.367 5.648-2.367 5.648T15 20.014t-5.648-2.367-2.367-5.648 2.367-5.648T15 3.984z"/>'
  }
})
