/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'addchart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 5.016v1.969h-3v3h-1.969v-3h-3V5.016h3v-3h1.969v3h3zm-3 13.968H5.015V5.015h6V2.999h-6q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43v-6h-2.016v6zm-3.984-6v4.031h2.016v-4.031H15zm-3.984 4.032h1.969V9h-1.969v8.016zm-2.016 0v-6H6.984v6H9z"/>'
  }
})
