/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'imagesearch_roller': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 2.016v6H6V6H3.984v3.984h10.031V15h1.969v8.016h-6V15H12v-3H2.016V3.984H6V2.015h14.016z"/>'
  }
})
