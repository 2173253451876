/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vibration': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 18.984V5.015H8.015v13.969h7.969zM16.5 3q.656 0 1.078.422T18 4.5v15q0 .656-.422 1.078T16.5 21h-9q-.656 0-1.078-.422T6 19.5v-15q0-.656.422-1.078T7.5 3h9zm2.484 14.016V6.985H21v10.031h-2.016zm3-8.016H24v6h-2.016V9zM3 17.016V6.985h2.016v10.031H3zM0 15V9h2.016v6H0z"/>'
  }
})
