/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_cellular_null': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 2.016v19.969H2.015zm-1.968 4.828L6.844 20.016h13.172V6.844z"/>'
  }
})
