/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'update_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.672 5.859L7.219 4.406q1.031-.656 2.25-1.031T12 3q2.063 0 3.844.867t3 2.273L21 3.984v6h-6l2.391-2.391q-.938-1.172-2.344-1.875T12 5.015q-.891 0-1.734.211t-1.594.633zm4.312 1.125h-1.969v1.172l1.969 2.016V6.984zm6.797 15.61l-3-3q-1.031.656-2.25 1.031T12 21q-1.875 0-3.492-.703t-2.859-1.945-1.945-2.859-.703-3.492q0-1.313.375-2.531t1.031-2.25l-3-3 1.406-1.406 18.375 18.375zm-4.453-4.453L5.859 8.672q-.422.75-.633 1.594T5.015 12q0 1.453.539 2.719t1.5 2.227 2.227 1.5 2.719.539q.891 0 1.734-.211t1.594-.633zm5.625-5.157h-2.016q-.188 1.219-.797 2.344l1.5 1.453q.516-.844.844-1.805t.469-1.992z"/>'
  }
})
