/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gif': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 10.5h-3v.984H18v1.5h-2.016V15h-1.5V9h4.5v1.5zM9 9q.422 0 .703.281t.281.703v.516H6.515v3h1.969V12h1.5v2.016q0 .422-.281.703T9 15H6q-.422 0-.703-.281t-.281-.703V9.985q0-.422.281-.703T6 9.001h3zm2.484 0h1.5v6h-1.5V9z"/>'
  }
})
