/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'timer_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q1.828 0 3.516-.984L5.953 9.469q-.938 1.594-.938 3.516 0 2.906 2.039 4.969t4.945 2.063zM3 3.984L20.766 21.75 19.5 23.016l-2.531-2.531q-2.344 1.5-4.969 1.5-3.75 0-6.375-2.648T3 12.985q0-1.125.445-2.602T4.5 8.016L1.734 5.25zm8.016 5.438V8.016h1.969v3.422zM15 .984V3H9V.984h6zm4.031 3.563l1.406 1.406-1.406 1.453Q21 9.89 21 12.984v.047q0 1.125-.445 2.578T19.5 17.953L18.047 16.5q.938-1.594.938-3.516 0-2.906-2.039-4.945T12.001 6q-1.875 0-3.469.938l-1.5-1.453q2.391-1.5 4.969-1.5 1.313 0 2.953.586t2.672 1.383z"/>'
  }
})
