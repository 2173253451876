/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_array': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 18V5.016h9V18h-9zM18 5.016h3V18h-3V5.016zM3.984 18V5.016h3V18h-3z"/>'
  }
})
