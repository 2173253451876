/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'business': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15v2.016h-2.016V15H18zm0-3.984v1.969h-2.016v-1.969H18zm2.016 7.968V9H12v2.016h2.016v1.969H12v2.016h2.016v2.016H12v1.969h8.016zm-10.032-12V5.015H8.015v1.969h1.969zm0 4.032V9H8.015v2.016h1.969zm0 3.984v-2.016H8.015V15h1.969zm0 3.984v-1.969H8.015v1.969h1.969zM6 6.984V5.015H3.984v1.969H6zm0 4.032V9H3.984v2.016H6zM6 15v-2.016H3.984V15H6zm0 3.984v-1.969H3.984v1.969H6zm6-12h9.984V21H2.015V3h9.984v3.984z"/>'
  }
})
