<template>
  <div class="js-player-container">
    <video id="remotevideo" ref="VideoPlayerId" class="js-player video-js vjs-big-play-centered vjs-default-skin vim-css" autoplay playsinline controls>
    <!-- <video id="remotevideo" width="100%" height="100%" autoplay playsinline/> -->
      <!-- <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video
        </a>
      </p> -->
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoWebRTCPlayer",
  props: {
    // target: {type: Object, default: null},
    url: {type: String, default: null},
    request_id: {type: Number, default: null}
  },
  watch: {
    // target() {
    //   console.log(this.target.url, this.target.ch);
    //   if (this.target.url !== null && this.target.url !== this.init_url) {
    //     console.log('url: ' + this.target.url);
    //     this.init_url = this.target.url;
    //     this.initWebRTC();
    //     return;
    //   }
    //   if (this.playing_id)
    //     this.stopStream();
    //   this.startStream();
    // }
    url() {
      console.log(`WebRTC 플레이리스트 업데이트 요청: ${this.url}`);
      this.initWebRTC();
    },
    request_id() {
      if (this.playing_id) {
        this.stopStream();
      }
      this.startStream();
    }
  },
  data() {
    return {
      player: null,

      streaming: null,
      opaqueId: "streamingtest-"+Janus.randomString(12),

      playlist: [],
      playing_id: null,
      janus_url: null
    }
  },
  beforeDestroy() {
    if (this.playing_id)
      this.stopStream();
  },
  methods: {
    initWebRTC: function() {
      let self = this;
      Janus.init({
        debug: false, // jay 2021-07-02 debugging시 true로 설정
        dependencies: Janus.useDefaultDependencies(), // or: Janus.useOldDependencies() to get the behaviour of previous Janus versions
        callback: function() {
          let janus = new Janus({
            server: self.url,
            success: function() {
              janus.attach({
                plugin: "janus.plugin.streaming",
                  opaqueId: self.opaqueId,
                  success: function(pluginHandle) {
                    self.streaming = pluginHandle;
                    Janus.log(`Plugin attached! (${self.streaming.getPlugin()}, id=${self.streaming.getId()})`);
                    self.updateStreamsList();
                  },
                  error: function(error) {
                    Janus.error("  -- Error attaching plugin... ", error);
                  },
                  iceState: function(state) {
                    Janus.log(`ICE state changed to ${state}`);
                  },
                  webrtcState: function(on) {
                    Janus.log(`Janus says our WebRTC PeerConnection is ${(on ? "up" : "down")} now`);
                  },
                  onmessage: function(msg, jsep) {
                    Janus.debug(" ::: Got a message :::", msg);
                    let result = msg["result"];
                    if(result) {
                      if(result["status"]) {
                        let status = result["status"];
                        if (status === 'stopped') {
                          self.stopStream();
                        }
                      } else if(msg["streaming"] === "event") {
                      }
                    } else if(msg["error"]) {
                      console.error("msg[error]", msg["error"]);
                      stopStream();
                      return;
                    }
                    if(jsep) {
                      Janus.debug("Handling SDP as well...", jsep);
                      let stereo = (jsep.sdp.indexOf("stereo=1") !== -1);
                      // Offer from the plugin, let's answer
                      self.streaming.createAnswer(
                        {
                          jsep: jsep,
                          // We want recvonly audio/video and, if negotiated, datachannels
                          media: { audioSend: false, videoSend: false, data: true },
                          customizeSdp: function(jsep) {
                            if(stereo && jsep.sdp.indexOf("stereo=1") == -1) {
                              // Make sure that our offer contains stereo too
                              jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                            }
                          },
                          success: function(jsep) {
                            Janus.debug("Got SDP!", jsep);
                            let body = { request: "start" };
                            self.streaming.send({ message: body, jsep: jsep });
                            // $('#watch').html("Stop").removeAttr('disabled').click(stopStream);
                          },
                          error: function(error) {
                            Janus.error("WebRTC error:", error);
                            // bootbox.alert("WebRTC error... " + error.message);
                          }
                        });
                    }
                  },
                  onremotestream: function(stream) {
                    Janus.debug(" ::: Got a remote stream :::", stream);
                    self.player = self.$refs.VideoPlayerId;
                    Janus.attachMediaStream(self.player, stream);
                    let videoTracks = stream.getVideoTracks();
                    if(!videoTracks || videoTracks.length === 0) {
                      console.error('No remote video');
                    }
                  },
                  ondataopen: function(data) {
                    Janus.log("The DataChannel is available!");
                  },
                  ondata: function(data) {
                    Janus.debug("We got data from the DataChannel!", data);
                  },
                  oncleanup: function() {
                    Janus.log(" ::: Got a cleanup notification :::");
                  }
              });
            },
            error: function(cause) {
              console.log('janus error');
              console.error(cause);
            },
            destroyed: function() {
              console.log('janus destroyed');
            }
          });
        }
      });
    },
    updateStreamsList: function() {
      const self = this;
      const body = { request: "list" };
      Janus.debug("Sending message:", body);
      this.streaming.send({ message: body, success: function(result) {
        if(!result) {
          console.error("Got no response to our query for available streams");
          return;
        }
        if(result["list"]) {
          self.playlist = result["list"];
          Janus.log("Got a list of available streams");
          if(self.playlist && Array.isArray(self.playlist)) {
            self.playlist.sort(function(a, b) {
              if(!a || a.id < (b ? b.id : 0))
                return -1;
              if(!b || b.id < (a ? a.id : 0))
                return 1;
              return 0;
            });
          }
          Janus.log(self.playlist);
          self.$emit('playlist', self.playlist);
        }
      }});
    },
    startStream: function() {
      Janus.log(`Selected video ch #${this.request_id} list_length ${this.playlist.length}`);
      if(!this.request_id || this.playlist.length <= 0) {
        console.error("Wrong request id");
        return;
      }
      if (this.playlist.findIndex(el => el.id === this.request_id) < 0) {
        console.error("Select a request_id from the playlist");
        return;
      }
      Janus.log(`Video Id #${this.request_id}`);
      const body = { request: "watch", id: parseInt(this.request_id) || this.request_id};
      this.streaming.send({ message: body });
      // Get some more info for the mountpoint to display, if any
      // this.getStreamInfo();
      this.playing_id = this.request_id;
    },
    stopStream: function() {
      Janus.log("Stop stream");
      var body = { request: "stop" };
      this.streaming.send({ message: body });
      this.streaming.hangup();
      this.playing_id = null;
    },
    getStreamInfo: function() {
      if(!this.request_id)
        return;
      // Send a request for more info on the mountpoint we subscribed to
      var body = { request: "info", id: parseInt(this.request_id) || this.request_id };
      streaming.send({ message: body, success: function(result) {
        if(result && result.info && result.info.metadata) {
          Janus.log(`getStreamInfo | metadata | ${result.info.metadata}`);
        }
      }});
    }
  }
}
</script>

<style lang="scss" scoped>
  .js-player-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .js-player {
    width: 1100px;
    height: 540px;
    max-width: 100%;
    max-height: 50vw;
  }
</style>
