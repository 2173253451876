/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'waterfall_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 9.984h3v4.031h-3V9.984zm3-4.968h3V9h-3V5.016zm4.032-1.032h3v3h-3v-3zM3 12.984h3v7.031H3v-7.031zm15-9h3v16.031h-3V3.984z"/>'
  }
})
