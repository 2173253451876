/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_cellular_connected_no_internet_4_bar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 21.984L21.985 2.015v6h-3.984v13.969H2.017zm18 0v-1.969h1.969v1.969h-1.969zm0-3.984V9.984h1.969V18h-1.969z"/>'
  }
})
