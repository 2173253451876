/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trending_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 18l2.297-2.297-4.875-4.875-3.984 3.984-7.406-7.406L3.422 6l6 6 3.984-3.984 6.281 6.281L21.984 12v6h-6z"/>'
  }
})
