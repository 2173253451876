<template>
  <div>
    <CRow class="justify-content-center mx-2" v-loading="loading">
      <CCol md="12" lg="10" v-if="place">
        <CRow class="justify-content-between my-4">
          <el-button class="ml-2" type="text" icon="el-icon-arrow-left" @click="onBack">Back</el-button>
          <span class="my-2">
            <strong class="h4">{{place.name}}</strong>
          </span>
          <el-button class="mr-2" type="primary" icon="el-icon-edit-outline" @click="onEdit" disabled />
        </CRow>

        <el-tabs v-model="activeName" @tab-click="onTab">
          <el-tab-pane :label="$t('cloud.title.tab_overview')" name="Overview">
            <CJumbotron v-if="!place || !place.floor_img_signed_url">
              <h1 class="display-3">Set up place information</h1>
              <p class="lead">You can easily track and act on the status of the space you manage.</p>
              <CButton color="primary" href="#" target="_blank">
                <CIcon class="mr-1" name="cil-settings" />Setting
              </CButton>
            </CJumbotron>
            <div v-else>
              <FloorMap :place="place" :space="space" :is_selected="activeName==='Overview'" />
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('cloud.title.tab_cameraview')" name="Cameraview">
            <CCard>
              <CameraView :place="place" :is_selected="activeName==='Cameraview'" />
            </CCard>
          </el-tab-pane>
          <el-tab-pane :label="$t('cloud.title.tab_setting')" name="Setting" disabled>
            Setting
          </el-tab-pane>
        </el-tabs>
      </CCol>
      <div else style="height:900px; background-color: rgba(0,0,0,0);"></div>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import FloorMap from './FloorMap';
import CameraView from './CameraView';

export default {
  name: 'Place',

  components: {
    FloorMap,
    CameraView
  },
  data() {
    return {
      loading: false,

      activeName: 'Overview',
      zoom: 15,
      center: [0, 0],
      rotation: 0,
      geolocPosition: undefined,
      place: null,
      sections: [],

      space: {
        place_id: null,
        center: null,
        size: null,
        extent: null,
        floor_img_signed_url: null
      }
    };
  },
  computed: {
    occupancy() {
      if (!this.place) return '';
      return `${this.place.occupancy}%`;
    }
  },
  mounted() {
    this.getPlace(this.$route.params.id);
  },
  methods: {
    getPlace(id) {
      this.loading = true;
      axios.get(`/api/parking/places/${id}/`)
        .then(response => {
          this.place = JSON.parse(JSON.stringify(response.data));
          this.place.space_cnt = this.place.spaces.length;
          this.place.occupied = this.place.spaces.filter(s => !s.is_free).length;
          this.place.occupancy = parseInt(this.place.occupied / this.place.space_cnt * 100) || 0;
          this.place.free = `${this.place.space_cnt - this.place.occupied} of ${this.place.space_cnt}`;
          this.place.overtime = this.$utility.RandomInteger(0, parseInt(this.place.capacity / 20));
          this.place.unlawful = this.$utility.RandomInteger(0, parseInt(this.place.capacity / 60));
          this.center = [+this.place.lng, +this.place.lat];

          // space tab
          this.space.place_id = this.place.id;
          this.space.size = [this.place.floor_img_w, this.place.floor_img_h];
          this.space.center = [this.space.size[0] / 2, this.space.size[1] / 2];
          this.space.extent = [0, 0, ...this.space.size];
          this.space.floor_img_signed_url = this.place.floor_img_signed_url;
        })
        .catch(error => {
          console.log(error);
          this.$router.push('/parking/places');
        })
        .finally(_ => {
          this.loading = false;
        })
    },
    onBack() {
      // this.$emit('back');
      this.$router.push('/parking/places');
    },
    onEdit() {
      this.$alert('edit');
    },
    onTab(prop) {
      console.log('tab', prop.label);
    }
  }
}
</script>
