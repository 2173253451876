import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheContainerPortal = () => import('@/containers/TheContainerPortal')

// Home
const Dashboard = () => import('@/views/dashboard/Dashboard')

// Alarm
const Alarm = () => import('@/views/alarm/Alarm')

// Statistics
// const StatisticsDaily = () => import('@/views/statistics/Daily')
// const StatisticsWeekly = () => import('@/views/statistics/Weekly')
// const StatisticsMonthly = () => import('@/views/statistics/Monthly')

// Organization Setup
const SetupOrganization = () => import('@/views/setup/organization/Organization')
const SetupBranches = () => import('@/views/setup/branches/Branches')
const SetupUsers = () => import('@/views/setup/users/Users')

// Common Service
// const InstallDashboard = () => import('@/views/install/dashboard/Dashboard')
const Devices = () => import('@/views/install/devices/Devices')
const Branches = () => import('@/views/install/branches/Branches')
const Organizations = () => import('@/views/install/organizations/Organizations')
const Users = () => import('@/views/install/users/Users')
// const Profile = () => import('@/views/install/users/Profile')

// Delivery Management
const DeliveryEvent = () => import('@/views/services/delivery-management/delivery/Event')
const DeliveryFreight = () => import('@/views/services/delivery-management/freight/Freight')
const DeliveryCorrespondent = () => import('@/views/services/delivery-management/correspondent/Correspondent')
const DeliveryReport = () => import('@/views/services/delivery-management/delivery/Report')
const DeliveryWearing = () => import('@/views/services/delivery-management/wearing/Wearing')
const DeliveryQRCode = () => import('@/views/services/delivery-management/qrcode/QRCode')
const DeliveryStats = () => import('@/views/services/delivery-management/statistics')

// Retail Service
const RetailStores = () => import('@/views/services/retail/stores')
const RetailStore = () => import('@/views/services/retail/stores/store/Store')
const RetailOccupancy = () => import('@/views/services/retail/occupancy/')
// const RetailAnalytics = () => import('@/views/services/retail/statistics')
const RetailQueue = () => import('@/views/services/retail/queue')
const RetailVisitorCounting = () => import('@/views/services/retail/visitor-counting')
const RetailAdvancedVisitorAnalysis = () => import('@/views/services/retail/visitor-advanced')
const RetailSetting = () => import('@/views/services/retail/setting')
// const RetailBranch = () => import('@/views/services/retail/branch/Branch')
// const RetailOccupancy = () => import('@/views/services/retail/occupancy/Occupancy')
// const RetailTraffic = () => import('@/views/services/retail/traffic/Traffic')
// const RetailCustomer = () => import('@/views/services/retail/customer-visit/CustomerVisit')
// const RetailGender = () => import('@/views/services/retail/gender/Gender')
// const RetailCounter = () => import('@/views/services/retail/counter/Counter')

// Parking Management
// const ParkingEvents = () => import('@/views/services/parking/Events/Events')
const ParkingStats = () => import('@/views/services/parking/statistics')
const ParkingPlaces = () => import('@/views/services/parking/Places/Places')
const ParkingPlace = () => import('@/views/services/parking/Places/place/Place')
// const ParkingGroups = () => import('@/views/services/parking/Groups/Groups')
const ParkingMap = () => import('@/views/services/parking/Maps/MapView')

// Congestion Control
const CongestionControlZones = () => import('@/views/services/congestion/ControlZone')

// Video Security
const Monitor = () => import('@/views/services/videosecurity/Monitor')

// Timelapse
const Generations = () => import('@/views/services/timelapse/Generations')

// // Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Settings = () => import('@/views/pages/Settings')
const Profile = () => import('@/views/pages/Profile')
const Test = () => import('@/views/pages/Test')

// Log
const EventLog = () => import('@/views/log/EventLog')

const DevicePortal = () => import('@/views/device-portal')
const DevicePortalRegistration = () => import('@/views/device-portal/registration')

const InitPage = () => import('@/views/pages/InitPage')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});
export default router;

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/dashboard',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard
        }
      ]
    },
    {
      path: '/alarm',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Alarm',
          component: Alarm
        }
      ]
    },
    // {
    //   path: '/statistics',
    //   redirect: '/statistics/daily',
    //   name: 'Statistics',
    //   component: TheContainer,
    //   children: [
    //     {
    //       path: 'daily',
    //       name: 'Daily',
    //       component: StatisticsDaily
    //     },
    //     {
    //       path: 'weekly',
    //       name: 'Weekly',
    //       component: StatisticsWeekly
    //     },
    //     {
    //       path: 'monthly',
    //       name: 'Monthly',
    //       component: StatisticsMonthly
    //     }
    //   ]
    // },
    // {
    //   path: '/bi/analysis',
    //   redirect: '/bi/analysis/branch',
    //   name: 'BI',
    //   component: TheContainer,
    //   children: [
    //     {
    //       path: 'branch',
    //       name: 'Shop',
    //       component: BIBranch
    //     },
    //     {
    //       path: 'statistics',
    //       name: 'Statistics',
    //       component: BIStats
    //     }
    //   ]
    // },
    // {
    //   path: '/bi/setting',
    //   redirect: '/bi/setting/occupancy',
    //   name: 'BI Setting',
    //   component: TheContainer,
    //   children: [
    //     {
    //       path: 'occupancy',
    //       name: 'Occupancy',
    //       component: BIOccupancy
    //     },
    //     {
    //       path: 'traffic-around',
    //       name: 'Traffic Around',
    //       component: BITraffic
    //     },
    //     {
    //       path: 'customer-visit',
    //       name: 'Customer Visit',
    //       component: BICustomer
    //     },
    //     {
    //       path: 'gender',
    //       name: 'Gender Counter',
    //       component: BIGender
    //     },
    //     {
    //       path: 'counter',
    //       name: 'Counter',
    //       component: BICounter
    //     }
    //   ]
    // },
    {
      path: '/retail',
      redirect: '/retail/stores',
      name: 'Retail Management',
      component: TheContainer,
      children: [
        // {
        //   path: 'branch',
        //   name: 'Shop',
        //   component: RetailBranch
        // },
        {
          path: 'stores',
          name: 'Stores',
          component: RetailStores
        },
        // {
        //   path: 'analytics',
        //   name: 'Analytics',
        //   component: RetailAnalytics
        // },
        {
          path: 'store-occupancy',
          name: 'Store Occupancy',
          component: RetailOccupancy
        },
        {
          path: 'visitor-counting',
          name: 'Visit Counting',
          component: RetailVisitorCounting
        },
        {
          path: 'advanced-visitor-analysis',
          name: 'Advanced Visitor Analysis',
          component: RetailAdvancedVisitorAnalysis
        },
        {
          path: 'queue-management',
          name: 'Queue Management',
          component: RetailQueue
        },
        {
          path: 'setting',
          name: 'Setting',
          component: RetailSetting
        }
        // {
        //   path: 'setting/occupancy',
        //   name: 'Occupancy',
        //   component: RetailOccupancy
        // },
        // {
        //   path: 'setting/traffic-around',
        //   name: 'Traffic Around',
        //   component: RetailTraffic
        // },
        // {
        //   path: 'setting/customer-visit',
        //   name: 'Customer Visit',
        //   component: RetailCustomer
        // },
        // {
        //   path: 'setting/gender',
        //   name: 'Gender Counter',
        //   component: RetailGender
        // },
        // {
        //   path: 'setting/counter',
        //   name: 'Counter',
        //   component: RetailCounter
        // }
      ]
    },
    {
      path: '/delivery-management',
      redirect: '/delivery-management/delivery',
      name: '골재입고 관리',
      component: TheContainer,
      children: [
        {
          path: 'delivery',
          name: '골재입고 현황',
          component: DeliveryEvent
        },
        {
          path: 'statistics',
          name: '입고량 통계',
          component: DeliveryStats
        },
        {
          path: 'freight',
          name: '골재종류',
          component: DeliveryFreight
        },
        {
          path: 'correspondent',
          name: '거래처',
          component: DeliveryCorrespondent
        },
        {
          path: 'qrcode',
          name: 'QR코드 출력',
          component: DeliveryQRCode
        }
      ]
    },
    {
      path: '/parking',
      redirect: '/parking/places',
      name: 'Parking Management',
      component: TheContainer,
      children: [
        // {
        //   path: 'events',
        //   name: 'Events',
        //   component: ParkingEvents
        // },
        {
          path: 'statistics',
          name: 'Statistics',
          component: ParkingStats
        },
        {
          path: 'places',
          name: 'Places',
          component: ParkingPlaces
        },
        {
          path: 'map',
          name: 'Map view',
          component: ParkingMap
        }
      ]
    },
    {
      path: '/congestion',
      redirect: '/congestion/control-zone',
      name: 'Congestion Control',
      component: TheContainer,
      children: [
        {
          path: 'control-zone',
          name: 'Control Zone',
          component: CongestionControlZones
        }
      ]
    },
    {
      path: '/service',
      redirect: '/pages/404',
      name: 'Service Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'retail/stores/:id',
          name: 'Store',
          component: RetailStore
        },
        {
          path: 'parking/places/:id',
          name: 'Place',
          component: ParkingPlace
        },
        {
          path: 'delivery/error-report',
          name: '불량 반입량 레포트',
          component: DeliveryReport
        },
        {
          path: 'delivery/wearing/:id',
          name: '골재 입고',
          component: DeliveryWearing
        }
      ]
    },
    {
      path: '/videosecurity',
      redirect: '/videosecurity/monitor',
      name: '영상보안',
      component: TheContainer,
      children: [
        {
          path: 'monitor',
          name: '모니터',
          component: Monitor
        }
      ]
    },
    {
      path: '/timelapse',
      redirect: '/timelapse/generations',
      name: '타임랩스',
      component: TheContainer,
      children: [
        {
          path: 'generations',
          name: '생성',
          component: Generations
        }
      ]
    },
    {
      path: '/setup',
      redirect: '/setup/organization',
      name: 'Setting',
      component: TheContainer,
      children: [
        {
          path: 'organization',
          name: 'Organizations',
          component: SetupOrganization
        },
        {
          path: 'branches',
          name: 'Branches',
          component: SetupBranches
        },
        {
          path: 'users',
          name: 'Users',
          component: SetupUsers
        }
      ]
    },
    {
      path: '/install',
      redirect: '/install/organizations',
      name: 'Install',
      component: TheContainer,
      children: [
        // {
        //   path: 'dashboard',
        //   name: '설치 대시보드',
        //   component: InstallDashboard
        // },
        {
          path: 'organizations',
          name: 'Organization',
          component: Organizations
        },
        {
          path: 'branches',
          name: 'Branch',
          component: Branches
        },
        {
          path: 'devices',
          name: 'Device',
          component: Devices
        },
        {
          path: 'users',
          name: 'User',
          component: Users
        }
      ]
    },
    {
      path: '/log',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Log',
          component: EventLog
        }
      ]
    },
    {
      path: '/device-portal',
      component: TheContainerPortal,
      children: [
        {
          path: '',
          name: 'DevicePortal',
          component: DevicePortal
        },
        {
          path: 'registration',
          name: 'DevicePortalRegistration',
          component: DevicePortalRegistration
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'init-page',
          name: 'Initial page',
          component: InitPage
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'settings',
          name: 'Settings',
          component: Settings
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile
        },
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'test',
          name: 'Test',
          component: Test
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: "/pages/404"
    }
  ]
}
