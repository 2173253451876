/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.266 5.625q.609.75 1.359 1.734t1.523 1.969 1.406 1.828 1.031 1.336l.398.492v6q0 .422.305.727t.727.305h1.969q.422 0 .727-.305t.305-.727v-6l.398-.492 1.031-1.336 1.406-1.828 1.523-1.969 1.359-1.734q.281-.328.234-.703t-.328-.656-.703-.281H5.061q-.422 0-.703.281t-.328.656.234.703z"/>'
  }
})
