/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_transfer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.188 21.188L2.813 2.813 1.407 4.219l2.578 2.625v9.141q0 .656.281 1.242t.75 1.008v1.781q0 .375.281.68t.703.305h.984q.422 0 .727-.305t.305-.68v-1.031h7.969v1.031q0 .375.305.68t.727.305h1.055l.07-.047 1.641 1.641zM7.5 17.016q-.609 0-1.055-.445T6 15.516q0-.656.445-1.078t1.055-.422 1.055.422T9 15.516q0 .609-.445 1.055t-1.055.445zm-1.5-6V8.813l2.156 2.203H6zM8.813 6L5.766 2.953q1.125-.609 2.742-.773T12 2.016q1.641 0 3.094.141t2.555.539 1.734 1.195T20.016 6v9.984q0 .281-.07.516t-.117.469l-6-5.953h4.172V6H8.813z"/>'
  }
})
