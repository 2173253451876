/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.109 5.016H18.89l-.938-1.031h-12zM12 17.484L17.484 12h-3.469V9.984H9.984V12H6.515zM20.531 5.25Q21 5.813 21 6.516v12.469q0 .797-.609 1.406T18.985 21H5.016q-.797 0-1.406-.609t-.609-1.406V6.516q0-.703.469-1.266l1.359-1.688q.469-.563 1.172-.563h12q.703 0 1.172.563z"/>'
  }
})
