/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard_customize': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 12.984v3h3V18h-3v3h-2.016v-3h-3v-2.016h3v-3H18zm-15 0h8.016V21H3v-8.016zM12.984 3H21v8.016h-8.016V3zM3 3h8.016v8.016H3V3z"/>'
  }
})
