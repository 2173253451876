/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload_file': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 15L12 11.016 15.984 15l-1.406 1.453-1.594-1.594v4.125h-1.969v-4.125l-1.594 1.547zM18 20.016V9h-5.016V3.984H6v16.031h12zm-3.984-18l6 6v12q0 .797-.609 1.383t-1.406.586h-12q-.797 0-1.406-.586t-.609-1.383l.047-16.031q0-.797.586-1.383t1.383-.586h8.016z"/>'
  }
})
