/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'motorcycle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.484 17.016q1.031 0 1.758-.75t.727-1.781-.727-1.758T17.484 12t-1.781.727-.75 1.758.75 1.781 1.781.75zm-8.625-1.735L6 15.234v-1.5h2.859Q8.296 12 6.468 12q-1.031 0-1.758.727t-.727 1.758.727 1.781 1.758.75q.75 0 1.477-.516t.914-1.219zm8.625-5.297q1.875 0 3.188 1.313t1.313 3.188-1.313 3.188-3.188 1.313-3.188-1.313-1.313-3.188q0-.094.023-.234t.023-.234l-2.109 1.266q-.281 1.547-1.57 2.625t-2.836 1.078q-1.875 0-3.188-1.313t-1.313-3.188 1.289-3.188 3.164-1.313h8.109l-1.969-1.969H8.997V5.999h4.406l4.031 4.031q0-.047.047-.047z"/>'
  }
})
