/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'opacity': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 14.016h12q0-2.578-1.781-4.359L12 5.251 7.781 9.61Q6 11.391 6 14.016zm11.672-6q2.344 2.344 2.344 5.625 0 3.328-2.344 5.672-.984.984-2.648 1.664t-3.023.68-3.023-.68-2.648-1.664q-2.344-2.344-2.344-5.672 0-1.359.68-3T6.33 8.016l5.672-5.672z"/>'
  }
})
