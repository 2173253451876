/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app_settings_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.797 12.75L21 12.094v-.188l.797-.656q.281-.188.094-.516l-.844-1.453q-.094-.234-.375-.234-.047 0-.141.047l-.938.375q-.141-.047-.188-.094l-.141-1.031q-.047-.328-.422-.328h-1.688q-.375 0-.422.328l-.141.984q0 .047-.047.047t-.047.047l-.094.047-.938-.375q-.094-.047-.141-.047-.234 0-.375.188l-.844 1.5q-.188.281.094.516l.797.609v.234l-.797.656q-.281.188-.094.469l.844 1.5q.094.188.375.188h.141l.938-.375.188.094.188 1.031q.047.328.375.328h1.734q.328 0 .375-.328l.141-1.031q.047 0 .07-.023l.023-.023.094-.047.984.375q.047.047.141.047.234 0 .328-.234l.844-1.453q.188-.328-.094-.516zM18 13.5q-.609 0-1.055-.445T16.5 12t.445-1.055T18 10.5t1.055.445T19.5 12t-.445 1.055T18 13.5zm-.984 3.516h1.969V21q0 .844-.586 1.43t-1.383.586H6.985q-.797 0-1.383-.586T5.016 21V3q0-.844.586-1.43T6.985.984h10.031q.797 0 1.383.586T18.985 3v3.984h-1.969V6H6.985v12h10.031v-.984z"/>'
  }
})
