/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'request_page': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 2.016H6q-.844 0-1.43.586t-.586 1.383v16.031q0 .797.586 1.383t1.43.586h12q.844 0 1.43-.586t.586-1.383v-12zm.984 9h-3.984V12h3q.375 0 .68.305t.305.68v3q0 .422-.305.727t-.68.305h-1.031v.984h-1.969v-.984H9v-2.016h3.984v-.984h-3q-.375 0-.68-.305t-.305-.727v-3q0-.375.305-.68T9.984 9h1.031v-.984h1.969V9H15v2.016z"/>'
  }
})
