/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_food': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.344 8.531l-.328-3.516h4.969V.984h2.016v4.031h5.016l-1.406 13.781zM.984 21v.984q0 .422.305.727t.727.305H15q.422 0 .703-.305t.281-.727V21h-15zm20.907.891L2.11 2.11.704 3.516l5.672 5.672q-1.547.375-2.766 1.148t-1.922 1.969T.985 15h11.203l1.969 2.016H.985v1.969h15v-.141l4.5 4.453z"/>'
  }
})
