/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop_rotate': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 15.984h12V18H18v2.016h-2.016V18H8.015q-.844 0-1.43-.586t-.586-1.43V8.015H3.983V5.999h2.016V3.983h2.016v12zm7.968-1.968v-6h-6V6h6q.844 0 1.43.586T18 8.016v6h-2.016zM12.047 0q4.688 0 8.133 3.188T24 11.016h-1.5q-.281-2.813-1.875-5.063t-4.078-3.422l-1.359 1.313L11.391.047q.141 0 .328-.023t.328-.023zM7.453 21.469l1.359-1.313 3.797 3.797q-.141 0-.328.023t-.328.023q-4.688 0-8.133-3.188T0 12.983h1.5q.281 2.813 1.875 5.063t4.078 3.422z"/>'
  }
})
