/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock_open': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 20.016V9.985H6v10.031h12zm0-12q.797 0 1.406.586t.609 1.383v10.031q0 .797-.609 1.383T18 21.985H6q-.797 0-1.406-.586t-.609-1.383V9.985q0-.797.609-1.383T6 8.016h9.094V6q0-1.266-.914-2.18T12 2.906t-2.18.914T8.906 6H6.984q0-2.063 1.477-3.539T12 .984t3.539 1.477T17.016 6v2.016H18zm-6 9q-.797 0-1.406-.609t-.609-1.406.609-1.406T12 12.986t1.406.609.609 1.406-.609 1.406-1.406.609z"/>'
  }
})
