/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help_center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zM12 18q-.516 0-.891-.375t-.375-.891.375-.891.891-.375q.563 0 .914.375t.352.891-.352.891T12 18zm3-7.406q-.563.844-1.055 1.242t-.773.914q-.141.234-.188.492t-.047.961h-1.828v-.938l.328-1.078q.328-.656.938-1.102t.984-1.055q.328-.375.281-.938t-.445-.984T12 7.686t-1.219.492-.563 1.008l-1.641-.703q.328-.984 1.195-1.734t2.227-.75q1.078 0 1.852.445t1.148 1.055q.328.563.422 1.453t-.422 1.641z"/>'
  }
})
