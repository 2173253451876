/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'how_to_vote': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.75 2.297q.281-.281.703-.281.516 0 .703.281l4.969 4.969q.281.281.281.703t-.281.703L12.75 15q-.281.281-.703.281T11.344 15l-4.969-4.922q-.281-.281-.281-.703t.281-.703zm4.266 5.672l-3.563-3.563-4.922 4.969 3.516 3.516zM18 12.984l3 3v4.031q0 .844-.586 1.406t-1.43.563H4.968q-.797 0-1.383-.586t-.586-1.383v-4.031l3-3h.844L8.812 15H6.796l-1.781 2.016h13.969L17.25 15h-1.922l1.969-2.016H18z"/>'
  }
})
