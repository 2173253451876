/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gesture': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.875 18.563q.656 0 1.313-.867t.844-2.648q-1.406.375-2.156 1.266t-.75 1.5q0 .328.234.539t.516.211zM4.594 6.891L2.86 5.203q.469-.563.844-.938Q4.97 2.999 6.423 2.999q.891 0 1.711.703t.82 2.156q0 1.406-1.313 3.281-1.313 1.828-1.828 3.516-.281.797-.164 1.359t.492.563q.422 0 1.125-.844 1.031-1.031 2.297-2.719 2.25-2.813 4.922-2.813 1.969 0 2.93 1.289t1.102 2.883h2.484v2.484h-2.438q-.281 3.234-1.734 4.688t-3 1.453q-1.313 0-2.25-.914t-.938-2.18q0-1.547 1.406-3.234t3.984-2.156q-.047-.375-.07-.516t-.141-.445-.281-.445-.492-.258-.797-.117q-1.313 0-4.078 3.422-.797.984-1.102 1.336t-.867.82-1.078.609q-1.641.516-2.813-.609T3.14 13.498q0-.703.258-1.547t.68-1.641.797-1.43.727-1.148T6 7.169q.797-1.313.281-1.5-.328-.141-1.688 1.219z"/>'
  }
})
