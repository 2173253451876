/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'strikethrough_s': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.375 13.125q0 2.531 2.906 2.531 2.297 0 2.297-1.688 0-.75-.305-1.078t-1.102-.703q-.047 0-.164-.047t-.211-.094-.188-.047H2.999V9.983h18v2.016h-3.891q0 .047.07.164t.117.164q.328.797.328 1.641 0 2.859-3.141 3.75-.984.281-2.156.281-.75 0-1.453-.141-1.688-.328-2.625-1.031-1.828-1.359-1.828-3.703h2.953zM14.578 7.5q0-2.109-2.391-2.109-1.688 0-2.203 1.031-.141.281-.141.656 0 .703.75 1.219.75.469 1.406.703H7.405q0-.047-.094-.117t-.094-.117q-.375-.609-.375-1.688 0-1.734 1.5-2.953Q9.795 3 12.233 3q2.484 0 3.891 1.266 1.453 1.313 1.453 3.234h-3z"/>'
  }
})
