/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'public_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 8.156l-4.547-4.5q1.219-.797 2.602-1.219t2.93-.422q2.063 0 3.867.773t3.188 2.156 2.156 3.188.773 3.867q0 1.547-.422 2.93t-1.219 2.602l-1.453-1.5q.516-.844.82-1.875T20.016 12q0-1.688-.656-3.141t-1.781-2.555-2.578-1.711v.422q0 .797-.586 1.383t-1.43.586h-1.969v1.172zm10.172 13.032l-1.406 1.406-2.25-2.25q-1.219.797-2.602 1.219t-2.93.422q-2.063 0-3.867-.773t-3.188-2.156-2.156-3.188-.773-3.867q0-1.547.422-2.93t1.219-2.602l-2.25-2.25 1.406-1.406zM11.016 18q-.844 0-1.43-.586T9 15.984V15l-4.781-4.781q-.094.422-.164.867t-.07.914q0 2.063.938 3.75t2.531 2.813 3.563 1.359V18z"/>'
  }
})
