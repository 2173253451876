/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exposure_neg_1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18h-1.969V7.359l-3 1.031V6.702l4.688-1.688h.281v12.984zm-15-6.984H12v1.969H3.984v-1.969z"/>'
  }
})
