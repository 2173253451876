/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outdoor_grill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.391 6.984q.328-1.266-.422-2.016-1.172-1.219-.891-2.953h.984q-.234 1.172.469 2.016.797.891.938 1.523t-.047 1.43h-1.031zm-2.485 0q.281-1.266-.469-2.016-1.172-1.219-.891-2.953h.984q-.234 1.172.469 2.016.797.891.938 1.523t-.047 1.43h-.984zm-2.484 0q.281-1.266-.469-2.016-1.172-1.219-.891-2.953h.984q-.234 1.172.469 2.016.797.891.938 1.523t-.047 1.43h-.984zM17.016 18q-.422 0-.727.281t-.305.703.305.727.727.305.703-.305.281-.727-.281-.703-.703-.281zm0 3.984q-.984 0-1.734-.563t-1.078-1.453H7.829l-.984 1.594q-.234.422-.844.422-.375 0-.563-.141-.422-.234-.422-.844 0-.375.141-.563l3.984-6.047q-1.875-.844-3-2.555t-1.125-3.82h13.969q0 1.875-1.195 3.75t-2.883 2.625l.563.891q-.938.375-1.594 1.219l-.984-1.547q-.234.047-.891.047t-.891-.047l-1.969 3.047h5.063q.328-.891 1.078-1.453t1.734-.563q1.219 0 2.109.891t.891 2.109-.891 2.109-2.109.891z"/>'
  }
})
