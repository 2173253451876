/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'looks_5': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9V6.984H9v6h3.984V15H9v2.016h3.984q.844 0 1.43-.586T15 15v-2.016q0-.844-.586-1.406t-1.43-.563h-1.969V8.999h3.984zm3.984-6q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
