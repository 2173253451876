<template>
  <div class="player-container" ref="liveplayercontainer">
    <!-- <div
      v-if="showLoading"
      v-loading="showLoading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
      :element-loading-text="msg"
      class="loading">
    </div> -->

    <video
      ref="liveplayer"
      v-show="true"
      :class="{player: true, 'filled-screen': filledScreen}"
      autoplay=""
      muted="muted"
      loop>
    </video>
  </div>
</template>

<script>
export default {
  props: {
    ch: {type: Number},
    url: {type: String},
    username: {type: String},
    password: {type: String},
    fixedSize: { type: Object, default: null },
    filledScreen: { type: Boolean, default: false },
    useSetup: {type: Boolean, default: false}
  },
  data() {
    return {
      janus: null,
      streaming: null,
      showLoading: true,
      showPlayer: false,
      progress: 0,
      playInterval: null,
      retryInterval: null,
      msg: 'Connecting...',

      pipeline: null,
      rtsphost: '',
      rtspUrl: '',
      webhost: '',
      webport: 0,
      ports: {},

      gridX: 4,
      gridY: 4
    }
  },
  watch: {
    url() {
      this.emitRtspUrl();
      this.reconnect();
    },
    ch() {
      this.emitRtspUrl();
      this.reconnect();
    },
    fixedSize: function () {
      this.resizePlayer();
    }
  },
  mounted() {
    const player = document.getElementsByClassName('player')[0];
    player.addEventListener("play", () => {
      console.debug('play....')
      this.showPlayer = true;
      this.resizePlayer();
    });
    player.addEventListener("playing", () => {
      console.debug('playing....');
      this.showLoading = false;
    });
    player.addEventListener("error", () => {
      console.log('player play error');
      this.showLoading = false;
    });

    document.addEventListener("visibilitychange", this.onVisibilityChange);
    window.addEventListener("resize", this.resizePlayer);
    this.resizePlayer();
    this.fetchIpAddr();
  },
  beforeDestroy() {
    this.stopStream();
  },
  destroyed() {
    clearInterval(this.playInterval);
    window.removeEventListener("resize", this.resizePlayer);
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  },
  methods: {
    play() {
      if (!this.url) return;
      // Grab a reference to the video element
      const player = document.getElementsByClassName('player')[0];
      const { pipelines } = window.mediaStreamLibrary;

      const ch = this.ch || 0;
      const ep = ch === 0 ? "ch0" : `ch${ch}vloss`;
      const vloss = ch === 0 ? "" : "vloss";
      const wsrtspUrl = `rtsp://127.0.0.1/live/${ep}`;

      // Setup a new pipeline
      this.pipeline = new pipelines.Html5VideoPipeline({
        // ws: { uri: `${this.wsProto}://${this.webhost}:${this.webport}/ws/rtsp` },
        // ws: { uri: 'wss://54.180.39.38:32874/ws/rtsp' },
        // ws: { uri: 'ws://192.168.101.140:8015/ws/rtsp?x-cloud-auth=Token zzz' },
        // ws: { uri : 'ws://dev.aws.ap-northeast-2.retail.api.sequrinet.com/ws/rtsp?x-cloud-auth=Token 9c3783d89abd9421d72bba7277b44bbcb55e1b86&x-proxy-device-id=783168d7fb1d' },
        ws: { uri : this.url },
        rtsp: { uri: wsrtspUrl },
        mediaElement: player,
        auth: { username:this.username, password:this.password },
        // auth: { username:this.username, password:this.password },
      })

      this.pipeline.onSourceOpen = (mse, tracks) => {
        console.debug("onSourceOpen....");

        mse.duration = Infinity;
        player.play();
      }

      this.pipeline.ready.then(() => {
        console.debug("Ready....");
        this.showLoading = true;
        this.showPlayer = false;
        this.pipeline.rtsp.play();
      });

      this.pipeline.onServerClose = () => {
        console.debug("onServerClose...");
      }

      this.pipeline.onCloseCallback = () => {
        console.debug("onCloseCallback...");
        this.reconnect();
      }
    },
    reconnect() {
      // this.progress = 0;
      this.showLoading = true;
      // this.showPlayer = false;

      this.stopStream();
      this.play();
    },
    stopStream() {
      const player = document.getElementsByClassName('player')[0];
      player && player.pause();

      if (this.pipeline) {
        this.pipeline.onCloseCallback = () => {};
        this.pipeline.rtsp.pause();
        this.pipeline.close();
      }
    },
    resizePlayer() {
      let elem;

      if (this.fixedSize === null) {
        // elem = document.getElementsByClassName('player')[0];
        // elem.style.height = (window.innerHeight - 46 - 50) + 'px';

        // elem = document.getElementsByClassName('player-container')[0];
        // elem.style.height = (window.innerHeight - 46 - 50) + 'px';
        return
      }

      elem = this.$refs.liveplayer || null
      if (elem === null) return
      elem.style.width = `${this.fixedSize.w}px`
      elem.style.height = `${this.fixedSize.h}px`

      elem = this.$refs.liveplayercontainer || null
      if (elem === null) return
      elem.style.width = `${this.fixedSize.w}px`
      elem.style.height = `${this.fixedSize.h}px`

    },
    onVisibilityChange() {
      if(document.hidden) {
        this.stopStream();
      } else {
        this.reconnect();
      }
    },
    onDoubleClick(e) {
      const player = document.getElementsByClassName('player')[0];
      const topmenu = document.getElementsByClassName('el-header')[0];
      const rect = player.getBoundingClientRect(); 
      let dispWidth = rect.width;
      let dispHeight = rect.height;
      let marginLeft = 0;
      let marginTop = 0;

      const whRate = rect.width / rect.height;
      if (whRate < 16 / 9) {
        // 위.아래 여백
        // 16 : 9 = rect.width : ?
        dispHeight = rect.width * 9 / 16;
        marginTop = (rect.height - dispHeight) / 2;
      } else {
        // 좌.우 여백
        // 16 : 9 = ? : rect.height
        dispWidth = rect.height * 16 / 9;
        marginLeft = (rect.width - dispWidth) / 2;
      }

      const clickX = (e.clientX - marginLeft) / dispWidth;
      const clickY = (e.clientY - topmenu.clientHeight - marginTop) / dispHeight;

      const gridRateX = 1.0 / this.gridX // 0.25
      const gridRateY = 1.0 / this.gridY // 0.25

      for (let i = 0; i < this.gridX; i++) {
        for (let j = 0; j < this.gridY; j++) {
          const minX = gridRateX * i;
          const maxX = gridRateX * (i + 1);
          const minY = gridRateY * j;
          const maxY = gridRateY * (j + 1);

          if (minX < clickX && clickX < maxX) {
            if (minY < clickY && clickY < maxY) {
              const ch = i + 1 + (j * this.gridY);
              this.$emit('ch-change', this.ch > 0 ? 0 : ch);
              e.preventDefault();
              return;
            }
          }
        }
      }
    },
    async fetchIpAddr() {
      this.emitRtspUrl();
      this.play();
    },
    emitRtspUrl() {
      const ch = parseInt(this.ch);
      const ep = ch >= 0 ? `ch${ch}` : "wbc";
      this.rtspUrl = `rtsp://127.0.0.1/live/${ep}`;
      this.$emit('rtsp-change', this.rtspUrl);
    }
  }
}
</script>

<style scoped>
.player-container {
  overflow: hidden;
  height: 100%;
}
.player {
  margin: auto;
  width: 100%;
  /*object-fit: fill;*/
}
.filled-screen {
  object-fit: fill;
}
.loading {
  margin: auto;
  color: white;
  height: 100%;
}
</style>
