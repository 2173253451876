/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bluetooth_searching': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.891 16.313l-1.875-1.922v3.797zm-1.875-10.5V9.61l1.875-1.922zm4.687 1.875l-4.313 4.313 4.313 4.313-5.719 5.672H9v-7.594l-4.594 4.594L3 17.58l5.578-5.578L3 6.424l1.406-1.406L9 9.612V2.018h.984zm3.844-.985Q21 9.047 21 11.906q0 2.813-1.547 5.297l-1.172-1.172q.984-1.969.984-4.031t-.984-4.031zM14.25 12l2.297-2.297q.469 1.172.469 2.297 0 1.172-.469 2.344z"/>'
  }
})
