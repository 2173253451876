/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outlined_flag': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 14.016v-6h-5.016L12 6H6.984v6h6l1.031 2.016h3.984zM14.016 6h6v9.984h-7.031l-.984-1.969H6.985v6.984H5.016V3.983h7.969z"/>'
  }
})
