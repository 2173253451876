/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stay_current_landscape': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6.984H5.015v10.031h13.969V6.984zm-17.953 0q0-.797.586-1.383T3 5.015h18q.797 0 1.406.586t.609 1.383v10.031q0 .797-.609 1.383T21 18.984H3q-.797 0-1.406-.586t-.609-1.383z"/>'
  }
})
