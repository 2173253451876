/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'switch_account': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15.984v-1.5q0-1.313-2.063-2.156t-3.938-.844-3.938.844-2.063 2.156v1.5h12zm-6-12q-1.219 0-2.109.891t-.891 2.109.891 2.109 2.109.891 2.109-.891.891-2.109-.891-2.109-2.109-.891zm6-1.968q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18h-12q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586h12zM3.984 6v14.016H18v1.969H3.984q-.797 0-1.383-.586t-.586-1.383V6h1.969z"/>'
  }
})
