/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop_free': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3q.797 0 1.406.609t.609 1.406v3.984h-2.016V5.015h-3.984V2.999h3.984zm0 15.984V15H21v3.984q0 .797-.609 1.406t-1.406.609h-3.984v-2.016h3.984zM5.016 15v3.984H9V21H5.016q-.797 0-1.406-.609t-.609-1.406v-3.984h2.016zM3 5.016q0-.797.609-1.406t1.406-.609h3.984v2.016H5.015v3.984H2.999V5.017z"/>'
  }
})
