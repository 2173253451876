/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leak_add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 21q0-2.906 2.039-4.945T21 14.016v1.969q-2.063 0-3.539 1.477t-1.477 3.539h-1.969zM18 21q0-1.219.891-2.109T21 18v3h-3zm-8.016 0q0-4.547 3.234-7.781t7.781-3.234v2.016q-3.75 0-6.375 2.625t-2.625 6.375H9.983zm0-18q0 2.906-2.039 4.945T3 9.984V8.015q2.063 0 3.539-1.477t1.477-3.539h1.969zm4.032 0q0 4.547-3.234 7.781t-7.781 3.234v-2.016q3.75 0 6.375-2.625t2.625-6.375h2.016zM6 3q0 1.219-.891 2.109T3 6V3h3z"/>'
  }
})
