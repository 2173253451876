/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'policy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12q0-1.219.891-2.109T12 9t2.109.891T15 12t-.891 2.109T12 15t-2.109-.891T9 12zm12-6.984v6q0 3.563-1.922 6.656l-2.906-2.906q.844-1.266.844-2.766 0-.844-.422-1.898t-1.031-1.664-1.664-1.031-1.898-.422-1.875.422-1.641 1.031-1.055 1.664T6.985 12t.445 1.875 1.055 1.641 1.641 1.055 1.875.445q1.5 0 2.766-.844l3.094 3.141q-2.438 2.859-5.859 3.703-3.844-.938-6.422-4.383t-2.578-7.617v-6l9-4.031z"/>'
  }
})
