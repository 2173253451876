/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sick': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 9q-.844 0-1.43-.586t-.586-1.43q0-.375.305-1.055t.703-1.336.703-1.125L21 2.999l.305.469.703 1.125.703 1.336.305 1.055q0 .844-.586 1.43T21 9zm-3.516-2.016q0-.516.281-1.219t.656-1.406q-1.313-1.125-2.953-1.734t-3.469-.609q-2.063 0-3.891.773T4.92 4.945 2.787 8.133 2.014 12t.773 3.867 2.133 3.188 3.188 2.156 3.891.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867q0-.422-.023-.82t-.117-.82q-.188.094-.398.117T21 10.5q-.984 0-1.758-.469t-1.266-1.266-.492-1.781zm-1.875.375l1.078 1.078-1.078 1.078 1.078 1.031-1.078 1.078L13.5 9.515zM7.313 8.438L8.391 7.36 10.5 9.516l-2.109 2.109-1.078-1.078 1.078-1.031zm8.109 8.578q-.516-.891-1.406-1.453T12 15t-2.016.563-1.406 1.453H6.89Q7.312 15.891 8.203 15L5.25 13.312q-.75.422-1.5 0-.563-.328-.703-.914t.141-1.148q.328-.563.914-.703t1.148.141q.375.234.563.586t.188.727l3.563 2.063q1.125-.563 2.438-.563 1.734 0 3.117.984t1.992 2.531h-1.688z"/>'
  }
})
