/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insert_photo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.484 13.5L5.015 18h13.969l-4.5-6-3.469 4.5zM21 18.984q0 .797-.609 1.406t-1.406.609H5.016q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T5.016 3h13.969q.797 0 1.406.609T21 5.015v13.969z"/>'
  }
})
