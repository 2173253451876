/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_color_text': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.609 12h4.781l-2.391-6.328zm1.407-9h1.969l5.484 14.016h-2.25l-1.078-3H8.86l-1.125 3h-2.25zM0 20.016h24V24H0v-3.984z"/>'
  }
})
