<template>
  <div>
    <ConfirmModal ref="AcquisitionConfirmModal" @ok="onConfirmedAcquisition" @cancel="onCanceledAcquisition" content="Obtain administrative authority for the organization. Continue?" />
    <ConfirmModal ref="ReturnConfirmModal" @ok="onConfirmedReturn" content="Return the authority for the organization. Continue?" />
    <OrganizationForm title="New Organization" ref="FormModal" :organization="init_form" @payload="onPayload" />
    <OrganizationView v-if="is_view"
      :organization="init_form"
      @back="onBack"
      @edit="onEdit">
    </OrganizationView>
    <Search @search="onSearch" v-if="!is_view && capability" AddBtn @add="onAdd">
      <CRow v-if="!loading">
        <!-- <CCol sm="6" md="4" v-if="myAuthorizationLevel.level < 10 && !searchText && pageCurrent === 1">
          <CCard @click="onAdd()" class="clickable-card-wrapper-add">
            <CCardImg class="custom-img-contain" variant="top" src="/img/add_org_256x256.png" height="174" />
            <CCardBody>
              <p>Let's make your business smarter with EdgeDX solution based on AI and data transformation.</p>
              <div class="text-center">
                <CButton shape="pill" size="lg" color="success">ADD NEW ORGANIZATION</CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol> -->
        <CCol v-for="(organization, index) in list" :key="`orgin-key-${index}`" sm="6" md="4">
          <CCard class="clickable-card-wrapper">
            <CCardHeader @click="onView(organization)">
              <div class="d-flex flex-wrap justify-content-center">
                <CImg class="custom-limit-logo-size"
                  :src="logoPath(organization.logo)"
                  />
              </div>
            </CCardHeader>
            <CCardBody @click="onView(organization)">
              <strong class="h5">{{organization.name}}</strong><br><br>
              <div class="d-flex flex-wrap">
                <CCallout class="m-1 w-100">
                  <small class="text-muted">Service Subscription</small><br>
                  <CBadge
                    class="mr-1"
                    v-for="service in organization.subscription"
                    :color="$utility.getSubscriptionStatusColor(service)"
                    v-c-tooltip="$utility.getSubscriptionStatusRemain(service)"
                    :key="`service-subscription-${organization.id}-${service.id}`"
                  >{{service.service_name}}</CBadge>
                  <!-- <el-tooltip
                    v-for="service in organization.subscription"
                    :key="`service-subscription-${organization.id}-${service.id}`"
                    :content="getSubscriptionStatusRemain(service)"
                  >
                    <el-tag class="mr-1" size="small" :type="getSubscriptionStatusColor(service)">{{ service.service_name }}</el-tag>
                  </el-tooltip> -->
                  <br/><br/>
                  <CRow>
                    <CCol>
                      <small class="text-muted">Users</small>
                      <p class="h5">{{organization.num_user}}</p>
                    </CCol>
                    <CCol>
                      <small class="text-muted">Branches</small>
                      <p class="h5">{{organization.num_branch}}</p>
                    </CCol>
                    <CCol>
                      <small class="text-muted">Devices</small>
                      <p class="h5">{{organization.num_device}}</p>
                    </CCol>
                  </CRow>
                </CCallout>
              </div>
            </CCardBody>
            <CCardFooter v-if="myAuthorizationLevel.level < 10">
              <div class="float-right">
                <el-tooltip content="Obtaining permission" v-if="capability.organization.id !== organization.id">
                  <el-button icon="el-icon-lock" size="small" type="primary" @click="onAcquireAuthority(organization.id)" />
                </el-tooltip>
                <el-tooltip content="Return of permission" v-if="capability.organization.id === organization.id">
                  <el-button icon="el-icon-unlock" size="small" type="danger" @click="onReturnAuthority(organization.id)" />
                </el-tooltip>
              </div>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="justify-content-end" v-if="pageTotal > 1 && !loading">
        <CPagination
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
    </Search>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import OrganizationView from './OrganizationView.vue'
import OrganizationForm from './OrganizationForm.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Organizations',
  components: {
    Search,
    ConfirmModal,
    OrganizationView,
    OrganizationForm
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    ...mapGetters([
      'myAuthorizationLevel'
    ])
  },
  data () {
    return {
      FIELDS: [
        'name',
        {key: 'state_name', label: 'State'},
        {key: 'max_user', label: 'Limited number of users'},
        {key: 'logo', label: 'Logo'},
        {key: 'ops', label: 'Operations', _style: 'width: 100px;' }
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],
      loading: false,

      confirm_show: false,

      is_view : false,
      init_form : null,
      regions : [],
      selected_regions : [],
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList: function() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent,
        ipp: 6
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading = true;
      axios.get(`/api/organizations/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
          this.list = [];
        })
        .finally(_ => {
          this.loading = false;
        })
    },
    onSearch: function(text) {
      this.searchText = text;
      this.getList();
    },
    onAdd: function() {
      this.init_form = null;
      this.$refs.FormModal.open();
    },
    onView : function(organization) {
      this.init_form = organization
      if (this.capability.organization.id !== organization.id) {
        this.$refs.AcquisitionConfirmModal.open(organization.id);
      } else {
        this.is_view = true;
      }
    },
    onBack: function() {
      this.is_view = false;
      this.init_form = null;
      this.getList();
    },
    onEdit: function() {
      this.$refs.FormModal.open();
    },
    onPayload: function(payload, id) {
      if (id) {
        axios.put(`/api/organizations/${id}/`, payload)
          .then(result => {
            this.getList();
            this.init_form = result.data;
            this.onConfirmedAcquisition(id);
            this.$notify.success({
              title: '완료',
              message: `항목이 변경되었습니다`,
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: '오류',
              message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
              offset: 30
            });
          })
        return;
      }
      axios.post(`/api/organizations/`, payload)
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 추가되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 추가할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onUpdatePage: function(e) {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet: function() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onAcquireAuthority: function(id) {
      this.$refs.AcquisitionConfirmModal.open(id);
    },
    onReturnAuthority: function(id) {
      this.$refs.ReturnConfirmModal.open();
    },

    onConfirmedAcquisition: function(payload) {
      axios.put(`/api/organizations/owner/`, {organization_id: payload})
        .then(result => {
          this.$store.dispatch('refreshCapability');
          if (this.init_form) {
            this.is_view = true;
          }
        })
        .catch(error => {
          this.init_form = null;
        })
    },
    onCanceledAcquisition: function() {
      this.init_form = null;
    },
    onConfirmedReturn: function() {
      axios.delete(`/api/organizations/owner/`)
        .then(result => {
          this.$store.dispatch('refreshCapability');
        })
    },
    logoPath : function(path){
      if(path===null)
        return '/img/no-logo4.jpg';
      else
        return path;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/coreui";

.clickable-card-wrapper-add {
  border: 0px;
  margin: 2px 2px 22px 2px;
  cursor: pointer;
}
.clickable-card-wrapper-add:hover {
  border: 2px solid $warning;
  margin: 0px 0px 20px 0px;
}
.clickable-card-wrapper {
  border: 0px;
  margin: 2px 2px 22px 2px;
  cursor: pointer;
}
.clickable-card-wrapper:hover {
  border: 2px solid $info;
  margin: 0px 0px 20px 0px;
}
</style>