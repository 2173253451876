/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stream': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3.984q0-.844.586-1.406T12 2.015t1.43.563.586 1.406-.586 1.43T12 6t-1.43-.586-.586-1.43zm9.797 14.297l-1.453 1.406q-.844-.844-2.391-2.414t-1.922-1.945l1.406-1.406.328.375zm-9.75-2.906q-.844.844-2.414 2.391t-1.945 1.922l-1.453-1.406q.844-.844 2.414-2.391t1.945-1.922zm3.891-6.75l4.406-4.406 1.453 1.406-4.406 4.406zm-3.891-.047L8.625 9.984l-.328-.281-3.984-4.078 1.406-1.406.281.328h.047zm-.047 11.438q0-.844.586-1.43T12 18t1.43.586.586 1.43-.586 1.406-1.43.563-1.43-.563-.586-1.406zM2.016 12q0-.844.563-1.43t1.406-.586 1.43.586.586 1.43-.586 1.43-1.43.586-1.406-.586T2.016 12zM18 12q0-.844.586-1.43t1.43-.586 1.406.586.563 1.43-.563 1.43-1.406.586-1.43-.586T18 12z"/>'
  }
})
