/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_business': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 17.016h2.016v-3H18V12l-.984-5.016h-15L.985 12v2.016h1.031v6h9v-6H15v3zM9 18H3.984v-3.984H9V18zM2.016 3.984h15V6h-15V3.984zm18 14.016v-3H18v3h-3v2.016h3v3h2.016v-3h3V18h-3z"/>'
  }
})
