/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'voicemail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.516 15q1.453 0 2.461-1.031t1.008-2.484-1.008-2.461-2.461-1.008-2.484 1.008-1.031 2.461 1.031 2.484T18.516 15zM5.484 15q1.453 0 2.484-1.031t1.031-2.484-1.031-2.461-2.484-1.008-2.461 1.008-1.008 2.461 1.008 2.484T5.484 15zm13.032-9q2.297 0 3.891 1.594t1.594 3.891-1.594 3.914-3.891 1.617H5.485q-2.297 0-3.891-1.617T0 11.485t1.594-3.891T5.485 6t3.914 1.594 1.617 3.891q0 2.016-1.266 3.516h4.5q-1.266-1.5-1.266-3.516 0-2.297 1.617-3.891T18.515 6z"/>'
  }
})
