/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'picture_in_picture_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 19.031V4.968H3v14.063h18zm2.016-.047q0 .797-.609 1.406t-1.406.609h-18q-.797 0-1.406-.609t-.609-1.406V4.968q0-.797.609-1.383t1.406-.586h18q.797 0 1.406.586t.609 1.383v14.016zm-4.032-7.968v6h-7.969v-6h7.969z"/>'
  }
})
