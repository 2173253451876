/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star_half': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15.422l3.75 2.25-.984-4.266 3.328-2.906-4.406-.375L12 6.094v9.328zm9.984-6.188l-5.438 4.734 1.641 7.031-6.188-3.75-6.188 3.75 1.641-7.031-5.438-4.734 7.172-.609 2.813-6.609 2.813 6.609z"/>'
  }
})
