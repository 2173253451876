/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.484 12.516l-3.469 4.5h13.969l-4.5-6-3.469 4.5zM23.016 18q0 .797-.609 1.406t-1.406.609h-18q-.797 0-1.406-.609T.986 18V6q0-.797.609-1.406t1.406-.609h18q.797 0 1.406.609T23.016 6v12z"/>'
  }
})
