/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_horizontal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.422 3.984q.563 0 .563.656v14.719q0 .656-.563.656-.094 0-.281-.094-4.5-1.641-9.141-1.641t-9.141 1.641q-.188.094-.281.094-.563 0-.563-.656V4.64q0-.656.563-.656.094 0 .281.094Q7.359 5.719 12 5.719t9.141-1.641q.188-.094.281-.094zm-1.406 2.579Q16.36 7.688 12 7.688q-4.125 0-8.016-1.125v10.875Q7.828 16.313 12 16.313q4.125 0 8.016 1.125V6.563z"/>'
  }
})
