/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_add_alt_1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 8.016q0-1.125-.539-2.039t-1.43-1.453-2.016-.539-2.016.539-1.43 1.453-.539 2.039q0 1.078.539 1.992t1.43 1.453T8.999 12t2.016-.539 1.43-1.453.539-1.992zM15 9.984V12h3v3h2.016v-3h3V9.984h-3v-3H18v3h-3zM.984 18v2.016h16.031V18q0-.797-.563-1.43t-1.477-1.125-1.992-.797-2.133-.469-1.852-.164-1.852.164-2.133.469-1.992.797-1.477 1.125T.981 18z"/>'
  }
})
