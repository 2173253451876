/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 20.016h1.453l-1.453-1.453v1.453zm-1.968 0v-3.469l-.563-.563H9.984v4.031h4.031zm-6-6v-3.469l-.563-.563H3.984v4.031h4.031zm0 6v-4.031H3.985v4.031h4.031zM3.984 6.563v1.453h1.453zm6 6v1.453h1.453zM1.266 1.266l21.469 21.469-1.266 1.266-2.016-2.016H3.984q-.797 0-1.383-.586t-.586-1.383V4.547L-.001 2.531zm14.718 2.718v4.031h4.031V3.984h-4.031zm-7.968 0H6.563L4.547 2.015h15.469q.797 0 1.383.586t.586 1.383v15.469l-1.969-2.016v-1.453h-1.453l-2.016-1.969h3.469V9.984h-4.031v3.469l-1.969-2.016V9.984h-1.453l-2.016-1.969h3.469V3.984H9.985v3.469L8.016 5.437V3.984z"/>'
  }
})
