/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'straighten': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15.984V8.015h-2.016v3.984h-1.969V8.015h-2.016v3.984h-2.016V8.015h-1.969v3.984H8.998V8.015H6.982v3.984H5.013V8.015H2.997v7.969h18zM21 6q.797 0 1.406.609t.609 1.406v7.969q0 .797-.609 1.406T21 17.999H3q-.797 0-1.406-.609t-.609-1.406V8.015q0-.797.609-1.406T3 6h18z"/>'
  }
})
