/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_backspace': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 11.016v1.969H6.844l3.563 3.609L9.001 18l-6-6 6-6 1.406 1.406-3.563 3.609H21z"/>'
  }
})
