/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subdirectory_arrow_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 9l1.406 1.406-3.609 3.609h9.188V3.984h2.016v12H8.814l3.609 3.609-1.406 1.406-6-6z"/>'
  }
})
