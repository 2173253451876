/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin_drop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 20.016h13.969v1.969H5.016v-1.969zm4.968-12q0 .844.586 1.406t1.43.563 1.43-.563.586-1.406q0-.797-.609-1.406t-1.406-.609-1.406.609-.609 1.406zm8.016 0q0 2.016-1.5 4.758t-3 4.477L12 18.985q-.656-.703-1.664-1.922t-2.672-4.125T6 8.016q0-2.484 1.758-4.242T12 2.016t4.242 1.758T18 8.016z"/>'
  }
})
