/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_up_off_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 9q.422 0 .727.281t.305.703v9q0 .422-.305.727t-.727.305H2.015V9h1.969zm10.032-6.984l.422.469q.563.563.891 1.219.141.328.094.656l-.938 4.641h5.531q.797 0 1.383.609t.586 1.406v1.078q0 .422-.141.797l-2.672 6.141q-.609.984-1.734.984H9.329q-.984 0-1.664-.68t-.68-1.664V9.844q0-.844.609-1.453zm-.891 3.703L9 9.797v7.875Q9 18 9.328 18h8.109l2.578-5.906v-1.078h-5.531q-.891 0-1.523-.727t-.398-1.664z"/>'
  }
})
