/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'anchor': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15l1.547 1.547q-.516.844-1.383 1.547t-1.969 1.172-2.227.656v-8.906h3V9h-3V7.828q.891-.328 1.453-1.102T15 5.015q0-.844-.398-1.523t-1.078-1.078-1.523-.398-1.523.398T9.4 3.492t-.398 1.523q0 .938.563 1.711t1.453 1.102V9h-3v2.016h3v8.906q-1.125-.188-2.227-.656t-1.969-1.172-1.383-1.547L6.986 15l-3.984-3v3q0 1.453.82 2.719t2.156 2.227 2.906 1.5 3.117.539 3.117-.539 2.906-1.5 2.156-2.227T21 15v-3zM12 3.984q.422 0 .703.305t.281.727q0 .375-.281.68T12 6.001t-.703-.305-.281-.68q0-.422.281-.727T12 3.984z"/>'
  }
})
