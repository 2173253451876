/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'import_contacts': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 18.516V6.985q-1.547-.469-3.516-.469-3.047 0-5.484 1.5V19.5q2.438-1.5 5.484-1.5 1.828 0 3.516.516zM17.484 4.5q3.563 0 5.531 1.5v14.578q0 .188-.164.352t-.352.164q-.141 0-.234-.047-1.922-1.031-4.781-1.031-3.047 0-5.484 1.5-2.016-1.5-5.484-1.5-2.531 0-4.781 1.078-.047 0-.117.023t-.117.023q-.188 0-.352-.141t-.164-.328V5.999q2.016-1.5 5.531-1.5 3.469 0 5.484 1.5 2.016-1.5 5.484-1.5z"/>'
  }
})
