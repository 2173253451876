/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'colorize': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.938 18.984l8.063-8.063-1.922-1.922-8.063 8.063zM20.719 5.625q.703.703 0 1.406l-3.141 3.141 1.922 1.922-1.406 1.406-1.406-1.406L7.735 21H3.001v-4.734l8.906-8.953-1.406-1.406 1.406-1.406 1.922 1.922 3.141-3.141q.281-.281.703-.281t.703.281z"/>'
  }
})
