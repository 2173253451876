/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 15.984q1.031 0 1.758-.727t.727-1.758-.727-1.758-1.758-.727h-.516q0-1.641-1.172-2.836t-2.813-1.195q-1.266 0-2.391.891T8.155 10.03l-.141-.047q-1.219 0-2.109.891t-.891 2.109.891 2.109 2.109.891h8.484zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
