<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <div>
      <CCard>
        <CCardBody>
          <div v-if="step === 0">
            <SearchList
              v-if="freight_updated.branch_id === null"
              ph="공장을 선택하십시오"
              :list="branch_list"
              :pageTotal="branch_page_total"
              @search="onSearchBranch"
              @focus="onFocusBranch"
              @select="onSelectBranch"
              @page="onPageBranch"
            />
            <CCard v-else>
              <CCardBody>
                <div class="my-2">공장</div>
                <CWidgetIcon :header="branch_name" color="primary">
                  <CIcon name="cil-factory" width="24"/>
                </CWidgetIcon>
                <CButton style="float: right;" color="danger" shape="pill" @click="onReset">초기화</CButton>
              </CCardBody>
            </CCard>
          </div>
          <div v-if="step === 1">
            <!-- <CInput
              label="코드"
              placeholder="골재종류 코드"
              horizontal
              v-model="freight_updated.code"
            /> -->
            <CInput v-if="cates"
              :label="cates[0]"
              horizontal
              v-model="freight_updated.cate1"
            />
            <CInput v-if="cates && cates.length > 1"
              :label="cates[1]"
              horizontal
              v-model="freight_updated.cate2"
            />
            <CInput v-if="cates && cates.length > 2"
              :label="cates[2]"
              horizontal
              v-model="freight_updated.cate3"
            />
            <CInput v-if="cates && cates.length > 3"
              :label="cates[3]"
              horizontal
              v-model="freight_updated.cate4"
            />
            <!-- <CInput
              label="이름"
              horizontal
              v-model="freight_updated.name"
            /> -->
          </div>
        </CCardBody>
      </CCard>
    </div>
    <template #header>
      <h6 class="modal-title">{{freight? '골재종류 수정': '새로운 골재종류'}}  {{ ` - STEP (${+(step+1)}/2)` }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step===0" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===0" @click="onNext" color="primary">{{$t('button.next')}}</CButton>
      <CButton v-if="step===1 && myAuthorizationLevel.level < 100" @click="onPrev" color="secondary">{{$t('button.prev')}}</CButton>
      <CButton v-if="step===1 && myAuthorizationLevel.level >= 100" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===1" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'FreightForm',
  components: {
    SearchList
  },

  data() {
    return {
      show: false,

      branch_list: [],
      branch_search: null,
      branch_page_total: 1,
      branch_page_current: 1,

      cates: [],
      freight: null,
      freight_updated: {
        id: null,
        name: null,
        // code: null,
        cate1: null,
        cate2: null,
        cate3: null,
        cate4: null,
        branch_id: null
      },
      branch_name: null,

      notification: '',
      dismissCountDown: 0,
      step: 0
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    ...mapState([
      'capability'
    ])
  },
  methods: {
    open(init_obj) {
      this.onCancel();
      this.cates = init_obj.cates;
      this.freight = init_obj.freight || null;
      this.initialize();
      this.show = true;
    },
    initialize() {
      if (this.freight) {
        this.freight_updated = {
          id: this.freight.id,
          name: this.freight.name,
          // code: this.freight.code,
          cate1: this.freight.cate1,
          cate2: this.freight.cate2,
          cate3: this.freight.cate3,
          cate4: this.freight.cate4,
          branch_id: this.freight.branch_id
        }
        this.branch_name = this.freight.branch_name
      } else {
        this.freight_updated = {
          id: null,
          name: null,
          // code: null,
          cate1: null,
          cate2: null,
          cate3: null,
          cate4: null,
          branch_id: null
        };
        this.branch_name = null;
      }
      if (this.myAuthorizationLevel.level >= 100) {
        this.freight_updated.branch_id = this.capability.user_profile.branch_id;
        this.step = 1;
      }
    },
    onCancel() {
      this.show = false;
      this.cates = [];
      this.freight = null;
      this.freight_updated = {
        id: null,
        name: null,
        // code: null,
        cate1: null,
        cate2: null,
        cate3: null,
        cate4: null,
        branch_id: null
      };
      this.step = 0;

      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_current = 1;
      this.branch_page_total = 1;
    },
    onSubmit() {
      // if (!this.freight_updated.code) {
      //   this.notification = '골재종류 코드를 입력하십시오';
      //   this.dismissCountDown = 3;
      //   return;
      // }
      if (this.cates.length > 0 && !this.freight_updated.cate1) {
        this.notification = `${cates[0]} 항목을 입력하십시오`;
        this.dismissCountDown = 3;
        return;
      }
      if (this.cates.length > 1 && !this.freight_updated.cate2) {
        this.notification = `${cates[1]} 항목을 입력하십시오`;
        this.dismissCountDown = 3;
        return;
      }
      if (this.cates.length > 2 && !this.freight_updated.cate3) {
        this.notification = `${cates[2]} 항목을 입력하십시오`;
        this.dismissCountDown = 3;
        return;
      }
      if (this.cates.length > 3 && !this.freight_updated.cate4) {
        this.notification = `${cates[3]} 항목을 입력하십시오`;
        this.dismissCountDown = 3;
        return;
      }

      this.freight_updated.cate = [];
      if (this.freight_updated.cate1) this.freight_updated.cate.push(this.freight_updated.cate1);
      if (this.freight_updated.cate2) this.freight_updated.cate.push(this.freight_updated.cate2);
      if (this.freight_updated.cate3) this.freight_updated.cate.push(this.freight_updated.cate3);
      if (this.freight_updated.cate4) this.freight_updated.cate.push(this.freight_updated.cate4);
      this.freight_updated.name = `${this.freight_updated.cate1} ${this.freight_updated.cate2}`;
      this.$emit('payload', this.freight_updated);
      this.onCancel();
      this.show = false;
    },
    onNext() {
      if (!this.freight_updated.branch_id) {
        this.notification = "공장을 선택하십시오";
        this.dismissCountDown = 3;
        return;
      }
      this.step++;
    },
    onPrev() {
      this.step--;
    },

    getBranches(){
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.branch_search,
        page: this.branch_page_current,
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          this.branch_page_total = result.data.page.total;
          this.branch_page_current = result.data.page.current;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onSearchBranch(text) {
      this.branch_search = text;
      this.getBranches();
    },
    onFocusBranch() {
      this.getBranches();
    },
    onPageBranch(p) {
      this.branch_page_current = p;
      this.getBranches();
    },
    onSelectBranch(id) {
      this.freight_updated.branch_id = id;
      this.branch_name = this.branch_list.find(el => el.id === id).name;
    },
    onReset() {
      this.freight_updated.branch_id=null;
      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_total = 1;
      this.branch_page_current = 1;
    }
  }
}
</script>
