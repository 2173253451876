/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip_camera_ios': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 15.516l2.484-2.531h-1.969q0-2.063-1.477-3.516t-3.539-1.453q-1.219 0-2.344.609l.703.703Q11.014 9 11.999 9q1.641 0 2.813 1.172t1.172 2.813h-1.969zM12 18q1.219 0 2.344-.609l-.703-.75q-.75.375-1.641.375-1.641 0-2.813-1.195t-1.172-2.836h1.969L7.5 10.501l-2.484 2.484h1.969q0 2.063 1.477 3.539t3.539 1.477zm8.016-12.984q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 21H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h3.188L9.001 3h6l1.828 2.016h3.188z"/>'
  }
})
