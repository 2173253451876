/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dry_cleaning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.563 14.016q.422 0 .422-.422 0-.328-.234-.422l-6.75-3-6.75 3q-.234.094-.234.375 0 .469.422.469h1.547v-1.031h10.031v1.031h1.547zm.984-2.672Q21 11.953 21 13.547v.047q0 1.031-.703 1.711t-1.734.68h-1.547v6H6.985v-6H5.438q-1.031 0-1.734-.68t-.703-1.711v-.047q0-1.547 1.453-2.156l6.563-2.953v-.609q-1.031-.375-1.594-1.336t-.375-2.086q.188-.891.844-1.523t1.547-.82q1.406-.234 2.484.656t1.078 2.297h-2.016q0-.422-.281-.727t-.703-.305-.703.305-.281.727.281.703.703.281.703.281.281.703v1.453z"/>'
  }
})
