/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'payments': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 14.016V6q0-.844-.586-1.43t-1.383-.586H2.999q-.844 0-1.43.586T.983 6v8.016q0 .797.586 1.383t1.43.586h14.016q.797 0 1.383-.586t.586-1.383zm-9-1.032q-.797 0-1.477-.398t-1.102-1.078-.422-1.523q0-.797.422-1.477t1.102-1.102 1.477-.422q.844 0 1.523.422t1.078 1.102.398 1.477q0 .844-.398 1.523t-1.078 1.078-1.523.398zm13.032-6V18q0 .844-.586 1.43t-1.43.586H3.984V18H21V6.984h2.016z"/>'
  }
})
