/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'first_page': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 6h2.016v12H6V6zm12.422 10.594L17.016 18l-6-6 6-6 1.406 1.406L13.828 12z"/>'
  }
})
