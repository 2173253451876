<template>
  <div>
    <CRow>
      <CCol md="6" lg="4" xl="3" v-for="item in items" :key="`card-list-${item.id}`">
        <CCard @click="onClick(item.id)" class="clickable-card-wrapper">
          <CCardImg class="custom-img-cover" variant="top" :src="item.image_signed_url || '/img/parking.gif'" height="220" />
          <CCardBody>
            <CProgress
              :value="item.occupancy"
              :color="getProgressColor(item.occupancy)"
              show-percentage
            />
          </CCardBody>
          <CCardFooter>
            <div class="lead">{{item.name}}</div>
            <CRow class="border-top mt-2 pt-2">
              <CCol sm="4">
                <p class="h5">{{item.free}}</p>
                <small class="text-muted">Free</small>
              </CCol>
              <CCol sm="4">
                <p class="h5">{{item.space_cnt}}</p>
                <small class="text-muted">Total</small>
              </CCol>
              <CCol sm="4">
                <p class="h5">0</p>
                <small class="text-muted">Alarm</small>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
      <!-- <CCol md="6" lg="4" xl="3" v-if="enableAddingCard">
        <CCard @click="onAdd()" class="adding-card-wrapper">
          <CCardImg class="custom-img-contain" variant="top" src="/img/card-add.png" height="240" />
          <CCardBody>
            <p>Let's make your parking lot smarter with EdgeDX solution based on AI and computer vision.</p>
            <div class="text-center">
              <CButton shape="pill" size="lg" color="success">ADD NEW PLACE</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol> -->
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'ParkingPlaceCardList',
  props: {
    items: {type: Array, default: []},
    enableProgressHeader: {type: Boolean, default: false},
    enableAddingCard: {type: Boolean, default: false}
  },
  methods: {
    onClick: function(id) {
      this.$emit('view', id);
    },
    onAdd: function() {
      console.log('add');
    },
    getProgressColor: function(rate) {
      if (rate < 40) {
        return 'success';
      }
      if (rate < 70) {
        return 'warning';
      }
      return 'danger';
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/coreui";

.adding-card-wrapper {
  height: 394px;
  border: 0px;
  margin: 2px 2px 22px 2px;
  cursor: pointer;
}
.adding-card-wrapper:hover {
  height: 398px;
  border: 2px solid $info;
  margin: 0px 0px 20px 0px;
}
.clickable-card-wrapper {
  border: 0px;
  margin: 2px 2px 22px 2px;
  cursor: pointer;
}
.clickable-card-wrapper:hover {
  border: 2px solid $info;
  margin: 0px 0px 20px 0px;
}
</style>