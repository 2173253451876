/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_hdr': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 6l9 12H.985l6-8.016 4.5 6 1.594-1.172-2.813-3.797z"/>'
  }
})
