/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_location_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016.984v3h3V6h-3v3H18V6h-3V3.984h3v-3h2.016zm-8.016 12q.844 0 1.43-.586t.586-1.383q0-.844-.586-1.43T12 8.999t-1.43.586-.586 1.43q0 .797.586 1.383t1.43.586zm2.016-9.75v3.75h3v3h2.906q.094.656.094 1.219 0 4.969-8.016 11.813-8.016-6.844-8.016-11.813 0-1.875.656-3.375T6.421 5.25t2.555-1.664T11.999 3q1.031 0 2.016.234z"/>'
  }
})
