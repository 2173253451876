/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pregnant_woman': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 12.984v4.031h-3v4.969h-3v-4.969H8.015V9.984q0-1.219.891-2.109t2.109-.891 2.109.891.891 2.109q.797.328 1.383 1.219t.586 1.781zM9 3.984q0-.844.586-1.406t1.43-.563 1.406.563.563 1.406-.563 1.43T11.016 6t-1.43-.586T9 3.984z"/>'
  }
})
