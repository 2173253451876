/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_quote': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 17.016l1.969-4.031h-3v-6h6v6l-1.969 4.031h-3zm-8.016 0l2.016-4.031h-3v-6h6v6L9 17.016H6z"/>'
  }
})
