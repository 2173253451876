/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chrome_reader_mode': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 18.984V6h-9v12.984h9zm0-15q.797 0 1.406.609t.609 1.406v12.984q0 .797-.609 1.406T21 20.998H3q-.797 0-1.406-.609t-.609-1.406V5.999q0-.797.609-1.406T3 3.984h18zm-8.016 10.5h7.031v1.5h-7.031v-1.5zm0-4.968h7.031v1.5h-7.031v-1.5zm0 2.484h7.031v1.5h-7.031V12z"/>'
  }
})
