/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'padding': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 6.984h2.016V9H15V6.984zm-8.016 0H9V9H6.984V6.984zm4.032 0h1.969V9h-1.969V6.984zm7.968 12V5.015H5.015v13.969h13.969zM3 3h18v18H3V3z"/>'
  }
})
