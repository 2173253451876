/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hot_tub': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.672 5.859q1.547 1.5 1.266 3.703l-.047.422h-1.922l.141-.563q.234-1.266-.75-2.25-1.594-1.594-1.313-3.75l.047-.422h1.922l-.094.609q-.234 1.266.656 2.203zm3.984 0q1.594 1.547 1.313 3.703l-.094.422H18l.094-.563q.234-1.266-.656-2.203l-.094-.047q-1.594-1.594-1.313-3.75l.094-.422H18l-.094.609q-.234 1.266.656 2.203zm.328 14.157v-6h-1.969v6h1.969zm-3.984 0v-6h-2.016v6H15zm-3.984 0v-6H9v6h2.016zm-4.032 0v-6H5.015v6h1.969zM11.156 12h10.828v8.016q0 .797-.586 1.383t-1.383.586H3.984q-.797 0-1.383-.586t-.586-1.383V12h3v-.75q0-.938.68-1.594T7.265 9q.938 0 1.688.75l1.359 1.547q.188.234.844.703zm-6.14-6q0-.844.563-1.43t1.406-.586 1.43.586T9.001 6t-.586 1.43-1.43.586-1.406-.586T5.016 6z"/>'
  }
})
