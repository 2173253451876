/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'car_repair': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 17.016h16.031v1.969h-7.031v3h-1.969v-3H3.984v-1.969zM17.25 3.609q.141.281 1.734 5.063v6.516q0 .797-.75.797h-.469q-.75 0-.75-.797v-1.172H6.984v1.172q0 .797-.75.797h-.469q-.75 0-.75-.797V8.672q1.359-4.031 1.547-4.688 0-.047.094-.164t.094-.211l.047-.047q.188-.234.422-.375t.375-.188h8.672q.563.047.938.563.047 0 .047.047zm-9.656.375L6.281 8.015h11.438L16.36 3.984H7.594zm-1.031 6.797q0 .516.352.867t.867.352.867-.352.352-.867-.352-.867-.867-.352-.867.352-.352.867zM16.219 12q.516 0 .867-.352t.352-.867-.352-.867-.867-.352-.867.352-.352.867.352.867.867.352z"/>'
  }
})
