<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="10">
            <div class="filter_row">
              <div class="filter_label d-md-down-none">Date Range</div>
              <el-date-picker v-if="PERIOD === 'daily'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="date" :clearable="false" />
              <el-date-picker v-else-if="PERIOD === 'weekly'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="week" :format="$t('cloud.title.time_fmt_weekly')" :clearable="false" />
              <el-date-picker v-else-if="PERIOD === 'monthly'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="month" :format="$t('cloud.title.time_fmt_monthly')" :clearable="false" />
              <el-date-picker
                v-else-if="PERIOD === 'custom'"
                class="ml-4 my-1"
                v-model="filter_dates"
                type="datetimerange"
                range-separator="-"
                :start-placeholder="$t('cloud.title.range_start_date')"
                :end-placeholder="$t('cloud.title.range_end_date')"
                :default-time="['00:00:00', '23:59:59']"
              />
              <div class="d-md-down-none">
                <CButton class="ml-2 my-1" color="primary" @click="onQuery">Query</CButton>
                <CButton class="ml-2 my-1" color="secondary" @click="onReset">Reset</CButton>
              </div>
            </div>
            <div class="filter_row">
              <div class="filter_label d-md-down-none">Parking Lot</div>
              <el-select class="ml-4 my-1 filter_input_size" v-model="filter_branch" placeholder="All Parking Lots" filterable clearable>
                <el-option-group
                  v-for="group in regions"
                  :key="`regions-${group.id}`"
                  :label="group.name">
                  <el-option
                    v-for="option in group.options"
                    :key="`branch-${option.id}`"
                    :label="option.name"
                    :value="option.id">
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
            <div class="filter_row d-lg-none">
              <div class="filter_label d-md-down-none border-0"></div>
              <CButton class="ml-4 my-1" color="primary" @click="onQuery">Query</CButton>
              <CButton class="ml-2 my-1" color="secondary" @click="onReset">Reset</CButton>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'ParkingStatFilter',
  props: {
    PERIOD: {type: String, default: 'daily'},
    BRANCH_OPTS: {type: Array, default: () => {return [];}}
  },

  data() {
    return {
      filter_dates: null,
      filter_date: null,
      filter_branch: null,

      payload: {}
    }
  },
  mounted() {
    this.filter_date = new Date();
    this.filter_dates = [new Date(), new Date()];
    this.filter_dates[0].setHours(0);
    this.filter_dates[0].setMinutes(0);
    this.filter_dates[0].setSeconds(0);
    this.filter_dates[0].setMilliseconds(0);
    this.filter_dates[1].setHours(23);
    this.filter_dates[1].setMinutes(59);
    this.filter_dates[1].setSeconds(59);
    this.filter_dates[1].setMilliseconds(999);
    this.onQuery();
  },
  computed: {
    regions() {
      let rtn = [];
      for (const branch of this.BRANCH_OPTS) {
        let rtn_region = rtn.find(el => el.name === branch.region_name);
        if (!rtn_region) {
          rtn.push(
            {
              name: branch.region_name,
              id: branch.region,
              options: [
                {name: branch.name, id: branch.id}
              ]
            }
          )
        } else {
          rtn_region.options.push({name: branch.name, id: branch.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    }
  },
  methods: {
    onQuery() {
      let payload = {
        dates: this.filter_dates,
        date: this.filter_date,
        branch: this.filter_branch
      };
      this.$emit('query', payload);
    },
    onReset() {
      this.filter_date = new Date();
      this.filter_dates = [new Date(), new Date()];
      this.filter_dates[0].setHours(0);
      this.filter_dates[0].setMinutes(0);
      this.filter_dates[0].setSeconds(0);
      this.filter_dates[0].setMilliseconds(0);
      this.filter_dates[1].setHours(23);
      this.filter_dates[1].setMinutes(59);
      this.filter_dates[1].setSeconds(59);
      this.filter_dates[1].setMilliseconds(999);
      this.onQuery();
    }
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size {
  width: 250px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>
