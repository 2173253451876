/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drafts': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12.984l8.25-5.156L12 3 3.75 7.828zm9.984-4.968V18q0 .797-.586 1.406t-1.383.609H3.984q-.797 0-1.383-.609T2.015 18V8.016q0-1.172.938-1.734L12 .985l9.047 5.297q.938.563.938 1.734z"/>'
  }
})
