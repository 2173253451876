/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tapas': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 9.984v-9h-7.969v9q0 1.406.844 2.461t2.156 1.43V21h-2.016v2.016h6V21h-2.016v-7.125q1.313-.375 2.156-1.43t.844-2.461zM20.016 3v3h-4.031V3h4.031zm-7.5 8.484q0 1.031-.75 1.781t-1.781.75H8.016v9H6v-9H3.984q-1.031 0-1.758-.75t-.727-1.781.727-1.758 1.758-.727H6v-.984H3.984q-1.031 0-1.758-.75t-.727-1.781.727-1.758 1.758-.727H6V.983h2.016v2.016h1.969q1.031 0 1.781.727t.75 1.758-.75 1.781-1.781.75H8.016v.984h1.969q1.031 0 1.781.727t.75 1.758z"/>'
  }
})
