/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brightness_4': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18q2.484 0 4.242-1.758T18 12t-1.758-4.242T12 6q-1.219 0-2.484.563 1.547.703 2.508 2.18t.961 3.258-.961 3.258-2.508 2.18q1.266.563 2.484.563zm8.016-9.328L23.297 12l-3.281 3.328v4.688h-4.688L12 23.297l-3.328-3.281H3.984v-4.688L.703 12l3.281-3.328V3.984h4.688L12 .703l3.328 3.281h4.688v4.688z"/>'
  }
})
