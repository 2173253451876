/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'd_rotation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0q4.688 0 8.133 3.164t3.82 7.805h-1.5q-.234-2.766-1.852-5.016t-4.102-3.422L15.14 3.844 11.343.047zm4.547 11.813q0-2.672-2.203-2.672h-.938v5.766h.891q1.688 0 2.156-1.594.094-.328.094-1.125v-.375zm-2.203-3.797q2.484 0 3.375 2.203.234.563.234 1.594v.375q0 1.781-.984 2.766-1.031 1.031-2.672 1.031H12V8.016h2.344zm-4.641 3.89q1.313.516 1.313 1.828 0 .469-.234.938-.281.563-.516.75-.703.563-1.875.563-1.125 0-1.828-.563t-.703-1.641h1.266q0 .516.352.844t.914.328q1.313 0 1.313-1.266t-1.453-1.266h-.75V11.39h.75q1.359 0 1.359-1.172T8.392 9.046q-1.172 0-1.172 1.078H5.907q0-.797.703-1.5.75-.609 1.781-.609 1.125 0 1.805.563t.68 1.641q0 1.172-1.172 1.688zM7.5 21.469l1.359-1.313 3.797 3.797L12 24q-4.688 0-8.133-3.188t-3.82-7.828h1.5q.234 2.484 1.969 4.969T7.5 21.469z"/>'
  }
})
