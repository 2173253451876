/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'double_arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.484 5.016L13.5 12l-5.016 6.984h-4.5L9 12 3.984 5.016h4.5zm7.032 0L20.485 12l-4.969 6.984h-4.5L15.985 12l-4.969-6.984h4.5z"/>'
  }
})
