/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_objects': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.516 11.391l1.828-1.781-.703-.75L12 10.501 10.359 8.86l-.703.75 1.828 1.781v2.625h1.031v-2.625zm1.5 5.625v-1.031H9.985v1.031h4.031zm0 1.968V18H9.985v.984h4.031zM12 3q2.906 0 4.945 2.039t2.039 4.945v.047q0 1.313-.68 2.836t-1.664 2.367q-.656.563-.656 1.453v2.297q0 .797-.586 1.406t-1.383.609h-.281q-.563.984-1.734.984t-1.734-.984h-.281q-.797 0-1.383-.609t-.586-1.406v-2.297q0-.891-.656-1.453-2.953-2.625-2.25-6.563.375-2.063 1.898-3.609t3.586-1.922q.703-.141 1.406-.141z"/>'
  }
})
