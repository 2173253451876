/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15.984q-.797 0-1.406-.586t-.609-1.383q0-.844.609-1.453l8.484-5.625-5.672 8.484q-.563.563-1.406.563zm8.391-7.406q.656 1.031 1.125 2.625t.469 2.813q0 2.766-1.313 4.969-.609 1.031-1.734 1.031H5.063q-1.125 0-1.734-1.031-1.313-2.203-1.313-4.969 0-4.125 2.93-7.055t7.102-2.93q1.219 0 2.789.469t2.602 1.125l-1.875 1.219Q13.876 6 12.001 6 8.72 6 6.353 8.344t-2.367 5.672q0 2.156 1.078 3.984h13.875q1.078-1.828 1.078-3.984 0-1.922-.844-3.609z"/>'
  }
})
