/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_inner': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 17.016V15H21v2.016h-2.016zm0 3.984v-2.016H21V21h-2.016zm-6-18v8.016H21v1.969h-8.016v8.016h-1.969v-8.016H2.999v-1.969h8.016V3h1.969zM15 21v-2.016h2.016V21H15zm3.984-18H21v2.016h-2.016V3zm0 6V6.984H21V9h-2.016zm-1.968-6v2.016H15V3h2.016zm-12 0v2.016H3V3h2.016zM9 3v2.016H6.984V3H9zM3 17.016V15h2.016v2.016H3zM5.016 6.984V9H3V6.984h2.016zM6.984 21v-2.016H9V21H6.984zM3 21v-2.016h2.016V21H3z"/>'
  }
})
