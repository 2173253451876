/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'volunteer_activism': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 17.016q.844 0 1.43.586t.586 1.383l-8.016 3-7.031-1.969v-9h1.969l7.266 2.672q.797.328.797 1.125 0 .469-.352.82t-.867.352h-2.813l-1.734-.656-.328.938 2.063.75h7.031zM15.984 3.234q1.078-1.219 2.719-1.219 1.359 0 2.32.961t.961 2.32q0 1.031-.984 2.461t-1.969 2.391-3.047 2.836q-2.063-1.875-3.047-2.836t-1.969-2.391-.984-2.461q0-1.359.984-2.32t2.344-.961q1.594 0 2.672 1.219zm-15 7.782h4.031v10.969H.984V11.016z"/>'
  }
})
