/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'storefront': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 18.984h14.016v-6h-.234q-1.406 0-2.25-.938-.938.938-2.25.938-1.266 0-2.297-.938-.938.938-2.203.938-1.406 0-2.344-.938-.844.938-2.203.938h-.234v6zm-.985-9.609q-.141.656.281 1.172.328.469.938.469.984 0 1.172-1.172l.609-4.828H5.062zm4.453.281q0 .516.352.938t.867.422q.563 0 .938-.398t.375-.914V5.016H9.047l-.563 4.5v.141zm4.5-4.64v4.688q0 .516.375.914t.844.398q.609 0 1.008-.445t.305-1.055l-.563-4.5h-1.969zm5.907-.047l-1.922.047.609 4.828q.047.469.375.82t.797.352q.563 0 .938-.469.422-.516.281-1.172zm3 3.937q.375 1.641-.609 2.859l-.281.281v6.938q0 .797-.609 1.406t-1.406.609H5.017q-.797 0-1.406-.609t-.609-1.406v-6.938l-.281-.281q-.984-1.219-.609-2.859L3.143 4.5q.141-.656.703-1.078T5.065 3H18.94q.656 0 1.195.422t.727 1.078z"/>'
  }
})
