/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'beach_access': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.391 8.813q-2.344-2.344-5.414-3.094T6 5.953q2.25-.281 4.945.867t4.992 3.445l-5.719 5.719q-2.297-2.297-3.445-4.992t-.867-4.945q-.984 2.906-.234 5.977t3.094 5.414l-2.859 2.859q-2.953-2.953-2.953-7.148t2.953-7.148q0-.047.047-.047 3.281-3.281 7.875-2.906 3.797.281 6.422 2.906zm-4.313 5.765l1.453-1.453 6.422 6.469L19.5 21z"/>'
  }
})
