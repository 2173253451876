/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_rotation_angleup': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.578 9.328v4.219l-1.406-1.406L10.313 21l-1.406-1.406 8.813-8.859-1.406-1.406h4.266zM7.594 11.016l2.625-2.625-4.875-2.25zM4.5 4.219l11.109 4.406-1.453 1.5-2.203-.938L8.39 12.75l.938 2.156-1.5 1.5L3.422 5.25z"/>'
  }
})
