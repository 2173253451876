/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder_open': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 18V8.016H3.985V18h16.031zm0-12q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h6L12.001 6h8.016z"/>'
  }
})
