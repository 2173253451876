/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'waves': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 8.109q1.359 0 2.953.797 1.125.563 2.016.563v1.969q-1.359 0-2.953-.797-1.219-.609-2.016-.609-.844 0-2.063.609-1.594.797-2.953.797t-2.953-.797q-1.219-.609-2.063-.609-.797 0-2.016.609-1.594.797-2.953.797V9.469q.891 0 2.016-.563 1.594-.797 2.953-.797t2.953.797q1.125.563 2.063.563t2.063-.563q1.594-.797 2.953-.797zm2.953-3.656q1.219.609 2.016.609v1.922q-1.359 0-2.953-.797-1.219-.609-2.016-.609-.844 0-2.063.609-1.594.797-2.953.797t-2.953-.797q-1.219-.609-2.063-.609-.797 0-2.016.609-1.594.797-2.953.797V5.062q.797 0 2.016-.609 1.594-.797 2.953-.797t2.953.797q1.219.609 2.063.609t2.063-.609q1.594-.797 2.953-.797t2.953.797zm-2.953 8.11q1.359 0 2.953.797 1.125.563 2.016.563v1.969q-1.359 0-2.953-.797-1.219-.609-2.016-.609-.844 0-2.063.609-1.594.797-2.953.797t-2.953-.797q-1.219-.609-2.063-.609-.797 0-2.016.609-1.594.797-2.953.797v-1.969q.891 0 2.016-.563 1.594-.797 2.953-.797t2.953.797q1.125.563 2.063.563t2.063-.563q1.594-.797 2.953-.797zm0 4.406q1.266 0 2.953.844 1.125.563 2.016.563v1.969q-1.359 0-2.953-.797-1.219-.609-2.016-.609-.844 0-2.063.609-1.594.797-2.953.797t-2.953-.797q-1.219-.609-2.063-.609-.797 0-2.016.609-1.594.797-2.953.797v-1.969q.891 0 2.016-.563 1.688-.844 2.953-.844t2.953.844q1.125.563 2.063.563t2.063-.563q1.688-.844 2.953-.844z"/>'
  }
})
