/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 9V6.984h1.969V9h-1.969zm0-3.984V3h1.969v2.016h-1.969zm0 7.968v-1.969h1.969v1.969h-1.969zM15 5.016V3h2.016v2.016H15zM15 21v-2.016h2.016V21H15zm3.984-18H21v18h-2.016V3zM15 12.984v-1.969h2.016v1.969H15zm-3.984 4.032V15h1.969v2.016h-1.969zM3 9V6.984h2.016V9H3zm0 8.016V15h2.016v2.016H3zm0-4.032v-1.969h2.016v1.969H3zM11.016 21v-2.016h1.969V21h-1.969zM3 21v-2.016h2.016V21H3zm3.984-8.016v-1.969H9v1.969H6.984zm0-7.968V3H9v2.016H6.984zM3 5.016V3h2.016v2.016H3zM6.984 21v-2.016H9V21H6.984z"/>'
  }
})
