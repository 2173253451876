/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'track_changes': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.078 4.922Q21.984 7.828 21.984 12q0 4.125-2.93 7.055t-7.055 2.93-7.055-2.93T2.014 12t2.93-7.055 7.055-2.93h.984v8.25q1.031.563 1.031 1.734 0 .797-.609 1.406t-1.406.609-1.406-.609-.609-1.406q0-1.172 1.031-1.734V8.156q-1.313.375-2.156 1.406T8.015 12q0 1.641 1.172 2.813T12 15.985t2.813-1.172T15.985 12q0-1.547-1.172-2.813l1.406-1.406Q18 9.562 18 12q0 2.484-1.758 4.242T12 18t-4.242-1.758T6 12q0-2.203 1.43-3.867t3.586-2.039V4.078q-2.953.375-4.992 2.625T3.985 12q0 3.281 2.367 5.648T12 20.015t5.648-2.367T20.015 12q0-1.359-.68-3.023t-1.664-2.648z"/>'
  }
})
