/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'done_all': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.422 13.406L1.828 12l5.578 5.578L6 18.984zm21.797-7.828l1.453 1.406-12 12-5.625-5.578L7.5 12l4.172 4.172zM18 6.984l-6.328 6.375-1.406-1.406 6.328-6.375z"/>'
  }
})
