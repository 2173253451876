/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'repeat_one_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 15V9H12l-2.016.984v1.031h1.5v3.984h1.5zm4.032 2.016H6.985v-3L3.001 18l3.984 3.984v-3h12v-6h-1.969v4.031zM6.984 6.984h10.031v3L20.999 6l-3.984-3.984v3h-12v6h1.969V6.985zM21 .984q.797 0 1.406.609t.609 1.406v18q0 .797-.609 1.406T21 23.014H3q-.797 0-1.406-.609t-.609-1.406v-18q0-.797.609-1.406T3 .984h18z"/>'
  }
})
