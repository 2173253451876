/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_underlined': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 18.984h13.969V21H5.016v-2.016zM12 17.016q-2.484 0-4.242-1.758T6 11.016V3h2.484v8.016q0 1.453 1.031 2.461t2.484 1.008 2.484-1.008 1.031-2.461V3h2.484v8.016q0 2.484-1.758 4.242t-4.242 1.758z"/>'
  }
})
