/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'horizontal_distribute': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 21.984H2.015V2.015h1.969v19.969zm18-19.968h-1.969v19.969h1.969V2.016zM13.5 6.984h-3v10.031h3V6.984z"/>'
  }
})
