/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'preview': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zm0 15.984H5.015v-12h13.969v12zm-5.484-6q0 .656-.445 1.078T12 14.484t-1.055-.422-.445-1.078q0-.609.445-1.055T12 11.484t1.055.445.445 1.055zM12 9q-1.359 0-2.555.516t-2.086 1.406T6 12.985q.469 1.172 1.359 2.086t2.086 1.43 2.555.516 2.555-.516 2.086-1.43T18 12.985q-.469-1.172-1.359-2.063t-2.086-1.406T12 9zm0 6.516q-1.031 0-1.758-.75t-.727-1.781.727-1.758T12 10.5t1.758.727.727 1.758-.727 1.781-1.758.75z"/>'
  }
})
