/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_events': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 8.016V6.985h-1.969v3.844q1.969-.703 1.969-2.813zm-12 2.812V6.984H5.015v1.031q0 2.109 1.969 2.813zm12-5.812q.797 0 1.406.586t.609 1.383v1.031q0 1.875-1.266 3.281t-3.141 1.641q-.422 1.031-1.477 1.875t-2.133 1.078v3.094h4.031v2.016H6.982v-2.016h4.031v-3.094q-1.078-.234-2.133-1.078t-1.477-1.875q-1.875-.234-3.141-1.641T2.996 8.016V6.985q0-.797.609-1.383t1.406-.586H6.98V3h10.031v2.016h1.969z"/>'
  }
})
