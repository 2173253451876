<template>
  <vl-map
    :load-tiles-while-animating="true"
    :load-tiles-while-interacting="true"
    data-projection="EPSG:4326"
    :style="`height: ${mapHeight}px`"
  >
    <vl-view :zoom="zoom" :center="center" :rotation="rotation"></vl-view>
    <!-- <vl-geoloc @update:position="geolocPosition = $event">
      <template slot-scope="geoloc">
        <vl-feature v-if="geoloc.position" id="position-feature">
          <vl-geom-point :coordinates="geoloc.position"></vl-geom-point>
          <vl-style-box>
            <vl-style-icon src="_media/marker.png" :scale="0.4" :anchor="[0.5, 1]"></vl-style-icon>
          </vl-style-box>
        </vl-feature>
      </template>
    </vl-geoloc> -->

    <vl-layer-tile id="osm">
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>

    <!-- <vl-layer-vector>
      <vl-source-vector :features="points"></vl-source-vector>
      <vl-style-box>
        <vl-style-circle :radius="40">
          <vl-style-fill color="rgba(200,200,0,0.7)"></vl-style-fill>
          <vl-style-stroke color="rgba(200,200,0,1)"></vl-style-stroke>
        </vl-style-circle>
      </vl-style-box>
    </vl-layer-vector> -->

    <vl-overlay v-for="(overlay, index) in overlays" :key="`overlay-${index}`" :position="overlay.position" positioning="center-center">
      <!-- <div>
        <el-button-group>
          <el-button type="primary">{{overlay.occupancy}}%</el-button>
          <el-button type="danger">{{overlay.notifications.length}}</el-button>
        </el-button-group>
      </div> -->
      <div :class="calcSize(overlay.space_cnt)">
        <div class="text-center mb-1">
          <el-button-group>
            <el-tooltip :content="overlay.name" placement="top">
              <el-button size="mini" type="success" icon="el-icon-more" @click="$router.push(`/service/parking/places/${overlay.id}`)" />
            </el-tooltip>
            <el-button size="mini" type="danger" icon="el-icon-bell"></el-button>
          </el-button-group>
        </div>
        <CChartPie
          :datasets="[{backgroundColor: ['#DB4B46','#61B883'],data: [overlay.occupied,overlay.space_cnt-overlay.occupied]}]"
          :labels="[`Parked(${overlay.occupied})`, `Free(${overlay.space_cnt-overlay.occupied})`]"
          :options="chartOption"
        />
      </div>
    </vl-overlay>
  </vl-map>
</template>

<script>
import { CChartPie } from '@coreui/vue-chartjs'

export default {
  name: 'Map',

  props: {
    overlays: {type:Array},
    rotation: {type:Number, default: 0},
    zoom: {type:Number, default: 5},
    mapHeight: {type:Number, default: 600},
    center: {type:Array, default: () => [30,30]}
  },
  components: {
    CChartPie
  },
  computed: {
    chartOption() {
      return {
        legend: null
      };
    }
  },
  methods: {
    calcSize(space_cnt) {
      if (space_cnt > 50) return 'size-4';
      else if (space_cnt > 40) return 'size-3';
      else if (space_cnt > 15) return 'size-2';
      return 'size-1';
    }
  }
}
</script>

<style lang="scss" scoped>
.size-1 {
  width: 100px;
}
.size-2 {
  width: 150px;
}
.size-3 {
  width: 200px;
}
.size-4 {
  width: 300px;
}
</style>
