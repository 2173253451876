/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brightness_3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T9 21.986q-1.594 0-3-.422 3.094-.938 5.039-3.586t1.945-5.977-1.945-5.977T6 2.438q1.406-.422 3-.422z"/>'
  }
})
