/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'watch_later': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.219 16.219l.797-1.313-4.5-2.719V6.984h-1.5v6zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
