/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grading': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 6.984h16.031V9H3.984V6.984zm0 6h16.031v-1.969H3.984v1.969zm0 4.032h7.031V15H3.984v2.016zm0 3.984h7.031v-2.016H3.984V21zm11.438-2.812l-1.406-1.453-1.406 1.406L15.423 21l4.594-4.594L18.564 15zM3.984 3v2.016h16.031V3H3.984z"/>'
  }
})
