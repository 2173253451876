/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fiber_dvr': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 11.484V10.5q0-.656-.422-1.078T19.5 9h-3.516v6h1.5v-2.016h1.172L19.5 15H21l-.891-2.109q.891-.422.891-1.406zM12.609 15l1.781-6h-1.5l-1.031 3.422L10.875 9h-1.5l1.734 6h1.5zm-4.593-1.5v-3q0-.656-.445-1.078T6.516 9H3v6h3.516q.609 0 1.055-.422t.445-1.078zM21 3q.844 0 1.43.586t.586 1.43v13.969q0 .844-.586 1.43t-1.43.586H3q-.844 0-1.43-.586t-.586-1.43V5.016q0-.844.586-1.43T3 3h18zM4.5 10.5h2.016v3H4.5v-3zm12.984 0H19.5v.984h-2.016V10.5z"/>'
  }
})
