/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'textsms': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 11.016V9H15v2.016h2.016zm-4.032 0V9h-1.969v2.016h1.969zm-3.984 0V9H6.984v2.016H9zm11.016-9q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18H6l-3.984 3.984v-18q0-.797.586-1.383t1.383-.586h16.031z"/>'
  }
})
