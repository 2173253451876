<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="list"
          :fields="FIELDS"
          header-icon="cil-notes"
          :count="count"
          :caption="$t('cloud.title.log')"
          hover
          small
          fixed
          :viewBtn="false"
          :editBtn="false"
        />
      </CCol>
    </CRow>
    <CRow class="justify-content-end" v-if="pageTotal > 1">
      <CPagination
        :activePage.sync="pageCurrent"
        :pages="pageTotal"
        @update:activePage="onUpdatePage"
      >
      </CPagination>
      <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
    </CRow>
  </div>
</template>
<script>
import axios from 'axios';
import qs from 'qs';
import CTableWrapper from '@/components/EdxTable'

export default {
  name : 'EventLogs',
  components : {
    CTableWrapper
  },

  data(){
    return {
      FIELDS: [
        {key: 'cate', label: this.$t('cloud.title.category'), _style:'width:5%;'},
        {key: 'sub_cate', label: this.$t('cloud.title.log_class'), _style:'width:5%;'},
        {key: 'event', label: this.$t('cloud.title.log_event'), _style:'width:30%;'},
        {key: 'name', label: this.$t('cloud.title.log_user'), _style:'width:20%;'},
        {key: 'ip', label: this.$t('cloud.title.log_ip'), _style:'width:10%;'},
        {key: 'created_at', label: this.$t('cloud.title.log_at'), _style:'width:20%;'},
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],
      is_view : false,

    }
  },
  mounted(){
    this.getList();
  },
  methods :{
    getList(){
      let query_string = "";
      const params = {
        search : this.searchText,
        page : this.pageCurrent,
      };
      if (params){
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/eventlogs/${query_string}`)
      .then(result =>{
        this.list = result.data.list;
        this.pageTotal = result.data.page.total;
        this.pageCurrent = result.data.page.current;
        this.count = result.data.page.count;
      })
      .catch(error =>{
        console.error(error);
      })
    },
    // onSearch: function(text) {
    //   this.searchText = text;
    //   this.getList();
    // },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },

  }

}
</script>
