/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.953 6.984l-3.656 3.656-1.406-1.406 3.656-3.656zm-.797 6.516l-1.453-1.453 6.516-6.469 1.406 1.406zM2.25 4.219l1.406-1.406 16.969 16.969-1.406 1.406-4.922-4.875-2.672 2.672-5.578-5.578 1.406-1.406 4.172 4.172 1.266-1.266zM1.781 12l5.578 5.578-1.406 1.406-5.578-5.578z"/>'
  }
})
