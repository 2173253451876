/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cached': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 12h3l-3.984 3.984L.985 12h3q0-3.281 2.367-5.648T12 3.985q2.344 0 4.266 1.266l-1.453 1.453Q13.547 6.001 12 6.001q-2.484 0-4.242 1.758T6 12.001zm12.984-3.984L23.015 12h-3q0 3.281-2.367 5.648T12 20.015q-2.344 0-4.266-1.266l1.453-1.453q1.313.703 2.813.703 2.484 0 4.242-1.758T18 11.999h-3z"/>'
  }
})
