/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sensor_door': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 2.016H6q-.844 0-1.43.586t-.586 1.383v18h16.031v-18q0-.797-.586-1.383t-1.43-.586zM15.516 13.5q-.656 0-1.078-.445T14.016 12t.422-1.055 1.078-.445q.609 0 1.055.445T17.016 12t-.445 1.055-1.055.445z"/>'
  }
})
