import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay, 
  cibCcAmex
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cil3d,
  cilAddressBook,
  cilApplications,
  cilApps,
  cilAppsSettings,
  cilArrowRight,
  cilArrowThickFromBottom,
  cilArrowThickLeft,
  cilArrowThickToBottom,
  cilAvTimer,
  cilBan,
  cilBarChart,
  cilBasket,
  cilBell,
  cilBorderAll,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCalendarCheck,
  cilCarAlt,
  cilCaretBottom,
  cilCaretTop,
  cilCart,
  cilCenterFocus,
  cilChart,
  cilChartLine,
  cilChartPie,
  cilCheck,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCloudDownload,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilDataTransferDown,
  cilDevices,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilFactory,
  cilFile,
  cilGarage,
  cilGlobeAlt,
  cilGrid,
  cilGroup,
  cilJustifyCenter,
  cilLan,
  cilLaptop,
  cilLayers,
  cilLibraryAdd,
  cilLightbulb,
  cilList,
  cilListFilter,
  cilLocationPin,
  cilLockLocked,
  cilLockUnlocked,
  cilMagnifyingGlass,
  cilMap,
  cilMonitor,
  cilMoodBad,
  cilMoodGood,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPencil,
  cilPenNib,
  cilPeople,
  cilPin,
  cilPlus,
  cilPuzzle,
  cilRectangle,
  cilRoom,
  cilRunning,
  cilSave,
  cilSearch,
  cilSettings,
  cilShieldAlt,
  cilSitemap,
  cilSortNumericUp,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilStorage,
  cilTask,
  cilTrash,
  cilTruck,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserX,
  cilVector,
  cilVideo,
  cilViewModule,
  cilViewQuilt,
  cilWalk,
  cilX,
  cilXCircle

} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cil3d,
    cilAddressBook,
    cilApplications,
    cilApps,
    cilAppsSettings,
    cilArrowRight,
    cilArrowThickFromBottom,
    cilArrowThickLeft,
    cilArrowThickToBottom,
    cilAvTimer,
    cilBan,
    cilBarChart,
    cilBasket,
    cilBell,
    cilBorderAll,
    cilBuilding,
    cilCalculator,
    cilCalendar,
    cilCalendarCheck,
    cilCarAlt,
    cilCaretBottom,
    cilCaretTop,
    cilCart,
    cilCenterFocus,
    cilChart,
    cilChartLine,
    cilChartPie,
    cilCheck,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCloudDownload,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilDataTransferDown,
    cilDevices,
    cilDollar,
    cilDrop,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilFactory,
    cilFile,
    cilGarage,
    cilGlobeAlt,
    cilGrid,
    cilGroup,
    cilJustifyCenter,
    cilLan,
    cilLaptop,
    cilLayers,
    cilLibraryAdd,
    cilLightbulb,
    cilList,
    cilListFilter,
    cilLocationPin,
    cilLockLocked,
    cilLockUnlocked,
    cilMagnifyingGlass,
    cilMap,
    cilMonitor,
    cilMoodBad,
    cilMoodGood,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPenNib,
    cilPeople,
    cilPin,
    cilPlus,
    cilPuzzle,
    cilRectangle,
    cilRoom,
    cilRunning,
    cilSave,
    cilSearch,
    cilSettings,
    cilShieldAlt,
    cilSitemap,
    cilSortNumericUp,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilStorage,
    cilTask,
    cilTrash,
    cilTruck,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserX,
    cilVector,
    cilVideo,
    cilViewModule,
    cilViewQuilt,
    cilWalk,
    cilX,
    cilXCircle
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard, 
    cibCcVisa, 
    cibStripe, 
    cibPaypal, 
    cibGooglePay,
    cibCcAmex
  }
)
