<template>
  <CardContainer title="Off-Peak Time" v-if="stats">
    <template slot="view_menu" v-if="false">
      <CButton color="secondary" size="sm" class="d-inline-block float-right ml-2">
        <svgicon name="settings" width="16px" height="16px" />
      </CButton>
    </template>
    <template slot="chart">
      <CChartBar
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'OffPeak',
  components: {
    CardContainer,
    CChartBar
  },
  props: {
    stats: {type: Array}
  },
  computed: {
    // *** datasets configurations ***
    // Name	                    Type         Scriptable      Indexable      Default
    // -----------------------------------------------------------------------------------------
    // backgroundColor          Color        Yes             Yes            'rgba(0, 0, 0, 0.1)'
    // borderAlign              string       Yes             Yes            'center'
    // borderColor              Color        Yes             Yes            '#fff'
    // borderWidth              number       Yes             Yes            2
    // data                     number[]     -               -              required
    // hoverBackgroundColor     Color        Yes             Yes            undefined
    // hoverBorderColor         Color        Yes             Yes            undefined
    // hoverBorderWidth         number       Yes             Yes            undefined
    // weight                   number       -               -              1
    chartData() {
      let rtn = {
        label: 'Free parking spaces',
        backgroundColor: [],
        data: []
      };
      if (this.stats.length <= 0) return [rtn];

      let free_array = this.stats.map(el => {
        return el.free;
      });
      const maxVal = Math.max(...free_array);
      for (let stat of this.stats) {
        let color = '#41B883';
        if (stat.free === maxVal) color = '#CB4B46';
        rtn.backgroundColor.push(color);
        rtn.data.push(stat.free);
      }
      return [rtn];
    },
    chartLabel() {
      if (this.stats.length <= 0) return ['No data'];
      let rtn = [];
      if (this.stats.length === 24) {
        for (let i in this.stats) {
          const created_at = this.stats[i].created_at;
          const d = new Date(created_at);
          const hour = d.getHours();
          const ampm = 'AM';
          if (hour >= 12) ampm = 'PM';
          if (i % 4 === 0) rtn.push(ampm+' '+hour);
          else rtn.push('');
        }
      } else if (this.stats.length === 7) {
        rtn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      } else {
        for (let i in this.stats) {
          if (i === 0 || i % 5 === 4) {
            rtn.push(+i+1);
          } else {
            rtn.push('');
          }
        }
      }
      return rtn;
    },
    chartOption() {
      let free_array = this.stats.map(el => {
        return el.free;
      });
      const maxVal = Math.max(...free_array);
      const scale_max = parseInt(maxVal * 1.2) + (5-(parseInt(maxVal * 1.2) % 5));
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Free'
            },
            stacked: true,
            ticks: {
              beginAtZero: true,
              // maxTicksLimit: 5,
              // stepSize: Math.ceil(250 / 5),
              // max: scale_max
            },
            gridLines: {
              display: true
            }
          }]
        }
      };
    }
  }
}
</script>