/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_applications': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.25 12q0-.469-.047-.703l1.5-1.125q.234-.188.047-.469l-1.406-2.438q-.141-.234-.422-.141l-1.734.703q-.609-.469-1.172-.703l-.281-1.828q-.094-.281-.328-.281h-2.813q-.281 0-.328.281l-.281 1.875q-.75.328-1.172.656l-1.734-.703q-.234-.094-.422.188L5.251 9.703q-.047.047-.047.188 0 .234.094.281l1.5 1.125q-.047.234-.047.703t.047.703l-1.5 1.125q-.094.047-.094.281 0 .141.047.188l1.406 2.438q.141.234.422.141l1.734-.703q.609.469 1.172.703l.281 1.828q.094.281.328.281h2.813q.281 0 .328-.281l.281-1.875q.75-.328 1.172-.656l1.734.703q.234.094.422-.188l1.406-2.391q.188-.281-.047-.469l-1.5-1.125q.047-.234.047-.703zm1.734-9q.844 0 1.43.586T21 5.016v13.969q0 .844-.586 1.43t-1.43.586H5.015q-.844 0-1.43-.586t-.586-1.43V5.016q0-.844.586-1.43T5.015 3h13.969zM12 9.984q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 9.984z"/>'
  }
})
