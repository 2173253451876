/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collections': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 6h1.969v14.016h14.016v1.969H3.985q-.797 0-1.383-.586t-.586-1.383V6zm9 6l-3 3.984h12l-4.031-4.969-2.953 3.703zm10.968 3.984q0 .797-.586 1.406t-1.383.609h-12q-.797 0-1.406-.609T6 15.984v-12q0-.797.609-1.383t1.406-.586h12q.797 0 1.383.586t.586 1.383v12z"/>'
  }
})
