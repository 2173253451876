/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sim_card': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15v-3.984H15V15h2.016zm-4.032-2.016v-1.969h-1.969v1.969h1.969zm0 6V15h-1.969v3.984h1.969zM9 15v-3.984H6.984V15H9zm8.016 3.984v-1.969H15v1.969h2.016zm-8.016 0v-1.969H6.984v1.969H9zm10.969-15l.047 16.031q0 .797-.609 1.383t-1.406.586h-12q-.797 0-1.406-.586t-.609-1.383v-12l6-6h8.016q.797 0 1.383.586t.586 1.383z"/>'
  }
})
