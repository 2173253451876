/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wheelchair_pickup': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.5 3.984q0-.797.586-1.383t1.43-.586q.797 0 1.383.586t.586 1.383q0 .844-.586 1.43T6.516 6q-.844 0-1.43-.586T4.5 3.984zm5.484 6.985V9q0-.844-.586-1.43t-1.383-.586h-3q-.844 0-1.43.586T2.999 9v6h2.016v6.984h3.469v-.094q-.938-.938-1.453-2.203t-.516-2.672q0-1.969.961-3.563t2.508-2.484zm6.516 6.047q0 .797-.398 1.477t-1.078 1.102-1.523.422-1.523-.422-1.078-1.102-.398-1.477q0-.844.422-1.523t1.078-1.055v-2.156q-1.5.469-2.508 1.758t-1.008 2.977q0 1.359.68 2.508t1.828 1.805 2.508.656 2.508-.656 1.828-1.805.68-2.508h-2.016zm3.047-3H15v-6h-2.016v7.969h5.484l2.484 3.703 1.641-1.078z"/>'
  }
})
