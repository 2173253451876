/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delivery_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 17.016q.422 0 .727-.305t.305-.727-.305-.703-.727-.281-.703.281-.281.703.281.727.703.305zm0-4.032q1.219 0 2.109.891t.891 2.109-.891 2.109-2.109.891-2.109-.891-.891-2.109.891-2.109 2.109-.891zM5.016 6h4.969v2.016H5.016V6zm1.968 11.016q.422 0 .727-.305t.305-.727H6q0 .422.281.727t.703.305zm12-10.032v3.375l-4.5 5.625h-4.5q0 1.219-.891 2.109t-2.109.891-2.109-.891-.891-2.109H2.015v-3q0-1.641 1.172-2.813T6 8.999h3.984v5.016H13.5l3.516-4.359V6.984h-3V5.015h3q.797 0 1.383.586t.586 1.383z"/>'
  }
})
