<template>
  <CardContainer title="Recent Entry/Exit" v-if="stats">
    <template slot="chart">
      <CChartBar
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'RecentEntryExit',
  components: {
    CardContainer,
    CChartBar
  },
  props: {
    stats: {type: Array}
  },
  computed: {
    chartData() {
      let entry = {
        label: 'Entry',
        backgroundColor: '#44BB33',
        data: []
      };
      let exit = {
        label: 'Exit',
        backgroundColor: '#BB3344',
        data: []
      }
      if (this.stats.length <= 0) return [entry, exit];
      for (let stat of this.stats) {
        entry.data.push(stat.enter);
        exit.data.push(stat.exit);
      }
      return [entry, exit];
    },
    chartLabel() {
      if (this.stats.length <= 0) return ['No data'];
      let rtn = [];
      if (this.stats.length === 24) {
        for (let i in this.stats) {
          const created_at = this.stats[i].time;
          const d = new Date(created_at);
          const hour = d.getHours();
          const ampm = 'AM';
          if (hour >= 12) ampm = 'PM';
          if (i % 4 === 0) rtn.push(ampm+' '+hour);
          else rtn.push('');
        }
      } else if (this.stats.length === 7) {
        rtn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      } else {
        for (let i in this.stats) {
          if (i === 0 || i % 5 === 4) {
            rtn.push(+i+1);
          } else {
            rtn.push('');
          }
        }
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            stacked: false,
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: false
            },
            stacked: false,
            ticks: {
              beginAtZero: true,
              // maxTicksLimit: 5,
              // stepSize: Math.ceil(250 / 5),
              // max: 250
            },
            gridLines: {
              display: true
            }
          }]
        }
      };
    }
  }
}
</script>