/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'event_available': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18.984V8.015H5.015v10.969h13.969zm0-15.984q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43V5.014q0-.797.586-1.406t1.43-.609h.984V.983h2.016v2.016h7.969V.983H18v2.016h.984zm-2.437 8.063l-5.953 5.953-3.188-3.188 1.078-1.078 2.109 2.109 4.875-4.875z"/>'
  }
})
