/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power_input': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 15v-2.016H21V15h-5.016zM9 15v-2.016h5.016V15H9zm-6.984 0v-2.016h4.969V15H2.016zm0-6H21v2.016H2.016V9z"/>'
  }
})
