/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accessibility_new': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6q-.797 0-1.406-.609t-.609-1.406.609-1.383T12 2.016t1.406.586.609 1.383-.609 1.406T12 6zm8.484 0L21 8.016q-2.344.656-6 .984v12.984h-2.016v-6h-1.969v6H8.999V9q-3.656-.328-6-.984L3.515 6q3.656.984 8.484.984T20.483 6z"/>'
  }
})
