/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_recline_normal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 20.063l-1.453 1.453L15.047 18H9.984q-1.219 0-2.109-.891T6.984 15V9.234q0-.891.68-1.57t1.57-.68h.047q.891 0 1.641.75l1.406 1.547q.797.891 2.133 1.453t2.555.563V13.5q-2.906 0-5.531-2.203V15h3.469zM6 15.984q0 1.219.891 2.109T9 18.984h6V21H9q-2.063 0-3.539-1.477t-1.477-3.539v-9H6v9zM7.594 5.391q-.609-.609-.609-1.406t.609-1.406T9 1.97t1.406.609.609 1.406-.609 1.406T9 6t-1.406-.609z"/>'
  }
})
