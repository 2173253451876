/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vertical_align_center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 11.016h16.031v1.969H3.984v-1.969zm12-6L12 9 8.016 5.016h3V.985h1.969v4.031h3zM8.016 18.984L12 15l3.984 3.984h-3v4.031h-1.969v-4.031h-3z"/>'
  }
})
