/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trending_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 6h6v6l-2.297-2.297-6.281 6.281L9.422 12l-6 6-1.406-1.406 7.406-7.406 3.984 3.984 4.875-4.875z"/>'
  }
})
