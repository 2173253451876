/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'k': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15l-2.25-3L18 9h-1.734l-1.781 2.25V9h-1.5v6h1.5v-2.25L16.266 15H18zm-6-1.5V12h-.984V9h-1.5v3h-1.5V9h-1.5v4.5h3V15h1.5v-1.5H12zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
