/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'handyman': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.656 18.188l-5.297-5.297h-.984l-2.531 2.531v.984l5.297 5.297q.281.281.703.281t.703-.281l2.109-2.109q.328-.281.328-.703t-.328-.703zm-4.312-8.016l1.406-1.406 2.109 2.156q.609-.609.797-1.383t0-1.523-.797-1.359l-3.516-3.516-1.406 1.406V1.688l-.703-.703-3.563 3.563.703.703h2.859l-1.406 1.406 1.031 1.078-2.859 2.859-4.172-4.125V5.063l-3-3-2.813 2.813 3 3.047H6.42l4.125 4.125-.844.844H7.592l-5.297 5.297q-.281.281-.281.703t.281.703l2.109 2.109q.328.281.727.281t.68-.281l5.297-5.297v-2.109l5.156-5.156z"/>'
  }
})
