/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_bold': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 15.516q.656 0 1.078-.445T15 14.016t-.422-1.055-1.078-.445H9.984v3H13.5zm-3.516-9v3h3q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445h-3zm5.625 4.265q2.156.984 2.156 3.422 0 1.594-1.055 2.695T14.062 18H6.984V3.984h6.281q1.688 0 2.836 1.172t1.148 2.859-1.641 2.766z"/>'
  }
})
