/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star_outline1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 9.234l-7.172-.609-2.813-6.609-2.813 6.609-7.172.609 5.438 4.734-1.641 7.031 6.188-3.75 6.188 3.75-1.641-7.031zM12 15.422l-3.75 2.25.984-4.266L5.906 10.5l4.406-.375L12 6.094l1.688 4.031 4.406.375-3.328 2.906.984 4.266z"/>'
  }
})
