/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'movie': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 3.984h3.984V18q0 .797-.586 1.406t-1.383.609H3.984q-.797 0-1.383-.609T2.015 18V6q0-.797.586-1.406t1.383-.609h1.031l1.969 4.031h3L8.015 3.985h1.969L12 8.016h3l-2.016-4.031H15l2.016 4.031h3z"/>'
  }
})
