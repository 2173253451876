/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sd': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.484 13.5v-3H16.5v3h-2.016zm-4.968 0H7.5v-.516H6v1.031q0 .422.281.703t.703.281h3q.422 0 .727-.281t.305-.703v-1.5q0-.422-.305-.727t-.727-.305H7.5v-.984h2.016v.516h1.5V9.984q0-.422-.305-.703T9.984 9h-3q-.422 0-.703.281T6 9.984v1.5q0 .422.281.727t.703.305h2.531v.984zM12.984 9v6h4.031q.422 0 .703-.281t.281-.703V9.985q0-.422-.281-.703t-.703-.281h-4.031zm6-6q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43V5.014q0-.844.586-1.43t1.43-.586h13.969z"/>'
  }
})
