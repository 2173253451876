/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_hospital': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 14.016V9.985h-3.984V6.001H9.985v3.984H6.001v4.031h3.984V18h4.031v-3.984H18zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
