/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'offline_share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15.984v-12H9.984v12H18zm0-15q.797 0 1.406.609t.609 1.406v14.016q0 .797-.609 1.383T18 18.984H9.984q-.797 0-1.383-.586t-.586-1.383V2.999q0-.797.586-1.406T9.984.984H18zm-2.016 22.032H6q-.797 0-1.406-.609t-.609-1.406V5.017h2.016v15.984h9.984v2.016zm-1.406-12.75q-2.344 0-3.563 1.641.656-3.094 3.563-3.516V7.078l2.438 2.25-2.438 2.25v-1.313z"/>'
  }
})
