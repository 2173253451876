/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_cell': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.813 6L5.11 2.297q.234-.563.75-.938T6.985.984l10.031.047q.797 0 1.383.563T18.985 3v13.172l-1.969-2.016V6H8.813zm10.968 16.594l-.891-.891q-.234.563-.75.938t-1.125.375H6.984q-.797 0-1.383-.586T5.015 21V7.828L1.406 4.219l1.406-1.406 18.375 18.375zM15.188 18L6.985 9.844V18h8.203z"/>'
  }
})
