import axios from 'axios'
import store from './store'

export default function () {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL,
    axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.withCredentials = true

  axios.interceptors.request.use(
    async (config) => {
      const NO_TOKEN_SET_API = [
        '/api/auth/token/',
        '/api/auth/token/refresh/',
      ];
      let access_token = localStorage.getItem('access');
      let access_exp = localStorage.getItem('access_exp');
      let remain_msecs = new Date(`${access_exp}Z`) - new Date();
      if (!NO_TOKEN_SET_API.includes(config.url) && access_exp && remain_msecs < 60 * 1000) {
        await store.dispatch('refreshToken');
        access_token = localStorage.getItem('access');
      }
      if (access_token && config.url.startsWith('/api')) {
        config.headers["Authorization"] = `token ${access_token}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && error.response.status) {
        const requestUrl = error.response.config.url;
        if (error.response.status === 401 && requestUrl !== '/api/auth/token/') {
          store.commit('set', ['auth/capability', null]);
          const LOGIN_SERVER_URL = process.env.VUE_APP_LOGIN_BASE_URL;
          let url = `${LOGIN_SERVER_URL}/#/pages/login`;
          url = url + '?redirect=' + window.btoa(window.location.hash.replace('#', ''));
          window.location.href = url;
        }
      }
      return Promise.reject(error);
    }
  );
}
