/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'forward_5': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.719 13.594q-.328.141-.328.188l-.094.141h-.609l.234-2.203h2.391v.703h-1.734l-.094.891q.094 0 .094-.094 0-.047.07-.07t.07-.07h.375q.375 0 .516.141.047.047.188.141t.188.141q.422.422.422 1.078 0 .422-.094.516-.047.047-.141.234t-.188.281q-.375.375-1.078.375-.422 0-.516-.094-.047-.047-.211-.094t-.258-.094q-.422-.234-.422-.891h.797q0 .469.609.469.188 0 .281-.094l.234-.188q.094-.188.094-.281v-.609l-.094-.188-.234-.234q-.188-.094-.281-.094h-.188zm-7.735-.61q0-3.281 2.344-5.625T12 5.015V.984L17.016 6 12 11.016V6.985q-2.438 0-4.219 1.758T6 12.985t1.781 4.242T12 18.985t4.219-1.758T18 12.985h2.016q0 3.328-2.367 5.672t-5.648 2.344-5.648-2.344-2.367-5.672z"/>'
  }
})
