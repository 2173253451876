/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'broken_image': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11.438l3 3v4.547q0 .797-.609 1.406T18.985 21H5.016q-.797 0-1.406-.609t-.609-1.406v-6.563l3 3 3.984-4.031 4.031 4.031zm3-6.422v6.563l-3-3-3.984 4.031-4.031-4.031-3.984 4.031-3-3.047V5.016q0-.797.609-1.406t1.406-.609h13.969q.797 0 1.406.609T21 5.016z"/>'
  }
})
