/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airport_shuttle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 11.016h5.016l-4.031-4.031h-.984v4.031zm2.484 6.468q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm-4.5-6.468V6.985H9v4.031h3.984zM6 17.484q.656 0 1.078-.445t.422-1.055-.422-1.055T6 14.484t-1.078.445-.422 1.055.422 1.055T6 17.484zm-3-6.468h3.984V6.985H3v4.031zm14.016-6l6 6v4.969h-2.531q0 1.219-.891 2.109t-2.109.891-2.109-.891-.891-2.109H9.001q0 1.219-.891 2.109t-2.109.891-2.109-.891-.891-2.109H.985v-9q0-.844.586-1.406t1.43-.563h14.016z"/>'
  }
})
