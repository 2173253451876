/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_color_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 20.016h24V24H0v-3.984zm18.984-8.532Q21 13.687 21 15q0 .797-.609 1.406t-1.406.609-1.383-.609T17.016 15q0-.563.492-1.453t.961-1.453zm-13.781-1.5h9.609L9.984 5.203zm11.344-1.031q.469.469.469 1.078t-.469 1.031l-5.484 5.484q-.469.469-1.078.469-.563 0-1.031-.469l-5.531-5.484q-.422-.422-.422-1.078 0-.609.422-1.031l5.156-5.156-2.391-2.391L7.641 0z"/>'
  }
})
