/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigate_before': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.422 7.406L10.828 12l4.594 4.594L14.016 18l-6-6 6-6z"/>'
  }
})
