/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'architecture': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.375 18.797L6.609 21l1.641-1.547 2.766-7.594q-1.031-.234-1.781-.984zm8.391-7.922q-.75.75-1.781.984l2.766 7.594L17.392 21l.281-2.203zM15 8.016q0-.984-.563-1.758t-1.453-1.055V3h-1.969v2.203q-.891.281-1.453 1.055t-.563 1.758q0 .797.398 1.477t1.078 1.102 1.523.422 1.523-.422 1.078-1.102.398-1.477zM12 9q-.422 0-.703-.305t-.281-.68q0-.422.281-.727T12 6.983t.703.305.281.727q0 .375-.281.68T12 9z"/>'
  }
})
