/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bedtime': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.328 2.016q-2.156-.094-4.031.68T5.016 4.852 2.813 8.063t-.797 3.938q0 2.063.773 3.867t2.156 3.188 3.188 2.156 3.867.773q1.875 0 3.516-.633t2.953-1.758 2.203-2.625q-2.25-.047-4.102-.984t-3.164-2.484-1.945-3.469-.445-3.984 1.313-4.031z"/>'
  }
})
