/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'theater_comedy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 8.063q.422 0 .727-.281t.305-.703-.305-.703-.727-.281-.703.281-.281.703.281.703.703.281zm-4.968 0q.422 0 .703-.281T15 7.079t-.281-.703-.703-.281-.727.281-.305.703.305.703.727.281zm-3-5.063h10.969v6.516q0 2.297-1.594 3.891T16.5 15.001q-1.266 0-2.484-.609v-2.906h4.969q0-.609-.727-1.055T16.5 9.986t-1.758.445-.727 1.055V9.002h-3v-6zm-6 9.984q-.422 0-.727.305t-.305.727.305.703.727.281.703-.281.281-.703-.281-.727-.703-.305zm4.968 0q-.422 0-.703.305T9 14.016t.281.703.703.281.727-.281.305-.703-.305-.727-.727-.305zM7.5 18.516q1.031 0 1.758-.445t.727-1.055H5.016q0 .609.727 1.055t1.758.445zM2.016 16.5V9.984h10.969V16.5q0 2.297-1.594 3.891T7.5 21.985t-3.891-1.594T2.015 16.5z"/>'
  }
})
