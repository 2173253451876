<template>
  <div>
    <CCard>
      <CCardBody>
        <CButton v-if="collapse" @click="onFold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_up" width="18px" height="18px" />
        </CButton>
        <CButton v-else @click="onUnfold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_down" width="18px" height="18px" />
        </CButton>
        <CRow>
          <CCol md="12" lg="10">
            <div class="filter_row">
              <div class="filter_label d-md-down-none">{{ $t('cloud.title.time_range') }}</div>
              <el-date-picker v-if="PERIOD === 'daily'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="date" :clearable="false" />
              <el-date-picker v-else-if="PERIOD === 'weekly'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="week" :format="$t('cloud.title.time_fmt_weekly')" :clearable="false" />
              <el-date-picker v-else-if="PERIOD === 'monthly'" class="ml-4 my-1 filter_input_size" v-model="filter_date" type="month" :format="$t('cloud.title.time_fmt_monthly')" :clearable="false" />
              <el-date-picker
                v-else-if="PERIOD === 'custom'"
                class="ml-4 my-1"
                v-model="filter_dates"
                type="datetimerange"
                range-separator="-"
                :start-placeholder="$t('cloud.title.range_start_date')"
                :end-placeholder="$t('cloud.title.range_end_date')"
                :default-time="['00:00:00', '23:59:59']"
              />
              <div class="d-md-down-none">
                <CButton class="ml-2 my-1" color="primary" @click="onQuery">{{$t('button.query')}}</CButton>
                <CButton class="ml-2 my-1" color="secondary" @click="onReset">{{$t('button.reset')}}</CButton>
              </div>
            </div>
            <div class="filter_row" v-if="myAuthorizationLevel.level < 100">
              <div class="filter_label d-md-down-none">{{$t('cloud.title.filter_factory')}}</div>
              <el-select class="ml-4 my-1 filter_input_size" v-model="filter_branch" :placeholder="$t('cloud.title.all_factory')" filterable clearable @change="onFilterBranch">
                <el-option-group
                  v-for="group in regions"
                  :key="`regions-${group.id}`"
                  :label="group.name">
                  <el-option
                    v-for="option in group.options"
                    :key="`factory-${option.id}`"
                    :label="option.name"
                    :value="option.id">
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
            <CCollapse :show="collapse">
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.filter_vendor')}}</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_correspondent" :placeholder="$t('cloud.title.all_vendor')" filterable clearable @change="onFilterCorrespondent">
                  <el-option-group
                    v-for="group in branches"
                    :key="`branches-${group.id}`"
                    :label="group.name">
                    <el-option
                      v-for="option in group.options"
                      :key="`correspondents-opts-${option.id}`"
                      :label="option.name"
                      :value="option.id">
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.filter_origin')}}</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_origin" :placeholder="$t('cloud.title.all_origin')" filterable clearable>
                  <el-option-group
                    v-for="group in origins"
                    :key="`correspondents-${group.id}`"
                    :label="group.name">
                    <el-option
                      v-for="option in group.options"
                      :key="`origins-opts-${option.id}`"
                      :label="option.name"
                      :value="option.name">
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.filter_freight')}}</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_freight" :placeholder="$t('cloud.title.all_freight')" filterable clearable>
                  <el-option
                    v-for="option in FREIGHT_OPTS"
                    :key="`freights-opts-${option.id}`"
                    :label="option.name"
                    :value="option.id">
                  </el-option>
                </el-select>
              </div>
            </CCollapse>
            <div class="filter_row d-lg-none">
              <div class="filter_label d-md-down-none border-0"></div>
              <CButton class="ml-4 my-1" color="primary" @click="onQuery">{{$t('button.query')}}</CButton>
              <CButton class="ml-2 my-1" color="secondary" @click="onReset">{{$t('button.reset')}}</CButton>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryStatFilter',
  props: {
    PERIOD: {type: String, default: 'daily'},
    FACTORY_OPTS: {type: Array, default: () => {return [];}},
    CORRESPONDENT_OPTS: {type: Array, default: () => {return [];}},
    FREIGHT_OPTS: {type: Array, default: () => {return [];}}
  },

  data() {
    return {
      filter_dates: [],
      filter_date: null,
      filter_branch: null,
      filter_correspondent: null,
      filter_origin: null,
      filter_freight: null,

      payload: {},
      collapse : false
    }
  },
  mounted() {
    this.filter_date = new Date();
    this.filter_dates = [new Date(), new Date()];
    this.filter_dates[0].setHours(0);
    this.filter_dates[0].setMinutes(0);
    this.filter_dates[0].setSeconds(0);
    this.filter_dates[0].setMilliseconds(0);
    this.filter_dates[1].setHours(23);
    this.filter_dates[1].setMinutes(59);
    this.filter_dates[1].setSeconds(59);
    this.filter_dates[1].setMilliseconds(999);
    this.onQuery();
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    regions() {
      let rtn = [];
      for (const factory of this.FACTORY_OPTS) {
        let rtn_region = rtn.find(el => el.name === factory.region_name);
        if (!rtn_region) {
          rtn.push(
            {
              name: factory.region_name,
              id: factory.region,
              options: [
                {name: factory.name, id: factory.id}
              ]
            }
          )
        } else {
          rtn_region.options.push({name: factory.name, id: factory.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    },
    branches() {
      let rtn = [];
      let corrs = this.CORRESPONDENT_OPTS;
      if (this.filter_branch) {
        corrs = corrs.filter(el => el.branch_id === this.filter_branch);
      }
      for (const correspondent of corrs) {
        let rtn_branch = rtn.find(el => el.name === correspondent.branch_name);
        if (!rtn_branch) {
          rtn.push(
            {
              name: correspondent.branch_name,
              id: correspondent.branch_id,
              options: [
                {name: correspondent.name, id: correspondent.id}
              ]
            }
          )
        } else {
          rtn_branch.options.push({name: correspondent.name, id: correspondent.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    },
    origins() {
      let rtn = [];
      let corrs = this.CORRESPONDENT_OPTS;
      if (this.filter_branch) {
        corrs = corrs.filter(el => el.branch_id === this.filter_branch);
      }
      for (const correspondent of corrs) {
        let rtn_corrs = rtn.find(el => el.name === correspondent.name);
        if (!rtn_corrs) {
          rtn.push(
            {
              name: correspondent.name,
              id: correspondent.id,
              options: correspondent.origins
            }
          )
        }
      }
      return rtn;
    }
  },
  methods: {
    onFilterBranch() {
      this.filter_correspondent = null;
      this.filter_origin = null;
    },
    onFilterCorrespondent() {
      this.filter_origin = null;
    },
    onQuery() {
      console.log(this.filter_date);
      let payload = {
        dates: this.filter_dates,
        date: this.filter_date,
        branch: this.filter_branch,
        correspondent: this.filter_correspondent,
        origin: this.filter_origin,
        freight: this.filter_freight
      };
      this.$emit('query', payload);
    },
    onReset() {
      this.filter_date = new Date();
      this.filter_dates = [new Date(), new Date()];
      this.filter_dates[0].setHours(0);
      this.filter_dates[0].setMinutes(0);
      this.filter_dates[0].setSeconds(0);
      this.filter_dates[0].setMilliseconds(0);
      this.filter_dates[1].setHours(23);
      this.filter_dates[1].setMinutes(59);
      this.filter_dates[1].setSeconds(59);
      this.filter_dates[1].setMilliseconds(999);
      this.filter_branch = null;
      this.filter_correspondent = null;
      this.filter_origin = null;
      this.filter_freight = null;
      this.onQuery();
    },
    onFold() {
      this.collapse = false;
    },
    onUnfold() {
      this.collapse = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size {
  width: 250px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>
