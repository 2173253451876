/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_bluetooth': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.859 14.297l-1.875-1.875v3.75zM12.984 3.844v3.75l1.875-1.875zm4.735 1.875l-4.313 4.266 4.313 4.313L12 20.017h-.984v-7.594l-4.594 4.594-1.406-1.406 5.578-5.625-5.578-5.578 1.406-1.406 4.594 4.594V.002H12zM15 24v-2.016h2.016V24H15zm-8.016 0v-2.016H9V24H6.984zm4.032 0v-2.016h1.969V24h-1.969z"/>'
  }
})
