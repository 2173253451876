/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bluetooth': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.859 16.313l-1.875-1.922v3.797zm-1.875-10.5V9.61l1.875-1.922zm4.735 1.875l-4.313 4.313 4.313 4.313L12 21.986h-.984v-7.594l-4.594 4.594-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 4.594 4.594V2.018H12z"/>'
  }
})
