/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera_rear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6q.797 0 1.383-.609t.586-1.406-.586-1.383T12 2.016t-1.406.586-.609 1.383.586 1.406 1.43.609zm5.016-6q.797 0 1.383.609t.586 1.406v13.969q0 .797-.586 1.406t-1.383.609H9.985l3 3-3 3v-2.016H5.016v-1.969h4.969v-2.016h-3q-.797 0-1.383-.609t-.586-1.406V2.014q0-.797.586-1.406t1.383-.609h10.031zm-3 20.016h4.969v1.969h-4.969v-1.969z"/>'
  }
})
