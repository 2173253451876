/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 14.484l3.469-3.469-3.469-3.516v2.484H9q-.422 0-.703.305t-.281.727v3.984h1.969v-3h4.031v2.484zm7.687-3.187q.281.281.281.703t-.281.703l-9 9q-.281.281-.703.281t-.703-.281l-9-9q-.281-.281-.281-.703t.281-.703l9-9q.281-.281.703-.281t.703.281z"/>'
  }
})
