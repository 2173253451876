/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'do_not_step': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.406 4.219l7.875 7.922q.328.328 0 .656-.141.188-.328.188-.234 0-.375-.188L6.89 11.109q-.188.609-.469 1.125l1.406 1.406q.141.141.141.352t-.141.352-.375.141q-.188 0-.328-.141l-1.266-1.266q-.375.422-.797.797l1.313 1.266q.141.141.141.352t-.141.352q-.188.141-.375.141-.234 0-.328-.141l-1.406-1.359q-.516.328-1.031.586t-.938.398q-.563.234-.938.75t-.375 1.172v2.625h9.516l3.328-3.328 5.953 5.906 1.406-1.406L2.811 2.814zm17.11 11.484L17.11 14.25l4.453-4.453 1.453 1.406zm2.343-6.609l-4.453 4.453-7.125-7.078 4.5-4.453z"/>'
  }
})
