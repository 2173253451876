/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app_blocking': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 8.016q-1.125 0-2.016.539t-1.43 1.43-.539 2.016.539 2.016 1.43 1.43 2.016.539 2.016-.539 1.43-1.43.539-2.016-.539-2.016-1.43-1.43T18 8.016zM15.516 12q0-1.031.727-1.758t1.758-.727q.609 0 1.172.281l-3.375 3.375q-.281-.563-.281-1.172zM18 14.484q-.609 0-1.172-.281l3.375-3.375q.281.563.281 1.172 0 1.031-.727 1.758t-1.758.727zM17.016 18H6.985V6h10.031v.984h1.969V3q0-.844-.586-1.43T17.016.984H6.985q-.797 0-1.383.586T5.016 3v18q0 .844.586 1.43t1.383.586h10.031q.797 0 1.383-.586t.586-1.43v-3.984h-1.969V18z"/>'
  }
})
