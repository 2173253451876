/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ballot': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 14.016h3v3h-3v-3zM6 18h5.016v-5.016H6V18zm.984-11.016h3v3h-3v-3zM6 11.016h5.016V6H6v5.016zM18.984 21H5.015q-.797 0-1.406-.609T3 18.985V5.016q0-.797.609-1.406t1.406-.609h13.969q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T18.984 21zm-6-4.5H18v-2.016h-5.016V16.5zm0-6.984H18V7.5h-5.016v2.016z"/>'
  }
})
