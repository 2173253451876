<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="onConfirmed" content="This will be removed. Continue?" />
    <FreightForm ref="FormModal" @payload="onPayload" />
    <CTabs variant="tabs" :active-tab="tabIndex">
      <CTab title="골재종류 등록관리">
        <Search class="my-4" @search="onSearch" v-if="cates && cates.length">
          <CRow>
            <CCol sm="12">
              <CTableWrapper
                :items="list"
                :fields="FIELDS"
                header-icon="cil-factory"
                add-btn
                add-btn-text="추가"
                edit-btn
                :view-btn="false"
                :import-btn="false"
                :count="count"
                caption="골재종류"
                hover
                small
                fixed
                @add="onAdd"
                @delete="onDelete"
                @edit="onEdit"
              />
            </CCol>
          </CRow>
          <CRow class="justify-content-end" v-if="pageTotal > 1">
            <CPagination
              :activePage.sync="pageCurrent"
              :pages="pageTotal"
              @update:activePage="onUpdatePage"
            >
            </CPagination>
            <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
          </CRow>
        </Search>
        <CJumbotron v-else-if="!loading">
          <h1 class="display-3">골재종류의 카테고리를 설정하십시오</h1>
          <p class="lead">카테고리를 설정하고 골재종류를 추가할 수 있습니다</p>
          <!-- <CButton color="primary" @click="$router.push('/install/organizations')"><CIcon name="cil-building" />&nbsp;Go to get permission</CButton> -->
        </CJumbotron>
      </CTab>
      <CTab title="골재종류 카테고리" v-if="hasCatePermission">
        <FreightCate @updated="onUpdateCate" @msg="onMsg" />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import CTableWrapper from '@/components/EdxTable';
import FreightForm from './FreightForm.vue';
import FreightCate from './FreightCate.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryFreight',
  components: {
    Search,
    CTableWrapper,
    ConfirmModal,
    FreightForm,
    FreightCate
  },
  data () {
    return {
      loading: false,

      FIELDS: [],

      filter_branch: null,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      searchText: null,
      count: 0,
      list: [],

      tabIndex: 0,
      cates: [], // ['원재료 종류', '상세분류']
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    hasCatePermission() {
      return this.myAuthorizationLevel.level < 100;
    }
  },
  mounted() {
    this.initFetch()
  },
  methods: {
    initFetch() {
      this.loading = true;
      axios.get(`/api/delivery/freight/cates/`)
        .then(result => {
          this.cates = result.data.cate.map(el => {
            return el.name;
          });
          this.FIELDS = [];
          if (this.myAuthorizationLevel.level < 100) {
            this.FIELDS.push({key: 'branch_name', label: '공장'});
          }
          this.FIELDS.push({key: 'name', label: '이름'});
          for (let i=0; i < this.cates.length; i++) {
            this.FIELDS.push({key: `cate${+i+1}`, label: this.cates[i]});
          }
          // this.FIELDS.push({key: 'code', label: '코드'});
          this.FIELDS.push({key: 'ops', label: '작업', _style: 'width:100px;' });
          this.getList();
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading = false;
        })
    },
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/freights/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onAdd() {
      this.$refs.FormModal.open({cates: this.cates});
    },
    onEdit(id) {
      const freight = this.list.find(el => el.id === id);
      this.$refs.FormModal.open({cates: this.cates, freight: freight});
    },
    onPayload(payload) {
      if (payload.id) {
        axios.put(`/api/delivery/freights/${payload.id}/`, payload)
          .then(result => {
            this.getList();
            this.$notify.success({
              title: '완료',
              message: '항목이 수정되었습니다',
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: '오류',
              message: `항목을 수정할 수 없습니다: ${error.response.data.error}`,
              offset: 30
            });
          })
        return;
      }
      axios.post(`/api/delivery/freights/`, payload)
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: '항목을 추가했습니다',
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 추가할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },

    onUpdatePage(e) {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onDelete(id) {
      this.$refs.DeleteConfirmModal.open(id);
    },
    onConfirmed(payload) {
      axios.delete(`/api/delivery/freights/${payload}/`)
        .then(result => {
          const del_name = this.list.find(el => el.id === payload).name;
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `삭제되었습니다`,
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
        });
    },

    onUpdateCate(payload) {
      console.log('onUpdateCate', payload);
      this.initFetch();
    },
    onMsg(payload) {
      this.$notify({
        title: payload.title,
        message: payload.msg,
        type: payload.color,
        offset: 30
      })
    }
  }
}
</script>
