<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    dropdownMode="closeInactive"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/device-portal" v-if="capability">
      <CImg class="custom-limit-logo-size-none c-sidebar-brand-full" src="/img/edgedx-brand3-portal.png" />
      <CImg class="custom-limit-logo-size-min c-sidebar-brand-minimized" src="/edgedx-emb.png" />
    </CSidebarBrand>
    <CSidebarNav>
      <CSidebarNavItem :name="$t('cloud.title.device')" icon="cil-storage" to="/device-portal" />
      <!-- <CSidebarNavItem :name="$t('cloud.title.applications')" icon="cil-storage" to="/" /> -->
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TheSidebar',
  computed: {
    ...mapState([
      'capability',
      'sidebarShow',
      'sidebarMinimize'
    ]),
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    show () {
      return this.sidebarShow 
    },
    minimize () {
      return this.sidebarMinimize 
    },
    org() {
      if (!this.capability) return null;
      return this.capability.organization.id;
    }
  },
  methods: {
    subscribing(service) {
      if (!this.capability) return false;
      if (!this.capability.organization) return false;
      if (!this.capability.organization.subscription) return false;
      const subscription_id = this.capability.organization.subscription.findIndex(subs => {
        if (subs.service_key === service) {
          // 구독 기간 만료 체크
          if (this.$utility.SubscriptionRemainHour(subs) > 0) {
            return true;
          }
        }
        return false;
      });
      if (subscription_id >= 0) return true;
      return false;
    }
  }
}
</script>
