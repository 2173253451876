/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open_in_full': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 11.016V3h-8.016l3.328 3.281L6.281 16.312 3 12.984V21h8.016l-3.328-3.281L17.719 7.688z"/>'
  }
})
