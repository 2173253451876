/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speaker_group': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 5.016V21h9.984v2.016H6q-.844 0-1.43-.586T3.984 21V5.016H6zm5.484 7.5q0-1.031.75-1.781t1.781-.75 1.758.75.727 1.781-.727 1.758-1.758.727-1.781-.727-.75-1.758zm2.532 3.984q1.641 0 2.813-1.172t1.172-2.813-1.172-2.836-2.813-1.195-2.836 1.195-1.195 2.836 1.195 2.813 2.836 1.172zm0-13.5q-.797 0-1.406.586t-.609 1.43.586 1.406 1.43.563q.797 0 1.383-.563t.586-1.406-.586-1.43T14.017 3zM18.188.984q.75 0 1.289.539t.539 1.289v14.391q0 .75-.539 1.266t-1.289.516H9.797q-.75 0-1.266-.516t-.516-1.266V2.812q0-.75.516-1.289T9.797.984h8.391z"/>'
  }
})
