/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hearing_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.047 3.188q.844-.563 1.828-.867t2.109-.305q1.5 0 2.766.539t2.227 1.477 1.5 2.227.539 2.742q0 1.969-1.078 3.891 0 .047-.023.07l-.07.07-1.453-1.5q.609-1.313.609-2.531 0-1.406-.656-2.531T12.54 4.665t-2.555-.68q-.656 0-1.289.188t-1.195.516zm11.156 11.203l1.453 1.406q1.078-1.406 1.711-3.141T21 9q0-2.297-.867-4.289t-2.367-3.492L16.36 2.625q1.219 1.219 1.922 2.859T18.985 9q0 1.5-.469 2.883t-1.313 2.508zM9.984 6.516q-.281 0-.563.047l3 3.047q.094-.328.094-.609 0-1.031-.75-1.758t-1.781-.727zm11.204 14.672L2.813 2.813 1.407 4.219l2.109 2.109q-.234.609-.375 1.289T3 9h2.016q0-.281.023-.539t.07-.492l6.609 6.609q-.656.469-1.266 1.125t-.984 1.734q-.375 1.125-.773 1.617t-.961.773q-.281.188-.75.188-.797 0-1.383-.586t-.586-1.43H2.999q0 1.125.539 2.016t1.453 1.43 1.992.539q.891 0 1.641-.328 1.031-.516 1.664-1.359t1.102-2.203q.234-.75.656-1.172t1.031-.891q.047 0 .047-.023t.047-.023l6.609 6.609z"/>'
  }
})
