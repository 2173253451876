/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_basketball': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.953 12.984h2.063v8.953q-1.453-.141-3.258-1.008t-2.883-1.945q1.453-.797 2.648-2.578t1.43-3.422zm8.156 0h4.828q-.234 2.344-1.547 4.406-1.172-.563-2.109-1.852t-1.172-2.555zm-13.5 4.407q-1.359-2.109-1.547-4.406H6.89q-.234 1.266-1.172 2.555t-2.109 1.852zm11.438-4.407q.234 1.641 1.43 3.422t2.648 2.578q-1.031 1.078-2.836 1.945t-3.305 1.008v-8.953h2.063zm-6.094-1.968q-.234-1.641-1.43-3.422T4.875 5.016q1.031-1.078 2.836-1.945t3.305-1.008v8.953H8.953zm6.094 0h-2.063V2.063q1.453.141 3.258 1.008t2.883 1.945q-1.453.797-2.648 2.578t-1.43 3.422zm-8.156 0H2.063Q2.297 8.672 3.61 6.61q1.172.563 2.109 1.852t1.172 2.555zm10.218 0q.234-1.266 1.172-2.555t2.109-1.852q1.313 2.063 1.547 4.406h-4.828z"/>'
  }
})
