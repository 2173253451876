/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_rotate_vertical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 19.734l-3-3h2.016V4.265h1.969v12.469h2.016zM13.125 12h3.75L15 6.984zm2.625-6.984l4.734 10.969h-2.063l-.938-2.203h-4.969l-.938 2.203H9.513l4.734-10.969h1.5z"/>'
  }
})
