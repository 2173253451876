/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'explore_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12q0 .656-.047.938v.281q0 .234-.094.609 0 .141-.094.328l-.141.563q-.047.047-.047.164t-.047.164q-.047.141-.141.398t-.141.352q-.094.281-.328.75l-.164.328-.211.328q-.047.047-.164.234t-.164.234l-5.203-5.203 3-6.469-6.469 3-5.203-5.203q.047-.047.234-.164t.234-.164q.047 0 .141-.094.094 0 .094-.047l.188-.094.164-.094.188-.094.211-.094.234-.141.234-.094q.094-.047.328-.117t.375-.117l.328-.094.563-.141q.141 0 .328-.094.375-.094.609-.094h.328q.141 0 .422-.023t.469-.023q4.125 0 7.055 2.93t2.93 7.055zM6 18l6.469-3L9 11.531zM2.25 2.25l19.5 19.5-1.313 1.266-2.766-2.813q-.047.047-.234.164t-.234.164q-.047 0-.141.094-.094 0-.094.047-.469.328-1.219.563l-.703.281q-.047.047-.164.047t-.164.047l-.563.141q-.188.094-.328.094-.375.094-.609.094h-.281q-.281.047-.938.047-4.125 0-7.055-2.93t-2.93-7.055q0-.609.047-.891v-.328q0-.234.094-.609.094-.188.094-.328l.141-.563.094-.328q.047-.141.117-.375t.117-.328q0-.047.094-.211t.141-.258l.375-.75q.047 0 .094-.094t.047-.141q.047-.047.164-.234t.164-.234L.984 3.563z"/>'
  }
})
