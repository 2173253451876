/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calculate': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zm-5.953 4.078L14.109 6l1.406 1.406L16.921 6l1.031 1.078-1.406 1.406 1.406 1.406-1.031 1.031-1.406-1.359-1.406 1.406-1.078-1.078 1.406-1.406zm-6.797.656h5.016v1.5H6.234v-1.5zm5.25 8.25H9.515V18h-1.5v-2.016H5.999v-1.5h2.016v-1.969h1.5v1.969h1.969v1.5zM18 17.25h-5.016v-1.5H18v1.5zm0-2.484h-5.016v-1.5H18v1.5z"/>'
  }
})
