/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_wide_angle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3.984q3.609 0 7.969.75l.891.141.281.891q.844 3.094.844 6.234t-.844 6.234l-.281.891-.891.141q-4.359.75-7.969.75t-7.969-.75l-.891-.141-.281-.891Q2.015 15.14 2.015 12t.844-6.234l.281-.891.891-.141q4.359-.75 7.969-.75zM12 6q-3.281 0-7.313.656Q3.984 9.375 3.984 12t.703 5.344Q8.718 18 12 18t7.313-.656q.703-2.719.703-5.344t-.703-5.344Q15.282 6 12 6z"/>'
  }
})
