/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'euro_symbol': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 18.516q2.391 0 4.219-1.594L21 18.703Q18.469 21 15 21q-2.906 0-5.227-1.688t-3.258-4.313H2.999v-2.016h3.047q-.047-.281-.047-.984t.047-.984H2.999V8.999h3.516q.938-2.625 3.258-4.313T15 2.998q1.406 0 3.188.68t2.813 1.617L19.22 7.076q-1.828-1.594-4.219-1.594-1.641 0-3.328 1.031T9.235 8.997h5.766v2.016H8.579q-.094.656-.094.984t.094.984h6.422v2.016H9.235q.75 1.453 2.438 2.484t3.328 1.031z"/>'
  }
})
