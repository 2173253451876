/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_color_reset': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.25 5.25l14.625 14.625-1.266 1.266-2.672-2.625q-1.734 1.5-3.938 1.5-2.484 0-4.242-1.758t-1.758-4.242q0-1.594 1.313-4.125L3.984 6.563zM18 14.016q0 .703-.141 1.313L9.281 6.704 12 3.188q.656.75 1.664 2.016t2.672 4.148T18 14.016z"/>'
  }
})
