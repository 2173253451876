/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'assignment_return': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 15v-3.984H12v-3l-5.016 4.969L12 18.001v-3h3.984zM12 3q-.422 0-.703.281t-.281.703.281.727.703.305.703-.305.281-.727-.281-.703T12 3zm6.984 0q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h4.172q.328-.891 1.078-1.453t1.734-.563 1.734.563T14.811 3h4.172z"/>'
  }
})
