/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'picture_in_picture': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 19.031V4.968H3v14.063h18zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18zm-2.016 3.984v6h-7.969v-6h7.969z"/>'
  }
})
