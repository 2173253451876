/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_offer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.484 6.984q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm15.938 4.594q.563.563.563 1.406t-.563 1.406l-7.031 7.031q-.563.563-1.406.563t-1.406-.563l-9-9q-.563-.563-.563-1.406V3.984q0-.797.586-1.383t1.383-.586h7.031q.844 0 1.406.563z"/>'
  }
})
