/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_list': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 5.016h12V9H9V5.016zm0 13.968V15h12v3.984H9zm0-4.968V9.985h12v4.031H9zM3.984 9V5.016h4.031V9H3.984zm0 9.984V15h4.031v3.984H3.984zm0-4.968V9.985h4.031v4.031H3.984z"/>'
  }
})
