<template>
  <el-dialog
    top="2vh"
    :visible.sync="show"
    width="74%"
    :before-close="onClose"
  >
    <div style="position:relative;">
      <CImg :src="url" class="w-100" />
      <div v-if="lp_num" style="position:absolute;top:8px;left:9%;background-color:rgba(200,160,120,.8);" class="px-2 h2 font-weight-bold">{{lp_num}}</div>
    </div>
    <FloatingBtn v-if="carousel" position="absolute" direction="left" @click="onLeft" />
    <FloatingBtn v-if="carousel" position="absolute" direction="right" @click="onRight" />
    <span slot="footer" class="dialog-footer">
      <CButton @click="onClose" color="primary">
        {{$t('button.close')}}
      </CButton>
    </span>
  </el-dialog>
</template>

<script>
import FloatingBtn from '@/components/FloatingBtn'

export default {
  name: 'ImagePopupModal',
  components: {
    FloatingBtn
  },
  props: {
    title: { type: String, default: 'Snapshot' },
    size: { type: String, default: 'xl' },
    carousel: {type: Boolean, default: true }
  },
  data() {
    return {
      show: false,
      alarm_id: null,
      urls: null,
      index: null,
      url: null,
      lp_num: null
    }
  },
  methods: {
    open(param) {
      this.alarm_id = param.id;
      this.urls = param.urls;
      this.index = param.index;
      this.url = this.urls[this.index];
      this.lp_num = param.lp_num;
      this.show = true;
    },
    onClose() {
      this.show = false;
      this.$emit('save', {id: this.alarm_id, index: this.index});
      this.alarm_id = null;
      this.urls = null;
      this.index = null;
      this.url = null;
    },
    onLeft() {
      this.index -= 1;
      if (this.index < 0) this.index += this.urls.length;
      this.url = this.urls[this.index];
    },
    onRight() {
      this.index += 1;
      if (this.index >= this.urls.length) this.index -= this.urls.length;
      this.url = this.urls[this.index];
    }
  }
}
</script>