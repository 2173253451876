/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'liquor': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 20.016V18h-7.031v2.016h7.031zm-7.032-9.563V12h7.031v-1.547l-.984-.328q-.844-.281-1.43-1.125t-.586-1.734v-.281h-1.031v.281q0 2.156-2.016 2.859zm3-6.469v1.031h1.031V3.984h-1.031zm4.641 4.547q1.359.469 1.359 1.922v9.563q0 .797-.586 1.383t-1.383.586h-7.031q-.797 0-1.383-.586t-.586-1.383v-9.563q0-1.453 1.359-1.922l.938-.281q.703-.234.703-.984V3q0-.422.281-.703t.703-.281h3q.422 0 .703.281t.281.703v4.266q0 .656.703.938zM5.016 8.016v3h1.969v-3H5.016zm-2.016 6V6h6v8.016Q9 15 8.437 15.75t-1.453 1.078v3.188H9v1.969H3v-1.969h2.016v-3.188q-.891-.328-1.453-1.078T3 14.016z"/>'
  }
})
