/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear_all': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 6.984H21V9H6.984V6.984zM3 17.016V15h14.016v2.016H3zm2.016-4.032v-1.969h13.969v1.969H5.016z"/>'
  }
})
