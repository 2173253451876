/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'electric_moped': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 5.016q0-.844-.586-1.43T17.015 3h-3v2.016h3v2.625L13.499 12H9.983V6.984H5.999q-1.125 0-2.016.539t-1.43 1.453-.539 2.039v3h1.969q0 .797.422 1.477t1.102 1.102 1.477.422q.844 0 1.523-.422t1.078-1.102.398-1.477h4.5l4.5-5.672V5.015zM6.984 15q-.375 0-.68-.305t-.305-.68h2.016q0 .375-.305.68T6.983 15zM5.016 3.984h4.969V6H5.016V3.984zm13.968 7.032q-.797 0-1.477.398t-1.102 1.078-.422 1.523q0 .797.422 1.477t1.102 1.102 1.477.422q.844 0 1.523-.422t1.078-1.102.398-1.477q0-.844-.398-1.523t-1.078-1.078-1.523-.398zm0 3.984q-.375 0-.68-.305t-.305-.68q0-.422.305-.727t.68-.305q.422 0 .727.305t.305.727q0 .375-.305.68t-.727.305zm-12 5.016h4.031V18l6 3h-4.031v2.016z"/>'
  }
})
