/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toc': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 12.984v-1.969H21v1.969h-2.016zm0-6H21V9h-2.016V6.984zm0 10.032V15H21v2.016h-2.016zM3 17.016V15h14.016v2.016H3zm0-4.032v-1.969h14.016v1.969H3zM3 9V6.984h14.016V9H3z"/>'
  }
})
