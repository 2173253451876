/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'align_horizontal_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 21.984H2.015V2.015h1.969v19.969zm18-15H6v3h15.984v-3zm-6 7.032H6v3h9.984v-3z"/>'
  }
})
