/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'all_out': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.078 16.031q1.594-1.594 1.594-3.867t-1.594-3.867-3.867-1.594-3.867 1.594-1.594 3.867 1.594 3.867 3.867 1.594 3.867-1.594zm1.078-8.812q2.063 2.063 2.063 4.969t-2.063 4.922-4.969 2.016-4.922-2.016-2.016-4.922 2.016-4.969 4.922-2.063 4.969 2.063zm-12.937.937V4.172h3.984zm3.984 12H4.219v-3.984zm12-3.984v3.984h-3.984zm-3.984-12h3.984v3.984z"/>'
  }
})
