/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'where_to_vote': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zm1.547-12q2.906 0 4.945 2.039T18.984 9q0 1.453-.727 3.328t-1.758 3.516-2.039 3.07-1.711 2.273l-.75.797q-.281-.328-.75-.867t-1.688-2.156-2.133-3.141-1.664-3.445T5.014 9q0-2.906 2.039-4.945t4.945-2.039z"/>'
  }
})
