/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'published_with_changes': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.672 9.516l-7.078 7.078-4.266-4.219 1.453-1.406 2.813 2.813 5.672-5.672zM3.984 12q0-1.734.727-3.258T6.609 6.14L9 8.484v-6H3l2.203 2.203Q3.75 6.046 2.883 7.945T2.016 12q0 1.969.68 3.68t1.898 3.047 2.859 2.18 3.563 1.031v-2.016q-1.5-.188-2.766-.867t-2.227-1.758-1.5-2.414T3.984 12zm18 0q0-1.969-.68-3.68t-1.898-3.047-2.859-2.18-3.563-1.031v2.016q1.5.188 2.766.867t2.227 1.758 1.5 2.414.539 2.883q0 1.734-.727 3.258t-1.898 2.602L15 15.516v6h6l-2.203-2.203q1.453-1.359 2.32-3.258T21.984 12z"/>'
  }
})
