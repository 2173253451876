/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_comfy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 5.016h3.984V9H18V5.016zm0 13.968V15h3.984v3.984H18zm-5.016 0V15h4.031v3.984h-4.031zm-4.968 0V15H12v3.984H8.016zm-5.016 0V15h3.984v3.984H3zm15-4.968V9.985h3.984v4.031H18zm-5.016-9h4.031V9h-4.031V5.016zM8.016 9V5.016H12V9H8.016zm4.968 5.016V9.985h4.031v4.031h-4.031zm-4.968 0V9.985H12v4.031H8.016zm-5.016 0V9.985h3.984v4.031H3zM3 9V5.016h3.984V9H3z"/>'
  }
})
