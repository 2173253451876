/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_ft_apart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 6q.844 0 1.43-.586t.586-1.43q0-.797-.586-1.383T6 2.015t-1.43.586-.586 1.383q0 .844.586 1.43T6 6zm3.984 3.422q0-.609-.328-1.102t-.891-.727q-.609-.281-1.313-.445t-1.453-.164-1.453.164-1.313.445q-.563.234-.891.727t-.328 1.102v.563h7.969v-.563zM18 6q.844 0 1.43-.586t.586-1.43q0-.797-.586-1.383T18 2.015t-1.43.586-.586 1.383q0 .844.586 1.43T18 6zm3.984 3.422q0-.609-.328-1.102t-.891-.727q-.609-.281-1.313-.445t-1.453-.164-1.453.164-1.313.445q-.563.234-.891.727t-.328 1.102v.563h7.969v-.563zm-3 7.594V15H5.015v2.016l-3-3 3-3v2.016l13.969-.047v-1.969l3 3zm-9 1.968V18H7.5q-.188 0-.352.141t-.164.375v3q0 .188.164.328t.352.141h2.016q.188 0 .328-.141t.141-.328v-1.5q0-.234-.141-.375t-.328-.141h-1.5v-.516h1.969zM9 20.484V21h-.984v-.516H9zm8.484-1.5H16.5v3h-.984v-3h-1.031V18h3v.984zm-4.968 0v.516h.984v.984h-.984v1.5h-1.031V18h2.531v.984h-1.5z"/>'
  }
})
