/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bike_scooter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 14.016h.75L8.812 5.578q-.141-.703-.703-1.148T6.89 3.985H2.999v2.016H6.89l1.406 6.234q-1.641.516-2.789 1.781t-1.43 3H-.001v1.969h6v-.984q0-1.125.539-2.016t1.453-1.43 1.992-.539zm9-6h-.797l-1.359-3.703q-.188-.609-.703-.961T14.953 3h-3.938v2.016h3.938l1.125 3h-5.672l.469 1.969H15q-.328.469-.563.961t-.328 1.055h-2.813l.469 2.016h2.344q.188 1.078.867 1.969t1.641 1.43 2.133.586q1.406.047 2.602-.609t1.922-1.828.727-2.578-.656-2.531-1.805-1.781-2.555-.656zm0 7.968q-.844 0-1.523-.398t-1.078-1.078-.398-1.523q0-.703.281-1.289t.797-.961l.938 2.625 1.875-.656-.938-2.672.023-.023.07-.023q.797 0 1.477.398t1.102 1.078.422 1.523-.422 1.523-1.102 1.078-1.523.398zm-9-.984q-.797 0-1.477.398t-1.102 1.078-.422 1.523.422 1.523T8.507 20.6t1.477.398q.844 0 1.523-.398t1.078-1.078.398-1.523-.398-1.523-1.078-1.078T9.984 15zm0 3.984q-.375 0-.68-.281T8.999 18t.305-.703.68-.281q.422 0 .727.281t.305.703-.305.703-.727.281z"/>'
  }
})
