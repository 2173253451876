/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit_location': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.906 7.547q.281-.234 0-.516l-.938-.938q-.094-.094-.234-.094-.188 0-.281.094l-.703.703 1.453 1.453zM10.453 12l3.328-3.328-1.453-1.453L9 10.547V12h1.453zM12 2.016q2.906 0 4.945 2.039T18.984 9q0 1.453-.727 3.328t-1.758 3.516-2.039 3.07-1.711 2.273l-.75.797q-.281-.328-.75-.867t-1.688-2.156-2.133-3.141-1.664-3.445T5.014 9q0-2.906 2.039-4.945t4.945-2.039z"/>'
  }
})
