/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'weekend': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 5.016q.797 0 1.406.586t.609 1.383v2.156q-.891.328-1.453 1.078t-.563 1.734v2.063h-12v-2.063q0-.984-.563-1.734T3.983 9.141V6.985q0-.797.609-1.383t1.406-.586h12zm3 4.968q.797 0 1.406.609t.609 1.406v5.016q0 .797-.609 1.383T21 18.984H3q-.797 0-1.406-.586t-.609-1.383v-5.016q0-.797.609-1.406T3 9.984t1.406.609.609 1.406v3h13.969v-3q0-.797.609-1.406t1.406-.609z"/>'
  }
})
