/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'more_vert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15.984q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 15.984zm0-6q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 9.984zm0-1.968q-.797 0-1.406-.609t-.609-1.406.609-1.406T12 3.986t1.406.609.609 1.406-.609 1.406T12 8.016z"/>'
  }
})
