/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_kabaddi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.25 10.547q1.547 1.547 3.609 1.547L15 14.157q-2.906 0-5.063-2.063l-.703-.703-2.344 2.391 2.109 2.109v6H6.983v-5.156l-1.266-1.172v2.203l-4.266 4.219-1.406-1.406 3.656-3.609-1.078-2.953q0-.047-.047-.164t-.07-.211-.023-.188q-.094-.844.609-1.547L6.42 8.579q.609-.609 1.406-.609.703 0 1.125.328.281.281 2.297 2.25zm-.234-2.156q-.75-.141-1.266-.844t-.328-1.5q.141-1.078 1.219-1.5.281-.094.422-.094.094 0 .188-.047h.141q.094 0 .211.023t.164.023q.469 0 1.031.563t.586 1.359-.586 1.453q-.281.281-.656.422-.047 0-.164.07t-.211.07q-.141.047-.375.047-.047 0-.188-.023t-.188-.023zM24 11.859h-2.016V8.484l-1.781-.703.891 4.453 1.031 5.203.891 4.453h-2.109l-1.828-8.016-2.063 2.016v6H15v-7.5q.844-.797 2.109-2.016l-.609-3q-.844.938-2.109 1.641-1.406-.141-2.484-1.219 2.344-.422 3.375-2.297l1.031-1.641q.938-1.5 2.625-.797l5.063 2.109v4.688zm-9.516-9.468q0-.844.586-1.43T16.5.375t1.43.586.586 1.43-.586 1.406-1.43.563-1.43-.563-.586-1.406z"/>'
  }
})
