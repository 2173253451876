/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 20.016H5.015V12h-3l9.984-9 9.984 9h-3v8.016h-4.969v-6H9.983v6z"/>'
  }
})
