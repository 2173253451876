/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'browser_not_supported': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6v10.5l1.969 1.969Q21 18.188 21 18V6q0-.844-.586-1.43t-1.43-.586H6.515L8.484 6h10.5zM3.234 3.328L1.968 4.594l1.031 1.031V18q0 .844.586 1.43t1.43.586h12.328l2.063 2.063 1.266-1.266zM15 18H5.016V7.641L15.375 18H15z"/>'
  }
})
