/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stroller': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 20.016q0-.844.586-1.43T15.985 18q.844 0 1.43.586t.586 1.43q0 .797-.586 1.383t-1.43.586q-.797 0-1.383-.586t-.586-1.383zm-10.032 0q0-.844.586-1.43T6 18t1.43.586.586 1.43q0 .797-.586 1.383T6 21.985t-1.43-.586-.586-1.383zm18-13.032v-.516q0-.938-.445-1.734t-1.195-1.266-1.688-.469q-.844 0-1.43.352t-1.008.82-.75.844L6.655 15.328q-.281.328-.234.727t.305.68.727.281H15q.844 0 1.43-.586t.586-1.43V6.281q.422-.516.773-.891t.867-.375q.563 0 .961.422t.398 1.031v.516h1.969zm-7.687-2.906q-.938-.516-2.039-.797T9.985 3q-1.453 0-2.789.445T4.735 4.734L9.61 9.609z"/>'
  }
})
