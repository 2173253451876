/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music_note': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3h6v3.984h-3.984v10.031q0 1.641-1.195 2.813T9.985 21t-2.813-1.172T6 17.015t1.172-2.836 2.813-1.195q.984 0 2.016.563V3z"/>'
  }
})
