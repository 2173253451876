/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'volume_mute': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 9h4.031l4.969-5.016v16.031l-4.969-5.016H6.984v-6z"/>'
  }
})
