/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'highlight_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 5.016H15V3h2.016v2.016zM15 21h2.016v-2.578L19.594 21 21 19.594l-2.578-2.578H21V15h-6v6zm3.984-12H21V6.984h-2.016V9zm0 3.984H21v-1.969h-2.016v1.969zM11.016 21h1.969v-2.016h-1.969V21zM6.984 5.016H9V3H6.984v2.016zM3 17.016h2.016V15H3v2.016zM5.016 21v-2.016H3q0 .844.586 1.43t1.43.586zM18.984 3v2.016H21q0-.844-.586-1.43T18.984 3zm-7.968 2.016h1.969V3h-1.969v2.016zM3 9h2.016V6.984H3V9zm3.984 12H9v-2.016H6.984V21zM3 12.984h2.016v-1.969H3v1.969zm0-7.968h2.016V3q-.844 0-1.43.586T3 5.016z"/>'
  }
})
