/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'align_horizontal_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 2.016h1.969v19.969h-1.969V2.016zm-18 7.968H18v-3H2.016v3zm6 7.032H18v-3H8.016v3z"/>'
  }
})
