/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'railway_alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9.984h2.016V12H15V9.984zm0-6h2.016V9H15V3.984zm-.328 8.813q.188.094.422.141h.281l.141.047h.984l.188-.047q1.781-.281 3.023-1.734t1.242-3.188q0-2.016-1.453-3.492t-3.516-1.523q-1.313 0-2.531.703l-.047.047-.469.281v.047q-.609.469-.844.797l-.375.563-.188.375-.094.141-.141.375q-.281.844-.281 1.688 0 1.5 1.008 2.883t2.414 1.852q.047.047.234.047zM9 18.984q.797 0 1.406-.586t.609-1.383-.609-1.406T9 15t-1.406.609-.609 1.406.609 1.383T9 18.984zM3 12h7.266Q9 10.219 9 8.016q0-.469.094-1.031H3v5.016zm20.016-3.984q0 2.484-1.758 4.523t-4.242 2.367v2.578q0 1.453-1.031 2.484t-2.484 1.031l1.5 1.5v.516h-12v-.516l1.5-1.5q-1.453 0-2.484-1.031T.986 17.484v-10.5q0-2.391 2.063-3.188t5.953-.797h.188q1.359 0 1.875.047.844-.844 2.297-1.453t2.625-.609q2.859 0 4.945 2.086t2.086 4.945z"/>'
  }
})
