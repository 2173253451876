/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_filter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.266 10.734L15.985 12l-2.719 1.266L12 15.985l-1.266-2.719L8.015 12l2.719-1.266L12 8.015zm3.75-.75l-.938-2.063-2.063-.938 2.063-.938.938-2.063.938 2.063 2.063.938-2.063.938zm2.015 0H21v9q0 .797-.586 1.406t-1.383.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h9v2.016h-9v13.969h14.016v-9z"/>'
  }
})
