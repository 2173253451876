/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'commute': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15.984q.422 0 .703-.281T21 15t-.281-.703-.703-.281-.727.281-.305.703.305.703.727.281zm-8.016 0q.422 0 .703-.281t.281-.703-.281-.703-.703-.281-.703.281-.281.703.281.703.703.281zm.422-6l-1.031 3h9.234l-1.031-3h-7.172zm8.156-.328l1.406 4.125v5.484q0 .281-.211.516t-.492.234h-.609q-.281 0-.469-.258t-.188-.539v-1.219h-8.016v1.219q0 .281-.211.539t-.492.258h-.609q-.281 0-.469-.234t-.188-.516l-.047-5.484 1.453-4.125Q11.67 9 12.42 9h7.172q.75 0 .984.656zm-15.562 4.36q-.422 0-.727.281T3.984 15t.305.703.727.281.703-.281T6 15t-.281-.703-.703-.281zM12 3.984q1.219 0 2.109.891T15 6.984v1.031h-2.016V5.999h-9v6.984H9v5.016l-2.016-.047-1.969 2.063H3.984v-1.031L5.015 18q-1.219 0-2.109-.891T2.015 15V6.984q0-1.219.891-2.109t2.109-.891h6.984z"/>'
  }
})
