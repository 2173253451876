/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'next_plan': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zm6 11.953h-5.016l2.297-2.25q-.703-.797-1.688-1.266t-2.109-.469q-1.172 0-2.18.492t-1.711 1.383-.938 1.969l-.984-.281q.328-1.313 1.172-2.344t2.039-1.641 2.602-.609q1.359 0 2.508.563t1.992 1.5L18 8.953v5.016z"/>'
  }
})
