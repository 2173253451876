/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brush': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.719 4.641q.281.281.281.703t-.281.703L11.766 15 9 12.234l8.953-8.953Q18.234 3 18.656 3t.703.281zM6.984 14.016q1.219 0 2.109.891t.891 2.109q0 1.641-1.172 2.813t-2.813 1.172q-2.438 0-3.984-2.016.703 0 1.336-.539t.633-1.43q0-1.219.891-2.109t2.109-.891z"/>'
  }
})
