/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'groups': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12.75q1.219 0 2.297.258t1.922.633 1.313 1.125.469 1.594v1.641h-12v-1.594q0-.891.469-1.641t1.313-1.125 1.922-.633 2.297-.258zm-8.016.234q.844 0 1.43-.586T6 11.015q0-.844-.586-1.43t-1.43-.586q-.797 0-1.383.586t-.586 1.43q0 .797.586 1.383t1.383.586zm1.125 1.125q-.234-.047-.516-.07t-.609-.023q-.75 0-1.43.141t-1.336.422q-.563.234-.891.75t-.328 1.125v1.547h4.5v-1.594q0-1.219.609-2.297zm14.907-1.125q.797 0 1.383-.586t.586-1.383q0-.844-.586-1.43t-1.383-.586q-.844 0-1.43.586T18 11.015q0 .797.586 1.383t1.43.586zM24 16.453q0-.609-.328-1.125t-.891-.75q-.656-.281-1.336-.422t-1.43-.141q-.328 0-.609.023t-.516.07q.609 1.078.609 2.297v1.594h4.5v-1.547zM12 6q.844 0 1.523.398t1.078 1.078.398 1.523-.398 1.523-1.078 1.078-1.523.398-1.523-.398-1.078-1.078-.398-1.523.398-1.523 1.078-1.078T12 6z"/>'
  }
})
