/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alt_route': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.797 11.156l-1.453 1.406q-.516-.516-.984-1.219t-.797-1.688l1.969-.516q.234.656.563 1.148t.703.867zM11.016 6L6.985 2.016 3.001 6h3q.047 1.266.188 2.156l1.969-.469q-.047-.375-.094-.797t-.047-.891h3zM21 6l-3.984-3.984L12.985 6h3q-.047 1.828-.422 2.906t-.938 1.734-1.172 1.219q-.375.328-.75.703t-.703.891q-.234-.375-.539-.68l-.586-.586-1.406 1.406q.469.422.82.844t.539 1.031.188 1.547v4.969h1.969v-4.969q0-1.031.234-1.641t.633-1.055.961-.961q.516-.469 1.055-1.031t1.008-1.383.773-2.016.352-2.93h3z"/>'
  }
})
