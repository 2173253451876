/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hourglass_empty': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.484L15.984 7.5V3.984H8.015V7.5zm3.984 5.016L12 12.516 8.016 16.5v3.516h7.969V16.5zM6 2.016h12v6L14.016 12 18 15.984v6H6v-6L9.984 12 6 8.016v-6z"/>'
  }
})
