/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_arrow_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.422 16.594L14.016 18l-6-6 6-6 1.406 1.406L10.828 12z"/>'
  }
})
