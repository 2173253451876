/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.297q.516 0 .914-.398t.398-.914-.398-.891-.914-.375-.914.375-.398.891.398.914.914.398zm10.266 4.453L21 23.016l-3.609-3.656-1.641 1.641h-7.5L3 15.751v-7.5L4.641 6.61.985 3.001l1.266-1.266zM11.016 6.984v.938L7.172 4.078 8.25 3h7.5L21 8.25v7.5l-1.078 1.078-6.938-6.891V6.984h-1.969z"/>'
  }
})
