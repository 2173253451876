/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'perm_media': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 15H21l-3.516-4.5-2.484 3L11.484 9zm15-11.016q.797 0 1.406.609t.609 1.406v9.984q0 .797-.609 1.406t-1.406.609H6q-.797 0-1.406-.609t-.609-1.406l.047-12q0-.797.586-1.383t1.383-.586h6l2.016 1.969h7.969zM2.016 6v14.016h18v1.969h-18q-.797 0-1.406-.586t-.609-1.383V6h2.016z"/>'
  }
})
