/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'roofing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 18h-1.969v-2.016h1.969V18zM15 14.016H9v6h6v-6zm3.984-4.735V3.984h-3v2.625L12 3l-9.984 9h3L12 5.672 18.984 12h3z"/>'
  }
})
