/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid_view': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18.984V15H15v3.984h3.984zm-6-6H21V21h-8.016v-8.016zm6-3.984V5.016H15V9h3.984zm-6-6H21v8.016h-8.016V3zM9 18.984V15H5.016v3.984H9zm-6-6h8.016V21H3v-8.016zM9 9V5.016H5.016V9H9zM3 3h8.016v8.016H3V3z"/>'
  }
})
