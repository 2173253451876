/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 12.656l2.625-2.578 1.406 1.406-5.016 5.016-5.016-5.016 1.406-1.406 2.625 2.578V3h1.969v9.656zm6-.656H21v6.984q0 .797-.609 1.406t-1.406.609H5.016q-.797 0-1.406-.609t-.609-1.406V12h2.016v6.984h13.969V12z"/>'
  }
})
