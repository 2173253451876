/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'align_horizontal_center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 2.016h1.969v4.969h8.016v3h-8.016v4.031h5.016v3h-5.016v4.969h-1.969v-4.969H6v-3h5.016V9.985H3v-3h8.016V2.016z"/>'
  }
})
