/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'all_inclusive': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.609 6.609q2.25 0 3.82 1.594T23.999 12t-1.57 3.797-3.82 1.594h-.047q-.891 0-1.992-.469t-1.805-1.125l-1.266-1.125 1.5-1.313 1.172.984q1.031 1.031 2.438 1.031T21 14.39t.984-2.391T21 9.608t-2.391-.984-2.391.984q-3.328 2.906-4.219 3.75l-2.813 2.484q-1.547 1.547-3.797 1.547t-3.82-1.594-1.57-3.797 1.57-3.797 3.82-1.594h.047q.938 0 2.039.469t1.758 1.125l1.266 1.125-1.547 1.313-1.125-.984Q6.796 8.624 5.389 8.624t-2.391.984-.984 2.391.984 2.391 2.391.984 2.391-.984q3.328-2.906 4.219-3.75l2.813-2.484q1.547-1.547 3.797-1.547z"/>'
  }
})
