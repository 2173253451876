<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="onConfirmed" :content="$t('cloud.title.confirm_remove2')" />
    <OccupancyForm ref="FormModal" @payload="onPayload" />
    <Search class="my-4" @search="onSearch">
      <CRow>
        <CCol sm="12">
          <CTableWrapper
            :items="list"
            :fields="FIELDS"
            header-svg="supervised_user_circle"
            :add-btn="false"
            :add-btn-text="$t('button.add')"
            edit-btn
            :view-btn="false"
            :import-btn="false"
            :count="count"
            :caption="$t('cloud.title.occupancy')"
            hover
            small
            fixed
            @add="onAdd"
            @delete="onDelete"
            @edit="onEdit"
          />
        </CCol>
      </CRow>
      <CRow class="justify-content-end" v-if="pageTotal > 1">
        <CPagination
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
    </Search>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import CTableWrapper from '@/components/EdxTable';
import OccupancyForm from '../counter/CounterForm.vue';

export default {
  name: 'BIOccupancy',
  components: {
    Search,
    CTableWrapper,
    ConfirmModal,
    OccupancyForm
  },
  data () {
    return {
      FIELDS: [
        {key: 'name', label: this.$t('cloud.title.name')},
        {key: 'branch_name', label: this.$t('cloud.title.store')},
        // {key: 'sid', label: this.$t('cloud.title.code')},
        {key: 'ops', label: this.$t('cloud.title.operation'), _style: 'width:100px; text-align: center;' }
      ],

      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      searchText: null,
      count: 0,
      list: []
    }
  },
  mounted() {
    this.initFetch()
  },
  methods: {
    initFetch() {
      this.getList();
    },
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/bi/occupancys/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onAdd() {
      this.$refs.FormModal.open(null, this.$t('cloud.title.occupancy'));
    },
    onEdit(id) {
      const occupancy = this.list.find(el => el.id === id);
      this.$refs.FormModal.open(occupancy, this.$t('cloud.title.occupancy'));
    },
    onPayload(payload) {
      if (payload.id) {
        axios.put(`/api/bi/occupancys/${payload.id}/`, payload)
          .then(() => {
            this.getList();
            this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.request_done'),
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: this.$t('cloud.title.error'),
              message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
              offset: 30
            });
          })
        return;
      }
      axios.post(`/api/bi/occupancys/`, payload)
        .then(() => {
          this.getList();
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        })
    },

    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onDelete(id) {
      this.$refs.DeleteConfirmModal.open(id);
    },
    onConfirmed(payload) {
      axios.delete(`/api/bi/occupancys/${payload}/`)
        .then(() => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
        });
    }
  }
}
</script>
