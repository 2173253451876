/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'museum': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 18v-6.984h-1.969l-2.016 3-2.016-3H8.014V18h1.969v-3.984l2.016 3 2.016-3V18h1.969zm6-6.984h-1.969v9h1.969v1.969H2.015v-1.969h1.969v-9H2.015V9l9.984-6.984L21.983 9v2.016z"/>'
  }
})
