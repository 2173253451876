/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'autorenew': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.75 7.734Q20.016 9.656 20.016 12q0 3.281-2.367 5.648t-5.648 2.367v3l-3.984-4.031L12.001 15v3q2.484 0 4.242-1.758T18.001 12q0-1.406-.703-2.813zM12 6Q9.516 6 7.758 7.758T6 12q0 1.5.703 2.813L5.25 16.266Q3.984 14.344 3.984 12q0-3.281 2.367-5.648t5.648-2.367v-3l3.984 4.031L11.999 9V6z"/>'
  }
})
