/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'align_vertical_top': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 2.016v1.969H2.015V2.016h19.969zm-15 19.968h3V6h-3v15.984zm7.032-6h3V6h-3v9.984z"/>'
  }
})
