/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phonelink_setup': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 1.031q.797 0 1.406.586T20.015 3v18q0 .797-.609 1.406T18 23.015H8.016q-.797 0-1.406-.609T6.001 21v-3h2.016v.984h9.984V5.015H8.017v.984H6.001v-3q0-.797.609-1.406T8.016.984zM6.984 13.734q.75 0 1.289-.492T8.812 12t-.539-1.242-1.289-.492-1.266.492T5.202 12t.516 1.242 1.266.492zm3.844-1.265l1.078.844q.188.188.094.328l-1.031 1.734q-.094.188-.328.094L9.375 15q-.375.234-.891.469l-.188 1.313q0 .234-.234.234H5.999q-.281 0-.281-.234l-.188-1.313q-.516-.234-.891-.469l-1.266.469q-.234.094-.328-.094l-.984-1.734q-.047-.047-.023-.164t.07-.164l1.078-.844q0-.047-.023-.211T3.14 12t.023-.234.023-.234l-1.078-.844q-.141-.141-.094-.328l1.031-1.734q.094-.188.328-.094l1.266.469q.375-.234.891-.469l.188-1.313q0-.234.234-.234h2.063q.234 0 .234.234l.234 1.313q.188.094.844.516l1.313-.516q.188-.094.281.094l1.031 1.734q.047.047.023.164t-.07.164l-1.078.844q0 .047.023.211t.023.258-.023.258-.023.211z"/>'
  }
})
