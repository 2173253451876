/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'network_locked': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15.984v-1.5q0-.609-.422-1.055t-1.078-.445-1.078.445T18 14.484v1.5h3zm.984 0q.422 0 .727.305t.305.727V21q0 .422-.305.703t-.727.281h-4.969q-.422 0-.727-.281T15.983 21v-3.984q0-.422.305-.727t.727-.305v-1.5q0-1.031.727-1.758t1.758-.727 1.758.727.727 1.758v1.5zm-2.484-6q-1.875 0-3.188 1.313t-1.313 3.188v.281q-.984.891-.984 2.25v3H.984L20.015.985v9.047q-.094 0-.258-.023t-.258-.023z"/>'
  }
})
