/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'luggage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 6H15V3q0-.422-.305-.703t-.68-.281H9.984q-.375 0-.68.281T8.999 3v3H6.983q-.797 0-1.383.586t-.586 1.43v10.969q0 .844.586 1.43t1.383.586q0 .422.305.703t.727.281q.375 0 .68-.281T9 21.001h6q0 .422.305.703t.68.281q.422 0 .727-.281t.305-.703q.797 0 1.383-.586t.586-1.43V8.016q0-.844-.586-1.43T17.017 6zm-7.5 12h-1.5V9h1.5v9zm3.234 0h-1.5V9h1.5v9zm.75-12h-3V3.516h3V6zm2.484 12h-1.5V9h1.5v9z"/>'
  }
})
