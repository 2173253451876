/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_to_drive': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.688 3.516L1.172 15l3.375 6L11.11 9.516l-3.422-6zM13.359 15H9.75l-3.469 6h8.25q-.703-.797-1.125-1.828t-.422-2.156q0-.563.094-1.055t.281-.961zm6.657.984v-3H18v3h-3V18h3v3h2.016v-3h3v-2.016h-3zm.703-4.734l-5.297-9.234H8.578l6.141 10.781q.844-.844 1.945-1.313t2.32-.469q.891 0 1.734.234z"/>'
  }
})
