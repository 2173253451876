/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'water_damage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3l-9.984 9h3v8.016h13.969V12h3zm0 12.984q-.844 0-1.43-.586t-.586-1.383q0-.422.305-1.078t.703-1.336.703-1.148L12 9.984l.305.469.703 1.148.703 1.336.305 1.078q0 .797-.586 1.383t-1.43.586z"/>'
  }
})
