/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_snippet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.391 8.391l-4.828-4.828Q15 3 14.157 3H5.016q-.844 0-1.43.586T3 5.016v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V9.844q0-.844-.609-1.453zM6.984 6.984h7.031V9H6.984V6.984zm10.032 10.032H6.985V15h10.031v2.016zm0-4.032H6.985v-1.969h10.031v1.969z"/>'
  }
})
