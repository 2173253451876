/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'follow_the_signs': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.516 5.484q.797 0 1.383-.586t.586-1.383q0-.844-.586-1.43t-1.383-.586q-.844 0-1.43.586T7.5 3.515q0 .797.586 1.383t1.43.586zm-3.75 3.422L3 23.015h2.109l1.734-8.016 2.156 2.016v6h2.016v-7.547l-2.063-2.063.609-3q.984 1.172 2.391 1.875t3.047.703v-1.969q-1.406 0-2.531-.68T10.64 8.529l-.938-1.594q-.281-.422-.727-.68t-.961-.258q-.375 0-.75.141l-5.25 2.156v4.688h1.969V9.654zm7.218-6.89V9h3.75v14.016h1.5V9h3.75V2.016h-9zm5.016 6V6.235h-3.516v-1.5H18V3.001l2.484 2.484z"/>'
  }
})
