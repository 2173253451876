/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transit_enterexit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 18H6V8.016h3v4.734L15.984 6 18 8.016 11.156 15h4.828v3z"/>'
  }
})
