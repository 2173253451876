/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rotate_90_degrees_ccw': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.359 6.656q1.078 1.078 1.852 2.93t.773 3.398-.773 3.422-1.852 2.953q-2.578 2.625-6.375 2.625-2.297 0-4.313-1.125l1.5-1.453q1.406.609 2.859.609 1.172 0 2.625-.609t2.297-1.453 1.453-2.32.609-2.648-.609-2.625-1.453-2.297Q15.889 6 12.983 6v3.234L8.764 5.015 12.983.749v3.234h.047q1.547 0 3.398.797t2.93 1.875zM3.703 12.891l3.656 3.656 3.656-3.656-3.656-3.656zm3.656-6.469l6.469 6.469-6.469 6.469-6.516-6.469z"/>'
  }
})
