/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rtt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.953 12.984L8.625 15h-6l.328-2.016h6zm-.656 4.032l-.281 1.969h-6l.281-1.969h6zM7.406 9l-.328 2.016H3.094L3.375 9h4.031zm.61-3.984l-.328 1.969H3.704l.281-1.969h4.031zM9.047 3h12.938l-1.078 7.078h-2.672l.703-4.5H16.36l-1.969 12.844h2.344L16.313 21H9.047l.422-2.578h2.344l2.016-12.844h-2.578l-.703 4.5H7.923z"/>'
  }
})
