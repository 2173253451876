/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fullscreen_exit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 8.016h3v1.969h-4.969V5.016h1.969v3zm-1.968 10.968v-4.969h4.969v1.969h-3v3h-1.969zm-6-10.968v-3h1.969v4.969H5.016V8.016h3zm-3 7.968v-1.969h4.969v4.969H8.016v-3h-3z"/>'
  }
})
