/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drag_indicator': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 15.984q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T15 15.984zm0-6q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T15 9.984zm0-1.968q-.797 0-1.406-.609t-.609-1.406.609-1.406T15 3.986t1.406.609.609 1.406-.609 1.406T15 8.016zM9 3.984q.797 0 1.406.609t.609 1.406-.609 1.406T9 8.014t-1.406-.609-.609-1.406.609-1.406T9 3.984zm0 6q.797 0 1.406.609t.609 1.406-.609 1.406T9 14.014t-1.406-.609-.609-1.406.609-1.406T9 9.984zM11.016 18q0 .797-.609 1.406t-1.406.609-1.406-.609T6.986 18t.609-1.406 1.406-.609 1.406.609.609 1.406z"/>'
  }
})
