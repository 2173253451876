/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mp23': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 12v-1.5h1.5V12h-1.5zm0 3v-1.5h1.969q.422 0 .727-.281t.305-.703V9.985q0-.422-.305-.703t-.727-.281h-3.469v6h1.5zm-9-6q-.422 0-.727.281t-.305.703V15h1.5v-4.5h1.031v3h1.5v-3h.984V15h1.5V9.984q0-.422-.281-.703T11.015 9h-4.5zm12.468-6q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
