/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_flash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.922 13.922L2.438 2.438 1.032 3.891l5.297 5.297-.188.234H3.61q-.656 0-1.125.469t-.469 1.125v9.375q0 .656.469 1.125t1.125.469h12.797q.563 0 .984-.328t.563-.891l2.156 2.203 1.453-1.406L18 18zm-3.938 6.094q-1.078 0-1.992-.539t-1.453-1.453T6 15.985q0-.938.422-1.781t1.172-1.406 1.641-.703l1.594 1.547q-.422-.141-.844-.141-1.031 0-1.758.727T7.5 15.986t.727 1.781 1.758.75 1.781-.75.75-1.781q0-.375-.188-.797l1.594 1.547q-.188.938-.75 1.664t-1.383 1.172-1.805.445zM18 15.188l-7.172-7.172h1.734l1.313 1.406h2.531q.656 0 1.125.469T18 11.016v4.172zm2.391-9.61h1.594l-3 5.438V6.985h-.984V2.016h3.984z"/>'
  }
})
