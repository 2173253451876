/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attach_file': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 6H18v11.484q0 2.297-1.594 3.914t-3.891 1.617-3.914-1.617-1.617-3.914V5.015q0-1.641 1.195-2.836T11.015.984t2.813 1.195T15 5.015v10.5q0 1.031-.727 1.758T12.515 18t-1.781-.727-.75-1.758V5.999h1.5v9.516q0 .422.305.703t.727.281.703-.281.281-.703v-10.5q0-1.031-.727-1.781t-1.758-.75-1.781.75-.75 1.781v12.469q0 1.641 1.195 2.836t2.836 1.195 2.813-1.195 1.172-2.836V6z"/>'
  }
})
