/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'security': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .984l9 4.031v6q0 4.172-2.578 7.617T12 23.015q-3.844-.938-6.422-4.383T3 11.015v-6zM12 12v8.953q2.766-.891 4.688-3.352t2.297-5.602h-6.984zm0 0V3.187L5.016 6.281V12H12z"/>'
  }
})
