/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'corporate_fare': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6.984V3H2.016v18h19.969V6.984h-9.984zm-2.016 12h-6v-1.969h6v1.969zm0-3.984h-6v-2.016h6V15zm0-3.984h-6V9h6v2.016zm0-4.032h-6V5.015h6v1.969zm10.032 12H12V9h8.016v9.984zM18 11.016h-3.984v1.969H18v-1.969zM18 15h-3.984v2.016H18V15z"/>'
  }
})
