/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'south_east': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9h-1.969v6.609L5.39 3.984 3.984 5.39l11.625 11.625H9v1.969h9.984V9z"/>'
  }
})
