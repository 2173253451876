/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plumbing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.266 4.922l-2.109-2.109q-.563-.609-1.406-.609t-1.406.609l-2.859 2.813 2.156 2.156 2.109-2.156 3.516 3.563q.609-.609.797-1.383t0-1.523-.797-1.359zM5.484 13.781q.469.422 1.078.422t1.031-.422l2.484-2.484-2.109-2.109-2.484 2.438q-.422.469-.422 1.078t.422 1.078zm9.563-6l-1.406 1.406-3.188-3.188q-.469-.469-1.078-.469t-1.078.469q-.422.422-.422 1.055t.422 1.055l3.188 3.188-.703.703-6.328 6.375q-.609.563-.609 1.406t.609 1.406q.563.609 1.383.609t1.43-.609l9.188-9.188q.281.281.703.281t.703-.281.281-.703-.281-.703z"/>'
  }
})
