/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location_city': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 15v-2.016h-1.969V15h1.969zm0 3.984v-1.969h-1.969v1.969h1.969zm-6-12V5.015h-1.969v1.969h1.969zm0 4.032V9h-1.969v2.016h1.969zm0 3.984v-2.016h-1.969V15h1.969zm0 3.984v-1.969h-1.969v1.969h1.969zm-6-7.968V9H5.015v2.016h1.969zm0 3.984v-2.016H5.015V15h1.969zm0 3.984v-1.969H5.015v1.969h1.969zM15 11.016h6V21H3V6.984h6V5.015l3-3 3 3v6z"/>'
  }
})
