/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 9.984h4.031q0-.797-.609-1.383T12 8.015t-1.406.586-.609 1.383zm9-.703l3 2.719h-3v8.016h-4.969v-6H9.984v6H5.015V12h-3l9.984-9 3.984 3.609V3.984h3v5.297z"/>'
  }
})
