/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'headset_mic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .984q3.75 0 6.375 2.648T21 9.984v10.031q0 1.219-.891 2.109T18 23.015h-6v-2.016h6.984v-.984H15v-8.016h3.984V9.983q0-2.906-2.039-4.945T12 2.999 7.055 5.038 5.016 9.983v2.016H9v8.016H6q-1.219 0-2.109-.891T3 17.015V9.984q0-3.703 2.625-6.352T12 .984z"/>'
  }
})
