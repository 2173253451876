/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_clear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 5.016V3h2.016v2.016H15zm0 7.968v-1.969h2.016v1.969H15zM15 21v-2.016h2.016V21H15zM11.016 5.016V3h1.969v2.016h-1.969zM18.984 3H21v2.016h-2.016V3zm-7.968 6V6.984h1.969V9h-1.969zm7.968 0V6.984H21V9h-2.016zm0 12v-2.016H21V21h-2.016zm0-8.016v-1.969H21v1.969h-2.016zm0 4.032V15H21v2.016h-2.016zm-7.968-4.032v-1.969h1.969v1.969h-1.969zM3 5.016V3h2.016v2.016H3zM3 9V6.984h2.016V9H3zm0 3.984v-1.969h2.016v1.969H3zm0 4.032V15h2.016v2.016H3zM3 21v-2.016h2.016V21H3zm8.016 0v-2.016h1.969V21h-1.969zm0-3.984V15h1.969v2.016h-1.969zM6.984 21v-2.016H9V21H6.984zm0-8.016v-1.969H9v1.969H6.984zm0-7.968V3H9v2.016H6.984z"/>'
  }
})
