/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accessibility': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 9h-6v12.984h-2.016v-6h-1.969v6H8.999V9h-6V6.984h18V9zm-9-6.984q.797 0 1.406.586t.609 1.383-.609 1.406T12 6t-1.406-.609-.609-1.406.609-1.383T12 2.016z"/>'
  }
})
