/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12q0-.844.586-1.43t1.43-.586 1.406.586.563 1.43-.563 1.43-1.406.586-1.43-.586T9 12zm2.016 3q1.219 0 2.109-.891T14.016 12t-.891-2.109T11.016 9t-2.109.891T8.016 12t.891 2.109 2.109.891zM4.5 9q.516 0 .516-.516 0-.469-.516-.469t-.516.469Q3.984 9 4.5 9zm6.75-3h10.734v4.031h-4.031q-.469 0-.773.328t-.258.797q.141 1.078-.047 2.063-.375 1.875-1.828 3.188t-3.375 1.547q-.094 0-.328.023t-.328.023q-2.484 0-4.242-1.758T5.016 12v-.281q0-.328.094-.797-.375.094-.609.094-1.031 0-1.758-.75t-.727-1.781.727-1.758T4.501 6q.844 0 1.5.492t.891 1.242Q8.72 6 11.251 6z"/>'
  }
})
