/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insert_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 17.016v-4.031H15v4.031h2.016zm-4.032 0V6.985h-1.969v10.031h1.969zm-3.984 0V9.985H6.984v7.031H9zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
