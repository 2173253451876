/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'festival': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 5.719l9 6.281v9.984H15v-4.969l-2.953-2.016L9 17.015v4.969H2.016V12l9-6.281V.985h4.969l-.984 1.547.984 1.453h-3v1.734z"/>'
  }
})
