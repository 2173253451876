/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bathtub': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 12.984h1.969v6q0 .797-.586 1.406t-1.383.609q0 .422-.305.703t-.727.281H5.015q-.422 0-.727-.281t-.305-.703q-.797 0-1.383-.609t-.586-1.406v-6h3v-.75q0-.891.68-1.57t1.57-.68q.938 0 1.688.75l1.359 1.547q.281.328.844.703h6.844V4.828q0-.328-.234-.586t-.609-.258q-.328 0-.563.234l-1.266 1.266q.094.375.094.516 0 .516-.328 1.078l-2.766-2.766q.563-.328 1.078-.328.234 0 .516.094l1.266-1.266q.797-.797 1.969-.797t2.016.82.844 1.992v8.156zm-15-6q0-.844.563-1.406t1.406-.563 1.43.563.586 1.406-.586 1.43T6.985 9t-1.406-.586-.563-1.43z"/>'
  }
})
