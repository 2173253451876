/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'design_services': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.219 11.531l1.594-1.594-3.75-3.75-1.594 1.594-4.125-4.172q-.563-.563-1.406-.563t-1.406.563L3.61 5.531q-.563.563-.563 1.406t.563 1.406l4.125 4.125-4.734 4.781v3.75h3.75l4.781-4.781 4.125 4.172q.469.469 1.008.539t1.031-.094.773-.445l1.922-1.922q.563-.563.563-1.406t-.563-1.406zm-7.031-.468L5.063 6.938l1.875-1.875 1.266 1.266-1.172 1.172 1.406 1.406L9.61 7.735l1.453 1.453zm7.875 7.875l-4.125-4.125 1.875-1.875 1.453 1.453-1.172 1.172 1.406 1.406 1.172-1.172 1.266 1.266zm3.656-11.907Q21 6.75 21 6.328t-.281-.703l-2.344-2.344q-.375-.328-.797-.258t-.609.258l-1.828 1.828 3.75 3.75z"/>'
  }
})
