/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'block': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648q0-1.125-.492-2.578t-1.195-2.344l-11.25 11.25Q9.187 20.017 12 20.017zM3.984 12q0 1.125.492 2.578t1.195 2.344l11.25-11.25q-2.109-1.688-4.922-1.688-3.281 0-5.648 2.367t-2.367 5.648zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
