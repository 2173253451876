/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop_5_4': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 17.016V6.985H5.015v10.031h13.969zm0-12q.797 0 1.406.586t.609 1.383v10.031q0 .797-.609 1.383t-1.406.586H5.015q-.797 0-1.406-.586T3 17.016V6.985q0-.797.609-1.383t1.406-.586h13.969z"/>'
  }
})
