/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'system_update_tv': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3.516q.797 0 1.406.586t.609 1.383v14.016q0 .797-.609 1.406T21 21.516H3q-.797 0-1.406-.609t-.609-1.406V5.485q0-.797.609-1.383T3 3.516h6v1.969H3v14.016h18V5.485h-6V3.516h6zM12 16.5l-3.984-3.984h3v-9h1.969v9h3z"/>'
  }
})
