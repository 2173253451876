<template>
  <div>
    <Map v-if="!loading" :overlays="list" :zoom="calcZoom" :center="[geoinfo.lng_center, geoinfo.lat_center]" :mapHeight="mapHeight" />
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Map from './Map.vue'

export default {
  name: 'MapView',

  components: {
    Map
  },
  computed: {
    calcZoom() {
      // 주차장 분포에 따라 적당한 zoom level을 계산한다.
      if (!this.geoinfo) return;

      let large = Math.max(this.geoinfo.lat_len, this.geoinfo.lng_len);
      let meter = large * 87000;
      let meter_per_pixel = meter / this.mapHeight;
      if (meter_per_pixel > 4888*8) return 1.6;
      else if (meter_per_pixel > 4888*4) return 2.5;
      else if (meter_per_pixel > 4888*2) return 3.4;
      else if (meter_per_pixel > 4888) return 4.3;
      else if (meter_per_pixel > 2444) return 5.2;
      else if (meter_per_pixel > 1222) return 6.1;
      else if (meter_per_pixel > 610) return 7;
      else if (meter_per_pixel > 152) return 8;
      else if (meter_per_pixel > 76) return 9;
      else if (meter_per_pixel > 38) return 10;
      // else if (meter_per_pixel > 19) return 10;
      else if (meter_per_pixel > 10) return 11;
      else if (meter_per_pixel > 5) return 12;
      else return 13;
    }
  },
  data() {
    return {
      loading: true,
      list: [],
      geoinfo: null,
      mapHeight: 750
    }
  },
  mounted() {
    this.loading = true;
    this.getList();
  },
  methods: {
    getList: function() {
      let query_string = '';
      const params = {
        page: this.pageCurrent,
        ipp: 100
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading = true;
      axios.get(`/api/parking/places/${query_string}`)
        .then(result => {
          this.list = JSON.parse(JSON.stringify(result.data.list));
          this.list.forEach(el => {
            el.space_cnt = el.spaces.length;
            el.occupied = el.spaces.filter(s => !s.is_free).length;
            el.occupancy = parseInt(el.occupied / el.space_cnt * 100);
            el.position = [el.lng, el.lat];
            el.notifications = [];
          });
          this.list = this.list.filter(el => el.space_cnt > 0);
          this.geoinfo = result.data.geoinfo;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading = false;
        })
    }
  }
}
</script>