/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'line_weight': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3.984h18v4.031H3V3.984zm0 9v-3h18v3H3zm0 7.032v-1.031h18v1.031H3zm0-3V15h18v2.016H3z"/>'
  }
})
