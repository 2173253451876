/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fitness_center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.578 14.859l1.406 1.453-2.109 2.109 1.406 1.453-1.406 1.406-1.453-1.406-2.109 2.109-1.453-1.406-1.406 1.406-1.453-1.406 3.563-3.563-8.578-8.578-3.563 3.563-1.406-1.453L3.423 9.14 2.017 7.687l2.109-2.109L2.72 4.125l1.406-1.406 1.453 1.406 2.109-2.109 1.453 1.406 1.406-1.406L12 3.422 8.437 6.985l8.578 8.578L20.578 12l1.406 1.453z"/>'
  }
})
