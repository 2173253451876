/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wb_twighlight': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.563 7.922l1.406-1.406 2.109 2.109-1.406 1.406zm7.453-3.938h1.969v3h-1.969v-3zm-9 12h19.969v4.031H2.016v-4.031zm15.89-1.968H6.093q.375-2.156 2.039-3.586T11.999 9t3.867 1.43 2.039 3.586zm-.937-5.344l2.109-2.109 1.406 1.406-2.109 2.109z"/>'
  }
})
