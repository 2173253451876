/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_legroom_reduced': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 12q0 1.219.891 2.109T8.016 15H12v2.016H8.016q-2.063 0-3.539-1.477T3 12V3h2.016v9zm14.953 7.219q.141.703-.305 1.242T18.516 21h-4.5v-3L15 14.016H9q-1.219 0-2.109-.891T6 11.016V3h6v6h5.016q.797 0 1.383.609t.586 1.406l-1.969 6.984h1.406q.563 0 1.008.352t.539.867z"/>'
  }
})
