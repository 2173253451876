/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'legend_toggle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15H3.985v-2.016h16.031V15zm0 2.016H3.985v1.969h16.031v-1.969zm-5.016-6l5.016-3.563V5.015L15 8.531 9.984 5.015l-6 3.656v2.344l5.953-3.609z"/>'
  }
})
