/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unsubscribe': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 10.5l6.984-3.516V5.015L12 8.484 5.016 5.015v1.969zm1.547 6.516H5.016q-.797 0-1.406-.609t-.609-1.406V5.017q0-.797.609-1.406t1.406-.609h13.969q.797 0 1.406.609T21 5.017v7.172q-1.266-.703-2.484-.703-2.063 0-3.539 1.477T13.5 16.502q0 .094.023.258t.023.258zm6.937 0v-1.031H16.5v1.031h3.984zm-1.968-4.032q1.453 0 2.461 1.031t1.008 2.484-1.008 2.484-2.461 1.031-2.484-1.031-1.031-2.484 1.031-2.484 2.484-1.031z"/>'
  }
})
