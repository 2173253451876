/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smoke_free': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15.938l-2.953-2.953h2.953v2.953zm-2.532-7.219q-1.359 0-2.344-1.008t-.984-2.367T12.14 3t2.344-.984v1.5q-.797 0-1.313.492t-.516 1.242q0 .797.539 1.406t1.289.609h1.547q1.406 0 2.438.914t1.031 2.227V12h-1.5v-1.266q0-.938-.586-1.477t-1.383-.539h-1.547zm4.36-3.844q1.406.656 2.273 2.016t.867 3.047v2.063h-1.5V9.938q0-1.688-1.148-2.883T16.5 5.86v-1.5q.75 0 1.289-.539t.539-1.336h1.5q0 1.406-.984 2.391zM18 12.984h1.5v3H18v-3zm2.484 0h1.5v3h-1.5v-3zM2.016 6l1.219-1.266L20.251 21.75l-1.266 1.266-6.984-7.031H2.017v-3h6.984z"/>'
  }
})
