/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 3.984h3.469V6H5.016V3.984h3.469L9.516 3h4.969zM8.016 9v9.984h7.969V9H8.016zM6 18.984v-12h12v12q0 .797-.609 1.406t-1.406.609H8.016q-.797 0-1.406-.609t-.609-1.406z"/>'
  }
})
