/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hvac': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15.984q.75 0 1.43-.258t1.195-.727h-5.25q.516.469 1.195.727t1.43.258zm-3.422-1.968h6.891q.375-.703.469-1.5H8.063q.094.797.516 1.5zm3.422-6q-.75 0-1.43.258t-1.195.727h5.25q-.516-.469-1.195-.727T12 8.016zM8.578 9.984q-.422.703-.516 1.5h7.875q-.094-.797-.516-1.5H8.577zM18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zM12 18q-1.641 0-3-.82T6.82 15 6 12t.82-3T9 6.82 12 6t3 .82T17.18 9t.82 3-.82 3T15 17.18 12 18z"/>'
  }
})
