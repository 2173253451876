/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_bus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11.016V6H6v5.016h12zm-1.5 6q.656 0 1.078-.445T18 15.516t-.422-1.055-1.078-.445-1.078.445T15 15.516t.422 1.055 1.078.445zm-9 0q.656 0 1.078-.445T9 15.516t-.422-1.055-1.078-.445-1.078.445T6 15.516t.422 1.055 1.078.445zm-3.516-1.032V6q0-2.391 2.063-3.188T12 2.015t5.953.797T20.016 6v9.984q0 1.313-1.031 2.25v1.781q0 .422-.281.703t-.703.281h-.984q-.422 0-.727-.281t-.305-.703v-1.031H8.016v1.031q0 .422-.305.703t-.727.281H6q-.422 0-.703-.281t-.281-.703v-1.781q-1.031-.938-1.031-2.25z"/>'
  }
})
