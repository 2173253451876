/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'workspaces_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 12.984q1.641 0 2.813 1.195t1.172 2.836-1.172 2.813T18 21t-2.813-1.172-1.172-2.813 1.172-2.836T18 12.984zM18 15q-.797 0-1.406.609t-.609 1.406.609 1.383 1.406.586 1.406-.586.609-1.383-.609-1.406T18 15zM12 3q1.641 0 2.813 1.172t1.172 2.813-1.172 2.836T12 11.016 9.187 9.821 8.015 6.985t1.172-2.813T12 3zm0 2.016q-.797 0-1.406.586t-.609 1.383.609 1.406T12 9t1.406-.609.609-1.406-.609-1.383T12 5.016zm-6 7.968q1.641 0 2.813 1.195t1.172 2.836-1.172 2.813T6 21t-2.813-1.172-1.172-2.813 1.172-2.836T6 12.984zM6 15q-.797 0-1.406.609t-.609 1.406.609 1.383T6 18.984t1.406-.586.609-1.383-.609-1.406T6 15z"/>'
  }
})
