/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fireplace': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 20.016V3.985H3.985v16.031h2.016V18h2.25q-1.172-1.5-1.266-3l-.047-.281v-.422l.07-.586.164-.75.281-.844.492-.938.773-.984L9.773 9.14l1.406-1.078 1.805-1.078q-.094.281-.141.773t.469 1.734 1.828 2.133q1.875 1.313 1.875 3.375 0 1.641-1.031 3H18v2.016h2.016zm-8.157-3.047q.938.797 2.086.352t1.289-1.711q.094-.891-.844-1.828t-1.125-1.313q-.234.656-.094 1.266.047.188.141.539t.117.516-.023.539q-.094.469-.539.961t-1.008.68zM2.016 2.016h19.969v19.969H2.016V2.016z"/>'
  }
})
