/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attractions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.844 9.563q-.609-.469-.75-1.195t.234-1.383 1.102-.891 1.383.047q.703-.75 1.945-1.453t2.273-.938q.094-.75.656-1.242T12 2.016t1.313.492.656 1.242q2.391.563 4.172 2.391.281-.141.797-.141 1.172 0 1.734.984.375.656.234 1.383t-.75 1.195q.328 1.031.328 2.438t-.328 2.438q.609.469.75 1.195t-.234 1.383-1.125.891-1.406-.047q-.703.703-1.313 1.125l1.313 3h-1.828l-.984-2.156q-.609.281-1.359.422-.094.75-.656 1.242t-1.313.492-1.313-.492-.656-1.242q-.141-.047-.445-.117t-.539-.141-.422-.164l-.984 2.156H5.767l1.359-3q-.563-.422-1.266-1.125-.703.281-1.43.047t-1.102-.891-.234-1.383.75-1.195Q3.516 13.407 3.516 12t.328-2.438zm1.312.421q-.234.844-.234 1.875 0 1.172.328 2.156 1.078.094 1.547.984.469.844.094 1.781.422.375.891.75l1.5-3.375q-.75-.891-.75-2.156 0-1.406 1.008-2.391t2.461-.984 2.461.984 1.008 2.391q0 1.172-.797 2.203l1.5 3.328q.328-.234.891-.797-.375-.938.188-1.805t1.453-.914q.375-1.125.375-2.156 0-.844-.281-1.875-1.125-.094-1.594-.984-.563-.938 0-1.969-1.406-1.547-3.469-2.063-.563 1.031-1.734 1.031t-1.734-1.031q-1.969.516-3.469 2.063.563 1.031 0 1.969-.469.891-1.641.984zm5.297 8.766Q11.062 18 12 18t1.547.75q.375-.094 1.125-.375l-1.406-3.188q-.563.234-1.266.234-.75 0-1.313-.234l-1.406 3.188q.75.281 1.172.375z"/>'
  }
})
